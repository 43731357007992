import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { message } from 'antd';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import DispatchForm from '../../components/DispatchForm';
import { fetchDispatchTrackingNumber, createDispatch } from '../ducks';

export class TransferDispatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trackingNumberValidation: [],
      preventDuplicate: true,
      errorMessage: ' ',
      trackingNumberRows: [],
      errors: [],
      isSubmitting: false,
    };
    this.packageFormHandler = this.packageFormHandler.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
  }

  deleteHandler(trackingNumber) {
    const { trackingNumberRows } = this.state;
    const filteredTrackingNumber = trackingNumberRows.filter(
      data => data.tracking_number !== trackingNumber,
    );

    this.setState({
      trackingNumberRows: filteredTrackingNumber,
    });

    message.success('Successfully removed tracking number!');
  }

  packageFormHandler(values) {
    const { trackingNumberRows } = this.state;
    const { doFetchDispatchTrackingNumber } = this.props;

    this.setState(prevState => ({
      trackingNumberValidation: [
        ...prevState.trackingNumberValidation,
        { tracking_number: values.tracking_number },
      ],
    }));

    const splitTrackingNumber = values.tracking_number
      ? values.tracking_number.split('\n')
      : '';
    const filteredTrackingNumber = splitTrackingNumber.filter(
      (item, key) => splitTrackingNumber.indexOf(item) >= key && item !== '',
    );
    // eslint-disable-next-line no-unused-vars
    filteredTrackingNumber.forEach((item, key) => {
      const { trackingNumberValidation: validation } = this.state;
      // console.log(validation);
      const existLocal = validation.filter(tn => tn.tracking_number === item);
      const exists = trackingNumberRows.filter(
        tn => tn.tracking_number === item,
      );
      if (existLocal.length > 0) {
        message.error(`Tracking number ${item} is already entered.`);
        this.setState(prevState => ({
          preventDuplicate: true,
          errorMessage: `Tracking number ${item} is already entered.`,
          errors: [
            ...prevState.errors,
            {
              tracking_number: item,
              message: 'Tracking number is already entered.',
            },
          ],
        }));
      } else if (exists.length > 0) {
        message.error(`Tracking number ${item} is already entered.`);
        this.setState(prevState => ({
          preventDuplicate: true,
          errorMessage: `Tracking number ${item} is already entered.`,
          errors: [
            ...prevState.errors,
            {
              tracking_number: item,
              message: 'Tracking number is already entered.',
            },
          ],
        }));
      } else {
        doFetchDispatchTrackingNumber('transfer', item)
          .then((action) => {
            this.setState(prevState => ({
              trackingNumberValidation: [],
              preventDuplicate: true,
              errorMessage: ' ',
              trackingNumberRows: [
                ...prevState.trackingNumberRows,
                {
                  address_city: action.payload.data.address_city,
                  tracking_number: item,
                  consignee_address: action.payload.data.consignee_address,
                  payment_type: action.payload.data.payment_type,
                },
              ],
            }));
            message.success(`Successfully added ${item}`);
          })
          .catch((action) => {
            let errorMessages;
            if (action.error.response.status === 400) {
              errorMessages = action.error.response.data.message;
              this.setState(prevState => ({
                trackingNumberValidation: [],
                preventDuplicate: true,
                errorMessage: action.error.response.data.message,
                errors: [
                  ...prevState.errors,
                  {
                    tracking_number: item,
                    message: action.error.response.data.message,
                  },
                ],
              }));
            } else {
              errorMessages = 'Something went wrong, please try again later.';
            }
            message.error(errorMessages, 3);
          });
      }
    });
  }

  formSubmitHandler(values) {
    const { trackingNumberRows } = this.state;
    const { doCreateDispatch, history } = this.props;
    if (trackingNumberRows.length > 0) {
      const packages = [];

      this.setState({
        isSubmitting: true,
      });
      // eslint-disable-next-line no-unused-vars
      trackingNumberRows.forEach((item, key) => {
        packages.push(item.tracking_number);
      });

      const params = {
        ...values,
        carrier: values.shipment_provider,
        type: 'transfer',
        packages,
      };

      doCreateDispatch(params)
        .then((action) => {
          this.setState({
            isSubmitting: false,
          });
          history.push(`/transfer/${action.payload.data.dh_id}`);
        })
        .catch(() => {
          this.setState({
            isSubmitting: false,
          });

          message.error('Something went wrong, please try again later.');
        });
    } else {
      message.error('No tracking number found!');
    }
  }

  render() {
    const {
      trackingNumberRows,
      errors,
      isSubmitting,
      errorMessage,
      preventDuplicate,
    } = this.state;

    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Transfer',
      },
      {
        breadcrumbName: 'Dispatch',
      },
    ];

    const listColumns = [
      {
        Header: 'Tracking Number',
        accessor: 'tracking_number',
      },
      {
        Header: 'Customer',
        accessor: 'address_city',
      },
      {
        Header: 'Consignee Address',
        accessor: 'consignee_address',
      },
      {
        Header: 'Payment Type',
        accessor: 'payment_type',
      },
    ];

    const selectOptions = ['vehicle', 'courier', 'outsource', 'destination'];

    const requiredFormSubmitFields = [
      'driver_id',
      'driver2_id',
      'helper_id',
      'helper2_id',
      'vehicle_id',
      'remarks',
      'outsource',
      'mode_of_shipment',
      'shipment_provider',
      'destination',
      'driver_rate',
      'driver2_rate',
      'helper_rate',
      'helper2_rate',
    ];

    const requiredPackageFormFields = ['tracking_number'];

    return (
      <div className="TransferDispatch">
        <PageHeader title="Transfer" routes={breadCrumbs} />
        <Container>
          <DispatchForm
            doPreventDuplicate={preventDuplicate}
            formInformation="Transfer Information"
            tablInformation="Transfer Dispatch List"
            selectOptions={selectOptions}
            requiredFormSubmitFields={requiredFormSubmitFields}
            requiredPackageFormFields={requiredPackageFormFields}
            listColumns={listColumns}
            packageFormHandler={this.packageFormHandler}
            formSubmitHandler={this.formSubmitHandler}
            deleteHandler={this.deleteHandler}
            dispatchItems={trackingNumberRows}
            errorItems={errors}
            errorMessageDispatch={errorMessage}
            isSubmitting={isSubmitting}
            hasDestination
            hasOutsource
          />
        </Container>
      </div>
    );
  }
}

TransferDispatch.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchDispatchTrackingNumber: PropTypes.func.isRequired,
  doCreateDispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchDispatchTrackingNumber: fetchDispatchTrackingNumber,
  doCreateDispatch: createDispatch,
};

export default connect(null, mapDispatchToProps)(TransferDispatch);
