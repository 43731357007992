import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
 DatePicker, Col, Form, Button, Input, Icon 
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faSearch } from '@fortawesome/free-solid-svg-icons';

import { waybill } from '../../components/waybill/ducks';
import {
  reportUnremittedList,
  reportCashierUnremittedList,
  reportPickOptions,
  fetchOptions,
} from './ducks';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import ReactTable from '../../components/ServerSideTable';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactSelect from '../../components/Select';
import Spacer from '../../components/Spacer';

export class UnremittedItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasFilter: false,
      handoverDate: [],
      dateConfirmation: false,
      loading: true,
      userData: {
        rows: [],
        pages: 0,
        total: 0,
      },
      optionState: {
        user: [],
        client: [],
        branch_code: [],
        status: [],
      },
      payloadReq: {
        page: 0,
        pageSize: 0,
        filter: [],
        sorted: [],
      },
      tableFilter: [],
      searchFilter: [],
      isFetchingSelect: false,
    };

    this.onFetchDataFunc = this.onFetchDataFunc.bind(this);
  }

  componentDidMount() {
    const { doCallreportPickOptions } = this.props;
    doCallreportPickOptions('user').then(res => this.setState(prevState => ({
        optionState: {
          ...prevState.optionState,
          user: res.payload.data.slice(0, 50),
        },
      })),);
    doCallreportPickOptions('client').then(res => this.setState(prevState => ({
        optionState: {
          ...prevState.optionState,
          client: res.payload.data.slice(0, 50),
        },
      })),);
    doCallreportPickOptions('branch_code').then(res => this.setState(prevState => ({
        optionState: {
          ...prevState.optionState,
          branch_code: res.payload.data.slice(0, 50),
        },
      })),);
    doCallreportPickOptions('destination').then(res => this.setState(prevState => ({
        optionState: {
          ...prevState.optionState,
          destination: res.payload.data.slice(0, 50),
        },
      })),);
  }

  onFetchDataFunc(state, instance) {
    // console.log(state);
    const { searchFilter, hasFilter } = this.state;
    const { unremittedNoFilterList } = this.props;
    const paramsReq = {
      export: false,
      page: state.page,
      pageSize: state.pageSize,
      filter: [...state.filtered, { id: 'unremitted', value: true }],
      sorted: state.sorted,
    };
    if (searchFilter.length) {
      paramsReq.filter = paramsReq.filter.concat(searchFilter);
    }
    this.setState({
      payloadReq: paramsReq,
    });
    if (hasFilter) {
      this.setState({
        loading: true,
      });

      unremittedNoFilterList(paramsReq).then(res => this.setState({
        loading: false,
        userData: {
          rows: res.payload.data.rows,
          pages: res.payload.data.pages,
          total: res.payload.data.total,
        },
        tableFilter: [...state.filtered, { id: 'unremitted', value: true }],
      }));
    }
  }

  getEditAccountHandler(e) {
    const { waybillComponent, history } = this.props;
    waybillComponent(e.tracking_number).then(() => history.push(`/waybill/${e.tracking_number}`),);
    // console.log(e);
  }

  searchFilter(value, name) {
    this.setState({
      isFetchingSelect: true,
    });
    const { doFetchOptions } = this.props;
    doFetchOptions(name, value).then(res => this.setState(prevState => ({
        optionState: {
          ...prevState.optionState,
          [name]: res.payload.data.slice(0, 50),
        },
        isFetchingSelect: false,
      })),);
  }

  submitHandler() {
    // unremittedList('');

    const {
      handoverDate,
      payloadReq,
      dateConfirmation,
      tableFilter,
    } = this.state;
    const { unremittedNoFilterList, form } = this.props;
    const { validateFields } = form;
    validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });
        const paramsReq = {
          export: false,
          page: payloadReq.page,
          pageSize: payloadReq.pageSize,
          filtered: [
            values.user
              ? { id: 'created_by', value: values.user }
              : { id: 'created_by' },
            values.client
              ? { id: 'client', value: values.client }
              : { id: 'client' },
            values.destination
              ? { id: 'location', value: values.destination }
              : { id: 'location' },
            values.branchCode
              ? { id: 'branch_code', value: values.branchCode }
              : { id: 'branch_code' },
            values.tNumber
              ? { id: 'tracking_number', value: values.tNumber }
              : { id: 'tracking_number' },
            dateConfirmation ? handoverDate : {},
          ],
          sorted: payloadReq.sorted,
        };
        if (tableFilter.length) {
          paramsReq.filtered = paramsReq.filtered.concat(tableFilter);
        }
        // unremittedNoFilterList(paramsReq).then(res => this.setState({
        //     loading: false,
        //     userData: {
        //       rows: res.payload.data.rows,
        //       pages: res.payload.data.pages,
        //       total: res.payload.data.total,
        //     },
        //     payloadReq: paramsReq,
        //     searchFilter: [
        //       values.user
        //         ? { id: 'created_by', value: values.user }
        //         : { id: 'created_by' },
        //       values.client
        //         ? { id: 'client', value: values.client }
        //         : { id: 'client' },
        //       values.destination
        //         ? { id: 'location', value: values.destination }
        //         : { id: 'location' },
        //       values.branchCode
        //         ? { id: 'branch_code', value: values.branchCode }
        //         : { id: 'branch_code' },
        //       values.tNumber
        //         ? { id: 'tracking_number', value: values.tNumber }
        //         : { id: 'tracking_number' },
        //       dateConfirmation ? handoverDate : {},
        //     ],
        //   }),);
        this.setState({
          hasFilter: true,
        }, () => this.onFetchDataFunc(paramsReq));
      }
    });
  }

  submitHandlerExport() {
    const { handoverDate, payloadReq, tableFilter } = this.state;
    const { unremittedNoFilterList, form } = this.props;
    const { validateFields } = form;
    validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });
        const paramsReq = {
          export: true,
          page: payloadReq.page,
          pageSize: payloadReq.pageSize,
          filter: [
            values.user
              ? { id: 'created_by', value: values.user }
              : { id: 'created_by' },
            values.client
              ? { id: 'client', value: values.client }
              : { id: 'client' },
            values.destination
              ? { id: 'location', value: values.destination }
              : { id: 'location' },
            values.branchCode
              ? { id: 'branch_code', value: values.branchCode }
              : { id: 'branch_code' },
            values.tNumber
              ? { id: 'tracking_number', value: values.tNumber }
              : { id: 'tracking_number' },
            !handoverDate || handoverDate.value === 'Invalid Date|Invalid Date'
              ? {}
              : { id: handoverDate.id, value: handoverDate.value },
          ],
          sorted: payloadReq.sorted,
        };
        if (tableFilter.length) {
          paramsReq.filter = paramsReq.filter.concat(tableFilter);
        }
        unremittedNoFilterList(paramsReq).then((res) => {
          const newBlob = new Blob([res.payload.data]);
          fileDownload(newBlob, 'Unremitted-items.csv');
          this.setState({
            loading: false,
          });
        });
      }
    });
  }

  dateHandler(dateStrings) {
    const newDateOne = dateStrings[0];
    const newDateTwo = dateStrings[1];
    const formatDateOne = format(newDateOne, 'YYYY-MM-DD HH:mm:ss');
    const formatDateTwo = format(newDateTwo, 'YYYY-MM-DD HH:mm:ss');
    const dateForm = `${formatDateOne}|${formatDateTwo}`;

    this.setState({
      handoverDate:
        dateForm !== 'Invalid Date|Invalid Date'
          ? { id: 'dispatch_date', value: dateForm }
          : { id: 'dispatch_date' },
      dateConfirmation: true,
    });
  }

  render() {
    const {
 userData, loading, optionState, isFetchingSelect, hasFilter,
} = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const breadCrumbs = [
      {
        breadcrumbName: 'Report',
      },
      {
        breadcrumbName: 'Unremitted',
      },
    ];
    return (
      <div className="UnremittedItems">
        <PageHeader title="UNREMITTED ITEMS" routes={breadCrumbs} />
        <Container>
          <Form>
            <Card>
              <Row>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item label="Created By">
                    {getFieldDecorator('user', {
                      rules: [
                        { required: false, message: 'Please insert value' },
                      ],
                    })(
                      <ReactSelect
                        allowClear
                        showSearch
                        loading={isFetchingSelect}
                        filterOption={false}
                        onSearch={e => this.searchFilter(e, 'user')}
                        style={{ width: '100%' }}
                        placeholder="Select User..."
                        options={optionState.user}
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item label="Client">
                    {getFieldDecorator('client', {
                      rules: [
                        { required: false, message: 'Please insert value' },
                      ],
                    })(
                      <ReactSelect
                        allowClear
                        showSearch
                        loading={isFetchingSelect}
                        filterOption={false}
                        onSearch={e => this.searchFilter(e, 'client')}
                        style={{ width: '100%' }}
                        placeholder="Select Client..."
                        options={optionState.client}
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item label="Branch Code">
                    {getFieldDecorator('branchCode', {
                      rules: [
                        { required: false, message: 'Please insert value' },
                      ],
                    })(
                      <ReactSelect
                        allowClear
                        showSearch
                        loading={isFetchingSelect}
                        filterOption={false}
                        onSearch={e => this.searchFilter(e, 'branch_code')}
                        style={{ width: '100%' }}
                        placeholder="Select Branch Code..."
                        options={optionState.branch_code}
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item label="Location">
                    {getFieldDecorator('destination', {
                      rules: [
                        { required: false, message: 'Please insert value' },
                      ],
                    })(
                      <ReactSelect
                        allowClear
                        showSearch
                        loading={isFetchingSelect}
                        filterOption={false}
                        onSearch={e => this.searchFilter(e, 'destination')}
                        style={{ width: '100%' }}
                        placeholder="Select Status..."
                        options={optionState.destination}
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={16}>
                  <Form.Item label="Date Range">
                    <DatePicker.RangePicker
                      style={{ width: '100%' }}
                      onChange={e => this.dateHandler(e)}
                      showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [
                          moment('00:00', 'HH:mm'),
                          moment('00:00', 'HH:mm'),
                        ],
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} lg={24}>
                  <Form.Item label="Tracking Number">
                    {getFieldDecorator('tNumber', {
                      rules: [
                        { required: false, message: 'Please insert value' },
                      ],
                    })(<Input.TextArea />)}
                  </Form.Item>
                </Col>
              </Row>
              <Button onClick={() => this.submitHandler()} type="primary" block>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faSearch} fixedWidth />
                </Icon>
                Filter
              </Button>
            </Card>
            <Spacer />
            <Card
              title={(
<Button
                  onClick={() => this.submitHandlerExport()}
                  type="danger"
                >
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faFileExport} fixedWidth />
                  </Icon>
                  Export
                </Button>
)}
              extra={`Total: ${userData.total
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
            >
              <ReactTable
                data={userData.rows}
                pages={userData.pages}
                columns={[
                  {
                    Header: 'Dispatch ID',
                    accessor: 'dh_id',
                  },
                  {
                    Header: 'Dispatch Date',
                    accessor: 'dispatch_date',
                  },
                  {
                    Header: 'Waybill Number',
                    accessor: 'tracking_number',
                  },
                  {
                    Header: 'Driver',
                    accessor: 'driver',
                  },
                  {
                    Header: 'City',
                    accessor: 'address_city',
                  },
                  {
                    Header: 'Remitted To',
                    accessor: 'remittance_processed_by',
                  },
                  {
                    Header: 'Remitted Amount',
                    accessor: 'remitted_amount',
                  },
                  {
                    Header: 'Remitted',
                    accessor: 'remitted',
                  },
                  {
                    Header: 'Options',
                    sortable: false,
                    filterable: false,
                    Cell: row => (
                      <center>
                        <Button
                          type="link"
                          onClick={() => this.getEditAccountHandler(row.original)
                          }
                        >
                          <Icon type="eye" />
                          View
                        </Button>
                      </center>
                    ),
                  },
                ]}
                loadingText={!hasFilter ? 'Use the filters to search for Unremitted Items' : 'Fetching Unremitted Items. . .'}
                loading={loading}
                onFetchData={this.onFetchDataFunc}
              />
            </Card>
          </Form>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  reportListTable: state.report.data,
  options: state.report.options,
});

const mapDispatchToProps = {
  unremittedList: reportUnremittedList,
  unremittedNoFilterList: reportCashierUnremittedList,
  waybillComponent: waybill,
  doCallreportPickOptions: reportPickOptions,
  doFetchOptions: fetchOptions,
};

UnremittedItems.propTypes = {
  unremittedNoFilterList: PropTypes.func.isRequired,
  waybillComponent: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doCallreportPickOptions: PropTypes.func.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchOptions: PropTypes.func.isRequired,
};

const WrappedUnremittedItems = Form.create({ name: 'Register' })(
  UnremittedItems,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedUnremittedItems);
