import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  message,
  Statistic,
  Slider,
} from 'antd';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Container from '../../components/Container';
import PageHeader from '../../components/PageHeader';
import ReactTable from '../../components/ReactTable';

import { updateSingleDimweightDimensions } from './ducks';
import Card from '../../components/Card';
import Spacer from '../../components/Spacer';
import Row from '../../components/Row';

class DimweightUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorList: [],
      successList: [],
      resultIsError: false,
      updateResult: null,
      sizeState: 50,
    };

    this.formHandler = this.formHandler.bind(this);
  }

  formHandler(e) {
    e.preventDefault();

    const { doUpdateSingleDimweightDimensions, form } = this.props;

    const { validateFields, setFieldsValue } = form;

    validateFields(
      (err, values) => {
        if (!err) {
          const data = {
            tracking_number: values.tracking_number,
          };

          doUpdateSingleDimweightDimensions(data).then((action) => {
            message.success(`Successfully updated ${action.payload.data.tracking_number}`);
            this.setState(prevState => ({
              successList: [
                ...prevState.successList,
                {
                  tracking_number: action.payload.data.tracking_number,
                  status: action.payload.data.status,
                  previous_LxWxHxWg: action.payload.data.prev,
                  new_LxWxHxWg: action.payload.data.new,
                },
              ],
              updateResult: `${action.payload.data.tracking_number} Current Status: ( ${action.payload.data.status} ) New Dimensions: ( ${action.payload.data.new} )`,
              resultIsError: false,
            }));

            setFieldsValue({
              tracking_number: '',
            });
          }).catch((action) => {
            if (action.error.response.status === 422) {
              message.error(action.error.response.data.message);
              this.setState(prevState => ({
                errorList: [
                  ...prevState.errorList,
                  {
                    tracking_number: action.error.response.data.tracking_number,
                    message: action.error.response.data.message,
                  },
                ],
                updateResult: `${action.error.response.data.tracking_number} ${action.error.response.data.message}`,
                resultIsError: true,
              }));
            }
          });
        }
      },
    );
  }

  render() {
    const {
      successList, errorList, resultIsError, updateResult, sizeState,
    } = this.state;

    const { form } = this.props;

    const { getFieldDecorator } = form;

    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Tools',
      },
    ];

    return (
      <div className="DimweightUpdate">
        <PageHeader title="Dimweight Update" routes={breadCrumbs} />
        <Spacer />
        <Container>
          <Card title="Tracking Number Form">
            <Form onSubmit={this.formHandler}>
              <Row>
                <Col>
                  <Form.Item label="Tracking Number">
                    {getFieldDecorator('tracking_number', {
                      rules: [
                        {
                          required: true,
                          message: 'Tracking number cannot be blank.',
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter Tracking Number"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    htmlType="submit"
                    type="primary"
                    block
                  >
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={faEdit} fixedWidth />
                    </Icon>
                      Update Dimensions
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
          {
              updateResult !== null ? (
                <React.Fragment>
                  <Spacer />
                  <Card>
                    <Row>
                      <Col
                        style={{
                          backgroundColor:
                            resultIsError ? 'red' : null,
                        }}
                        xs={24}
                        sm={24}
                        lg={50 > 30 ? 24 : 8}
                      >
                        <Statistic
                          style={{ textAlign: 'center' }}
                          value={`${updateResult}`}
                          valueStyle={{
                            fontSize: `${sizeState}0%`,
                            textAlign: 'center',
                            color: resultIsError ? 'white' : 'black',
                          }}
                        />
                      </Col>
                    </Row>
                    <Spacer />
                    <Row>
                      <Col
                        xs={12}
                        sm={12}
                      >
                        <Statistic
                          style={{ textAlign: 'center' }}
                          title="Successful Updates"
                          value={successList.length}
                          valueStyle={{
                            fontSize: `${sizeState}0%`,
                            textAlign: 'center',
                            color: 'green',
                          }}
                        />
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                      >
                        <Statistic
                          style={{ textAlign: 'center' }}
                          title="Failed Updates"
                          value={errorList.length}
                          valueStyle={{
                            fontSize: `${sizeState}0%`,
                            textAlign: 'center',
                            color: 'red',
                          }}
                        />
                      </Col>
                    </Row>
                    <Slider
                      defaultValue={50}
                      onChange={e => this.setState({ sizeState: e })}
                    />
                  </Card>
                </React.Fragment>

              ) : null
            }
          {successList.length > 0 ? (
            <React.Fragment>
              <Spacer />
              <Card
                title={`Total Success List: ${successList.length}`}
                extra={(
                  <CSVLink
                    data={successList}
                    target="_blank"
                    filename="dimweight_update.csv"
                    onClick={this.downloadUpdate}
                  >
                    <Button type="primary">
                      <Icon type="download" />
                    Export
                    </Button>
                  </CSVLink>
                )}
              >
                <ReactTable
                  data={successList}
                  columns={[
                    {
                      Header: 'Tracking Number',
                      accessor: 'tracking_number',
                    },
                    {
                      Header: 'Current Status',
                      accessor: 'status',
                    },
                    {
                      Header: 'Previous Dimensions (L x W x H Wt)',
                      accessor: 'previous_LxWxHxWg',
                    },
                    {
                      Header: 'New Dimensions (L x W x H Wt)',
                      accessor: 'new_LxWxHxWg',
                    },
                  ]}
                />
              </Card>
            </React.Fragment>
          ) : null}
          {errorList.length > 0 ? (
            <React.Fragment>
              <Spacer />
              <Card title={`Total Error List: ${errorList.length}`}>
                <ReactTable
                  data={errorList}
                  columns={[
                    {
                      Header: 'Tracking Number',
                      accessor: 'tracking_number',
                    },
                    {
                      Header: 'Error Message',
                      accessor: 'message',
                    },
                  ]}
                />
              </Card>
            </React.Fragment>
          ) : null}

        </Container>
      </div>

    );
  }
}

DimweightUpdate.propTypes = {
  doUpdateSingleDimweightDimensions: PropTypes.func.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapDispatchToProps = {
  doUpdateSingleDimweightDimensions: updateSingleDimweightDimensions,
};

const WrappedDimweightUpdate = Form.create({ name: 'DimweightUpdate' })(DimweightUpdate);

export default connect(null, mapDispatchToProps)(WrappedDimweightUpdate);
