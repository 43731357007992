import React, { Component } from 'react';
import { connect } from 'react-redux';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExport,
  faSearch,
  faEdit,
  faEye,
  faBan,
} from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import {
 Form, Button, DatePicker, Col, Icon, message, Popconfirm 
} from 'antd';
import Card from './Card';
import Row from './Row';
import Spacer from './Spacer';
import ServerSideTable from './ServerSideTable';
import {
  fetchPickupList,
  fetchDispatchList,
  fetchTransmittalList,
  deleteDispatch,
} from '../pages/ducks';

export class DispatchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dispatchList: {
        rows: [],
        total: 0,
        pages: 0,
      },
      dateFilter: [],
      isFetching: false,
      resTablePayload: {},
      isDisabledButton: false,
    };

    this.onFetchData = this.onFetchData.bind(this);
    this.datePickerFilterHandler = this.datePickerFilterHandler.bind(this);
    this.applyDateFilterHandler = this.applyDateFilterHandler.bind(this);
    this.cancelHandler = this.cancelHandler.bind(this);
    this.inArray = this.inArray.bind(this);
  }

  // eslint-disable-next-line no-unused-vars
  onFetchData(state, instance) {
    const { dateFilter } = this.state;
    const {
      doFetchPickupList,
      doFetchDispatchList,
      doFetchTransmittalList,
      isCustomerMerchantPickUp,
      isTransmittal,
      typeDispatch,
    } = this.props;
    const dispatchType = [{ id: 'type', value: typeDispatch }];
    const params = {
      export: false,
      page: state.page,
      pageSize: state.pageSize,
      filter: [...state.filtered, ...dateFilter, ...dispatchType],
      sorted: state.sorted,
    };

    this.setState({
      isFetching: true,
      resTablePayload: params,
    });

    if (isCustomerMerchantPickUp) {
      doFetchPickupList(params).then((action) => {
        this.setState({
          dispatchList: {
            rows: action.payload.data.rows,
            total: action.payload.data.total,
            pages: action.payload.data.pages,
          },
          isFetching: false,
        });
      });
    } else if (isTransmittal) {
      doFetchTransmittalList(params).then((action) => {
        this.setState({
          dispatchList: {
            rows: action.payload.data.rows,
            total: action.payload.data.total,
            pages: action.payload.data.pages,
          },
          isFetching: false,
        });
      });
    } else {
      doFetchDispatchList(params).then((action) => {
        this.setState({
          dispatchList: {
            rows: action.payload.data.rows,
            total: action.payload.data.total,
            pages: action.payload.data.pages,
          },
          isFetching: false,
        });
      });
    }
  }

  inArray(userId, ids) {
    var id = ids.length;
    for(var i = 0; i < id; i++) {
      if(ids[i] == userId) return true;
    }
    return false;
  }

  exportFileList() {
    const { dateFilter, resTablePayload } = this.state;
    const { doFetchDispatchList, dispatchExportName } = this.props;

    this.setState({
      isDisabledButton: true,
      isFetching: true,
    });

    const params = {
      export: true,
      page: resTablePayload.page,
      pageSize: resTablePayload.pageSize,
      filter: [...resTablePayload.filter, ...dateFilter],
      sorted: resTablePayload.sorted,
    };

    doFetchDispatchList(params).then((res) => {
      const newBlob = new Blob([res.payload.data]);
      fileDownload(newBlob, `${dispatchExportName}-Dispatch.csv`);
      this.setState({
        isDisabledButton: false,
        isFetching: false,
      });
    });
  }

  datePickerFilterHandler(dateStrings) {
    const newDateOne = dateStrings[0];
    const newDateTwo = dateStrings[1];
    const formatDateOne = format(newDateOne, 'YYYY-MM-DD HH:mm:ss');
    const formatDateTwo = format(newDateTwo, 'YYYY-MM-DD HH:mm:ss');
    this.setState({
      dateFilter: [
        formatDateOne !== 'Invalid Date'
          ? { id: 'dateFrom', value: formatDateOne }
          : { id: 'dateFrom' },
        formatDateTwo !== 'Invalid Date'
          ? { id: 'dateTo', value: formatDateTwo }
          : { id: 'dateTo' },
      ],
    });
  }

  applyDateFilterHandler() {
    const { dateFilter } = this.state;

    const params = {
      page: 0,
      pageSize: 10,
      filtered: [],
      sorted: [],
    };

    if (dateFilter.length === 2) {
      this.setState(() => {
        this.onFetchData(params);
      });
    } else {
      message.warning('Please select date range!');
    }
  }

  cancelHandler(dispatchId) {
    const { resTablePayload } = this.state;
    const { doDeleteDispatch } = this.props;
    const params = {
      export: false,
      page: resTablePayload.page,
      pageSize: resTablePayload.pageSize,
      filtered: resTablePayload.filter,
      sorted: resTablePayload.sorted,
    };
    doDeleteDispatch(dispatchId)
      .then(() => {
        this.onFetchData(params);
        message.success('Successfully deleted dispatch!');
      })
      .catch(() => {
        message.error('Something went wrong, please try again later.');
      });
  }

  render() {
    const { dispatchList, isFetching, isDisabledButton } = this.state;
    const {
      fetchText,
      isCustomerMerchantPickUp,
      urlEndpoint,
      isTransmittal,
      user,
    } = this.props;
    const { RangePicker } = DatePicker;

    const { hasExport, dispatchListColumns } = this.props;
    return (
      <div className="DispatchList">
        <Card title="List Filter">
          <Row>
            <Col>
              <Form.Item label="Date Range">
                <RangePicker
                  style={{ width: '100%' }}
                  onChange={e => this.datePickerFilterHandler(e)}
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment('00:00', 'HH:mm'),
                      moment('00:00', 'HH:mm'),
                    ],
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                block
                onClick={this.applyDateFilterHandler}
              >
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faSearch} fixedWidth />
                </Icon>
                Search
              </Button>
            </Col>
          </Row>
        </Card>
        <Spacer />
        <Card
          title={
            hasExport ? (
              <React.Fragment>
                <Row>
                  <Col>
                    <Button
                      onClick={() => this.exportFileList()}
                      disabled={isDisabledButton}
                      type="primary"
                      htmlType="submit"
                    >
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faFileExport} fixedWidth />
                      </Icon>
                      Export
                    </Button>
                  </Col>
                </Row>
              </React.Fragment>
            ) : null
          }
          extra={`Total: ${dispatchList.total
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
        >
          <ServerSideTable
            data={dispatchList.rows}
            pages={dispatchList.pages}
            columns={[
              ...dispatchListColumns,
              {
                Header: 'Options',
                sortable: false,
                filterable: false,
                width: isTransmittal ? 100 : 245,
                Cell: data => (
                  <center>
                    <React.Fragment>
                      {isTransmittal ? (
                        <Row>
                          <Col>
                            <NavLink to={`${urlEndpoint}/${data.row.id}`}>
                              <Button type="link">
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faEye} fixedWidth />
                                </Icon>
                                View
                              </Button>
                            </NavLink>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col span={isCustomerMerchantPickUp ? 12 : 6}>
                            <NavLink
                              to={
                                isCustomerMerchantPickUp
                                  ? `${urlEndpoint}/${data.row.ph_id}`
                                  : `${urlEndpoint}/${data.row.dh_id}`
                              }
                            >
                              <Button type="link">
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faEye} fixedWidth />
                                </Icon>
                                View
                              </Button>
                            </NavLink>
                          </Col>
                          {data.row.status === 'new'
                          || isCustomerMerchantPickUp ? (
                            <Col
                              span={
                                data.row.status === 'dispatched'
                                || data.row.status === 'canceled'
                                || isCustomerMerchantPickUp
                                  ? 12
                                  : 6
                              }
                            >
                              <NavLink
                                to={
                                  isCustomerMerchantPickUp
                                    ? `${urlEndpoint}/edit/${data.row.ph_id}`
                                    : `${urlEndpoint}/edit/${data.row.dh_id}`
                                }
                              >
                                <Button type="link">
                                  <Icon viewBox="0 0 1024 1024">
                                    <FontAwesomeIcon icon={faEdit} fixedWidth />
                                  </Icon>
                                  Edit
                                </Button>
                              </NavLink>
                            </Col>
                            ) : null}

                          {data.row.status !== 'canceled'
                          && !isCustomerMerchantPickUp
                          && this.inArray(user.role_id, [1, 18, 23]) ? (
                            <Col span={12}>
                              <Popconfirm
                                placement="leftBottom"
                                title={`Are you sure you want to delete ${
                                  isCustomerMerchantPickUp
                                    ? data.row.ph_id
                                    : data.row.dh_id
                                } from the list?`}
                                okText="Yes"
                                onConfirm={() => this.cancelHandler(
                                    isCustomerMerchantPickUp
                                      ? data.row.ph_id
                                      : data.row.dh_id,
                                  )
                                }
                                cancelText="No"
                              >
                                <Button type="link" block>
                                  <Icon viewBox="0 0 1024 1024">
                                    <FontAwesomeIcon icon={faBan} fixedWidth />
                                  </Icon>
                                  Cancel
                                </Button>
                              </Popconfirm>
                            </Col>
                            ) : null}
                        </Row>
                      )}
                    </React.Fragment>
                  </center>
                ),
              },
            ]}
            loading={isFetching}
            loadingText={fetchText}
            onFetchData={this.onFetchData}
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

DispatchList.defaultProps = {
  isCustomerMerchantPickUp: false,
  isTransmittal: false,
};

DispatchList.propTypes = {
  dispatchExportName: PropTypes.string.isRequired,
  hasExport: PropTypes.bool.isRequired,
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isCustomerMerchantPickUp: PropTypes.bool,
  isTransmittal: PropTypes.bool,
  typeDispatch: PropTypes.string.isRequired,
  fetchText: PropTypes.string.isRequired,
  urlEndpoint: PropTypes.string.isRequired,
  dispatchListColumns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  doFetchPickupList: PropTypes.func.isRequired,
  doFetchDispatchList: PropTypes.func.isRequired,
  doFetchTransmittalList: PropTypes.func.isRequired,
  doDeleteDispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchPickupList: fetchPickupList,
  doFetchDispatchList: fetchDispatchList,
  doFetchTransmittalList: fetchTransmittalList,
  doDeleteDispatch: deleteDispatch,
};

export default connect(mapStateToProps, mapDispatchToProps)(DispatchList);
