import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Descriptions,
  Button, Icon, Spin,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import DispatchView from '../../components/DispatchView';
import { fetchTransmittalId } from '../ducks';

const styleTableHeader = width => ({
  borderCollapse: 'collapse',
  border: '1px solid black',
  fontSize: '12px',
  padding: '5px',
  width,
});

export class MerchantPickupTransmittalView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dispatchDetails: [
        {
          packages: [{'client_id': 0}],
          isMountingModule: true,
        },
      ],
    };
  }

  componentDidMount() {
    const { doFetchMerchantPickupDetails, match } = this.props;

    doFetchMerchantPickupDetails(match.params.id).then((action) => {
      this.setState({
        dispatchDetails: action.payload.data,
        isMountingModule: false,
      });
    });
  }

  printDispatch() {
    const content = document.getElementById('divcontents');
    const pri = document.getElementById('ifmcontentstoprint').contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  render() {
    const { dispatchDetails, isMountingModule } = this.state;
    const { match } = this.props;

    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Transmittal',
      },
      {
        breadcrumbName: 'List',
      },
      {
        breadcrumbName: `View #${match.params.id}`,
      },
    ];

    const dispatchPackageListColumns = [
      {
        id: 'tracking_number',
        Header: 'Tracking Number',
        filterable: false,
        sortable: false,
        accessor: data => data.tracking_number,
      },
      {
        id: 'consignee_name',
        Header: 'Consignee Name',
        filterable: false,
        sortable: false,
        accessor: data => data.consignee_name,
      },
      {
        id: 'consignee_address',
        Header: 'Consignee Address',
        filterable: false,
        sortable: false,
        accessor: data => data.consignee_address,
      },
      {
        id: 'payment_type',
        Header: 'Payment Type',
        filterable: false,
        sortable: false,
        accessor: data => data.payment_type,
      },
      {
        id: 'statud',
        Header: 'Status',
        filterable: false,
        sortable: false,
        accessor: data => data.status,
      },
    ];
    return (
      <div className="MerchantPickupTransmittalView">
        <div style={{ display: 'none' }} id="divcontents">
          <center>
            <strong style={{ fontSize: '14px' }}>TRANSMITTAL SHEET</strong>
          </center>
          <div style={{ marginBottom: '20px' }}>
            <img
              style={{ float: 'left' }}
              src="http://portal.xdelogistics.com/public/img/xde-logo.png"
              alt="xdeLogo"
            />
            <div
              style={{
                float: 'left',
                paddingTop: '15px',
                marginLeft: '20px',
                width: '570px',
              }}
            >
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>XDE Logistics</strong>
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                Ximex Building, #9B , Industrial Complex, Bagumbayan, Bicutan, 1st Ave, Taguig, 1631
                Metro Manila
              </p>
            </div>
          </div>
          <div
            style={{
              content: '',
              display: 'inline-block',
              verticalAlign: 'middle',
              width: '100%',
              marginTop: '20px',
            }}
          >
            <div
              style={{
                width: '33%',
                display: 'inline-block',
                verticalAlign: 'middle',
                textAlign: 'middle',
                float: 'left',
              }}
            >
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Transmittal ID: </strong>
                {match.params.id}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>From: </strong>
                {dispatchDetails.transmittal ? dispatchDetails.transmittal.from : ''}
              </p>
            </div>
            <div
              style={{
                width: '33%',
                display: 'inline-block',
                verticalAlign: 'middle',
                textAlign: 'middle',
              }}
            >
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Created at: </strong>
                {dispatchDetails.transmittal ? dispatchDetails.transmittal.created_at : ''}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>To: </strong>
                {dispatchDetails.transmittal ? dispatchDetails.transmittal.to : ''}
              </p>
            </div>
            <div
              style={{
                width: '33%',
                display: 'inline-block',
                verticalAlign: 'middle',
                textAlign: 'middle',
              }}
            >
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Created by: </strong>
                {dispatchDetails.transmittal ? dispatchDetails.transmittal.created_by : ''}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Via: </strong>
                {dispatchDetails.transmittal ? dispatchDetails.transmittal.via : ''}
              </p>
            </div>
          </div>
          <div
            style={{
              content: '',
              display: 'inline-block',
              verticalAlign: 'middle',
              width: '33%',
              marginBottom: '15px',
            }}
          >
            <p style={{ fontSize: '12px', margin: '5px' }}>
              <strong>Remarks: </strong>
              {dispatchDetails.transmittal ? dispatchDetails.transmittal.remarks : ''}
            </p>
          </div>
          <div
            style={{
              content: '',
              display: 'inline-block',
              verticalAlign: 'middle',
              width: '33%',
              marginBottom: '15px',
            }}
          >
            <p style={{ fontSize: '12px', margin: '5px' }}>
              <strong>Remittance: </strong>
              {dispatchDetails.packages
              ? dispatchDetails.packages.length !== 0
                ? dispatchDetails.packages
                  .map(res => parseFloat(res.total_price))
                  .filter(resFilter => resFilter).length === 0
                  ? 0
                  : dispatchDetails.packages
                    .map(res => parseFloat(res.total_price))
                    .filter(resFilter => resFilter)
                    .reduce((a, b) => a + b)
                    .toFixed(2)
                : 0
              : 0}
            </p>
          </div>
          <div style={{ marginBottom: '30px' }}>
            <table style={{ borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={styleTableHeader('15%')}>Request Date</th>
                  <th style={styleTableHeader('15%')}>Order #</th>
                  <th style={styleTableHeader('15%')}>Waybill #</th>
                  <th style={styleTableHeader('45%')}>{ dispatchDetails.packages ? dispatchDetails.packages[0].client_id == 1025 ? 'Collection Type' :  'Item Description' : 'Item Description'}</th>
                  <th style={styleTableHeader('10%')}>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {
                  dispatchDetails.packages
                    ? dispatchDetails.packages.map((val, key) => (
                      <tr key={key}>
                        <td style={styleTableHeader('15%')}>{val.created_at}</td>
                        <td style={styleTableHeader('15%')}>{val.package_id}</td>
                        <td style={styleTableHeader('15%')}>{val.tracking_number}</td>
                        <td style={styleTableHeader('45%')}>{val.client_id == 1025 ? 'Payment Type: '+val.payment_type+" FM Collection LM Collection" : val.description}</td>
                        <td style={styleTableHeader('10%')}>{' '}</td>
                      </tr>
                    )) : null
                }
              </tbody>
            </table>
          </div>
          <p style={{ fontSize: '12px', margin: '5px' }}>
            Total Transaction:
            {' '}
            <span style={{ color: 'red' }}>{dispatchDetails.packages ? dispatchDetails.packages.length : 0}</span>
          </p>
          <div style={{ marginTop: '40px' }}>
            <strong style={{ fontSize: '12px', float: 'left' }}>
              Signature of Authorized Personnel
            </strong>
            <strong style={{ fontSize: '12px', float: 'left', marginLeft: '250px' }}>
              Signature of XDE Personnel
            </strong>
          </div>
        </div>
        <iframe
          title="myFrame"
          id="ifmcontentstoprint"
          style={{ height: '0px', width: '0px', position: 'absolute' }}
        />
        <Spin spinning={isMountingModule} size="small" tip="Fetching transmittal details. . .">
          <PageHeader title={`Transmittal #${match.params.id}`} routes={breadCrumbs} />
          <Container>
            <DispatchView
              dispatchTitle="Transmittal Information"
              dispatchItems={dispatchDetails.packages}
              dispatchDetails={(
                <React.Fragment>
                  <Descriptions bordered size="small">
                    <Descriptions.Item label="Transmittal Number: ">{dispatchDetails.transmittal ? dispatchDetails.transmittal.id : ''}</Descriptions.Item>
                    <Descriptions.Item label="Via: " span={2}>{dispatchDetails.transmittal ? dispatchDetails.transmittal.via : ''}</Descriptions.Item>
                    <Descriptions.Item label="From: ">{dispatchDetails.transmittal ? dispatchDetails.transmittal.from : ''}</Descriptions.Item>
                    <Descriptions.Item label="To: " span={2}>{dispatchDetails.transmittal ? dispatchDetails.transmittal.to : ''}</Descriptions.Item>
                    <Descriptions.Item label="Remarks: ">{dispatchDetails.transmittal ? dispatchDetails.transmittal.remarks : ''}</Descriptions.Item>
                    <Descriptions.Item label="Remittance: "></Descriptions.Item>
                  </Descriptions>
                </React.Fragment>
              )}
              dispatchPackageListColumns={dispatchPackageListColumns}
              printTypes={(
                <React.Fragment>
                  <Button onClick={() => this.printDispatch()} type="primary" block>
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={faPrint} fixedWidth />
                    </Icon>
                      Print
                  </Button>
                </React.Fragment>
              )}
            />
          </Container>
        </Spin>
      </div>
    );
  }
}

MerchantPickupTransmittalView.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchMerchantPickupDetails: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchMerchantPickupDetails: fetchTransmittalId,
};

export default connect(null, mapDispatchToProps)(MerchantPickupTransmittalView);
