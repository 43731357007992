import React, { Component } from 'react';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import DispatchList from '../../components/DispatchList';

class CustomerPickupList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Customer Pickup',
      },
      {
        breadcrumbName: 'List',
      },
    ];

    const dispatchListColumns = [
      {
        Header: 'Dispatch ID',
        accessor: 'ph_id',
      },
      {
        Header: 'Courier',
        accessor: 'driver',
      },
      {
        Header: 'Merchant',
        accessor: 'merchant',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
      },
    ];

    const customFilter = {
      id: 'type',
      value: 'customer',
    };

    return (
      <div className="CustomerPickupList">
        <PageHeader title="Customer Pickup" routes={breadCrumbs} />
        <Container>
          <DispatchList
            hasExport={false}
            dispatchListColumns={dispatchListColumns}
            customFilter={customFilter}
            fetchText="Fetching Customer Pickup List. . ."
            urlEndpoint="/customer-pickup"
            typeDispatch="customer"
            isCustomerMerchantPickUp
          />
        </Container>
      </div>
    );
  }
}

export default CustomerPickupList;
