import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faFile, faEye, faTrash, faEdit, faArrowRight, faList,
} from '@fortawesome/free-solid-svg-icons';
import {
  Button, Icon, Col, Form, Spin, Input, Tag, Modal, Descriptions, Checkbox, DatePicker, message,
} from 'antd';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import Row from '../../components/Row';
import Card from '../../components/Card';
import Spacer from '../../components/Spacer';
import Select from '../../components/Select';
import ReactTable from '../../components/ReactTable';
import {
  itemTrackerByReference, itemTrackerByTrackingNumber, itemTrackerTaggingEndpoints, itemTrackerByBarcodeScan,
} from './ducks';
import { fetchOptions, fetchOptionsLike } from '../ducks';

export class TrackItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataConfirmToTag: [
        {
          itemID: null,
          editQuantity: null,
        },
      ],
      dataFilter: [],
      // dataFilterTNSuccess: [],
      // dataFilterRNSuccess: [],
      isVisibleeditQuantity: false,
      editQuantityView: null,
      dataFailedFilter: [],
      isVisibleConfirmModal: false,
      isLoading: false,
      isVisible: false,
      isVisibleTagging: false,
      dataforView: null,
      spinner: false,
      setStatus: null,
      tagItemsGetId: {},
      itemsForTag: [],
      sucesstagTable: [],
      handoverDate: null,
      finalizeTag: false,
      finalizeData: null,
      finalizeFailed: [],
      options: {
        destination: [],
        reason: [],
      },
      isFetching: false,
      enteredQuantity: [],
      selectAllChecker: null,
      barcodeChecker: [],
      isVisibleScannedItems: false,
      scannedItems: [],
      itemsIDToView: null,
    };

    this.formSubmit = this.formSubmit.bind(this);
  }

  componentDidMount() {
    const { doFetchOptions } = this.props;
    const optionList = ['destination', 'reason'];

    optionList.forEach((key) => {
      doFetchOptions(key).then((action) => {
        this.setState(prevState => ({
          options: {
            ...prevState.options,
            [key]: action.payload.data.slice(0, 50),
          },
        }));
      });
    });
    // console.log('test');
  }

  setAddTag(e, itemChecked) {
    // console.log(e);
    const { itemsForTag } = this.state;

    if (e.target.checked) {
      this.setState(prevState => ({
        itemsForTag: [
          ...prevState.itemsForTag,
          {
            ...itemChecked,
            checker: e.target.checked,
          },
        ],
      }));
    } else {
      const itemTag = itemsForTag.filter(res => res.item_id !== itemChecked.item_id);

      this.setState({
        itemsForTag: itemTag,
      });
    }
  }

  searchOption(type, e) {
    const { doFetchOptionsLike } = this.props;

    this.setState({
      isFetching: true,
    });

    doFetchOptionsLike(type, e).then((action) => {
      this.setState(prevState => ({
        options: {
          ...prevState.options,
          [type]: action.payload.data.slice(0, 50),
        },
        isFetching: false,
      }));
    });
  }

  removeTag(removeItems) {
    const { sucesstagTable, itemsForTag } = this.state;

    const removeSuccessItems = sucesstagTable.filter(res => res.item_id !== removeItems);
    const removeSuccessItemsTag = itemsForTag.filter(res => res.item_id !== removeItems);
    this.setState({
      sucesstagTable: removeSuccessItems,
      itemsForTag: removeSuccessItemsTag,
    });
  }

  checkAll(e) {
    const { dataFilter } = this.state;
    if (e === 'check') {
      dataFilter.forEach((item) => {
        this.setState(prevState => ({
          itemsForTag: [
            ...prevState.itemsForTag,
            {
              ...item,
              checker: true,
            },
          ],
          selectAllChecker: true,
        }));
      });
    } else {
      this.setState({
        itemsForTag: [],
        selectAllChecker: false,
      });
    }
  }

  successTag() {
    const { itemsForTag, sucesstagTable } = this.state;
    // console.log(data)
    const removeDuplicates = Array.from(new Set(itemsForTag.map(a => a.item_id))).map(id => itemsForTag.find(a => a.item_id === id));
    // console.log(removeDuplicates);
    removeDuplicates.forEach((item) => {
      const filteredItems = sucesstagTable.filter(res => res.item_id === item.item_id);
      if (!filteredItems.length > 0) {
        this.setState(prevState => ({
          sucesstagTable: [
            ...prevState.sucesstagTable,
            item,
          ],
        }));
      }
    });

    this.setState({
      dataFilter: [],
      isVisibleConfirmModal: false,
      selectAllChecker: null,
    });
  }

  formSubmit() {
    const {
      form, doItemTrackerByTrackingNumber, doItemTrackerByReference, doItemTrackerByBarcodeScan,
    } = this.props;
    const { validateFields, setFieldsValue } = form;

    validateFields(['trackingnumber', 'referencenumber', 'status', 'barcode'], (err, values) => {
      if (values.barcode) {
        setFieldsValue({
          trackingnumber: '',
          referencenumber: '',
        });
      }

      if (!err) {
        this.setState({
          isLoading: true,
        });

        if (values.barcode ? undefined : values.trackingnumber) {
          const trackingNumber = values.trackingnumber;
          const splitTrackingNumber = trackingNumber.split('\n');

          const filteredTrackingNumber = splitTrackingNumber.filter(
            (item, key) => splitTrackingNumber.indexOf(item) >= key && item !== '',
          );

          filteredTrackingNumber.forEach((item) => {
            doItemTrackerByTrackingNumber(item, values.status).then((res) => {
              const { dataFilter: dataFilterTNSuccess, dataFailedFilter: dataFailedTNFilter } = this.state;

              /* eslint-disable */
              const itemIDFilter = new Set(dataFilterTNSuccess.map(({ item_id }) => item_id));
              const dataFilter = [
                ...dataFilterTNSuccess,
                ...res.payload.data.success.filter(({ item_id }) => !itemIDFilter.has(item_id)),
              ];

              const failedObject = res.payload.data.failed;
              const failedArray = Object.keys(failedObject).map(i => failedObject[i]);

              const dataFailedFilter = [...dataFailedTNFilter, ...failedArray];

              this.setState({
                dataFilter,
                dataFailedFilter,
                isLoading: false,
                setStatus: values.status,
              });
            });
          });

          this.setState({
            isVisibleConfirmModal: true,
          });
        }

        if (values.barcode ? undefined : values.referencenumber) {
          const referenceNumber = values.referencenumber;
          const splitReferenceNumber = referenceNumber.split('\n');

          const filteredReferenceNumber = splitReferenceNumber.filter(
            (item, key) => splitReferenceNumber.indexOf(item) >= key && item !== '',
          );

          filteredReferenceNumber.forEach((item) => {
            doItemTrackerByReference(item, values.status).then((res) => {
              const { dataFilter: dataFilterRNSuccess, dataFailedFilter: dataFailedRNFilter } = this.state;
              /* eslint-disable */
              const itemIDFilter = new Set(dataFilterRNSuccess.map(({ item_id }) => item_id));
              const dataFilter = [
                ...dataFilterRNSuccess,
                ...res.payload.data.success.filter(({ item_id }) => !itemIDFilter.has(item_id)),
              ];

              const failedObject = res.payload.data.failed;
              const failedArray = Object.keys(failedObject).map(i => failedObject[i]);

              const dataFailedFilter = [...dataFailedRNFilter, ...failedArray];

              this.setState({
                dataFilter,
                dataFailedFilter,
                isLoading: false,
                setStatus: values.status,
              });
            });
          });

          this.setState({
            isVisibleConfirmModal: true,
          });
        }
          // Note : if barcode hasValue , disable TN's and Reference Number.
        if (values.barcode) {
          const barcode = values.barcode;
          const { barcodeChecker } = this.state;
          const barcodeFilter = barcodeChecker.filter(resBarcode => resBarcode === barcode);

          if (barcodeFilter.length === 0) {
            this.setState(prevState => ({
              barcodeChecker: [
                ...prevState.barcodeChecker,
                barcode,
              ],
            }));
            const arraySplit = barcode.split('-');
            doItemTrackerByBarcodeScan(arraySplit[1], values.status).then((res) => {
              // console.log(res.payload.data.success);
              if (res.payload.data.success.length !== 0) {

                const { sucesstagTable, scannedItems } = this.state;
                const itemChecker = sucesstagTable.filter(resArray => resArray.item_id === parseInt(arraySplit[1], 10));

                if (itemChecker.length !== 0) {
                  // update the quantity of scanned items in the table
  
                  const updateQuantity = sucesstagTable.findIndex(element => element.item_id === parseInt(arraySplit[1], 10));
  
                  let newArrayOfSucesstagTable = [...sucesstagTable];
                  newArrayOfSucesstagTable[updateQuantity] = {
                    ...newArrayOfSucesstagTable[updateQuantity],
                    scannedQuantity: newArrayOfSucesstagTable[updateQuantity].scannedQuantity + 1,
                  };
  
                  // update the state scanned items for view
  
                  const updateScannedItems = scannedItems.findIndex(elementItems => elementItems.id === parseInt(arraySplit[1], 10));
  
                  let newArrayOfScannedItems = [...scannedItems];
                  newArrayOfScannedItems[updateScannedItems] = {
                    ...newArrayOfScannedItems[updateScannedItems],
                    items: [
                      ...newArrayOfScannedItems[updateScannedItems].items,
                      barcode,
                    ],
                  };
  
                  if (parseInt(newArrayOfSucesstagTable[updateQuantity].quantity, 10) >= newArrayOfScannedItems[updateScannedItems].items.length) {
                    this.setState({
                      scannedItems: newArrayOfScannedItems,
                      sucesstagTable: newArrayOfSucesstagTable,
                      isLoading: false,
                    });
                  } else {
                    message.error('The scanned item reach the maximum quantity.');
                    this.setState({
                      isLoading: false,
                    });
                  }
                } else {
                  let dataChecker = res.payload.data.success;
                  dataChecker = dataChecker.map(data => ({
                    ...data,
                    scannedQuantity: 1,
                    isBarcode: true,
                  }));
  
                  this.setState(prevState => ({
                    sucesstagTable: [
                      ...prevState.sucesstagTable,
                      ...dataChecker,
                    ],
                    scannedItems: [
                      ...prevState.scannedItems,
                      {
                        id: dataChecker[0].item_id,
                        items: [barcode],
                      }
                    ],
                    isLoading: false,
                    setStatus: values.status,
                  }));
                }
              } else {
                message.error('Tagged item does not exist.');
                this.setState({
                  isLoading: false,
                });
              }

            });
          } else {
            message.error('The barcode is already tagged.');
            this.setState({
              isLoading: false,
            });
          }

          setFieldsValue({
            barcode: '',
          });
        }
      }
    });
  }

  openViewModal(type, rows) {
    if (type === 'viewItemInfo') {
      this.setState({
        isVisible: true,
        dataforView: rows,
      });
    } else if (type === 'editQuantity') {
      this.setState({
        isVisibleeditQuantity: true,
        editQuantityView: rows,
      });
    } else if (type === 'scannedItems') {
      // show item scanned **
      this.setState({
        isVisibleScannedItems: true,
        itemsIDToView: rows,
      });
    } else {
      const { enteredQuantity } = this.state;
      rows.forEach((tagItems) => {
        const convertToString = tagItems.item_id.toString();

        const itemQuantity = enteredQuantity.length !== 0 ? enteredQuantity.filter(res => res.id === tagItems.item_id) : [];

        if (tagItems.isBarcode) {
          this.setState(prevState => ({
            isVisibleTagging: true,
            tagItemsGetId: {
              ...prevState.tagItemsGetId,
              [convertToString]: parseInt(tagItems.scannedQuantity, 10),
            },
          }));
        } else {
          if (tagItems.status === 'for_pickup' || tagItems.status === 'partially_picked') {
            this.setState(prevState => ({
              isVisibleTagging: true,
              tagItemsGetId: {
                ...prevState.tagItemsGetId,
                [convertToString]: itemQuantity.length !== 0 ? parseInt(itemQuantity[0].value, 10) : parseInt(tagItems.quantity, 10) - parseInt(tagItems.handover_quantity, 10),
              },
            }));
          } else {
            this.setState(prevState => ({
              isVisibleTagging: true,
              tagItemsGetId: {
                ...prevState.tagItemsGetId,
                [convertToString]: itemQuantity.length !== 0 ? parseInt(itemQuantity[0].value, 10) : parseInt(tagItems.handover_quantity, 10) - (parseInt(tagItems.delivered_quantity, 10) + parseInt(tagItems.returned_quantity, 10) + parseInt(tagItems.claims_quantity, 10)),
              },
            }));
          }
        }
      });
      // console.log(rows);
    }
  }

  closeViewModal(type) {
    if (type === 'viewItemInfo') {
      this.setState({
        isVisible: false,
        dataforView: null,
      });
    } else if (type === 'showTableConfirmTag') {
      this.setState({
        isVisibleConfirmModal: false,
        dataFilter: [],
      });
    } else if (type === 'editQuantity') {
      this.setState({
        isVisibleeditQuantity: false,
      });
    } else if (type === 'viewScannedItems') {
      this.setState({
        isVisibleScannedItems: false,
        itemsIDToView: null,
      });
    } else {
      this.setState({
        isVisibleTagging: false,
      });
    }
  }

  confirmTag() {
    this.setState({
      spinner: true,
    });
    const { setStatus, tagItemsGetId, handoverDate, barcodeChecker } = this.state;
    const { form, doItemTrackerTaggingEndpoints } = this.props;
    const { validateFields } = form;
    validateFields(['reference', 'comment'], (err, values) => {
      if (!err) {
        const taggingPackage = {
          status: setStatus,
          reference: values.reference ? values.reference : '',
          comment: values.comment,
          ...handoverDate,
          items: {
            ...tagItemsGetId,
          },
          scanned_codes: barcodeChecker.length !== 0 ? barcodeChecker.toString() : ''
        };

        // console.log(taggingPackage);

        doItemTrackerTaggingEndpoints(taggingPackage).then((res) => {
          const failedObject = res.payload.data.failed;
          const failedArray = Object.keys(failedObject).map(i => failedObject[i]);

          // console.log(res);
          this.setState({
            spinner: false, isVisibleTagging: false, finalizeTag: true, finalizeData: res.payload.data, finalizeFailed: failedArray,
          });
        });
      }
    });
  }

  dateHandler(dateStrings) {
    // eslint-disable-next-line no-underscore-dangle
    const dateFormat = dateStrings ? dateStrings._d : null;
    const formatDateOne = dateFormat ? format(dateFormat, 'YYYY-MM-DD HH:mm:ss') : null;

    this.setState({
      handoverDate: {
        created_at: formatDateOne,
      },
    });
    // console.log(formatDateOne);
  }

  backToTagging() {
    const { form } = this.props;
    const { setFieldsValue } = form;

    this.setState({
      dataFilter: [],
      isVisibleeditQuantity: false,
      editQuantityView: null,
      dataFailedFilter: [],
      isVisibleConfirmModal: false,
      isLoading: false,
      isVisible: false,
      isVisibleTagging: false,
      dataforView: null,
      spinner: false,
      setStatus: null,
      tagItemsGetId: {},
      itemsForTag: [],
      sucesstagTable: [],
      handoverDate: null,
      finalizeTag: false,
      finalizeData: null,
      finalizeFailed: [],
      isFetching: false,
      enteredQuantity: [],
    });

    setFieldsValue({
      reference: '',
      comment: '',
      dateItems: '',
    });
  }

  editQuantity() {
    const { editQuantityView, sucesstagTable, enteredQuantity } = this.state;
    const { form } = this.props;
    const { validateFields, setFieldsValue } = form;
    validateFields(['quantity'], (err, values) => {
      const statusEQV = editQuantityView.status;

      const quantityPicked = parseInt(editQuantityView.quantity, 10) - parseInt(editQuantityView.handover_quantity, 10);
      const quantityDelivery = parseInt(editQuantityView.handover_quantity, 10) - (parseInt(editQuantityView.delivered_quantity, 10) + parseInt(editQuantityView.returned_quantity, 10) + parseInt(editQuantityView.claims_quantity, 10));

      if (!err) {
        const valuesQuantity = parseInt(values.quantity, 10);

        const baseQuantity = statusEQV === 'for_pickup' || statusEQV === 'partially_picked' ? quantityPicked : quantityDelivery;

        if (valuesQuantity <= baseQuantity) {
          const { enteredQuantity: enteredQ } = this.state;

          const findeditQuantityView = sucesstagTable.filter(res => res.item_id === editQuantityView.item_id);

          const enterQuantityDplicateFlter = enteredQ.filter(res => res.id !== findeditQuantityView.map(resArr => resArr.item_id)[0]);
          const enterQuantityDplicateFind = enteredQ.filter(res => res.id === findeditQuantityView.map(resArr => resArr.item_id)[0]);

          if (enterQuantityDplicateFind.length !== 0) {
            const enterQuantityDplicateMps = enterQuantityDplicateFind.map(data => ({
              ...data,
              value: values.quantity,
            }));

            this.setState(prevState => ({
              editQuantityView: null,
              isVisibleeditQuantity: false,
              enteredQuantity: [
                ...enterQuantityDplicateFlter,
                ...enterQuantityDplicateMps,
              ],
              dataConfirmToTag: [
                ...prevState.dataConfirmToTag,
                {
                  itemID: editQuantityView.item_id,
                  editQuantity: values.quantity,
                },
              ],
            }));

            setFieldsValue({
              quantity: '',
            });
          } else {
            this.setState(prevState => ({
              editQuantityView: null,
              isVisibleeditQuantity: false,
              enteredQuantity: [
                ...prevState.enteredQuantity,
                {
                  id: findeditQuantityView.map(res => res.item_id)[0],
                  value: values.quantity,
                },
              ],
              dataConfirmToTag: [
                ...prevState.dataConfirmToTag,
                {
                  itemID: editQuantityView.item_id,
                  editQuantity: values.quantity,
                },
              ],
            }));

            setFieldsValue({
              quantity: '',
            });
          }
        } else {
          // console.log('b');
          message.error('The quantity you put is higher than latest quantity.');
          this.setState({
            editQuantityView: null,
            isVisibleeditQuantity: false,
          });

          setFieldsValue({
            quantity: '',
          });
        }
        // console.log(editQuantityView);
        // console.log(values.quantity);
      }
    });
  }

  render() {
    const {
      scannedItems, itemsIDToView, dataFilter, isLoading, dataforView, isVisible, isVisibleTagging, spinner, isVisibleConfirmModal, sucesstagTable, finalizeData, finalizeTag, finalizeFailed, isVisibleeditQuantity, editQuantityView, itemsForTag, setStatus, options, isFetching, selectAllChecker, enteredQuantity, isVisibleScannedItems,
    } = this.state;

    // console.log(itemsIDToView);

    const breadCrumbs = [
      {
        breadcrumbName: 'Items',
      },
      {
        breadcrumbName: 'Item Tagging',
      },
    ];

    const statusOption = [
      {
        value: 'picked',
        label: 'Picked',
      },
      {
        value: 'accepted_to_warehouse',
        label: 'Accepted To Warehouse',
      },
      {
        value: 'forwarded_to_branch',
        label: 'Forwarded To Branch',
      },
      {
        value: 'delivery_successful',
        label: 'Delivery Successful',
      },
      {
        value: 'returned',
        label: 'Returned',
      },
      {
        value: 'claims',
        label: 'Claims',
      },
      {
        value: 'void',
        label: 'Void',
      },
    ];

    const successColumns = [
      {
        Header: null,
        sortable: false,
        filterable: false,
        width: 40,
        Cell: row => (
          <Checkbox
            onChange={e => this.setAddTag(e, row.original)}
            checked={itemsForTag.filter(res => res.item_id === row.original.item_id).length !== 0}
          />
        ),
      },
      {
        Header: 'Tracking Number',
        sortable: true,
        filterable: true,
        accessor: 'tracking_number',
      },
      {
        Header: 'Item ID',
        sortable: true,
        filterable: true,
        accessor: 'item_id',
      },
      {
        Header: 'Reference',
        sortable: true,
        filterable: true,
        accessor: 'reference',
      },
      {
        Header: 'Description',
        sortable: true,
        filterable: true,
        accessor: 'description',
      },
      {
        Header: 'Status',
        sortable: true,
        filterable: true,
        accessor: 'status',
      },
      {
        Header: 'Client Name',
        sortable: true,
        filterable: true,
        accessor: 'client_name',
      },
      {
        Header: 'Options',
        sortable: false,
        filterable: false,
        Cell: row => (
          <center>
            <Button
              type="link"
              onClick={() => this.openViewModal('viewItemInfo', row.original)}
            >
              <Icon viewBox="0 0 1024 1024">
                <FontAwesomeIcon icon={faEye} fixedWidth />
              </Icon>
              view
            </Button>
          </center>
        ),
      },
    ];

    const successColumnsToBeTagged = [
      {
        Header: 'Tracking Number',
        sortable: false,
        filterable: false,
        accessor: 'tracking_number',
      },
      {
        Header: 'Reference',
        sortable: false,
        filterable: false,
        accessor: 'reference',
      },
      {
        Header: 'Status',
        sortable: false,
        filterable: false,
        accessor: 'status',
      },
      {
        Header: 'Quantity',
        sortable: false,
        filterable: false,
        accessor: 'quantity',
      },
      {
        Header: 'Handover Q',
        sortable: false,
        filterable: false,
        accessor: 'handover_quantity',
      },
      {
        Header: 'Close Q',
        sortable: false,
        filterable: false,
        Cell: row => (
          <p>{parseInt(row.original.delivered_quantity, 10) + parseInt(row.original.returned_quantity, 10) + parseInt(row.original.claims_quantity, 10)}</p>
        ),
      },
      {
        Header: 'Open Q',
        sortable: false,
        filterable: false,
        Cell: row => (
          <React.Fragment>
            {/* eslint-disable */
          row.original.status === 'for_pickup' || row.original.status === 'partially_picked' ? (
            <p>{parseInt(row.original.quantity, 10) - parseInt(row.original.handover_quantity, 10)}</p>
          ) : (
            <p>{parseInt(row.original.handover_quantity, 10) - (parseInt(row.original.delivered_quantity, 10) + parseInt(row.original.returned_quantity, 10) + parseInt(row.original.claims_quantity, 10))}</p>
          )}
          </React.Fragment>
        ),
      },
      {
        Header: 'Entered Q',
        sortable: false,
        filterable: false,
        Cell: (row) => {
          const quantityMps = enteredQuantity.length !== 0 ? enteredQuantity.filter(res => res.id === row.original.item_id) : [];
          const editQuantityID = quantityMps.length !== 0 ? 
                    quantityMps.map(resArr => resArr.value)[0] : 
                    row.original.status === 'for_pickup' || row.original.status === 'partially_picked' ?
                      parseInt(row.original.quantity, 10) - parseInt(row.original.handover_quantity, 10) : 
                      parseInt(row.original.handover_quantity, 10) - (parseInt(row.original.delivered_quantity, 10) + parseInt(row.original.returned_quantity, 10) + parseInt(row.original.claims_quantity, 10));
          return (
            <React.Fragment>
              <p>{row.original.isBarcode ? row.original.scannedQuantity : editQuantityID}</p>
            </React.Fragment>
          );
          // console.log(editQuantityID);
        },
      },
      {
        Header: 'Options',
        sortable: false,
        filterable: false,
        width: 310,
        Cell: row => (
          <center>
            <Row>
              <Col xs={24} sm={10} lg={10}>
                { row.original.isBarcode ? 
                (
                  <Button
                    type="link"
                    onClick={() => this.openViewModal('scannedItems', row.original.item_id)}
                  >
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={faEdit} fixedWidth />
                    </Icon>
                    scanned items
                  </Button>
                ) : (
                  <Button
                    type="link"
                    onClick={() => this.openViewModal('editQuantity', row.original)}
                  >
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={faEdit} fixedWidth />
                    </Icon>
                    edit quantity
                  </Button>
                ) }
              </Col>
              <Col xs={24} sm={6} lg={6}>
                <Button
                  type="link"
                  onClick={() => this.openViewModal('viewItemInfo', row.original)}
                >
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faEye} fixedWidth />
                  </Icon>
                  view
                </Button>
              </Col>
              <Col xs={24} sm={8} lg={8}>
                <Button
                  type="link"
                  onClick={() => this.removeTag(row.original.item_id)}
                >
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faTrash} fixedWidth />
                  </Icon>
                  remove
                </Button>
              </Col>
            </Row>
          </center>
        ),
      },
    ];

    const failedColumns = [
      {
        Header: 'Code',
        accessor: 'code',
      },
      {
        Header: 'Message',
        accessor: 'message',
      },
      {
        Header: 'Tracking Number',
        accessor: 'tracking_number',
      },
      {
        Header: 'Client',
        accessor: 'client',
      },
    ];

    const successColumnsFinalize = [
      {
        Header: 'Tracking Number',
        sortable: true,
        filterable: true,
        accessor: 'tracking_number',
      },
      {
        Header: 'Item ID',
        sortable: true,
        filterable: true,
        accessor: 'item_id',
      },
      {
        Header: 'Reference',
        sortable: true,
        filterable: true,
        accessor: 'reference',
      },
      {
        Header: 'Description',
        sortable: true,
        filterable: true,
        accessor: 'description',
      },
      {
        Header: 'Status',
        sortable: true,
        filterable: true,
        accessor: 'status',
      },
      {
        Header: 'Client Name',
        sortable: true,
        filterable: true,
        accessor: 'client_name',
      },
      {
        Header: 'Options',
        sortable: false,
        filterable: false,
        Cell: row => (
          <center>
            <Button
              type="link"
              onClick={() => this.openViewModal('viewItemInfo', row.original)}
            >
              <Icon viewBox="0 0 1024 1024">
                <FontAwesomeIcon icon={faEye} fixedWidth />
              </Icon>
              view
            </Button>
          </center>
        ),
      },
    ];

    // console.log(sucesstagTable);
    const { form } = this.props;
    const { getFieldDecorator } = form;
    // console.log(handoverDate);
    return (
      <div className="TrackItem">
        {/* edit quantity */}
        <Modal
          onCancel={() => this.closeViewModal('editQuantity')}
          closable
          footer={null}
          visible={isVisibleeditQuantity}
        >
          <Card>
            {getFieldDecorator('quantity', {
              rules: [
                {
                  required: false,
                },
              ],
            })(
              <Input
                placeholder="Enter Quantity"
                autoComplete="off"
                // defaultValue={editQuantityView ? editQuantityView.quantity : ''}
              />,
            )}
            <br />
            <br />
            <Button type="primary" onClick={() => this.editQuantity()} block>Edit Quantity</Button>
          </Card>
        </Modal>
        {/* after add item */}
        <Modal
          title={`Total Success Items after add item: ${dataFilter.length}`}
          onCancel={() => this.closeViewModal('showTableConfirmTag')}
          closable
          footer={null}
          visible={isVisibleConfirmModal}
          width
          style={{ top: 20 }}
        >
          <Card title={
            !selectAllChecker ?
            (
              <Checkbox checked={false} onClick={e => this.checkAll('check')}>Select All</Checkbox>
            ) : (
              <Checkbox checked={true} onClick={e => this.checkAll('uncheck')}>Unselect All</Checkbox>
            )
          }>
            <ReactTable
              data={dataFilter}
              columns={successColumns}
              loading={isLoading}
            />
            <br />
            <Button type="primary" onClick={() => this.successTag()} block>
              <Icon viewBox="0 0 1024 1024">
                <FontAwesomeIcon icon={faList} fixedWidth />
              </Icon>
              Add to List
            </Button>
          </Card>
        </Modal>
        {/* finalize confirmation of tagging */}
        <Modal
          title="Item Tagging"
          closable
          onCancel={() => this.closeViewModal()}
          footer={null}
          visible={isVisibleTagging}
          style={{ height: '100%', width: '100%' }}
        >
          <Spin spinning={spinner} tip="Loading please wait...">
            <Form>
              <Row>
                {(() => {
                  switch (setStatus) {
                    // Enter Reference
                    case 'claims':
                    case 'void':
                      return (
                        <Col xs={24} sm={12} lg={12}>
                          <Form.Item label="Reference">
                            {getFieldDecorator('reference', {
                              rules: [
                                {
                                  required: true,
                                  message: 'Reference cannot be blank.',
                                },
                              ],
                            })(
                              <Input
                                placeholder="Enter Reference"
                                autoComplete="off"
                              />,
                            )}
                          </Form.Item>
                        </Col>
                      );
                    case 'failed_delivery':
                    // reason
                      return (
                        <Col xs={24} sm={12} lg={12}>
                          <Form.Item label="Reason">
                            {getFieldDecorator('reference', {
                              rules: [
                                {
                                  required: true,
                                  message: 'Driver Name cannot be blank.',
                                },
                              ],
                            })(
                              <Select
                                options={options.reason}
                                placeholder="Select Reason"
                                allowClear
                                loading={isFetching}
                                onSearch={e => this.searchOption('destination', e)}
                                filterOption={false}
                                showSearch
                              />,
                            )}
                          </Form.Item>
                        </Col>
                      );
                    case 'delivery_successful':
                    // consignee name
                      return (
                        <Col xs={24} sm={12} lg={12}>
                          <Form.Item label="Consignee Name">
                            {getFieldDecorator('reference', {
                              rules: [
                                {
                                  required: true,
                                  message: 'Consignee Name cannot be blank.',
                                },
                              ],
                            })(
                              <Input
                                placeholder="Enter Consignee Name"
                                autoComplete="off"
                              />,
                            )}
                          </Form.Item>
                        </Col>
                      );
                    case 'forwarded_to_branch':
                    // select branch
                      return (
                        <Col xs={24} sm={12} lg={12}>
                          <Form.Item label="Branch">
                            {getFieldDecorator('reference', {
                              rules: [
                                {
                                  required: true,
                                  message: 'Branch cannot be blank.',
                                },
                              ],
                            })(
                              <Select
                                options={options.destination}
                                placeholder="Select Branch"
                                allowClear
                                loading={isFetching}
                                onSearch={e => this.searchOption('destination', e)}
                                filterOption={false}
                                showSearch
                              />,
                            )}
                          </Form.Item>
                        </Col>
                      );
                    default:
                      return null;
                  }
                })()}
                <Col xs={24} sm={setStatus === 'picked' || setStatus === 'accepted_to_warehouse' || setStatus === 'returned' ? 24 : 12} lg={setStatus === 'picked' || setStatus === 'accepted_to_warehouse' || setStatus === 'returned' ? 24 : 12}>
                  <Form.Item label="Comment">
                    {getFieldDecorator('comment', {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter Comment"
                        autoComplete="off"
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={24}>
                  <Form.Item label="Created Date">
                    {getFieldDecorator('dateItems', {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(
                      <DatePicker
                        style={{ width: '100%' }}
                        showTime={{
                          hideDisabledOptions: true,
                          defaultValue: [
                            moment('00:00:00', 'HH:mm:ss'),
                          ],
                        }}
                        onChange={e => this.dateHandler(e)}
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Button type="primary" onClick={() => this.confirmTag()} block>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faFile} fixedWidth />
                </Icon>
                Submit Tagging
              </Button>
            </Form>
          </Spin>
        </Modal>
        {/* view item information */}
        <Modal
          title="View Items Information"
          closable
          onCancel={() => this.closeViewModal('viewItemInfo')}
          visible={isVisible}
          footer={null}
          width={950}
        >
          <Descriptions bordered size="small">
            <Descriptions.Item label="Tracking Number: "><b>{dataforView ? dataforView.tracking_number : ' '}</b></Descriptions.Item>
            <Descriptions.Item label="Reference: "><Tag color="orange">{dataforView ? dataforView.reference : ' '}</Tag></Descriptions.Item>
            <Descriptions.Item label="Client Name: ">{dataforView ? dataforView.client_name : ' '}</Descriptions.Item>
            <Descriptions.Item label="Quantity: ">{dataforView ? dataforView.quantity : ' '}</Descriptions.Item>
            <Descriptions.Item label="UOM: ">{dataforView ? dataforView.uom : ' '}</Descriptions.Item>
            <Descriptions.Item label="Length: ">{dataforView ? dataforView.length : ' '}</Descriptions.Item>
            <Descriptions.Item label="Width: ">{dataforView ? dataforView.width : ' '}</Descriptions.Item>
            <Descriptions.Item label="Height: ">{dataforView ? dataforView.height : ' '}</Descriptions.Item>
            <Descriptions.Item label="Weight: ">{dataforView ? dataforView.weight : ' '}</Descriptions.Item>
            <Descriptions.Item label="Volume: ">{dataforView ? dataforView.volume : ' '}</Descriptions.Item>
            <Descriptions.Item label="Value: ">{dataforView ? dataforView.value : ' '}</Descriptions.Item>
            <Descriptions.Item label="Comment: ">{dataforView ? dataforView.comment : ' '}</Descriptions.Item>
            <Descriptions.Item label="Status: "><Tag color="orange">{dataforView ? dataforView.status : ' '}</Tag></Descriptions.Item>
            <Descriptions.Item label="Current Location: ">{dataforView ? dataforView.current_location : ' '}</Descriptions.Item>
            <Descriptions.Item label="Handover Quantity: ">{dataforView ? dataforView.handover_quantity : ' '}</Descriptions.Item>
            <Descriptions.Item label="Deliverd Quantity: ">{dataforView ? dataforView.delivered_quantity : ' '}</Descriptions.Item>
            <Descriptions.Item label="Returned Quantity: ">{dataforView ? dataforView.returned_quantity : ' '}</Descriptions.Item>
            <Descriptions.Item label="Claims Quantity: ">{dataforView ? dataforView.claims_quantity : ' '}</Descriptions.Item>
            <Descriptions.Item label="Description: ">{dataforView ? dataforView.description : ' '}</Descriptions.Item>
          </Descriptions>
        </Modal>
        <Modal
          title="View Scanned Items"
          closable
          onCancel={() => this.closeViewModal('viewScannedItems')}
          visible={isVisibleScannedItems}
          footer={null}
        >
        { scannedItems.length !== 0 ? (
          scannedItems.filter(res => res.id === itemsIDToView).length !== 0 ? 
            scannedItems.filter(res => res.id === itemsIDToView)[0].items.map((resArr) => (
              <React.Fragment>
                <Tag color="orange"><strong>{resArr}</strong></Tag>
                <br />
              </React.Fragment>
              )) 
            : 
            null 
          ) : null
        }
        </Modal>
        <PageHeader title="Item Tagging" routes={breadCrumbs} />
        {finalizeTag ? (
          <Container>
            <Card
              title={`Total Success Items: ${finalizeData ? finalizeData.success.length : 0}`}
            >
              <ReactTable
                data={finalizeData ? finalizeData.success : []}
                columns={successColumnsFinalize}
                loading={isLoading}
              />
            </Card>
            <Spacer />
            <Card
              title={`Total Error Items: ${finalizeFailed.length}`}
            >
              <ReactTable
                data={finalizeFailed}
                columns={failedColumns}
                loading={isLoading}
              />
              <Spacer />
              <Button type="primary" onClick={() => this.backToTagging()} block>
                <Icon type="arrow-left" />
                Back To Tagging
              </Button>
            </Card>
          </Container>
        ) : (
          <Container>
            <Card>
              <Form>
                <Row>
                  <Col xs={24} sm={12} lg={12}>
                    <Form.Item label="Tracking Number">
                      {getFieldDecorator('trackingnumber', {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                      })(
                        <Input.TextArea
                          placeholder="Enter Tracking Number"
                          autoComplete="off"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={12}>
                    <Form.Item label="Reference Number">
                      {getFieldDecorator('referencenumber', {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                      })(
                        <Input.TextArea
                          placeholder="Enter Reference Number"
                          autoComplete="off"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={12} lg={12}>
                    <Form.Item label="Barcode">
                      {getFieldDecorator('barcode', {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                      })(
                        <Input
                          style={{ width: '100%' }}
                          placeholder="Enter Barcode"
                          onPressEnter={() => this.formSubmit()}
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={12}>
                    <Form.Item label="Status">
                      {getFieldDecorator('status', {
                        rules: [
                          {
                            required: true,
                            message: 'Status cannot be blank.',
                          },
                        ],
                      })(
                        <Select
                          style={{ width: '100%' }}
                          allowClear
                          disabled={sucesstagTable.length !== 0 ? true : false}
                          // loading={isFetching}
                          options={statusOption}
                          // onChange={e => this.labelChange(e)}
                          filterOption={false}
                          placeholder="Enter Package Status"
                          showSearch
                        />,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Button type="primary" onClick={() => this.formSubmit()} block>
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faPlus} fixedWidth />
                  </Icon>
                  Add Item
                </Button>
              </Form>
            </Card>
            <Spacer />
            <Card
              title={`Total Items: ${sucesstagTable.length}`}
            >
              <ReactTable
                data={sucesstagTable}
                columns={successColumnsToBeTagged}
                loading={isLoading}
              />
              <br />
              {sucesstagTable.length !== 0
                ? (
                  <Button type="primary" onClick={() => this.openViewModal('', sucesstagTable)} block>
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={faArrowRight} fixedWidth />
                    </Icon>
                    Continue
                  </Button>
                ) : (
                  <Button type="primary" disabled block>
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={faArrowRight} fixedWidth />
                    </Icon>
                    Continue
                  </Button>
                )
              }
            </Card>
          </Container>
        )}
      </div>
    );
  }
}

TrackItem.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doItemTrackerByTrackingNumber: PropTypes.func.isRequired,
  doItemTrackerTaggingEndpoints: PropTypes.func.isRequired,
  doItemTrackerByBarcodeScan: PropTypes.func.isRequired,
  doItemTrackerByReference: PropTypes.func.isRequired,
  doFetchOptions: PropTypes.func.isRequired,
  doFetchOptionsLike: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doItemTrackerByReference: itemTrackerByReference,
  doItemTrackerByBarcodeScan: itemTrackerByBarcodeScan,
  doItemTrackerByTrackingNumber: itemTrackerByTrackingNumber,
  doItemTrackerTaggingEndpoints: itemTrackerTaggingEndpoints,
  doFetchOptions: fetchOptions,
  doFetchOptionsLike: fetchOptionsLike,
};

const WrappedTrackItem = Form.create({ name: 'create' })(TrackItem);
export default connect(null, mapDispatchToProps)(WrappedTrackItem);
