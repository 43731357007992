import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Descriptions, Button, Icon, Col, Spin, Modal, Result,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import Row from '../../components/Row';
import Spacer from '../../components/Spacer';
import DispatchView from '../../components/DispatchView';

import {
  fetchDispatchDetails,
  printDispatch,
  fetchDispatchList,
} from '../ducks';
import CardImageList from '../../components/CardImageList';

const styleTable = width => ({
  border: '0.5px solid black',
  fontSize: '12px',
  padding: '5px',
  width,
  borderCollapse: 'collapse',
});

export class RTSView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dispatchDetails: [],
      isMountingModule: true,
      accountStatus: null,
      hasVisible: false,
      updateStatusLoading: false,
      loadingText: 'Fetching rts details...',
    };

    this.fetchDispatch = this.fetchDispatch.bind(this);
  }

  componentDidMount() {
    this.fetchDispatch();
  }

  fetchDispatch() {
    const { doFetchDispatchDetails, match, doFetchDispatchList } = this.props;

    doFetchDispatchDetails(match.params.id).then((action) => {
      const sortedPackages = action.payload.data.packages.sort((a, b) => {
        if (a.shipper_name < b.shipper_name) {
          return -1;
        }
        if (a.shipper_name > b.shipper_name) {
          return 1;
        }
        return 0;
      });

      this.setState({
        dispatchDetails: {
          ...action.payload.data,
          sortedPackages,
        },
        isMountingModule: false,
      });
    });


    const params = {
      export: false,
      filter: [{ id: 'dh_id', value: match.params.id }],
      page: 0,
      pageSize: 10,
      sorted: [],
    };
    doFetchDispatchList(params).then(res => this.setState({
      accountStatus: res.payload.data.rows[0].status,
    }));
  }

  confimationPrint() {
    this.setState({
      hasVisible: true,
    });
  }

  handleCancel() {
    this.setState({
      hasVisible: false,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  printOnlyManifest() {
    const content = document.getElementById('divcontents');
    const pri = document.getElementById('ifmcontentstoprint').contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  printDispatch() {
    this.setState({
      updateStatusLoading: true,
      isMountingModule: true,
      loadingText: 'Updating dispatch . . .',
    });
    const { doPrintDispatch, match } = this.props;
    doPrintDispatch(match.params.id).then(() => {
      this.setState({
        hasVisible: false,
        updateStatusLoading: false,
      });
      const content = document.getElementById('divcontents');
      const pri = document.getElementById('ifmcontentstoprint').contentWindow;
      pri.document.open();
      pri.document.write(content.innerHTML);
      pri.document.close();
      pri.focus();
      pri.print();
      this.fetchDispatch();
    });
    // const { tableDataBody } = this.state;
    // console.log(tableDataBody.map(res => (res))[0]);
  }

  render() {
    const {
      dispatchDetails,
      isMountingModule,
      accountStatus,
      hasVisible,
      updateStatusLoading,
      loadingText,
    } = this.state;

    const { dispatch } = dispatchDetails;
    let typeTitle = 'RTS';
    let dispatchType = 'rts';
    if (dispatch) {
      dispatchType = dispatch.type;
    }

    typeTitle = dispatchType === 'rts' ? 'RTS' : 'RTS Transfer';

    const { match } = this.props;
    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: typeTitle,
      },
      {
        breadcrumbName: 'List',
      },
      {
        breadcrumbName: `View #${match.params.id}`,
      },
    ];

    const dispatchPackageListColumns = [
      {
        id: 'tracking_number',
        Header: 'Tracking Number',
        filterable: false,
        // sortable: false,
        accessor: data => data.tracking_number,
      },
      {
        id: 'shipper_name',
        Header: 'Shipper Name',
        filterable: false,
        // sortable: false,
        accessor: 'shipper_name',
        // accessor: data => data.consignee_name,
      },
      {
        id: 'shipper_address',
        Header: 'Shipper Address',
        filterable: false,
        // sortable: false,
        accessor: 'shipper_address',
        // accessor: data => data.consignee_address,
      },
      {
        id: 'payment_type',
        Header: 'Payment Type',
        filterable: false,
        // sortable: false,
        accessor: data => data.payment_type,
      },
      {
        id: 'handover_date',
        Header: 'Handover Date',
        filterable: false,
        // sortable: false,
        accessor: data => data.handover_date,
      },
      {
        id: 'status',
        Header: 'Status',
        filterable: false,
        // sortable: false,
        accessor: data => data.status,
      },
    ];

    return (
      <div className="RTSView">
        <Modal
          title="New status confirmation"
          visible={hasVisible}
          // onOk={() => this.printDispatch()}
          // onCancel={() => this.handleCancel()}
          footer={(
            <React.Fragment>
              <Button
                disabled={updateStatusLoading}
                onClick={() => this.handleCancel()}
              >
                Cancel
              </Button>
              {updateStatusLoading ? (
                <Button
                  disabled={updateStatusLoading}
                  style={{ backgroundColor: '#e8641a' }}
                  onClick={() => this.printDispatch()}
                >
                  <Icon
                    type="loading"
                    style={{ fontSize: 16, color: 'white' }}
                    spin
                  />
                  Confirm
                </Button>
              ) : (
                <Button type="primary" onClick={() => this.printDispatch()}>
                  Confirm
                </Button>
              )}
            </React.Fragment>
          )}
        >
          <Result
            status="warning"
            title="Are you sure you want to print this manifest?"
          />
        </Modal>
        <div style={{ display: 'none' }} id="divcontents">
          <center>
            <strong style={{ fontSize: '14px' }}>
              ORIGINATING WAREHOUSE MANIFEST
            </strong>
          </center>
          <div
            style={{
              content: '',
              display: 'inline-block',
              verticalAlign: 'middle',
              width: '100%',
              marginTop: '20px',
              marginBottom: '20px',
            }}
          >
            <div
              style={{
                width: '25%',
                display: 'inline-block',
                verticalAlign: 'middle',
                textAlign: 'middle',
                float: 'left',
              }}
            >
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Dispatch Number: </strong>
                {dispatchDetails.dispatch ? dispatchDetails.dispatch.dh_id : ''}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Operator: </strong>
                {dispatchDetails.dispatch
                  ? dispatchDetails.dispatch.driver
                  : ''}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Outsource: </strong>
                {dispatchDetails.dispatch
                  ? dispatchDetails.dispatch.outsource
                  : ''}
              </p>
            </div>
            <div
              style={{
                width: '25%',
                display: 'inline-block',
                verticalAlign: 'middle',
                textAlign: 'middle',
              }}
            >
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Vehicle: </strong>
                {dispatchDetails.dispatch
                  ? dispatchDetails.dispatch.vehicle_name
                  : ''}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Mobile Number: </strong>
                {dispatchDetails.dispatch
                  ? dispatchDetails.dispatch.driver_mobile
                  : ''}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Dispatch Date: </strong>
                {dispatchDetails.dispatch
                  ? dispatchDetails.dispatch.dispatch_date
                  : ''}
              </p>
            </div>
            <div
              style={{
                width: '25%',
                display: 'inline-block',
                verticalAlign: 'middle',
                textAlign: 'middle',
              }}
            >
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Destination: </strong>
                {dispatchDetails.dispatch
                  ? dispatchDetails.dispatch.destination
                  : ''}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Helper: </strong>
                {dispatchDetails.dispatch
                  ? dispatchDetails.dispatch.helper
                  : ''}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Remarks: </strong>
                {dispatchDetails.dispatch
                  ? dispatchDetails.dispatch.remarks
                  : ''}
              </p>
            </div>
            <div
              style={{
                width: '23%',
                display: 'inline-block',
                verticalAlign: 'middle',
                textAlign: 'middle',
                float: 'right',
              }}
            >
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Origin: </strong>
                {dispatchDetails.dispatch
                  ? dispatchDetails.dispatch.port_code
                  : ''}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Mobile Number: </strong>
                {dispatchDetails.dispatch
                  ? dispatchDetails.dispatch.helper_mobile
                  : ''}
              </p>
            </div>
          </div>
          <div style={{ marginBottom: '30px' }}>
            <strong style={{ fontSize: '12px' }}>Customer Waybill</strong>
            <table style={styleTable()}>
              <thead>
                <tr>
                  <th style={styleTable('3%')} />
                  <th style={styleTable('10%')}>Cargo #</th>
                  <th style={styleTable('25%')}>Consignee Address</th>
                  <th style={styleTable('10%')}>Seller Name</th>
                  <th style={styleTable('25%')}>Seller Address</th>
                  <th style={styleTable('5%')}>Route Order</th>
                  <th style={styleTable('5%')}>Signature</th>
                  <th style={styleTable('5%')}>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {dispatchDetails.packages
                  ? dispatchDetails.packages.map((val, key) => (
                    <React.Fragment key={val.tracking_number}>
                      <tr key={`${val.tracking_number}`}>
                        <td style={styleTable('3%')}>{key + 1}</td>
                        <td style={styleTable('10%')}>
                          <h3 style={{ margin: 0 }}><strong>{val.tracking_number}</strong></h3>
                        </td>
                        <td style={styleTable('25%')}>
                          {val.consignee_address}
                        </td>
                        <td style={styleTable('10%')}>
                          {val.shipper_name}
                        </td>
                        <td style={styleTable('25%')}>
                          {val.shipper_address}
                        </td>
                        <td style={styleTable('7%')} />
                        <td style={styleTable('7%')} />
                        <td style={styleTable('7%')} />
                      </tr>
                      <tr key={`${val.tracking_number}b`}>
                        <td style={styleTable('3%')} />
                        <td>
                          <strong>Weight: </strong>
                          {val.weight}
                        </td>
                        <td colSpan="2">
                          <strong>Handover Date: </strong>
                          {val.handover_date}
                        </td>
                        <td colSpan="4">
                          <strong>Consignee: </strong>
                          {val.consignee_name}
                          {' '}
                          /
                          {' '}
                          {val.consignee_mobile}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                  : null}
              </tbody>
            </table>
          </div>
          <p style={{ fontSize: '12px', margin: '5px' }}>
            {'Total Transaction:'}
            {' '}
            <span style={{ color: 'red' }}>
              {dispatchDetails.packages ? dispatchDetails.packages.length : 0}
            </span>
          </p>
          <p style={{ fontSize: '12px', margin: '5px' }}>
            {/* eslint-disable no-nested-ternary */}
            {'Total Weight:'}
            {' '}
            {dispatchDetails.packages
              ? dispatchDetails.packages.length !== 0
                ? dispatchDetails.packages
                  .map(res => parseFloat(res.weight))
                  .filter(resFilter => resFilter).length === 0
                  ? 0
                  : dispatchDetails.packages
                    .map(res => parseFloat(res.weight))
                    .filter(resFilter => resFilter)
                    .reduce((a, b) => a + b)
                    .toFixed(2)
                : 0
              : 0
            }
            {/* eslint-enable no-nested-ternary */}
          </p>
          <div style={{ marginTop: '50px' }}>
            <strong style={{ fontSize: '12px', float: 'left' }}>
              Signature of Warehouse Representative
            </strong>
            <strong
              style={{ fontSize: '12px', float: 'left', marginLeft: '250px' }}
            >
              Signature of Operator
            </strong>
          </div>
        </div>
        <iframe
          title="myFrame"
          id="ifmcontentstoprint"
          style={{ height: '0px', width: '0px', position: 'absolute' }}
        />
        <Spin
          spinning={isMountingModule}
          small="size"
          tip={loadingText}
        >
          <PageHeader title={`${typeTitle} #${match.params.id}`} routes={breadCrumbs} />
          <Spacer />
          <Container>
            <DispatchView
              dispatchTitle="RTS Information"
              dispatchExportName={typeTitle}
              loadingUpdate={updateStatusLoading}
              dispatchPackageListColumns={dispatchPackageListColumns}
              dispatchItems={dispatchDetails.packages}
              hasExport
              exportType="dispatch"
              exportId={match.params.id}
              dispatchDetails={(
                <React.Fragment>
                  <Descriptions bordered size="small">
                    <Descriptions.Item label="Driver: ">
                      {dispatchDetails.dispatch
                        ? dispatchDetails.dispatch.driver
                        : ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="Type: " span={2}>
                      {dispatchDetails.dispatch
                        ? dispatchDetails.dispatch.type
                        : ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="Origin: ">
                      {dispatchDetails.dispatch
                        ? dispatchDetails.dispatch.port_code
                        : ''}
                    </Descriptions.Item>
                    { dispatchType !== 'rts' ? (
                      <Descriptions.Item label="Destination: ">
                        {dispatchDetails.dispatch
                          ? dispatchDetails.dispatch.destination
                          : ''}
                      </Descriptions.Item>
                    ) : null
                    }
                    <Descriptions.Item label="Vehicle: " span={2}>
                      {dispatchDetails.dispatch
                        ? dispatchDetails.dispatch.vehicle_name
                        : ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="Dispatch Date: ">
                      {dispatchDetails.dispatch
                        ? dispatchDetails.dispatch.dispatch_date
                        : ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="Outsource: ">
                      {dispatchDetails.dispatch
                        ? dispatchDetails.dispatch.outsource
                        : ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="Processed By: ">
                      {dispatchDetails.dispatch
                        ? `${dispatchDetails.dispatch.added_by}`
                        : ''}
                    </Descriptions.Item>
                  </Descriptions>
                </React.Fragment>
              )}
              printTypes={(
                <React.Fragment>
                  <Row>
                    <Col xs={24} sm={24} lg={12} style={{ marginBottom: 5 }}>
                      <Button
                        onClick={() => this.printOnlyManifest()}
                        type="primary"
                        block
                      >
                        <Icon viewBox="0 0 1024 1024">
                          <FontAwesomeIcon icon={faPrint} fixedWidth />
                        </Icon>
                        Print Manifest Only
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} lg={12}>
                      <Button
                        onClick={
                          accountStatus === 'new'
                            ? () => this.confimationPrint()
                            : () => this.printDispatch()
                        }
                        type="primary"
                        block
                      >
                        <Icon viewBox="0 0 1024 1024">
                          <FontAwesomeIcon icon={faPrint} fixedWidth />
                        </Icon>
                        Print and Update
                      </Button>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            />
            <CardImageList
              pod={dispatchDetails.pod}
            />
          </Container>
        </Spin>
      </div>
    );
  }
}

RTSView.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchDispatchDetails: PropTypes.func.isRequired,
  doPrintDispatch: PropTypes.func.isRequired,
  doFetchDispatchList: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchDispatchDetails: fetchDispatchDetails,
  doPrintDispatch: printDispatch,
  doFetchDispatchList: fetchDispatchList,
};

export default connect(null, mapDispatchToProps)(RTSView);
