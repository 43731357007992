import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form, Col, Input, Button, Icon, Row, Card, Radio, message, Popconfirm, Spin
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faEdit,
  faTrash,
  faSave,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import Container from '../../components/Container';
import PageHeader from '../../components/PageHeader';
import Select from '../../components/Select';
import Spacer from '../../components/Spacer';
import ReactTable from '../../components/ReactTable';
import _ from 'lodash';
import { fetchOptions, fetchOptionsLike } from '../ducks';
import {
  createClientStatusMap,
  fetchClientStatusMap,
  updateClientStatusMap,
  deleteClientStatusMap,
} from './ducks'

export class StatusMap extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      statusOptions: [],
      clientOptions: [],
      client: {
        clientDetails: {
          name: '',
          id: null
        },
        statusMap: []
      },
      editedStatusMapId: null,
      isLoading: true,
    }
    this.formSubmit = this.formSubmit.bind(this);
    this.fetchClientStatusMap = this.fetchClientStatusMap.bind(this);
    this.setToEdit = this.setToEdit.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
  }

  componentDidMount() {
    const { doFetchOptions} = this.props;
    doFetchOptions(['status']).then((res) => {
      this.setState({
        statusOptions: res.payload.data,
      });
    });
    doFetchOptions(['client']).then((res) => {
      this.setState({
        clientOptions: res.payload.data.slice(0, 50),
      });
    });

    this.fetchClientStatusMap();
  }

  fetchClientStatusMap() {
    const { doFetchClientStatusMap, match } = this.props;

    doFetchClientStatusMap(match.params.id).then((res) => {
      this.setState({
        isLoading: false,
        editedStatusMapId: null,
        client: {
          clientDetails: res.payload.data.client,
          statusMap: res.payload.data.client_status_maps
        }
      });
    });
  }

  setToEdit(statusMapId) {
    this.setState({
      editedStatusMapId: statusMapId,
    });
  }

  cancelEdit() {
    this.setState({
      editedStatusMapId: null,
    });
  }

  onEdit(cellInfo) {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { client, editedStatusMapId } = this.state;
    if (editedStatusMapId === cellInfo.original.id) {
      if (cellInfo.column.id === 'external_status') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              initialValue: client.statusMap[cellInfo.index][cellInfo.column.id],
            })(<Input autoComplete="off" />)}
          </Form.Item>
        );
      }
      if (cellInfo.column.id === 'privacy') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              initialValue: client.statusMap[cellInfo.index][cellInfo.column.id],
            })(<Select allowClear={false} options={[
              {
                label: 'Private',
                value: 'private'
              },
              {
                label: 'Public',
                value: 'public'
              }
            ]} />)}
          </Form.Item>
        );
      }
      if (cellInfo.column.id === 'show_updater') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              initialValue: client.statusMap[cellInfo.index][cellInfo.column.id],
            })(<Select allowClear={false} options={[
              {
                label: 'Yes',
                value: 1
              },
              {
                label: 'No',
                value: 0
              }
            ]} />)}
          </Form.Item>
        );
      }
      if (cellInfo.column.id === 'show_reference') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              initialValue: client.statusMap[cellInfo.index][cellInfo.column.id],
            })(<Select allowClear={false} options={[
              {
                label: 'Yes',
                value: 1
              },
              {
                label: 'No',
                value: 0
              }
            ]} />)}
          </Form.Item>
        );
      }
      if (cellInfo.column.id === 'show_remarks') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              initialValue: client.statusMap[cellInfo.index][cellInfo.column.id],
            })(<Select allowClear={false} options={[
              {
                label: 'Yes',
                value: 1
              },
              {
                label: 'No',
                value: 0
              }
            ]} />)}
          </Form.Item>
        );
      }
    }

    if(['show_updater', 'show_reference', 'show_remarks'].includes(cellInfo.column.id)) {
      return <div>{client.statusMap[cellInfo.index][cellInfo.column.id] === 1 ? 'Yes': 'No'}</div>;
    }
    return <div>{client.statusMap[cellInfo.index][cellInfo.column.id]}</div>;
  }

  formSubmit(e) {
    e.preventDefault();
    const { form, doCreateClientStatusMap, match } = this.props;
    const { validateFields } = form;
    validateFields([
      'internal_status',
      'external_status',
      'privacy',
      'show_updater',
      'show_reference',
      'show_remarks',
      'description'
    ], (err, values) => {
      if (!err) {
        this.setState({ isLoading: true });
        const params = {
          client_id: match.params.id,
          internal_status: values.internal_status,
          external_status: values.external_status,
          privacy: values.privacy,
          show_updater: values.show_updater,
          show_reference: values.show_reference,
          show_remarks: values.show_remarks,
          description: values.description
        };

        doCreateClientStatusMap(params).then((res) => {
          if (res.payload.data.code === 'success') {
            this.fetchClientStatusMap();
            message.success('Successfully created client!');
          }
          if (!res.payload.data.passed) {
            _.forEach(res.payload.data.errors, (value, key) => {
              message.error(value);
            })
            this.setState({ isLoading: false });
          }
        });
      }
    });

  }

  onUpdateSubmit(e) {
    e.preventDefault();
    const { doUpdateClientStatusMap, form } = this.props;
    const { validateFields } = form;
    const { editedStatusMapId } = this.state;
    validateFields([
      'external_status_update',
      'privacy_update',
      'show_updater_update',
      'show_reference_update',
      'show_remarks_update',
      'description_update',
    ], (err, values) => {
      if (!err) {
        this.setState({ isLoading: true });
        const data = {
          external_status: values.external_status_update,
          privacy: values.privacy_update,
          show_updater: values.show_updater_update,
          show_reference: values.show_reference_update,
          show_remarks: values.show_remarks_update,
          description: values.description_update,
        };
        doUpdateClientStatusMap(data, editedStatusMapId)
          .then(() => {
            this.fetchClientStatusMap();

            message.success('Successfully updated status map', 3);
          });
      }
    });
  }

  confirmDelete(statusMapId) {
    const { doDeleteClientStatusMap } = this.props;

    this.setState({
      isLoading:true,
    });
    doDeleteClientStatusMap(statusMapId)
      .then((action) => {
        if (action.payload.data) {
          message.success('Successfully deleted status map.', 3);
          this.fetchClientStatusMap();
        }
      })
  }

  render() {
    const { form } = this.props;
    const { statusOptions, client, editedStatusMapId, isLoading } = this.state;
    const { getFieldDecorator } = form;
    const { TextArea } = Input;
    const breadCrumbs = [
      {
        breadcrumbName: 'Admin',
      },
      {
        breadcrumbName: 'Client',
      },
      {
        breadcrumbName: 'Status Map',
      },
    ];
    return (
      <div className="StatusMap">
        <PageHeader title="Status Map" routes={breadCrumbs} />
        <Spacer />
        <Container>
        <Card title={`${client.clientDetails.name}`}>
          <Form onSubmit={this.formSubmit}>
            <Row gutter={20}>
              <Col xs={24} sm={12} lg={12}>
                <Form.Item label="Internal Status">
                  {getFieldDecorator('internal_status', {
                    rules: [
                      { required: true, message: 'Internal status cannot be blank.' },
                    ],
                  })(
                    <Select
                      options={statusOptions}
                      placeholder="Select client"
                      autoComplete="off"
                      optionFilterProp="children"
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={12}>
                <Form.Item label="External Status">
                  {getFieldDecorator('external_status', {
                    rules: [
                      { required: true, message: 'External status cannot be blank.' },
                    ],
                  })(
                    <Input placeholder="Enter external status" autoComplete="off" />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item label="Description">
                  {getFieldDecorator('description', {})(
                    <TextArea placeholder="Enter description" autoComplete="off" rows={3}/>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={6} lg={6}>
                <Form.Item label="Privacy" labelAlign="left">
                  {getFieldDecorator('privacy', {
                    rules: [
                      { 
                        required: true,
                      },
                    ],
                    initialValue: 'private'
                  })(
                    <Radio.Group>
                      <Radio value="private">Private</Radio>
                      <Radio value="public">Public</Radio>
                    </Radio.Group>,
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={6} lg={6}>
                <Form.Item label="Show Updater" labelAlign="left">
                  {getFieldDecorator('show_updater', {
                    rules: [
                      { 
                        required: true,
                      },
                    ],
                    initialValue: false,
                  })(
                    <Radio.Group>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>,
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={6} lg={6}>
                <Form.Item label="Show Reference" labelAlign="left">
                  {getFieldDecorator('show_reference', {
                    rules: [
                      { 
                        required: true,
                      },
                    ],
                    initialValue: false
                  })(
                    <Radio.Group>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>,
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={6} lg={6}>
                <Form.Item label="Show Remarks" labelAlign="left">
                  {getFieldDecorator('show_remarks', {
                    rules: [
                      { 
                        required: true,
                      },
                    ],
                    initialValue: false
                  })(
                    <Radio.Group>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} lg={24}>
                <Button type="primary" htmlType="submit" block>
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faPlus} fixedWidth />
                  </Icon>
                  Add Status Map
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
        <Spacer />
        <Spin
              spinning={isLoading}
              size="small"
              tip="Fetching status maps . . ."
            >
          <Card title="Status Map List">
            <Form onSubmit={this.onUpdateSubmit}>
              <ReactTable
                data={client.statusMap}
                columns={[
                  {
                    Header: 'Internal Status',
                    accessor: 'internal_status',
                    filterable: true,
                    sortable: true,
                  },
                  {
                    id: 'external_status',
                    Header: 'External Status',
                    accessor: 'external_status',
                    filterable: true,
                    sortable: true,
                    Cell: this.onEdit,
                  },
                  {
                    id: 'privacy',
                    Header: 'Privacy',
                    accessor: 'privacy',
                    sortable: true,
                    Cell: this.onEdit,
                  },
                  {
                    id: 'show_updater',
                    Header: 'Show Updater',
                    accessor: 'show_updater',
                    sortable: true,
                    Cell: this.onEdit,
                  },
                  {
                    id: 'show_reference',
                    Header: 'Show Reference',
                    accessor: 'show_reference',
                    sortable: true,
                    Cell: this.onEdit,
                  },
                  {
                    id: 'show_remarks',
                    Header: 'Show Remarks',
                    accessor: 'show_remarks',
                    sortable: true,
                    Cell: this.onEdit,
                  },
                  {
                    id: 'description',
                    Header: 'Description',
                    accessor: 'description',
                    sortable: true,
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Options',
                    sortable: false,
                    filterable: false,
                    // eslint-disable-next-line no-unused-vars
                    Cell: data => (
                      <Row>
                      {
                     (editedStatusMapId !== data.original.id)
                       ? (
                         <Row>
                           <Col span={12}>
                             <Button type="link" onClick={() => this.setToEdit(data.original.id)} block>
                               <Icon viewBox="0 0 1024 1024">
                                 <FontAwesomeIcon icon={faEdit} fixedWidth />
                               </Icon>
                          Edit
                             </Button>
                           </Col>
                           <Col span={12}>
                             <Popconfirm
                               placement="leftBottom"
                               title={`Are you sure you want to delete ${data.original.external_status} from the list?`}
                               okText="Yes"
                               onConfirm={() => this.confirmDelete(data.original.id)}
                               cancelText="No"
                             >
                               <Button type="link" block>
                                 <Icon viewBox="0 0 1024 1024">
                                   <FontAwesomeIcon icon={faTrash} fixedWidth />
                                 </Icon>
                          Delete
                               </Button>
                             </Popconfirm>
                           </Col>
                         </Row>
                       )
                       : (
                         <div>
                           <Col span={12}>
                             <Button type="link" htmlType="submit" block>
                               <Icon viewBox="0 0 1024 1024">
                                 <FontAwesomeIcon icon={faSave} fixedWidth />
                               </Icon>
                               Save
                             </Button>
                           </Col>

                           <Col span={12}>
                             <Button type="link" onClick={this.cancelEdit} block>
                               <Icon viewBox="0 0 1024 1024">
                                 <FontAwesomeIcon icon={faTimes} fixedWidth />
                               </Icon>
                               Cancel
                             </Button>
                           </Col>
                         </div>
                       )
                      }
                    </Row>
                  ),
                  },
                ]}
              />
            </Form>
          </Card>
        </Spin>
      </Container>
    </div>
    )
  }
}

const mapDispatchToProps = {
  doFetchOptions: fetchOptions,
  doFetchOptionsLike: fetchOptionsLike,
  doCreateClientStatusMap: createClientStatusMap,
  doFetchClientStatusMap: fetchClientStatusMap,
  doUpdateClientStatusMap: updateClientStatusMap,
  doDeleteClientStatusMap: deleteClientStatusMap,
}

StatusMap.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchOptions: PropTypes.func.isRequired,
  doFetchOptionsLike: PropTypes.func.isRequired,
  doCreateClientStatusMap: PropTypes.func.isRequired,
  doFetchClientStatusMap: PropTypes.func.isRequired,
  doUpdateClientStatusMap: PropTypes.func.isRequired,
  doDeleteClientStatusMap: PropTypes.func.isRequired,
}

const WrappedStatusMap = Form.create({ name: 'StatusMap' })(StatusMap);
export default connect(null, mapDispatchToProps)(WrappedStatusMap);