import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { message, Spin } from 'antd';
import moment from 'moment';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import DispatchForm from '../../components/DispatchForm';
import { fetchCustomerPickupTrackingNumber, updateCustomerPickup } from './newDucks';
import { fetchPickupDetails } from '../ducks';

export class CustomerPickupDispatchEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dispatchDetails: {},
      trackingNumberRows: [],
      errors: [],
      isMountingModule: true,
      isSubmitting: false,
    };
    this.packageFormHandler = this.packageFormHandler.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
  }

  componentDidMount() {
    const { doFetchCustomerPickupDetails, match } = this.props;

    doFetchCustomerPickupDetails(match.params.id).then((action) => {
      const packageList = [];
      // eslint-disable-next-line no-unused-vars
      action.payload.data.packages.forEach((item, index) => {
        packageList.push({
          customer: item.shipper_name,
          tracking_number: item.tracking_number,
          customer_address: '-',
          customer_contact_number: '-',
        });
      });

      this.setState({
        trackingNumberRows: packageList,
        dispatchDetails: action.payload.data.pickup,
        isMountingModule: false,
      });
    });
  }

  deleteHandler(trackingNumber) {
    const { trackingNumberRows } = this.state;
    const filteredTrackingNumber = trackingNumberRows.filter(
      data => data.tracking_number !== trackingNumber,
    );

    this.setState({
      trackingNumberRows: filteredTrackingNumber,
    });

    message.success('Successfully removed tracking number!');
  }

  packageFormHandler(values) {
    const { trackingNumberRows } = this.state;
    const { doFetchCustomerPickupTrackingNumber } = this.props;
    const splitTrackingNumber = values.tracking_number ? values.tracking_number.split('\n') : '';
    const filteredTrackingNumber = splitTrackingNumber.filter(
      (item, key) => splitTrackingNumber.indexOf(item) >= key && item !== '',
    );
    // eslint-disable-next-line no-unused-vars
    filteredTrackingNumber.forEach((item, key) => {
      const exists = trackingNumberRows.filter(tn => tn.tracking_number === item);

      if (exists.length > 0) {
        message.error(`Tracking number ${item} is already entered.`);
        this.setState(prevState => ({
          errors: [
            ...prevState.errors,
            {
              tracking_number: item,
              message: 'Tracking number is already entered.',
            },
          ],
        }));
      } else {
        doFetchCustomerPickupTrackingNumber(item)
          .then((action) => {
            this.setState(prevState => ({
              trackingNumberRows: [
                ...prevState.trackingNumberRows,
                {
                  customer: action.payload.data.shipper.shipper_name,
                  tracking_number: item,
                  customer_address: action.payload.data.shipper.shipper_address,
                  customer_contact_number: action.payload.data.shipper.shipper_contact,
                },
              ],
            }));
            message.success(`Successfully added ${item}`);
          })
          .catch((action) => {
            let errorMessage;
            if (action.error.response.status === 400) {
              errorMessage = action.error.response.data.message;
              this.setState(prevState => ({
                errors: [
                  ...prevState.errors,
                  {
                    tracking_number: item,
                    message: action.error.response.data.message,
                  },
                ],
              }));
            } else {
              errorMessage = 'Something went wrong, please try again later.';
            }
            message.error(errorMessage, 3);
          });
      }
    });
  }

  formSubmitHandler(values) {
    const { trackingNumberRows } = this.state;
    const { doUpdateCustomerPickup, match, history } = this.props;
    if (trackingNumberRows.length > 0) {
      const packages = [];

      this.setState({
        isSubmitting: true,
      });
      // eslint-disable-next-line no-unused-vars
      trackingNumberRows.forEach((item, key) => {
        packages.push(item.tracking_number);
      });

      const params = {
        ...values,
        shipper_id: 1,
        expected_arrival: moment(values.expected_arrival).format('YYYY-MM-DD HH:mm:ss'),
        type: 'customer',
        packages,
      };

      doUpdateCustomerPickup(match.params.id, params)
        .then(() => {
          this.setState({
            isSubmitting: false,
          });
          history.push(`/customer-pickup/${match.params.id}`);
        })
        .catch(() => {
          this.setState({
            isSubmitting: false,
          });
          message.error('Something went wrong, please try again later.');
        });
    } else {
      message.error('No tracking number found!');
    }
  }

  render() {
    const {
      trackingNumberRows,
      errors,
      dispatchDetails,
      isMountingModule,
      isSubmitting,
    } = this.state;
    const { match } = this.props;

    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Customer Pickup',
      },
      {
        breadcrumbName: 'List',
      },
      {
        breadcrumbName: `Edit #${match.params.id}`,
      },
    ];

    const listColumns = [
      {
        Header: 'Tracking Number',
        accessor: 'tracking_number',
      },
      {
        Header: 'Customer',
        accessor: 'customer',
      },
      {
        Header: 'Customer Address',
        accessor: 'customer_address',
      },
      {
        Header: 'Customer Contact Number',
        accessor: 'customer_contact_number',
      },
    ];

    const selectOptions = ['vehicle', 'courier'];

    const requiredFormSubmitFields = [
      'driver_id',
      'driver2_id',
      'helper_id',
      'helper2_id',
      'vehicle_id',
      'remarks',
      'expected_arrival',
      'shipper_id',
      'driver_rate',
      'driver2_rate',
      'helper_rate',
      'helper2_rate',
    ];

    const requiredPackageFormFields = ['tracking_number'];

    return (
      <div className="CustomerPickupDispatchEdit">
        <Spin spinning={isMountingModule} size="small" tip="Fetching customer pickup details. . .">
          <PageHeader title={`Customer Pickup #${match.params.id}`} routes={breadCrumbs} />
          <Container>
            <DispatchForm
              formInformation="Customer Pickup Information"
              tablInformation="Customer Pickup Dispatch List"
              selectOptions={selectOptions}
              requiredFormSubmitFields={requiredFormSubmitFields}
              requiredPackageFormFields={requiredPackageFormFields}
              listColumns={listColumns}
              packageFormHandler={this.packageFormHandler}
              formSubmitHandler={this.formSubmitHandler}
              deleteHandler={this.deleteHandler}
              dispatchItems={trackingNumberRows}
              errorItems={errors}
              dispatchDetails={dispatchDetails}
              isSubmitting={isSubmitting}
              hasExpectedArrival
              hasFormMerchant
              isDispatchEdit
            />
          </Container>
        </Spin>
      </div>
    );
  }
}

CustomerPickupDispatchEdit.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchCustomerPickupTrackingNumber: PropTypes.func.isRequired,
  doUpdateCustomerPickup: PropTypes.func.isRequired,
  doFetchCustomerPickupDetails: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchCustomerPickupTrackingNumber: fetchCustomerPickupTrackingNumber,
  doUpdateCustomerPickup: updateCustomerPickup,
  doFetchCustomerPickupDetails: fetchPickupDetails,
};

export default connect(
  null,
  mapDispatchToProps,
)(CustomerPickupDispatchEdit);
