/* exported formValidationSetup, refreshErrorMessages */
/* jshint unused:false */
import axios from 'axios';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const waybillViews = tNumber => ({
  url: `/web/package/details/${tNumber}?history=true`,
  method: 'GET',
});

export const acceptToWarehouseTnumber = (type, tNumber) => ({
  url: `/web/package/validate/${type}/${tNumber}`,
  method: 'GET',
});

export const apiLogin = {
  url: '/web/login',
  method: 'POST',
};

// kind of list
export const apiFetchList = page => ({
  url: `/web/${page}/list`,
  method: 'POST',
});

// resend_delivered
// resend_failed_delivery
// resend_returned
export const repushStatus = (resentMethod, tnumber) => ({
  url: `/web/package/validate/${resentMethod}/${tnumber}`,
  method: 'GET',
});

export const apiCreateUser = {
  url: '/web/user',
  method: 'POST',
};

export const webListUsers = {
  url: '/web/user/list',
  method: 'POST',
};

export const webUserGetUserAccount = userId => ({
  url: `/web/user/${userId}`,
  method: 'GET',
});

export const apiUsersEdit = userId => ({
  url: `/web/user/edit/${userId}`,
  method: 'POST',
});

export const apiUsersResetPassword = userId => ({
  url: `/web/user/reset-password/${userId}`,
  method: 'GET',
});

export const getOptions = option => ({
  url: `/web/options/${option}`,
  method: 'GET',
});

export const getOptionsProvinceCity = (option, filter) => ({
  url: `/web/options/${option}?filter=${filter}`,
  method: 'GET',
});

export const getOptionsCity = option => ({
  url: `/web/options/city?filter=&reference=${option}`,
  method: 'GET',
});

export const remittanceCreate = dispatchNumber => ({
  url: `/web/dispatch/${dispatchNumber}`,
  method: 'GET',
});

export const remittanceCreateCancel = dispatchNumber => ({
  url: `/web/dispatch/${dispatchNumber}`,
  method: 'DELETE',
});

export const remittanceCreateTrackingNumber = (
  dispatchNumber,
  trackingNumber,
) => ({
  url: `/web/remittance/validate/${dispatchNumber}/${trackingNumber}`,
  method: 'GET',
});

export const remittanceRemitted = {
  url: '/web/remittance/remitted',
  method: 'POST',
};

export const unRemittanceRemitted = {
  url: '/web/remittance/unremitted',
  method: 'POST',
};

export const unRemittedViews = id => ({
  url: `/web/remittance/${id}`,
  method: 'GET',
});

export const remittance = () => ({
  url: '/web/remittance',
  method: 'POST',
});

export const cashierUnremittedList = {
  url: '/web/xde-remittance/cashier-unremitted-list',
  method: 'POST',
};

export const cashierRemittanceList = page => ({
  url: `/web/xde-remittance/${page}`,
  method: 'POST',
});

export const cashierRemittanceImport = {
  url: '/web/xde-remittance/import',
  method: 'POST',
};

export const billingUnbilledViews = tNumber => ({
  url: `/web/package/details/${tNumber}?history=true`,
  method: 'GET',
});

export const billingListViews = id => ({
  url: `/web/billing/${id}`,
  method: 'GET',
});

export const billingList = page => ({
  url: `/web/billing/${page}`,
  method: 'POST',
});

export const uploadConfirm = page => ({
  url: `/web/${page}`,
  method: 'POST',
});

export const customerPickupList = {
  url: '/web/pickup/list/get',
  method: 'POST',
};

export const deliveryStatus = tNumber => ({
  url: `/web/package/status/${tNumber}`,
  method: 'POST',
});

export const deliveryValidate = (page, tNumber) => ({
  url: `/web/package/validate/${page}/${tNumber}`,
  method: 'GET',
});

export const deliveryList = {
  url: '/web/dispatch/list/get',
  method: 'POST',
};

export const merchantPickupType = (type, tNumber) => ({
  url: `/web/transmittal/package/${type}/${tNumber}`,
  method: 'GET',
});

export const merchantPickupReject = {
  url: '/web/transmittal',
  method: 'POST',
};

export const transmittalReject = transID => ({
  url: `/web/transmittal/${transID}`,
  method: 'GET',
});

export const waybillList = {
  url: '/web/package/list',
  method: 'POST',
};

export const postWaybill = {
  url: '/web/package',
  method: 'POST',
};

export const dispatchTnumber = (page, tnumber) => ({
  url: `/web/dispatch/package/${page}/${tnumber}`,
  method: 'GET',
});

export const dispatchSend = {
  url: '/web/dispatch',
  method: 'POST',
};

export const getOptionsDispatch = options => ({
  url: `/web/options/${options}?filter=`,
  method: 'GET',
});

export const customerPickupDispatch = tNumber => ({
  url: `/web/customer-pickup/package/${tNumber}`,
  method: 'GET',
});

export const sendAllCustomer = page => ({
  url: `/web/${page}`,
  method: 'POST',
});

export const merchantWebPickup = pickupID => ({
  url: `/web/pickup/${pickupID}`,
  method: 'GET',
});

export const merchantPickupSendTrackingNumber = (Tnumber, shipperID) => ({
  url: `/web/pickup/package/merchant/${Tnumber}?shipper_id=${shipperID}`,
  method: 'GET',
});

export const carrierShipment = {
  url: '/web/carrier/selections',
  method: 'GET',
};

export const viewPickups = (page, id) => ({
  url: `/web/${page}/${id}`,
  method: 'GET',
});

export const pickupDispatchEdit = (page, id) => ({
  url: `/web/${page}/${id}`,
  method: 'POST',
});

export const adminEditPage = (page, id) => ({
  url: `/web/${page}/${id}`,
  method: 'PUT',
});

export const deleteDispatchList = tnumber => ({
  url: `/web/dispatch/${tnumber}`,
  method: 'DELETE',
});

export const monitoringPage = page => ({
  url: `/web/monitoring/${page}`,
  method: 'GET',
});

export const dispatchPrint = dispatchNumber => ({
  url: `/web/dispatch/print/${dispatchNumber}`,
  method: 'GET',
});

export const apiFetchClientsLike = string => ({
  url: `/web/options/client?filter=${string}`,
  method: 'GET',
});

export const massUpload = page => ({
  url: `web/${page}/import`,
  method: 'POST',
});

// billing_upload_template.xlsx
// remittance_upload_template.xlsx

export const template = tempName => ({
  url: `/templates/${tempName}`,
  method: 'GET',
});

export const exportwaybill = todo => ({
  url: `/web/package/${todo}`,
  method: 'POST',
});

const openAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const secureAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const secureRequestMiddlewareOptions = {
  returnRejectedPromiseOnError: true,
  interceptors: {
    request: [
      {
        success: async ({ getState, dispatch, getSourceAction }, req) => {
          req.headers = {
            ...req.headers,
            token: await window.localStorage.getItem('token'),
          };
          return req;
        },
        error: ({ getState, dispatch, getSourceAction }, req) => req,
      },
    ],
    response: [
      {
        success: ({ getState, dispatch, getSourceAction }, res) => res,
        error: ({ getState, dispatch, getSourceAction }, res) => {
          if (res.response && res.response.status === 401) {
            // dispatch(logout());
          }
          return Promise.reject(res);
        },
      },
    ],
  },
};

const openRequestMiddlewareOptions = {
  returnRejectedPromiseOnError: true,
  interceptors: {
    response: [
      {
        success: ({ getState, dispatch, getSourceAction }, res) => res,
        error: ({ getState, dispatch, getSourceAction }, res) => {
          if (!res.response) {
            // dispatch(navigateToOfflineScreen);
          }
          return Promise.reject(res);
        },
      },
    ],
  },
};

export const apiClients = {
  default: {
    client,
  },
  secure: {
    client: secureAxiosInstance,
    options: secureRequestMiddlewareOptions,
  },
  open: {
    client: openAxiosInstance,
    options: openRequestMiddlewareOptions,
  },
};
