import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, message, Icon, Popconfirm } from "antd";
import moment from "moment";
import PageHeader from "../../components/PageHeader";
import Container from "../../components/Container";
import StatusTaggingForm from "../../components/StatusTaggingForm";
import {
  fetchTrackingNumber,
  updateTrackingNumberStatus,
  fetchOptions
} from "../ducks";

export class DeliveryFailed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trackingNumberRows: [],
      errors: [],
      reason: [],
      success: [],
      isDisabled: false
    };

    this.validatePackage = this.validatePackage.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.confirmRemove = this.confirmRemove.bind(this);
  }

  componentDidMount() {
    const { doFetchOptions } = this.props;
    doFetchOptions("reason").then(action => {
      this.setState({
        reason: action.payload.data
      });
    });
  }

  submitForm() {
    this.setState({ isDisabled: true });
    const { trackingNumberRows } = this.state;
    const { doUpdateTrackingNumberStatus } = this.props;

    // eslint-disable-next-line no-unused-vars
    trackingNumberRows.forEach((item, key) => {
      const payload = {
        current_status: item.current_status,
        comment: item.comment,
        status: item.status,
        reference_code: item.reference_code,
        tracking_number: item.tracking_number,
        timestamp: item.timestamp
      };

      doUpdateTrackingNumberStatus(payload, item.tracking_number)
        .then(action => {
          this.setState({
            trackingNumberRows: [],
            isDisabled: false
          });

          message.success(`Successfully updated ${item.tracking_number}`);
          this.setState(prevState => ({
            success: [
              ...prevState.success,
              {
                tracking_number: action.payload.data.tracking_number,
                address_city: action.payload.data.address_city,
                reference_code: action.payload.data.status_reference,
                status: action.payload.data.status
              }
            ]
          }));
          message.success(`Successfully updated ${item.tracking_number}`);
        })
        .catch(() => {
          message.error("Something went wrong, please try again later.");
          this.setState({
            isDisabled: false
          });
        });
    });
  }

  validatePackage(values) {
    const { trackingNumberRows } = this.state;
    const { doFetchTrackingNumber } = this.props;

    const splitTrackingNumber = values.trackingNumber
      ? values.trackingNumber.split("\n")
      : "";
    const filteredTrackingNumber = splitTrackingNumber.filter(
      (item, key) => splitTrackingNumber.indexOf(item) >= key && item !== ""
    );
    // eslint-disable-next-line no-unused-vars
    filteredTrackingNumber.forEach((item, key) => {
      const exists = trackingNumberRows.filter(
        tn => tn.tracking_number === item
      );

      if (exists.length > 0) {
        message.error(`Tracking number ${item} is already entered.`);
        this.setState(prevState => ({
          errors: [
            ...prevState.errors,
            {
              tracking_number: item,
              message: "Tracking number is already entered."
            }
          ]
        }));
      } else {
        doFetchTrackingNumber("failed_delivery", item)
          .then(action => {
            this.setState(prevState => ({
              trackingNumberRows: [
                ...prevState.trackingNumberRows,
                {
                  tracking_number: action.payload.data.tracking_number,
                  current_status: action.payload.data.status,
                  status: "failed_delivery",
                  reference_code: values.reason,
                  timestamp: moment(values.timestamp).format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                  status_reference: action.payload.data.status_reference,
                  comment: values.comment
                }
              ]
            }));
            message.success("Successfully added tracking number!.");
          })
          .catch(action => {
            let errorMessage;
            if (action.error.response.status === 400) {
              errorMessage = action.error.response.data.message;
              this.setState(prevState => ({
                errors: [
                  ...prevState.errors,
                  {
                    tracking_number: item,
                    message: action.error.response.data.message
                  }
                ]
              }));
            } else {
              errorMessage = "Something went wrong, please try again later.";
            }
            message.error(errorMessage, 3);
          });
      }
    });
  }

  confirmRemove(trackingNumber) {
    const { trackingNumberRows } = this.state;
    const filteredTrackingNumber = trackingNumberRows.filter(
      data => data.tracking_number !== trackingNumber
    );

    this.setState({
      trackingNumberRows: filteredTrackingNumber
    });

    message.success("Successfully removed tracking number!");
  }

  render() {
    const {
      trackingNumberRows,
      errors,
      reason,
      success,
      isDisabled
    } = this.state;
    const breadCrumbs = [
      {
        breadcrumbName: "Packages"
      },
      {
        breadcrumbName: "Delivery"
      },
      {
        breadcrumbName: "Failed"
      }
    ];

    const trackingNumberRowColumns = [
      {
        Header: "Tracking Number",
        accessor: "tracking_number"
      },
      {
        Header: "Current Status",
        accessor: "current_status"
      },
      {
        Header: "Target Status",
        accessor: "status"
      },
      {
        Header: "Reason",
        accessor: "reference_code"
      },
      {
        Header: "Comment",
        accessor: "comment"
      },
      {
        Header: "Timestamp",
        accessor: "timestamp"
      },
      {
        Header: "Options",
        sortable: false,
        filterable: false,
        Cell: data => (
          <React.Fragment>
            <Popconfirm
              placement="leftBottom"
              title={`Are you sure you want to delete ${data.row.tracking_number} from the list?`}
              okText="Yes"
              onConfirm={() => this.confirmRemove(data.row.tracking_number)}
              cancelText="No"
            >
              <Button type="link" block>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faTrash} fixedWidth />
                </Icon>
                Remove
              </Button>
            </Popconfirm>
          </React.Fragment>
        )
      }
    ];

    return (
      <div className="DeliveryFailed">
        <PageHeader title="Delivery" routes={breadCrumbs} />
        <Container>
          <StatusTaggingForm
            inputDisabled={isDisabled}
            validateHandler={this.validatePackage}
            submitHandler={this.submitForm}
            trackingNumberList={trackingNumberRows}
            trackingNumberListColumns={trackingNumberRowColumns}
            successList={success}
            errorList={errors}
            options={reason}
            isFailedStatus
            hasDatePicker
          />
        </Container>
      </div>
    );
  }
}

DeliveryFailed.propTypes = {
  doFetchTrackingNumber: PropTypes.func.isRequired,
  doUpdateTrackingNumberStatus: PropTypes.func.isRequired,
  doFetchOptions: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  doFetchTrackingNumber: fetchTrackingNumber,
  doUpdateTrackingNumberStatus: updateTrackingNumberStatus,
  doFetchOptions: fetchOptions
};

export default connect(null, mapDispatchToProps)(DeliveryFailed);
