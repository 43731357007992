import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Card, Form, Steps, Upload, Icon, Col, Button, Row, message, Result, Spin,
} from 'antd';
import { connect } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import Spacer from '../../components/Spacer';
import Container from '../../components/Container';
import ReactTable from '../../components/ReactTable';

import { validateDimweightFile, updateDimweightDimensions } from './ducks';

class DimweightUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
      fileContentPreview: {
        success: [],
        total_success: 0,
        errors: [],
        total_errors: 0,
      },
      spinText: null,
      fileList: [],
      isUpdating: false,
      isValidating: false,
      uploadDisabled: false,
    };

    this.beforeUploadHandler = this.beforeUploadHandler.bind(this);
    this.removeUploadHandler = this.removeUploadHandler.bind(this);
    this.validateFile = this.validateFile.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.confirmUpdate = this.confirmUpdate.bind(this);
  }

  validateFile() {
    const { fileList } = this.state;
    const { doValidateDimweightFile } = this.props;

    if (fileList.length === 0) {
      message.error('Please provide an excel file to be validated!');
    } else {
      this.setState({
        isValidating: true,
        uploadDisabled: true,
        spinText: 'Validating tracking numbers...',
      });

      const formData = new FormData();

      formData.append('file', fileList[0]);
      // perform dispatch

      doValidateDimweightFile(formData).then((action) => {
        this.setState({
          currentStep: 1,
          fileContentPreview: {
            success: action.payload.data.success,
            total_success: action.payload.data.total_success,
            errors: action.payload.data.errors,
            total_errors: action.payload.data.total_errors,
          },
          isValidating: false,
          uploadDisabled: false,
          fileList: [],
        });
      }).catch(() => {
        this.setState({
          fileList: [],
          isValidating: false,
          uploadDisabled: false,
        });
      });
    }
  }

  beforeUploadHandler(file) {
    this.setState({
      fileList: [file],
    });
    return false;
  }

  removeUploadHandler() {
    this.setState({
      fileList: [],
    });
  }

  previousStep() {
    this.setState(prevState => ({
      currentStep: prevState.currentStep - 1,
    }));
  }

  // eslint-disable-next-line consistent-return
  confirmUpdate(data) {
    const { doUpdateDimweightDimensions } = this.props;

    if (data.length === 0) {
      message.error('There are no tracking numbers to be updated!');
      return false;
    }
    this.setState({
      isUpdating: true,
      spinText: 'Updating package dimensions...',
    });


    doUpdateDimweightDimensions(data).then(() => {
      this.setState({
        currentStep: 2,
        isUpdating: false,
        spinText: null,
      });
    }).catch(() => {
      this.setState({
        currentStep: 0,
        isUpdating: false,
        spinText: null,
      });
    });
  }

  render() {
    const { Step } = Steps;
    const { Dragger } = Upload;
    const {
      currentStep, fileList, fileContentPreview, isUpdating, isValidating, uploadDisabled, spinText,
    } = this.state;

    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Tools',
      },
      {
        breadcrumbName: 'Dimweight Upload',
      },
    ];

    return (
      <Spin spinning={isValidating || isUpdating} tip={spinText}>
        <div className="DimweightUpload">
          <PageHeader title="DIMWEIGHT UPLOAD" routes={breadCrumbs} />
          <Spacer />
          <Container>
            <Card>
              <Steps current={currentStep}>
                <Step title="Select File" />
                <Step title="File Preview" />
                <Step title="Done" />
              </Steps>
            </Card>
            <Spacer />
            <Card
              title="Upload Form"
              extra={(
                <React.Fragment>
                  {
                            currentStep === 0 ? (
                              <Button
                                type="primary"
                                onClick={() => window.open(
                                  `${process.env.REACT_APP_API_URL}/templates/template_dimweight.upload.xlsx`,
                                )}
                              >
                                    Download Template
                              </Button>
                            ) : ''
                        }
                </React.Fragment>
)}
            >
              {currentStep === 0 ? (
                <React.Fragment>
                  <Dragger
                    fileList={fileList}
                    beforeUpload={this.beforeUploadHandler}
                    onRemove={this.removeUploadHandler}
                    multiple={false}
                    accept=".xlsx"
                    disabled={uploadDisabled}
                  >
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">Download the template on the top right to use the correct format.</p>
                  </Dragger>
                  <Spacer />
                  <Button onClick={this.validateFile} type="primary" style={{ float: 'right' }} disabled={isValidating}>
                    {isValidating ? '...Validating' : 'Submit'}
                  </Button>
                </React.Fragment>
              ) : ''}

              {currentStep === 1 ? (
                <React.Fragment>
                  <Card
                    title={`Success (${fileContentPreview.total_success})`}
                  >
                    <ReactTable
                      data={fileContentPreview.success}
                      columns={[
                        {
                          Header: 'Tracking Number',
                          accessor: 'tracking_number',
                        },
                        {
                          Header: 'Length',
                          accessor: 'length',
                        },
                        {
                          Header: 'Width',
                          accessor: 'width',
                        },
                        {
                          Header: 'Height',
                          accessor: 'height',
                        },
                        {
                          Header: 'Weight',
                          accessor: 'weight',
                        },
                        {
                          Header: 'Package Size',
                          accessor: 'package_size',
                        },
                      ]}
                      minRows={0}
                    />
                  </Card>
                  <Spacer />
                  <Card
                    title={`Errors (${fileContentPreview.total_errors})`}
                  >
                    <ReactTable
                      data={fileContentPreview.errors}
                      columns={[
                        {
                          Header: 'Tracking Number',
                          accessor: 'tracking_number',
                        },
                        {
                          Header: 'Message',
                          accessor: 'error',
                        },
                      ]}
                      minRows={0}
                    />

                  </Card>
                  <Spacer />
                  <Row gutter={5}>
                    <Col>
                      <Button
                        type="primary"
                        onClick={() => { this.confirmUpdate(fileContentPreview.success); }}
                        style={{ float: 'right' }}
                        disabled={isUpdating || fileContentPreview.success.length === 0}
                      >
                        {isUpdating ? '...Uploading' : 'Confirm'}
                      </Button>
                    </Col>
                    <Col>
                      <Button onClick={this.previousStep} type="primary" style={{ float: 'right', marginRight: '5px' }} disabled={isUpdating}>
                        Back
                      </Button>
                    </Col>
                  </Row>

                </React.Fragment>
              ) : ''}

              {currentStep === 2 ? (
                <Result
                  status="success"
                  title="Dimweight successfully updated"
                  extra={[
                    <Button
                      type="primary"
                      key="console"
                      onClick={() => {
                        this.setState({
                          currentStep: 0,
                        });
                      }}
                    >
                        Finish
                    </Button>,
                  ]}
                />
              ) : ''}
            </Card>
          </Container>
        </div>
      </Spin>
    );
  }
}

DimweightUpload.propTypes = {
  doValidateDimweightFile: PropTypes.func.isRequired,
  doUpdateDimweightDimensions: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doValidateDimweightFile: validateDimweightFile,
  doUpdateDimweightDimensions: updateDimweightDimensions,
};

const WrappedDimweightUpload = Form.create({ name: 'DimweightUpload' })(DimweightUpload);

export default connect(null, mapDispatchToProps)(WrappedDimweightUpload);
