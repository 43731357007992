import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, Button } from 'antd';
import fileDownload from 'js-file-download';
import Container from '../../../components/Container';
import Spacer from '../../../components/Spacer';
import PageHeader from '../../../components/PageHeader';
import PortClusterAdvancedSearch from './PortClusterAdvancedSearch';
import PortClusterList from './PortClusterList';
import {
  fetchPortClusterListRequest,
  fetchBranchCodeListRequest,
} from '../ducks';


function Main(props) {
  const {
    doFetchPortClusterListRequest,
    doFetchBranchCodeListRequest,
  } = props;

  const [listDetails, setListDetails] = useState({
    rows: [],
    pages: 0,
    total: 0,
  });

  const [tableFilter, setTableFilter] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted: [],
  });

  const [branchCodeList, setBranchCodeList] = useState([]);

  const [isFetchingTableDetails, setIsFetchingTableDetails] = useState(true);

  const [isExporting, setIsExporting] = useState(false);

  const [advancedFilter, setAdvancedFilter] = useState([]);

  const breadCrumbs = [
    {
      breadcrumbName: 'Home',
    },
    {
      breadcrumbName: 'Admin',
    },
    {
      breadcrumbName: 'Port Cluster',
    },
    {
      breadcrumbName: 'List',
    },
  ];

  function TableUpdater(state) {
    setTableFilter({
      page: state.page,
      pageSize: state.pageSize,
      filtered: state.filtered,
      sorted: state.sorted,
    });

    setIsFetchingTableDetails(true);
  }

  useEffect(() => {
    const locationTypes = ['province', 'barangay', 'city', 'branch', 'route_code'];

    locationTypes.forEach((item) => {
      tableFilter.filtered = tableFilter.filtered.filter(obj => obj.id !== `advanced_${item}_filter`);
    });

    if (advancedFilter.length !== 0) {
      Object.keys(advancedFilter).forEach((item) => {
        tableFilter.filtered.push(
          {
            id: advancedFilter[item].id,
            value: advancedFilter[item].value,
          },
        );
      });
    }

    doFetchPortClusterListRequest(tableFilter, false).then((res) => {
      setListDetails({
        rows: res.payload.data.rows,
        pages: res.payload.data.page,
        total: res.payload.data.total,
      });

      setIsFetchingTableDetails(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);


  useEffect(() => {
    doFetchBranchCodeListRequest().then((res) => {
      setBranchCodeList(res.payload.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exportPortClusterList = () => {
    setIsExporting(true);

    doFetchPortClusterListRequest(tableFilter, true).then((res) => {
      const file = new Blob([res.payload.data]);
      fileDownload(file, 'Port Cluster.csv');
      setIsExporting(false);
    });
  };

  return (
    <Container
      title="Port Cluster List"
    >
      <PageHeader title="PORT CLUSTER LIST" routes={breadCrumbs} />

      <Spacer />

      <Card
        title="Advanced Search"
      >
        <PortClusterAdvancedSearch
          tableFilter={tableFilter}
          onTableUpdate={TableUpdater}
          branchCodeList={branchCodeList}
          advancedFilter={advancedFilter}
          setAdvancedFilter={setAdvancedFilter}
        />
      </Card>

      <Spacer />

      <Card
        title={`Port Cluster List - ${listDetails.total}`}
        extra={(
          <React.Fragment>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => { exportPortClusterList(); }}
              disabled={isExporting}
              block
            >
                Export
            </Button>
          </React.Fragment>
        )}
      >
        <PortClusterList
          data={listDetails}
          tableFilter={tableFilter}
          onTableUpdate={TableUpdater}
          fetchingTableDetails={isFetchingTableDetails}
          branchCodeList={branchCodeList}
        />
      </Card>

    </Container>
  );
}

Main.propTypes = {
  doFetchPortClusterListRequest: PropTypes.func.isRequired,
  doFetchBranchCodeListRequest: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchPortClusterListRequest: fetchPortClusterListRequest,
  doFetchBranchCodeListRequest: fetchBranchCodeListRequest,
};

export default connect(null, mapDispatchToProps)(Main);
