import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
 DatePicker, Form, Button, Popconfirm, Icon 
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { accountList } from './ducks';
import PageHeader from '../../components/PageHeader';
import ReactTable from '../../components/ReactTable';
import Card from '../../components/Card';
import Container from '../../components/Container';
import Spacer from '../../components/Spacer';

export class Accounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: {
        statistics: [],
        displayDate: {},
      },
      dateSubmit: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    const { doAccountList } = this.props;
    doAccountList('statistics').then(res => this.setState({
        account: {
          statistics: res.payload.data.statistics,
          displayDate: res.payload.data.displayDate,
        },
        loading: false,
      }),);
  }

  getEditAccountHandler(clientId, category) {
    const { doAccountList } = this.props;
    const { account } = this.state;
    const params = {
      client_id: clientId,
      category,
      dates: {
        start: `${account.displayDate.start}`,
        end: `${account.displayDate.end}`,
      },
    };
    doAccountList('export', params).then((res) => {
      const newBlob = new Blob([res.payload.data]);
      fileDownload(newBlob, 'monitoring.csv');
    });
  }

  submitHandler() {
    this.setState({
      loading: true,
    });
    const { dateSubmit } = this.state;
    const { doAccountList } = this.props;
    const wrapDate = { 0: dateSubmit };
    doAccountList('statistics', wrapDate).then(res => this.setState({
        account: {
          statistics: res.payload.data.statistics,
          displayDate: res.payload.data.displayDate,
        },
        loading: false,
      }),);
  }

  dateHandler(dateStrings) {
    const newDateOne = dateStrings[0];
    const newDateTwo = dateStrings[1];
    const formatDateOne = format(newDateOne, 'YYYY-MM-DD HH:mm:ss');
    const formatDateTwo = format(newDateTwo, 'YYYY-MM-DD HH:mm:ss');
    this.setState({
      dateSubmit:
        formatDateOne !== undefined
          ? { dateFrom: `${formatDateOne}`, dateTo: `${formatDateTwo}` }
          : null,
    });
  }

  render() {
    const { account, loading } = this.state;
    const breadCrumbs = [
      {
        breadcrumbName: 'Monitoring',
      },
      {
        breadcrumbName: 'Accounts',
      },
    ];
    return (
      <div className="Accounts">
        <PageHeader title="Accounts" routes={breadCrumbs} />
        <Container>
          <Card>
            <Form.Item label="Date Range">
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                onChange={e => this.dateHandler(e)}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment('00:00', 'HH:mm'),
                    moment('00:00', 'HH:mm'),
                  ],
                }}
              />
            </Form.Item>
            <Button onClick={e => this.submitHandler(e)} type="primary" block>
              <Icon viewBox="0 0 1024 1024">
                <FontAwesomeIcon icon={faSearch} fixedWidth />
              </Icon>
              Filter
            </Button>
          </Card>
          <Spacer />
          <Card
            title={
              account.displayDate.start !== undefined
                ? `Performance from ${account.displayDate.start} to ${account.displayDate.end}`
                : ''
            }
          >
            <ReactTable
              data={account.statistics}
              loading={loading}
              columns={[
                {
                  Header: 'Account',
                  accessor: 'name',
                },
                {
                  Header: 'Performance %',
                  Cell: row => (
                    <React.Fragment>
                      {`${(
                        (row.original.closed / row.original.volume)
                        * 100
                      ).toFixed(2)} %`}
                    </React.Fragment>
                  ),
                },
                {
                  Header: 'Volume',
                  accessor: 'volume',
                  Cell: row => (
                    <React.Fragment>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getEditAccountHandler(
                            row.original.client_id,
                            'volume',
                          )
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">{row.original.volume}</Button>
                      </Popconfirm>
                    </React.Fragment>
                  ),
                },
                {
                  Header: 'Open',
                  accessor: 'open',
                  Cell: row => (
                    <React.Fragment>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getEditAccountHandler(
                            row.original.client_id,
                            'open',
                          )
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">{row.original.open}</Button>
                      </Popconfirm>
                    </React.Fragment>
                  ),
                },
                {
                  Header: 'Close',
                  accessor: 'closed',
                  Cell: row => (
                    <React.Fragment>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getEditAccountHandler(
                            row.original.client_id,
                            'closed',
                          )
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">{row.original.closed}</Button>
                      </Popconfirm>
                    </React.Fragment>
                  ),
                },
                {
                  Header: 'Delivered',
                  accessor: 'delivered',
                  Cell: row => (
                    <React.Fragment>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getEditAccountHandler(
                            row.original.client_id,
                            'delivered',
                          )
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">{row.original.delivered}</Button>
                      </Popconfirm>
                    </React.Fragment>
                  ),
                },
                {
                  Header: 'POD Returned',
                  accessor: 'pod_returned',
                  Cell: row => (
                    <React.Fragment>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getEditAccountHandler(
                            row.original.client_id,
                            'pod_returned',
                          )
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">{row.original.pod_returned}</Button>
                      </Popconfirm>
                    </React.Fragment>
                  ),
                },
                {
                  Header: 'Returned',
                  accessor: 'returned',
                  Cell: row => (
                    <React.Fragment>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getEditAccountHandler(
                            row.original.client_id,
                            'returned',
                          )
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">{row.original.returned}</Button>
                      </Popconfirm>
                    </React.Fragment>
                  ),
                },
                {
                  Header: 'Claims',
                  accessor: 'claims',
                  Cell: row => (
                    <React.Fragment>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getEditAccountHandler(
                            row.original.client_id,
                            'claims',
                          )
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">{row.original.claims}</Button>
                      </Popconfirm>
                    </React.Fragment>
                  ),
                },
                {
                  Header: 'Rejected',
                  accessor: 'rejected',
                  Cell: row => (
                    <React.Fragment>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getEditAccountHandler(
                            row.original.client_id,
                            'rejected',
                          )
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">{row.original.rejected}</Button>
                      </Popconfirm>
                    </React.Fragment>
                  ),
                },
              ]}
            />
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  doAccountList: accountList,
};
Accounts.propTypes = {
  doAccountList: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
