import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form, Button, Input, message, Row, Col, Card,
} from 'antd';
import Select from '../../components/Select';
import Container from '../../components/Container';
import PageHeader from '../../components/PageHeader';
import {
  portClusterCreateRequest,
  fetchBranchCodeListRequest,
  fetchPhilippineLocationTypeListRequest,
  fetchPhilippineLocationTypeListLikeRequest,
} from './ducks';
import Spacer from '../../components/Spacer';

function PortClusterCreate(props) {
  const {
    doPortClusterCreateRequest,
    form,
    doFetchBranchCodeListRequest,
    dofetchPhilippineLocationTypeListRequest,
    dofetchPhilippineLocationTypeListLikeRequest,
  } = props;

  const { getFieldDecorator, validateFields } = form;

  const [provinceList, setProvinceList] = useState([]);
  const [provinceValue, setProvinceValue] = useState(null);

  const [cityList, setCityList] = useState([]);
  const [cityValue, setCityValue] = useState(null);

  const [barangayList, setBarangayList] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [barangayValue, setBarangayValue] = useState(null);

  const [branchCodeList, setBranchCodeList] = useState([]);

  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    doFetchBranchCodeListRequest().then((res) => {
      setBranchCodeList(res.payload.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsCreating(true);
    validateFields((err, values) => {
      if (!err) {
        doPortClusterCreateRequest(values).then((res) => {
          message.success(res.payload.data.message);
          setIsCreating(false);
        }).catch((resErr) => {
        //   let errorMessage;
          if (resErr.error.response.status === 422) {
            // const index = Object.values(resErr.error.response.data);

            // eslint-disable-next-line prefer-destructuring
            // errorMessage = index[0]['barangay'];

            message.error(resErr.error.response.data.message, 3);
          }
          setIsCreating(false);
        });
      } else {
        setIsCreating(false);
      }
    });
  };

  const focusHandler = (type) => {
    if (type === 'province') {
      dofetchPhilippineLocationTypeListRequest(type).then((res) => {
        setProvinceList(res.payload.data);
      });
    } else if (type === 'city') {
      dofetchPhilippineLocationTypeListLikeRequest(type, provinceValue).then((res) => {
        setCityList(res.payload.data);
      });
    } else if (type === 'barangay') {
      dofetchPhilippineLocationTypeListLikeRequest(type, cityValue).then((res) => {
        setBarangayList(res.payload.data);
      });
    }
  };

  const searchHandler = (e, type) => {
    if (e.length >= 5) {
      if (type === 'province') {
        setProvinceList([
          {
            value: e,
            label: e,
          },
        ]);
      } else if (type === 'city') {
        setCityList([
          {
            value: e,
            label: e,
          },
        ]);
      } else if (type === 'barangay') {
        setBarangayList([
          {
            value: e,
            label: e,
          },
        ]);
      }
    }
  };

  const selectHandler = (e, type) => {
    if (type === 'province') {
      setProvinceValue(e);
    } else if (type === 'city') {
      setCityValue(e);
    } else if (type === 'barangay') {
      setBarangayValue(e);
    }
  };


  const breadCrumbs = [
    {
      breadcrumbName: 'Home',
    },
    {
      breadcrumbName: 'Admin',
    },
    {
      breadcrumbName: 'Port Cluster',
    },
    {
      breadcrumbName: 'Create',
    },
  ];

  return (
    <Container
      title="Port Cluster Form"
    >
      <PageHeader title="PORT CLUSTER CREATE" routes={breadCrumbs} />

      <Spacer />

      <Card
        title="Port Cluster Information"
      >
        <Form onSubmit={handleSubmit}>
          <Row gutter={12}>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item label="Porvince">
                {getFieldDecorator('province', {
                  rules: [
                    {
                      required: true,
                      message: 'Porvince field is required',
                    },
                  ],
                })(<Select
                  onSearch={e => searchHandler(e, 'province')}
                  onFocus={() => focusHandler('province')}
                  onSelect={e => selectHandler(e, 'province')}
                  options={provinceList}
                  placeholder="Select or Type a new province..."
                />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item label="City">
                {getFieldDecorator('city', {
                  rules: [
                    {
                      required: true,
                      message: 'City field is required',
                    },
                  ],
                })(<Select
                  disabled={provinceValue === null}
                  onSearch={e => searchHandler(e, 'city')}
                  onFocus={() => focusHandler('city')}
                  onSelect={e => selectHandler(e, 'city')}
                  options={cityList}
                  placeholder="Select or Type a new city..."
                />)}
              </Form.Item>
            </Col>

          </Row>

          <Row gutter={12}>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item label="Barangay">
                {getFieldDecorator('barangay', {
                  rules: [
                    {
                      required: true,
                      message: 'Barangay field is required',
                    },
                  ],
                })(<Select
                  disabled={cityValue === null}
                  onSearch={e => searchHandler(e, 'barangay')}
                  onFocus={() => focusHandler('barangay')}
                  onSelect={e => selectHandler(e, 'barangay')}
                  options={barangayList}
                  placeholder="Select or Type a new barangay..."
                />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item label="Route Code">
                {getFieldDecorator('route_code', {
                  rules: [
                    {
                      required: true,
                      message: 'Route Code field is required',
                    },
                  ],
                })(<Input placeholder="Enter route code here..." />)}
              </Form.Item>
            </Col>

          </Row>
          <Row gutter={12}>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item label="Branch">
                {getFieldDecorator('branch', {
                  rules: [
                    {
                      required: true,
                      message: 'Branch field is required',
                    },
                  ],
                })(<Select
                  options={branchCodeList}
                  showSearch
                  placeholder="Select branch here..."
                />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item label="Cluster">
                {getFieldDecorator('cluster', {
                  rules: [
                    {
                      required: true,
                      message: 'Cluster field is required',
                    },
                  ],
                })(<Input placeholder="Enter cluster here..." />)}
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ textAlign: 'right', marginRight: '10px' }}>
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                loading={isCreating}
              >
            Create
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Card>
    </Container>
  );
}


PortClusterCreate.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doPortClusterCreateRequest: PropTypes.func.isRequired,
  doFetchBranchCodeListRequest: PropTypes.func.isRequired,
  dofetchPhilippineLocationTypeListRequest: PropTypes.func.isRequired,
  dofetchPhilippineLocationTypeListLikeRequest: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doPortClusterCreateRequest: portClusterCreateRequest,
  doFetchBranchCodeListRequest: fetchBranchCodeListRequest,
  dofetchPhilippineLocationTypeListRequest: fetchPhilippineLocationTypeListRequest,
  dofetchPhilippineLocationTypeListLikeRequest: fetchPhilippineLocationTypeListLikeRequest,
};

const WrappedPortClusterCreate = Form.create({ name: 'PortClusterCreate ' })(PortClusterCreate);

export default connect(null, mapDispatchToProps)(WrappedPortClusterCreate);
