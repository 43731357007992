import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form, Col, Input, Button, Icon, Row, Card, Radio, Spin, message
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import Container from '../../components/Container';
import PageHeader from '../../components/PageHeader';
import Select from '../../components/Select';
import Spacer from '../../components/Spacer';
import ReactTable from '../../components/ReactTable';
import _ from 'lodash';
import { fetchOptions, fetchOptionsLike } from '../ducks';
import {
  createClientStatusMap,
  fetchStatusMapList,
} from './ducks'

export class StatusMap extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      statusOptions: [],
      clientOptions: [],
      statusMapList: [],
      isLoading: true,
      isSubmitting: false,
    }
    this.searchClientOption = this.searchClientOption.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
    this.fetchStatusMapList = this.fetchStatusMapList.bind(this);
  }

  componentDidMount() {
    const { doFetchOptions } = this.props;
    doFetchOptions(['status']).then((res) => {
      this.setState({
        statusOptions: res.payload.data,
      });
    });

    doFetchOptions(['client']).then((res) => {
      this.setState({
        clientOptions: res.payload.data.slice(0, 50),
      });
    });

    this.fetchStatusMapList();
  }

  fetchStatusMapList() {
    const { doFetchStatusMapList } = this.props;

    doFetchStatusMapList().then((res) => {
      this.setState({
        isLoading: false,
        editedStatusMapId: null,
        statusMapList: res.payload.data
      });
    });
  }

  searchClientOption(e) {
    const { doFetchOptionsLike } = this.props;

    this.setState({
      isFetching: true,
    });

    doFetchOptionsLike('client', e).then((res) => {
      this.setState({
          clientOptions: res.payload.data.slice(0, 50),
          isFetching: false,
       });
    });
  }

  formSubmit(e) {
    e.preventDefault();
    const { form, doCreateClientStatusMap, history } = this.props;
    const { validateFields } = form;
    validateFields([
      'client_id',
      'internal_status',
      'external_status',
      'privacy',
      'show_updater',
      'show_reference',
      'show_remarks'
    ], (err, values) => {
      if (!err) {
        this.setState({isSubmitting: true})
        const params = {
          client_id: values.client_id,
          internal_status: values.internal_status,
          external_status: values.external_status,
          privacy: values.privacy,
          show_updater: values.show_updater,
          show_reference: values.show_reference,
          show_remarks: values.show_remarks,
        };

        doCreateClientStatusMap(params).then((res) => {
          if (res.payload.data.code === 'success') {
            history.push(`/clients/${params.client_id}/status-map`);
          }
          if (!res.payload.data.passed) {
            _.forEach(res.payload.data.errors, (value, key) => {
              message.error(value);
            })
            this.setState({ isSubmitting: false });
          }
        });
      }
    });

  }

  render() {
    const { form, history } = this.props;
    const { statusOptions, clientOptions, statusMapList, isLoading, isSubmitting } = this.state;
    const { getFieldDecorator } = form;
    const { TextArea } = Input;
    const breadCrumbs = [
      {
        breadcrumbName: 'Admin',
      },
      {
        breadcrumbName: 'Client',
      },
      {
        breadcrumbName: 'Status Map',
      },
    ];
    return (
      <div className="StatusMap">
        <PageHeader title="Status Map" routes={breadCrumbs} />
        <Spacer />
        <Container>
        <Spin
              spinning={isSubmitting}
              size="small"
              tip="Submitting data . . ."
            >
          <Card>
            <Form onSubmit={this.formSubmit}>
              <Row>
                <Col xs={24} sm={24} lg={24}>
                  <Form.Item label="Client">
                    {getFieldDecorator('client_id', {
                      rules: [
                        {
                          required: true,
                          message: 'Client cannot be blank.',
                        },
                      ],
                    })(
                      <Select
                        onSearch={filter => this.searchClientOption(filter)}
                        options={clientOptions}
                        placeholder="Select client"
                        autoComplete="off"
                        optionFilterProp="children"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Internal Status">
                    {getFieldDecorator('internal_status', {
                      rules: [
                        { required: true, message: 'Internal status cannot be blank.' },
                      ],
                    })(
                      <Select
                        options={statusOptions}
                        placeholder="Select client"
                        autoComplete="off"
                        optionFilterProp="children"
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="External Status">
                    {getFieldDecorator('external_status', {
                      rules: [
                        { required: true, message: 'External status cannot be blank.' },
                      ],
                    })(
                      <Input placeholder="Enter external status" autoComplete="off" />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item label="Description">
                    {getFieldDecorator('description', {
                      rules: [
                        { 
                          required: true,
                        },
                      ]
                    })(
                      <TextArea placeholder="Enter description" autoComplete="off" rows={3}/>,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={6} lg={6}>
                  <Form.Item label="Privacy" labelAlign="left">
                    {getFieldDecorator('privacy', {
                      rules: [
                        { 
                          required: true,
                        },
                      ],
                      initialValue: 'private'
                    })(
                      <Radio.Group>
                        <Radio value="private">Private</Radio>
                        <Radio value="public">Public</Radio>
                      </Radio.Group>,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6} lg={6}>
                  <Form.Item label="Show Updater" labelAlign="left">
                    {getFieldDecorator('show_updater', {
                      rules: [
                        { 
                          required: true,
                        },
                      ],
                      initialValue: false,
                    })(
                      <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6} lg={6}>
                  <Form.Item label="Show Reference" labelAlign="left">
                    {getFieldDecorator('show_reference', {
                      rules: [
                        { 
                          required: true,
                        },
                      ],
                      initialValue: false
                    })(
                      <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6} lg={6}>
                  <Form.Item label="Show Remarks" labelAlign="left">
                    {getFieldDecorator('show_remarks', {
                      rules: [
                        { 
                          required: true,
                        },
                      ],
                      initialValue: false
                    })(
                      <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} lg={24}>
                  <Button type="primary" htmlType="submit" block>
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={faPlus} fixedWidth />
                    </Icon>
                    Create
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
          
          <Spacer />
          <Spin
                spinning={isLoading}
                size="small"
                tip="Fetching status maps . . ."
              >
            <Card title="Clients">
              <Form onSubmit={this.onUpdateSubmit}>
                <ReactTable
                  data={statusMapList}
                  columns={[
                    {
                      Header: 'Id',
                      accessor: 'client_id',
                    },
                    {
                      Header: 'Client',
                      accessor: 'name',
                      filterable: true,
                      sortable: true,
                    },
                    {
                      Header: 'Options',
                      sortable: false,
                      filterable: false,
                      // eslint-disable-next-line no-unused-vars
                      Cell: data => (
                        <Button type="link" onClick={() => history.push(`/clients/${data.original.client_id}/status-map`)} block>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faEye} fixedWidth />
                          </Icon>
                          View
                        </Button>
                      ),
                    },
                  ]}
                />
              </Form>
            </Card>
          </Spin>
        </Spin>
      </Container>
    </div>
    )
  }
}

const mapDispatchToProps = {
  doFetchOptions: fetchOptions,
  doFetchOptionsLike: fetchOptionsLike,
  doCreateClientStatusMap: createClientStatusMap,
  doFetchStatusMapList: fetchStatusMapList,
}

StatusMap.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchOptions: PropTypes.func.isRequired,
  doFetchOptionsLike: PropTypes.func.isRequired,
  doCreateClientStatusMap: PropTypes.func.isRequired,
  doFetchStatusMapList: PropTypes.func.isRequired,
}

const WrappedStatusMap = Form.create({ name: 'StatusMap' })(StatusMap);
export default connect(null, mapDispatchToProps)(WrappedStatusMap);