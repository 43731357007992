import { merchantPickupReject, transmittalReject } from '../../services/api';

const PICKUP_SUBMIT = 'transmittal/PICKUP_SUBMIT';
const PICKUP_SUBMIT_SUCCESS = 'transmittal/PICKUP_SUBMIT_SUCCESS';

export const submitReject = rejectData => ({
  type: PICKUP_SUBMIT,
  payload: {
    client: 'secure',
    request: {
      ...merchantPickupReject,
      data: rejectData,
    },
  },
});

const TRANSMITTAL_ID = 'transmittal/TRANSMITTAL_ID';
const TRANSMITTAL_ID_SUCCESS = 'transmittal/TRANSMITTAL_ID_SUCCESS';

export const submitTransId = rejectData => ({
  type: TRANSMITTAL_ID,
  payload: {
    client: 'secure',
    request: {
      ...transmittalReject(rejectData),
    },
  },
});

const initialState = {
  views: {},
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case PICKUP_SUBMIT_SUCCESS:
      return {
        ...state,
        views: action.payload.data,
      };

    case TRANSMITTAL_ID_SUCCESS:
      return {
        ...state,
        views: action.payload.data,
      };

    default:
      return state;
  }
}

export default reducer;
