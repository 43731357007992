import { waybillViews } from '../../services/api';
import {
  fetchItemsInWaybill,
  apiFetchInternalStatusHistory,
} from '../../services/apiEndpoints';

const WAYBILL_ACCOUNT_VIEW = 'componentWaybill/WAYBILL_ACCOUNT_VIEW';
const WAYBILL_ACCOUNT_VIEW_SUCCESS = 'componentWaybill/WAYBILL_ACCOUNT_VIEW_SUCCESS';

export const waybill = tNumber => ({
  type: WAYBILL_ACCOUNT_VIEW,
  payload: {
    client: 'secure',
    request: {
      ...waybillViews(tNumber),
    },
  },
});

const WAYBILL_ITEM_LIST = 'componentWaybill/WAYBILL_ITEM_LIST';

export const waybillItemList = tNumber => ({
  type: WAYBILL_ITEM_LIST,
  payload: {
    client: 'secure',
    request: {
      ...fetchItemsInWaybill(tNumber),
    },
  },
});

const FETCH_INTERNAL_STATUS_HISTORY = 'componentWaybill/FETCH_INTERNAL_STATUS_HISTORY';

export const fetchInternalStatusTransactionHistory = trackingNumber => ({
  type: FETCH_INTERNAL_STATUS_HISTORY,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchInternalStatusHistory(trackingNumber),
    },
  },
});

const initialState = {
  views: {},
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case WAYBILL_ACCOUNT_VIEW_SUCCESS:
      return {
        ...state,
        views: action.payload.data,
      };

    default:
      return state;
  }
}

export default reducer;
