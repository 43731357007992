import {
  faTachometerAlt,
  faFileAlt,
  faStream,
  faUndo,
  faPlus,
  faFileUpload,
  faWarehouse,
  faPeopleCarry,
  faTruck,
  faTimesCircle,
  faClipboardCheck,
  faReply,
  faBan,
  faTruckLoading,
  faCheckDouble,
  faTimes,
  faReplyAll,
  faRetweet,
  faHandPaper,
  faTrash,
  faFileInvoiceDollar,
  faWrench,
  faUser,
  faUserPlus,
  faUsers,
  faLayerGroup,
  faRedo,
  faTruckMoving,
  faMoneyBill,
  faChartBar,
  faCashRegister,
  faPiggyBank,
  faDesktop,
  faUserCheck,
  faClipboardList,
  faPallet,
  faBoxes,
  faMapMarkedAlt,
  faCheck,
  faShippingFast,
  faUpload,
  faList,
  faLocationArrow,
  faChartLine,
  faTrophy,
  faTasks,
  faCalendar,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import roles from '../services/roles';

export default {
  Dashboard: {
    roles: roles.dashboardPanel,
    routes: [
      {
        roles: roles.billingDashboard,
        key: '/dashboard',
        text: 'Dashboard',
        icon: faTachometerAlt,
        path: '/dashboard',
      },
      {
        roles: roles.dashboard,
        key: '/kpi',
        text: 'KPI Dashboard',
        icon: faTrophy,
        path: '/kpi',
      },
      {
        roles: roles.dashboard,
        key: '/backlogs',
        text: 'Branch Backlogs',
        icon: faWarehouse,
        path: '/backlogs',
      },
      {
        roles: roles.dashboard,
        key: '/volume',
        text: 'Volume Trend',
        icon: faChartLine,
        path: '/volume',
      },
      {
        roles: roles.dashboard,
        key: '/pickup/calendar',
        text: 'Pickup Calendar',
        icon: faCalendar,
        path: '/pickup/calendar',
      },
      {
        roles: roles.dashboard,
        key: '/courier/performance',
        text: 'Fleet Performance',
        icon: faPeopleCarry,
        path: '/courier/performance',
      },
    ],
  },
  Packages: {
    roles: roles.packagesPanel,
    routes: [
      {
        roles: roles.packageWaybillList,
        key: 'waybill',
        text: 'Waybill',
        icon: faFileAlt,
        items: [
          {
            roles: roles.packageWaybillList,
            key: '/waybill/list',
            text: 'List',
            icon: faStream,
            path: '/waybill/list',
          },
          {
            roles: roles.waybillOperations,
            key: '/waybill/create',
            text: 'Create',
            icon: faPlus,
            path: '/waybill/create',
          },
          {
            roles: roles.waybillOperations,
            key: '/waybill/upload',
            text: 'Upload',
            icon: faFileUpload,
            path: '/waybill/upload',
          },
        ],
      },
      {
        roles: roles.acceptToWarehouse,
        key: '/accept-to-warehouse',
        text: 'Accept To Warehouse',
        icon: faWarehouse,
        path: '/accept-to-warehouse',
      },
      {
        roles: roles.customerPickup,
        key: 'customer-pickup',
        text: 'Customer Pickup',
        icon: faPeopleCarry,
        items: [
          {
            roles: roles.customerPickupViews,
            key: '/customer-pickup/list',
            text: 'List',
            icon: faStream,
            path: '/customer-pickup/list',
          },
          {
            roles: roles.customerPickupOperations,
            key: '/customer-pickup/dispatch',
            text: 'Dispatch',
            icon: faTruckMoving,
            path: '/customer-pickup/dispatch',
          },
          {
            roles: roles.customerPickupTagging,
            key: 'customer_header',
            text: 'UPDATE STATUS',
          },
          {
            roles: roles.customerPickupTagging,
            key: '/customer-pickup/failed-pickup',
            text: 'Failed Pickup',
            icon: faTimesCircle,
            path: '/customer-pickup/failed-pickup',
          },
          {
            roles: roles.customerPickupTagging,
            key: '/customer-pickup/accept-by-courier',
            text: 'Accept By Courier',
            icon: faClipboardCheck,
            path: '/customer-pickup/accept-by-courier',
          },
        ],
      },
      {
        roles: roles.merchantPickup,
        key: 'merchant-pickup',
        text: 'Merchant Pickup',
        icon: faClipboardCheck,
        items: [
          {
            roles: roles.merchantPickupViews,
            key: '/merchant-pickup/list',
            text: 'List',
            icon: faStream,
            path: '/merchant-pickup/list',
          },
          // {
          //   roles: roles.packageTransmittal,
          //   key: '/merchant-pickup/transmittal/list',
          //   text: 'Transmittal List',
          //   icon: faStream,
          //   path: '/merchant-pickup/transmittal/list',
          // },
          {
            roles: roles.merchantPickupOperations,
            key: '/merchant-pickup/dispatch',
            text: 'Single Store Pickup',
            icon: faTruckMoving,
            path: '/merchant-pickup/dispatch',
          },
          {
            roles: roles.merchantPickupOperations,
            key: '/merchant-pickup/multi-dispatch',
            text: 'Multi Store Pickup',
            icon: faTruckMoving,
            path: '/merchant-pickup/multi-dispatch',
          },
          {
            roles: roles.merchantPickupTagging,
            key: 'merchant_header',
            text: 'UPDATE STATUS',
          },
          // {
          //   roles: roles.packagePickupUpdate,
          //   key: '/merchant-pickup/released',
          //   text: 'Released',
          //   icon: faReply,
          //   path: '/merchant-pickup/released',
          // },
          // {
          //   roles: roles.packageTransmittal,
          //   key: '/merchant-pickup/rejected',
          //   text: 'Rejected',
          //   icon: faBan,
          //   path: '/merchant-pickup/rejected',
          // },
          {
            roles: roles.merchantPickupTagging,
            key: '/merchant-pickup/failed-pickup',
            text: 'Failed Pickup',
            icon: faTimesCircle,
            path: '/merchant-pickup/failed-pickup',
          },
          {
            roles: roles.merchantPickupTagging,
            key: '/merchant-pickup/accept-by-courier',
            text: 'Accept By Courier',
            icon: faClipboardCheck,
            path: '/merchant-pickup/accept-by-courier',
          },
        ],
      },
      {
        roles: roles.shipperPickup,
        key: 'pickup',
        text: 'Shipper Pickup',
        icon: faTruckLoading,
        items: [
          {
            roles: roles.shipperPickupViews,
            key: '/pickup/list',
            text: 'List',
            icon: faStream,
            path: '/pickup/list',
          },
          {
            roles: roles.shipperPickupOperations,
            key: '/pickup/dispatch',
            text: 'Dispatch',
            icon: faTruckMoving,
            path: '/pickup/dispatch',
          },
        ],
      },
      {
        roles: roles.delivery,
        key: 'delivery',
        text: 'Delivery',
        icon: faShippingFast,
        items: [
          {
            roles: roles.deliveryViews,
            key: '/delivery/list',
            text: 'List',
            icon: faStream,
            path: '/delivery/list',
          },
          {
            roles: roles.deliveryOperations,
            key: '/delivery/dispatch',
            text: 'Dispatch',
            icon: faTruckMoving,
            path: '/delivery/dispatch',
          },
          {
            roles: roles.deliveryTagging,
            key: 'delivery_header',
            text: 'UPDATE STATUS',
          },
          {
            roles: roles.deliveryTagging,
            key: '/delivery/delivered',
            text: 'Delivered',
            icon: faCheckDouble,
            path: '/delivery/delivered',
          },
          {
            roles: roles.deliveryTagging,
            key: '/delivery/failed',
            text: 'Failed',
            icon: faTimes,
            path: '/delivery/failed',
          },
          {
            roles: roles.deliveryPODReturned,
            key: '/delivery/pod-return',
            text: 'POD Return',
            icon: faFileAlt,
            path: '/delivery/pod-return',
          },
        ],
      },
      {
        roles: roles.RTS,
        key: 'rts',
        text: 'RTS',
        icon: faReplyAll,
        items: [
          {
            roles: roles.RTSViews,
            key: '/rts/list',
            text: 'List',
            icon: faStream,
            path: '/rts/list',
          },
          {
            roles: roles.RTSOperations,
            key: '/rts/dispatch',
            text: 'Dispatch RTS',
            icon: faTruckMoving,
            path: '/rts/dispatch',
          },
          {
            roles: roles.RTSOperations,
            key: '/rts-transfer/dispatch',
            text: 'Dispatch RTS Transfer',
            icon: faTruckMoving,
            path: '/rts-transfer/dispatch',
          },
          {
            roles: roles.RTSTagging,
            key: 'rts_header',
            text: 'UPDATE STATUS',
          },
          {
            roles: roles.RTSTagging,
            key: '/rts/returned',
            text: 'Returned',
            icon: faRetweet,
            path: '/rts/returned',
          },
          {
            roles: roles.RTSTagging,
            key: '/rts/refused',
            text: 'Refused',
            icon: faHandPaper,
            path: '/rts/refused',
          },
          {
            roles: roles.RTSPODReturned,
            key: '/rts/pod-return',
            text: 'POD Return',
            icon: faFileAlt,
            path: '/rts/pod-return',
          },
        ],
      },
      {
        roles: roles.transfer,
        key: 'transfer',
        text: 'Transfer',
        icon: faTruckMoving,
        items: [
          {
            roles: roles.transferViews,
            key: '/transfer/list',
            text: 'List',
            icon: faStream,
            path: '/transfer/list',
          },
          {
            roles: roles.transferOperations,
            key: '/transfer/dispatch',
            text: 'Dispatch',
            icon: faTruckMoving,
            path: '/transfer/dispatch',
          },
        ],
      },
      {
        roles: roles.RTM,
        key: 'rtm',
        text: 'RTM',
        icon: faReplyAll,
        items: [
          {
            roles: roles.RTMViews,
            key: '/rtm/list',
            text: 'List',
            icon: faStream,
            path: '/rtm/list',
          },
          {
            roles: roles.RTMOperations,
            key: '/rtm/dispatch',
            text: 'Dispatch',
            icon: faTruckMoving,
            path: '/rtm/dispatch',
          },
          {
            roles: roles.RTMTagging,
            key: 'rtm_header',
            text: 'UPDATE STATUS',
          },
          {
            roles: roles.RTMTagging,
            key: '/rtm/returned',
            text: 'Returned',
            icon: faRetweet,
            path: '/rtm/returned',
          },
          {
            roles: roles.RTMTagging,
            key: '/rtm/refused',
            text: 'Refused',
            icon: faHandPaper,
            path: '/rtm/refused',
          },
        ],
      },
      {
        roles: roles.merchantPickupViews,
        key: 'transmittal',
        text: 'Transmittal',
        icon: faClipboardCheck,
        items: [
          {
            roles: roles.merchantPickupViews,
            key: '/merchant-pickup/transmittal/list',
            text: 'Transmittal List',
            icon: faStream,
            path: '/merchant-pickup/transmittal/list',
          },
          {
            roles: roles.merchantPickupTagging,
            key: '/merchant-pickup/refusal',
            text: 'Refusal',
            icon: faUndo,
            path: '/merchant-pickup/refusal',
          },
          {
            roles: roles.merchantPickupTagging,
            key: '/merchant-pickup/released',
            text: 'Released',
            icon: faReply,
            path: '/merchant-pickup/released',
          },
          {
            roles: roles.merchantPickupTagging,
            key: '/merchant-pickup/rejected',
            text: 'Rejected',
            icon: faBan,
            path: '/merchant-pickup/rejected',
          },
          {
            roles: roles.merchantPickupTagging,
            key: '/merchant-pickup/drop-off',
            text: 'Drop Off Acceptance',
            icon: faFileInvoiceDollar,
            path: '/merchant-pickup/drop-off',
          },
        ],
      },
      {
        roles: roles.voidWaybill,
        key: '/void-waybill',
        text: 'Void Waybill',
        icon: faTrash,
        path: '/void-waybill',
      },
      {
        roles: roles.claims,
        key: '/claims',
        text: 'Claims',
        icon: faFileInvoiceDollar,
        path: '/claims',
      },
      {
        roles: roles.toolsSidebarMenu,
        key: '/tools',
        text: 'Tools',
        icon: faWrench,
        items: [
          {
            roles: roles.repush,
            key: 'repush',
            text: 'Repush',
            icon: faRedo,
            path: '/repush',
          },
          {
            roles: roles.toolsGroupChecker,
            key: '/tools/package-group-checker',
            text: 'Group Checker',
            icon: faCheck,
            path: '/tools/package-group-checker',
          },
          {
            roles: roles.toolsStatusUpdater,
            key: '/tools/status-updater',
            text: 'Status Updater',
            icon: faTasks,
            path: '/tools/status-updater',
          },
          {
            roles: roles.toolsDimweightUpload,
            key: '/tools/dimweight-upload',
            text: 'Dimweight Upload',
            icon: faUpload,
            path: '/tools/dimweight-upload',
          },
          {
            roles: roles.toolsDimweightUpload,
            key: '/tools/dimweight-update',
            text: 'Dimweight Update',
            icon: faEdit,
            path: '/tools/dimweight-update',
          },
        ],
      },
    ],
  },
  Items: {
    roles: roles.itemsPanel,
    routes: [
      {
        roles: roles.all,
        key: '/item-tagging',
        text: 'Item Tagging',
        icon: faFileInvoiceDollar,
        path: '/item-tagging',
      },
      {
        roles: roles.itemsViews,
        key: '/items/list',
        text: 'Item List',
        icon: faFileInvoiceDollar,
        path: '/items/list',
      },
      {
        roles: roles.itemsOperations,
        key: '/barcode',
        text: 'Barcode Generator',
        icon: faFileInvoiceDollar,
        path: '/barcode',
      },
    ],
  },
  Reports: {
    roles: roles.reportsPanel,
    routes: [
      {
        roles: roles.monitoring,
        key: 'monitoring',
        text: 'Monitoring',
        icon: faDesktop,
        items: [
          {
            roles: roles.monitoring,
            key: '/monitoring/accounts',
            text: 'Accounts',
            icon: faUserCheck,
            path: '/monitoring/accounts',
          },
          {
            roles: roles.monitoring,
            key: '/monitoring/firstmile',
            text: 'LZD First Mile',
            icon: faTruck,
            path: '/monitoring/firstmile',
          },
        ],
      },
      {
        roles: roles.reports,
        key: 'reports',
        text: 'Reports',
        icon: faChartBar,
        items: [
          {
            roles: roles.reportsItems,
            key: '/reports/dispatched-items',
            text: 'Dispatched Items',
            icon: faClipboardList,
            path: '/reports/dispatched-items',
          },
          {
            roles: roles.reportsItems,
            key: '/reports/picked-items',
            text: 'Picked Items',
            icon: faPallet,
            path: '/reports/picked-items',
          },
          {
            roles: roles.reportsItems,
            key: '/reports/items-for-pickup',
            text: 'Items For Pickup',
            icon: faBoxes,
            path: '/reports/items-for-pickup',
          },
          {
            roles: roles.reportsItemsLocation,
            key: '/reports/items-location',
            text: 'Items Location',
            icon: faMapMarkedAlt,
            path: '/reports/items-location',
          },
          {
            roles: roles.reportsUnremittedItems,
            key: '/reports/unremitted-items',
            text: 'Unremitted Items',
            icon: faClipboardList,
            path: '/reports/unremitted-items',
          },
          {
            roles: roles.reportsCashierUnremitted,
            key: '/reports/cashier-unremitted',
            text: 'Cashier Unremitted',
            icon: faFileInvoiceDollar,
            path: '/reports/cashier-unremitted',
          },
        ],
      },
    ],
  },

  Finance: {
    roles: roles.financePanel,
    routes: [
      {
        roles: roles.courierRemittance,
        key: 'courier-remittance',
        text: 'Courier Remittance',
        icon: faCashRegister,
        items: [
          {
            roles: roles.courierRemittanceOperations,
            key: '/courier-remittance/create',
            text: 'Create',
            icon: faPlus,
            path: '/courier-remittance/create',
          },
          {
            roles: roles.courierRemittanceViews,
            key: '/courier-remittance/list',
            text: 'List',
            icon: faStream,
            path: '/courier-remittance/list',
          },
          {
            roles: roles.courierRemittanceViews,
            key: '/courier-remittance/remitted-items',
            text: 'Remitted Items',
            icon: faFileAlt,
            path: '/courier-remittance/remitted-items',
          },
        ],
      },
      {
        roles: roles.cashierRemittance,
        key: 'cashier-remittance',
        text: 'Cashier Remittance',
        icon: faPiggyBank,
        items: [
          {
            roles: roles.cashierRemittanceViews,
            key: '/cashier-remittance/unremitted-list',
            text: 'Unremitted List',
            icon: faStream,
            path: '/cashier-remittance/unremitted-list',
          },
          {
            roles: roles.cashierRemittanceOperations,
            key: '/cashier-remittance/create',
            text: 'New',
            icon: faPlus,
            path: '/cashier-remittance/create',
          },
          {
            roles: roles.cashierRemittanceViews,
            key: '/cashier-remittance/list',
            text: 'List',
            icon: faStream,
            path: '/cashier-remittance/list',
          },
          {
            roles: roles.cashierRemittanceViews,
            key: '/cashier-remittance/remittance-reports',
            text: 'Remittance Report',
            icon: faChartBar,
            path: '/cashier-remittance/remittance-reports',
          },
        ],
      },
      {
        roles: roles.billing,
        key: 'billing',
        text: 'Billing',
        icon: faMoneyBill,
        items: [
          {
            roles: roles.billingViews,
            key: '/billing/unbilled-list',
            text: 'Unbilled List',
            icon: faStream,
            path: '/billing/unbilled-list',
          },
          {
            roles: roles.billingOperations,
            key: '/billing/upload',
            text: 'Upload',
            icon: faFileUpload,
            path: '/billing/upload',
          },
          {
            roles: roles.billingViews,
            key: '/billing/list',
            text: 'List',
            icon: faStream,
            path: '/billing/list',
          },
          {
            roles: roles.billingViews,
            key: '/billing/reports',
            text: 'Reports',
            icon: faChartBar,
            path: '/billing/reports',
          },
        ],
      },
    ],
  },

  Admin: {
    roles: roles.adminPanel,
    routes: [
      {
        roles: roles.usersViews,
        key: 'users',
        text: 'User',
        icon: faUser,
        items: [
          {
            roles: roles.usersViews,
            key: '/user/list',
            text: 'List',
            icon: faStream,
            path: '/user/list',
          },
          {
            roles: roles.admin,
            key: '/user/create',
            text: 'Create',
            icon: faUserPlus,
            path: '/user/create',
          },
        ],
      },
      {
        roles: roles.clients,
        key: '/clients',
        text: 'Clients',
        icon: faUsers,
        items: [
          {
            roles: roles.clients,
            key: '/clients',
            text: 'List',
            icon: faList,
            path: '/clients',
          },
          {
            roles: roles.admin,
            key: '/clients/status-map',
            text: 'Status Map',
            icon: faList,
            path: '/clients/status-map',
          },
        ],
      },
      {
        roles: roles.portClusterViews,
        key: '/port-cluster',
        text: 'Port Cluster',
        icon: faLocationArrow,
        items: [
          {
            roles: roles.portClusterViews,
            key: '/port-cluster',
            text: 'List',
            icon: faStream,
            path: '/port-cluster',
          },
          {
            roles: roles.portClusterOperations,
            key: '/port-cluster/create',
            text: 'Create',
            icon: faPlus,
            path: '/port-cluster/create',
          },
          {
            roles: roles.portClusterOperations,
            key: '/port-cluster/upload',
            text: 'Upload',
            icon: faUpload,
            path: '/port-cluster/upload',
          },
        ],
      },
      {
        roles: roles.admin,
        key: '/outsource',
        text: 'Outsource',
        icon: faLayerGroup,
        path: '/outsource',
      },
      {
        roles: roles.admin,
        key: '/vehicles',
        text: 'Vehicles',
        icon: faTruck,
        path: '/vehicles',
      },
    ],
  },
};
