import { monitoringPage } from '../../services/api';

const ACCOUNT_LIST = 'monitoring/ACCOUNT_LIST';

export const accountList = (page, data) => ({
  type: ACCOUNT_LIST,
  payload: {
    client: 'secure',
    request: {
      ...monitoringPage(page),
      params: data,
    },
  },
});

const initialState = {
  listViews: {},
};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
