import React, { Component } from 'react';
import { connect } from 'react-redux';
import fileDownload from 'js-file-download';
import { NavLink } from 'react-router-dom';
import {
  Icon, Button, Col, Descriptions, Tag, Spin, Result, Modal,
} from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPrint, faEye } from '@fortawesome/free-solid-svg-icons';

import {
  waybill,
  waybillItemList,
  fetchInternalStatusTransactionHistory,
} from './ducks';
import Row from '../Row';
import PageHeader from '../PageHeader';
import ReactTable from '../ReactTable';
import Card from '../Card';
import Container from '../Container';
import Spacer from '../Spacer';
import Select from '../Select';

export class Waybill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      loadSpinItem: false,
      loadingTransactionHistory: true,
      loadSpin: false,
      checkTNstatus: ' ',
      tnumberItems: [],
      dataforView: null,
      transactionHistory: [],
      transactionType: 'internal',
    };

    this.transactionTypeHandler = this.transactionTypeHandler.bind(this);
  }

  componentDidMount() {
    this.setState({ loadSpin: true, loadSpinItem: true });
    const {
      waybillComponent, match, doWaybillItemList, doFetchInternalStatusTransactionHistory,
    } = this.props;
    waybillComponent(match.params.tnumber).then((res) => {
      this.setState({
        checkTNstatus: res.payload.data.package,
      });

      this.setState({ loadSpin: false });
    });

    doWaybillItemList(match.params.tnumber).then((res) => {
      this.setState({
        loadSpinItem: false,
        tnumberItems: res.payload.data,
      });
    });

    doFetchInternalStatusTransactionHistory(match.params.tnumber).then((res) => {
      this.setState({
        loadingTransactionHistory: false,
        transactionHistory: res.payload.data.history,
      });
    });
  }

  openViewModal(rows) {
    this.setState({
      isVisible: true,
      dataforView: rows,
    });
  }

  closeViewModal() {
    this.setState({
      isVisible: false,
      dataforView: null,
    });
  }

  printWaybill() {
    const { viewAccount } = this.props;
    window.open(
      `${process.env.REACT_APP_API_URL}/web/print-waybill/${viewAccount.key}`,
    );
  }

  printFullWaybill() {
    const { viewAccount } = this.props;
    window.open(
      `${process.env.REACT_APP_API_URL}/web/print-waybill/full/${viewAccount.key}`,
    );
  }

  printMotherWaybill() {
    const { viewAccount } = this.props;
    window.open(
      `${process.env.REACT_APP_API_URL}/web/print-waybill/mother/${viewAccount.key}`,
    );
  }

  transactionTypeHandler(e) {
    this.setState({
      transactionType: e,
    });
  }

  render() {
    const imageHandler = 'https://img.icons8.com/wired/2x/file-delete.png';
    const {
      loadSpin, checkTNstatus, tnumberItems, loadSpinItem, isVisible, dataforView,
      transactionHistory, transactionType, loadingTransactionHistory,
    } = this.state;

    const { viewAccount, history, user } = this.props;
    const breadCrumbs = [
      {
        breadcrumbName: 'Waybill',
      },
      {
        breadcrumbName: `View #${
          viewAccount.package ? viewAccount.package.tracking_number : ''
        }`,
      },
    ];

    // eslint-disable-next-line no-nested-ternary
    const signature = viewAccount.package && viewAccount.package.signature !== null
      ? viewAccount.package.signature.includes('signature') ? `${process.env.REACT_APP_API_URL}/${viewAccount.package.signature}`
        : `data:image/png;base64, ${viewAccount.package.signature}`
      : imageHandler;

    return (
      <div className="ListView">
        <Modal
          closable
          onCancel={() => this.closeViewModal()}
          visible={isVisible}
          footer={null}
          width={950}
        >
          <NavLink to={`/items/${dataforView ? dataforView.reference : ' '}/${dataforView ? dataforView.item_id : ' '}`}>
            <Button type="primary">
              View Items Information
              <Icon type="arrow-right" />
            </Button>
          </NavLink>
          <Spacer />
          <Descriptions bordered size="small">
            <Descriptions.Item label="Reference: "><Tag color="orange">{dataforView ? dataforView.reference : ' '}</Tag></Descriptions.Item>
            <Descriptions.Item label="Client Name: ">{dataforView ? dataforView.client_name : ' '}</Descriptions.Item>
            <Descriptions.Item label="Quantity: ">{dataforView ? dataforView.quantity : ' '}</Descriptions.Item>
            <Descriptions.Item label="UOM: ">{dataforView ? dataforView.uom : ' '}</Descriptions.Item>
            <Descriptions.Item label="Length: ">{dataforView ? dataforView.length : ' '}</Descriptions.Item>
            <Descriptions.Item label="Width: ">{dataforView ? dataforView.width : ' '}</Descriptions.Item>
            <Descriptions.Item label="Height: ">{dataforView ? dataforView.height : ' '}</Descriptions.Item>
            <Descriptions.Item label="Weight: ">{dataforView ? dataforView.weight : ' '}</Descriptions.Item>
            <Descriptions.Item label="Volume: ">{dataforView ? dataforView.volume : ' '}</Descriptions.Item>
            <Descriptions.Item label="Value: ">{dataforView ? dataforView.value : ' '}</Descriptions.Item>
            <Descriptions.Item label="Comment: ">{dataforView ? dataforView.comment : ' '}</Descriptions.Item>
            <Descriptions.Item label="Status: "><Tag color="orange">{dataforView ? dataforView.status : ' '}</Tag></Descriptions.Item>
            <Descriptions.Item label="Current Location: ">{dataforView ? dataforView.current_location : ' '}</Descriptions.Item>
            <Descriptions.Item label="Handover Quantity: ">{dataforView ? dataforView.handover_quantity : ' '}</Descriptions.Item>
            <Descriptions.Item label="Deliverd Quantity: ">{dataforView ? dataforView.delivered_quantity : ' '}</Descriptions.Item>
            <Descriptions.Item label="Returned Quantity: ">{dataforView ? dataforView.returned_quantity : ' '}</Descriptions.Item>
            <Descriptions.Item label="Claims Quantity: ">{dataforView ? dataforView.claims_quantity : ' '}</Descriptions.Item>
            <Descriptions.Item label="Description: ">{dataforView ? dataforView.description : ' '}</Descriptions.Item>
          </Descriptions>
        </Modal>
        {checkTNstatus ? (
          <React.Fragment>
            <PageHeader title="View Waybill" routes={breadCrumbs} />
            <Spacer />
            <Container>
              <Spin spinning={loadSpin} tip="Loading package details...">
                <Row>
                  <Col xs={24} sm={24} lg={8} style={{ marginBottom: 5 }}>
                    <div style={{ padding: '5px' }}>
                      <Button
                        type="primary"
                        onClick={e => this.printWaybill(e)}
                        block
                      >
                        <Icon type="printer" />
                        Print Waybill
                      </Button>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} lg={8} style={{ marginBottom: 5 }}>
                    <div style={{ padding: '5px' }}>
                      <Button
                        type="primary"
                        onClick={e => this.printFullWaybill(e)}
                        block
                      >
                        <Icon type="printer" />
                        Print Full Waybill
                      </Button>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} lg={8} style={{ marginBottom: 5 }}>
                    <div style={{ padding: '5px' }}>
                      <Button
                        type="primary"
                        onClick={e => this.printMotherWaybill(e)}
                        block
                      >
                        <Icon type="printer" />
                        Print Mother Waybill
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Spacer />
                <Card>
                  <Row>
                    <Col xs={24} sm={24} lg={12}>
                      <div style={{ background: '', padding: '5px' }}>
                        <Descriptions
                          title="Package Reference"
                          bordered
                          size="small"
                        >
                          <Descriptions.Item label="Tracking Number" span={3}>
                            <strong>
                              {viewAccount.package
                                ? viewAccount.package.tracking_number
                                : ''}
                            </strong>
                          </Descriptions.Item>
                          <Descriptions.Item label="Created At" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.created_at
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Status" span={3}>
                            <Tag color="orange">
                              {viewAccount.package
                                ? viewAccount.package.status
                                : ''}
                            </Tag>
                          </Descriptions.Item>
                          <Descriptions.Item label="Client" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.client
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Branch Code" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.branch_code
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Package ID" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.package_id
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Group ID" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.group_id
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Order Number" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.order_no
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Reference 1" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.reference_1
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Reference 2" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.reference_2
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Reference 3" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.reference_3
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Reference 4" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.reference_4
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Reference 5" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.reference_5
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Transport Mode" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.transport_mode
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Delivery Type" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.delivery_type
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Shipping Type" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.shipping_type
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Journey Type" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.journey_type
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Package Type" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.package_type
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Remarks" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.remarks
                              : ''}
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} lg={12}>
                      <div style={{ background: '', padding: '5px' }}>
                        <Descriptions
                          title="Consignee Information"
                          bordered
                          size="small"
                        >
                          <Descriptions.Item label="Consignee Name" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.consignee_name
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Address" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.consignee_address
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Consignee Email" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.consignee_email
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Consignee Mobile" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.consignee_mobile
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="City" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.address_city
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Province" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.address_province
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Barangay" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.address_barangay
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Building Type" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.building_type
                              : ''}
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                      <div style={{ background: '', padding: '5px' }}>
                        <Descriptions
                          title="Merchant Information"
                          bordered
                          size="small"
                        >
                          <Descriptions.Item label="Merchant Name" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.shipper_name
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Address" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.shipper_address
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Merchant Email" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.shipper_email
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Merchant Mobile" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.shipper_contact
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="City" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.shipper_city
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Province" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.shipper_province
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Barangay" span={3}>
                            {viewAccount.package
                              ? viewAccount.package.shipper_barangay
                              : ''}
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} lg={24}>
                      <div
                        style={{
                          background: '',
                          padding: '5px',
                          marginTop: 10,
                        }}
                      >
                        <Descriptions
                          title="Package Information"
                          bordered
                          size="small"
                        >
                          <Descriptions.Item label="Package Size" span={1.5}>
                            {viewAccount.package
                              ? viewAccount.package.package_size
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Payment Type" span={1.5}>
                            {viewAccount.package
                              ? viewAccount.package.payment_type
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label="Amount to be Collected"
                            span={1.5}
                          >
                            {viewAccount.package
                              ? viewAccount.package.total_price
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Declared Value" span={1.5}>
                            {viewAccount.package
                              ? viewAccount.package.declared_value
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Length" span={1.5}>
                            {viewAccount.package
                              ? viewAccount.package.length
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Width" span={1.5}>
                            {viewAccount.package
                              ? viewAccount.package.width
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Height" span={1.5}>
                            {viewAccount.package
                              ? viewAccount.package.height
                              : ''}
                          </Descriptions.Item>
                          <Descriptions.Item label="Weight" span={1.5}>
                            {viewAccount.package
                              ? viewAccount.package.weight
                              : ''}
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                      <Spacer />
                      {
                        user.role_id === 1 ? (
                          <Button
                            type="primary"
                            block
                            onClick={() => history.push(
                              `/waybill/edit/${
                                viewAccount.package
                                  ? viewAccount.package.tracking_number
                                  : ''
                              }`,
                            )
                            }
                          >
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faEdit} fixedWidth />
                            </Icon>
                            Edit
                          </Button>
                        ) : (
                          <Button
                            disabled
                            type="primary"
                            block
                            onClick={() => history.push(
                              `/waybill/edit/${
                                viewAccount.package
                                  ? viewAccount.package.tracking_number
                                  : ''
                              }`,
                            )
                            }
                          >
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faEdit} fixedWidth />
                            </Icon>
                            Edit
                          </Button>
                        )
                      }
                    </Col>
                  </Row>
                </Card>
              </Spin>
              <Spacer />
              <Card
                title="Item List"
                extra={`Total: ${tnumberItems.length}`}
              >
                <Spin spinning={loadSpinItem} tip="Loading package items...">
                  <ReactTable
                    data={tnumberItems}
                    columns={[
                      {
                        Header: 'Reference',
                        sortable: true,
                        filterable: true,
                        accessor: 'reference',
                      },
                      {
                        Header: 'Description',
                        sortable: true,
                        filterable: true,
                        accessor: 'description',
                      },
                      {
                        Header: 'Status',
                        sortable: true,
                        filterable: true,
                        accessor: 'status',
                      },
                      {
                        Header: 'Type',
                        sortable: true,
                        filterable: true,
                        accessor: 'type',
                      },
                      {
                        Header: 'Quantity',
                        sortable: true,
                        filterable: true,
                        accessor: 'quantity',
                      },
                      {
                        Header: 'UOM',
                        sortable: true,
                        filterable: true,
                        accessor: 'uom',
                      },
                      {
                        Header: 'Handover Quantity',
                        sortable: true,
                        filterable: true,
                        accessor: 'handover_quantity',
                      },
                      {
                        Header: 'Close Quantity',
                        sortable: true,
                        filterable: true,
                        accessor: 'handover_quantity',
                        Cell: row => (
                          <p>{row.original.delivered_quantity + row.original.returned_quantity + row.original.claims_quantity}</p>
                        ),
                      },
                      {
                        Header: 'Volume',
                        sortable: true,
                        filterable: true,
                        accessor: 'volume',
                      },
                      {
                        Header: 'Length',
                        sortable: true,
                        filterable: true,
                        accessor: 'length',
                      },
                      {
                        Header: 'Width',
                        sortable: true,
                        filterable: true,
                        accessor: 'width',
                      },
                      {
                        Header: 'Height',
                        sortable: true,
                        filterable: true,
                        accessor: 'height',
                      },
                      {
                        Header: 'Weight',
                        sortable: true,
                        filterable: true,
                        accessor: 'weight',
                      },
                      {
                        Header: 'Remarks',
                        sortable: true,
                        filterable: true,
                        accessor: 'comment',
                      },
                      {
                        Header: 'Options',
                        sortable: false,
                        filterable: false,
                        Cell: row => (
                          <center>
                            <Button
                              type="link"
                              onClick={() => this.openViewModal(row.original)}
                            >
                              <Icon viewBox="0 0 1024 1024">
                                <FontAwesomeIcon icon={faEye} fixedWidth />
                              </Icon>
                              view
                            </Button>
                          </center>
                        ),
                      },
                    ]}
                  />
                </Spin>
              </Card>
              <Spacer />
              <Row>
                <Col xs={24} sm={24} lg={18}>
                  <Spin spinning={loadingTransactionHistory} tip="Loading transaction history...">
                    <Card
                      title={`Transaction History ( Total: ${transactionHistory.length} )`}
                      extra={(
                        <React.Fragment>
                          <Select
                            defaultValue="internal"
                            allowClear={false}
                            options={[
                              {
                                label: 'Internal',
                                value: 'internal',
                              },
                              {
                                label: 'External',
                                value: 'external',
                              },
                            ]}
                            onChange={e => this.transactionTypeHandler(e)}
                          />
                        </React.Fragment>
                      )}
                    >
                      <ReactTable
                        data={transactionHistory}
                        columns={[
                          {
                            Header: 'Date',
                            Cell: row => (
                              <p>{row.original.created_at}</p>
                            ),
                          },
                          {
                            Header: 'Status',
                            accessor: 'status',
                            Cell: row => (
                              <p>{transactionType === 'internal' ? row.original.internal_status : row.original.mapped_status }</p>
                            ),
                          },
                          {
                            Header: 'Reference',
                            accessor: 'reference',
                          },
                          {
                            Header: 'Remarks',
                            accessor: 'remarks',
                          },
                          {
                            Header: 'Updated By',
                            accessor: 'updated_by',
                          },
                        ]}
                      />
                    </Card>
                  </Spin>
                </Col>
                <Col xs={24} sm={24} lg={6}>
                  {/* eslint-disable */
                    viewAccount.package ? (
                    viewAccount.package.signature ? (
                      <React.Fragment>
                        <Card title="E-Signature">
                          <img
                            alt="E-Signature"
                            style={{ width: '100%', marginTop: 0 }}
                            src={signature}
                          />
                          <Button
                            block
                            onClick={() => {
                              const file = new Blob(
                                [viewAccount.package.signature],
                                { type: 'application/octet-stream' },
                              );
                              fileDownload(file, 'ePOD.png');
                              // window.location.href = `data:image/png;base64, ${viewAccount.package.signature}`;
                              // viewAccount.package.signature
                            }}
                          >
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faPrint} fixedWidth />
                            </Icon>
                            Download EPOD
                          </Button>
                        </Card>
                        <div style={{ marginTop: 10 }} />
                      </React.Fragment>
                    ) : null
                  ) : null}
                  {viewAccount.package ? (
                    viewAccount.package.pod_url ? (
                      <Card title="Actual PoD">
                        <img
                          alt="example"
                          style={{ width: '100%', marginTop: 0 }}
                          src={
                            viewAccount.package
                            && viewAccount.package.pod_url !== null
                              ? viewAccount.package.pod_url
                              : imageHandler
                          }
                        />
                        <Button
                          block
                          onClick={() => {
                            window.open(viewAccount.package.pod_url);
                            // viewAccount.package.pod_url
                          }}
                        >
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faPrint} fixedWidth />
                          </Icon>
                          Download POD
                        </Button>
                      </Card>
                    ) : null
                  ) : null}
                </Col>
              </Row>
            </Container>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Result
              status="404"
              title="Invalid Tracking Number"
              subTitle="Sorry, the tracking number does not exist."
              // Because eslint auto format line 16
              /* eslint-disable */
              extra={
                <Button type="primary">
                  <NavLink to="/waybill/list">Back to Waybill List</NavLink>
                </Button>
              }
              /* eslint-enable */
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  viewAccount: state.componentWaybill.views,
  user: state.auth.user,
});

const mapDispatchToProps = {
  waybillComponent: waybill,
  doWaybillItemList: waybillItemList,
  doFetchInternalStatusTransactionHistory: fetchInternalStatusTransactionHistory,
};

Waybill.propTypes = {
  viewAccount: PropTypes.oneOfType([PropTypes.object]).isRequired,
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
  waybillComponent: PropTypes.func.isRequired,
  doWaybillItemList: PropTypes.func.isRequired,
  doFetchInternalStatusTransactionHistory: PropTypes.func.isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Waybill);
