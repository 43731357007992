import { apiLogin } from '../../services/api';

export const LOGIN = 'login/LOGIN';
export const LOGIN_SUCCESS = 'login/LOGIN_SUCCESS';
export const LOGIN_FAIL = 'login/LOGIN_FAIL';
export const login = (username, password,otpcode,isPortal) => ({
  type: LOGIN,
  payload: {
    request: {
      ...apiLogin,
      data: {
        username,
        password,
        otpcode,
        isPortal
      },
    },
  },
});

export const LOGOUT = 'auth/LOGOUT';
export const logout = () => ({
  type: LOGOUT,
});

const initialState = {
  user: null,
  error: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        error: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.data.user,
        error: false,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        error: 'Invalid user credentials',
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
