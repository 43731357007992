import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faRedo } from '@fortawesome/free-solid-svg-icons';
import fileDownload from 'js-file-download';
import moment from 'moment';
import {
  Col, Button, Icon, Modal, Result, message,
} from 'antd';
import PageHeader from '../../components/PageHeader';
import ServerSideTable from '../../components/ServerSideTable';
import Card from '../../components/Card';
import Container from '../../components/Container';
import Spacer from '../../components/Spacer';
import Row from '../../components/Row';
import { fetchUserList, resetPassword } from './ducks';

export class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: {
        rows: [],
        pages: 0,
        total: 0,
      },
      currentFilters: {
        page: 0,
        pageSize: 0,
        filter: [],
        sorted: [],
      },
      isResetting: false,
      isLoading: false,
      isVisible: false,
      fullName: null,
      userId: null,
      newPassword: null,
      isExporting: false,
    };

    this.onFetchData = this.onFetchData.bind(this);
    this.showResetModal = this.showResetModal.bind(this);
    this.cancelHandler = this.cancelHandler.bind(this);
    this.clearResetState = this.clearResetState.bind(this);
    this.resetHandler = this.resetHandler.bind(this);
    this.export = this.export.bind(this);
  }

  onFetchData(state) {
    const { doFetchUserList } = this.props;

    const params = {
      page: state.page,
      pageSize: state.pageSize,
      filter: state.filtered,
      sorted: state.sorted,
    };

    this.setState({
      isLoading: true,
      currentFilters: params,
    });

    doFetchUserList(params).then((action) => {
      this.setState({
        userList: {
          rows: action.payload.data.rows,
          pages: action.payload.data.pages,
          total: action.payload.data.total,
        },
        isLoading: false,
      });
    });
  }

  showResetModal(details) {
    this.setState({
      isVisible: true,
      fullName: `${details.firstname} ${details.lastname}`,
      userId: details.user_id,
    });
  }

  cancelHandler() {
    this.setState({
      isVisible: false,
    });
  }

  resetHandler() {
    const { userId } = this.state;
    const { doResetPassword } = this.props;

    this.setState({
      isResetting: true,
    });

    doResetPassword(userId)
      .then((action) => {
        this.setState({
          newPassword: action.payload.data.password,
          isResetting: false,
        });
      })
      .catch(() => {
        message.error('Something went wrong, please try again later.');
        this.setState({
          isResetting: false,
        });
      });
  }

  // Set to avoid displaying null values on UI before closing modal
  clearResetState() {
    this.setState({
      fullName: null,
      userId: null,
      newPassword: null,
    });
  }

  export() {
    const { currentFilters } = this.state;
    const { doFetchUserList } = this.props;

    const params = {
      ...currentFilters,
      export: true,
      isExporting: true,
    };
    this.setState({ isExporting: true });
    doFetchUserList(params).then((res) => {
      this.setState({ isExporting: false });
      const file = new Blob([res.payload.data]);
      fileDownload(file, `users_${moment().format('YYYY-MM-DD')}.csv`);
    });
  }

  render() {
    const {
      isLoading,
      userList,
      newPassword,
      isVisible,
      fullName,
      isResetting,
      isExporting,
    } = this.state;
    const breadCrumbs = [
      {
        breadcrumbName: 'Admin',
      },
      {
        breadcrumbName: 'User',
      },
      {
        breadcrumbName: 'List',
      },
    ];

    return (
      <div className="UserList">
        <PageHeader title="Users" routes={breadCrumbs} />
        <Spacer />
        <Container>
          <Modal
            title="Reset Password"
            closable={false}
            visible={isVisible}
            afterClose={this.clearResetState}
            footer={[
              <Button
                key="cancel"
                type="default"
                onClick={this.cancelHandler}
                disabled={isResetting}
              >
                Cancel
              </Button>,
              <Button
                key="reset"
                type="primary"
                onClick={
                  newPassword === null ? this.resetHandler : this.cancelHandler
                }
                loading={isResetting}
              >
                {newPassword === null ? 'Reset' : 'Done'}
              </Button>,
            ]}
          >
            <Result
              status={newPassword === null ? 'info' : 'success'}
              title={newPassword === null
                ? `Are you sure you want to reset ${fullName}'s password ?`
                : 'Success password reset!'}
              subTitle={newPassword !== null ? `Password is: ${newPassword}` : null}
            />
          </Modal>
          <Card
            title={`User List (${userList.total})`}
            extra={(

              <Button
                type="primary"
                onClick={this.export}
                disabled={isExporting || !userList.rows.length}
              >
                {isExporting ? 'Exporting . . .' : 'Export'}
              </Button>
            )}
          >
            <ServerSideTable
              data={userList.rows}
              pages={userList.pages}
              columns={[
                {
                  Header: 'ID',
                  accessor: 'user_id',
                  width: 50,
                  id: 'user_id',
                },
                {
                  Header: 'Username',
                  accessor: 'username',
                },
                {
                  Header: 'First Name',
                  accessor: 'firstname',
                },
                {
                  Header: 'Last Name',
                  accessor: 'lastname',
                },
                {
                  Header: 'Mobile Number',
                  accessor: 'mobile_no',
                },
                {
                  Header: 'Port Code',
                  accessor: 'port_code',
                  width: 80,
                },
                {
                  Header: 'Outsource',
                  accessor: 'outsource',
                },
                {
                  Header: 'Portal Role',
                  accessor: 'role',
                },
                {
                  Header: 'Options',
                  sortable: false,
                  filterable: false,
                  width: 215,
                  Cell: row => (
                    <Row>
                      <Col span={7}>
                        <NavLink to={`/user/edit/${row.original.user_id}`}>
                          <Button type="link">
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faEdit} fixedWidth />
                            </Icon>
                            Edit
                          </Button>
                        </NavLink>
                      </Col>
                      <Col span={17}>
                        <Button
                          type="link"
                          onClick={() => this.showResetModal(row.original)}
                        >
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faRedo} fixedWidth />
                          </Icon>
                          Reset Password
                        </Button>
                      </Col>
                    </Row>
                  ),
                },
              ]}
              loadingText="Fetching user list. . ."
              loading={isLoading}
              onFetchData={this.onFetchData}
            />
          </Card>
        </Container>
      </div>
    );
  }
}

List.propTypes = {
  doFetchUserList: PropTypes.func.isRequired,
  doResetPassword: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchUserList: fetchUserList,
  doResetPassword: resetPassword,
};

export default connect(null, mapDispatchToProps)(List);
