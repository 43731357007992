import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Legacy
import kpiDash from '../pages/ducks';
import editDispatchPackage from '../components/editDispatchPackage/ducks';
import viewPickups from '../components/viewPickups/ducks';
import options from '../components/options/ducks';
import pickup from '../pages/pickup/ducks';
import transfer from '../pages/transfer/ducks';
import waybill from '../pages/waybill/ducks';
import componentWaybill from '../components/waybill/ducks';
import dispatch from '../components/dispatch/ducks';
import transmittal from '../components/transmittal/ducks';
import rts from '../pages/rts/ducks';
import merchantPickup from '../pages/merchantPickup/newDucks';
import customerPickup from '../pages/customerPickup/newDucks';
import acceptToWarehouse from '../pages/acceptToWarehouse/ducks';
import report from '../pages/reports/ducks';
import billing from '../pages/billing/ducks';
import cashierRemittance from '../pages/cashierRemittance/ducks';
import courierRemittance from '../pages/courierRemittance/ducks';
import repush from '../pages/repush/ducks';
import auth from '../pages/auth/ducks';
import itemTracker from '../pages/itemTracker/ducks';

//
import user from '../pages/user/ducks';
import vehicle from '../pages/vehicle/ducks';
import outsource from '../pages/outsource/ducks';
import client from '../pages/client/ducks';

import waybillExport from '../pages/waybill/newDucks';

export const history = createBrowserHistory();
const router = connectRouter(history);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'waybillExport'],
};

export default persistReducer(
  persistConfig,
  combineReducers({
    editDispatchPackage,
    viewPickups,
    options,
    pickup,
    waybill,
    transfer,
    componentWaybill,
    dispatch,
    billing,
    cashierRemittance,
    courierRemittance,
    report,
    repush,
    router,
    auth,
    acceptToWarehouse,
    customerPickup,
    merchantPickup,
    transmittal,
    rts,
    user,
    vehicle,
    outsource,
    client,
    kpiDash,
    itemTracker,
    waybillExport,
  }),
);
