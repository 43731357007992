import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {
  Button, Icon, Form, Tag, Descriptions, Spin,
} from 'antd';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import Card from '../../components/Card';
import Spacer from '../../components/Spacer';
import ReactTable from '../../components/ReactTable';
import { itemfetchviews } from './ducks';

export class ItemListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataforView: null,
      history: [],
      loadingItemview: false,
    };
  }

  componentDidMount() {
    this.setState({ loadingItemview: true });
    const { doItemfetchviews, match } = this.props;
    doItemfetchviews(match.params.reference, match.params.item_id).then((res) => {
      this.setState({
        dataforView: res.payload.data.details,
        history: res.payload.data.history,
        loadingItemview: false,
      });

      // console.log(res.payload.data);
    });
    // console.log(this.props.match.params.reference);
    // console.log(this.props.match.params.item_id);
  }

  render() {
    const { dataforView, history, loadingItemview } = this.state;

    const breadCrumbs = [
      {
        breadcrumbName: 'Items',
      },
      {
        breadcrumbName: 'Item View',
      },
    ];

    const itemListColumns = [
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
      },
      {
        Header: 'Reference Code',
        accessor: 'reference_code',
      },
      {
        Header: 'Comment',
        accessor: 'comment',
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
      },
      {
        Header: 'Created By',
        accessor: 'user',
      },
    ];

    return (
      <div className="ItemListView">
        <PageHeader title="Item View" routes={breadCrumbs} />
        <Container>
          <Card>
            <NavLink to="/items/list">
              <Button type="primary">
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faArrowLeft} fixedWidth />
                </Icon>
                Back to List
              </Button>
            </NavLink>
            <Spacer />
            <Spin spinning={loadingItemview} tip="Loading item view...">
              <Descriptions bordered size="small">
                <Descriptions.Item label="Tracking Number: "><b><NavLink to={`/waybill/${dataforView ? dataforView.tracking_number : ' '}`}>{dataforView ? dataforView.tracking_number : ' '}</NavLink></b></Descriptions.Item>
                <Descriptions.Item label="Reference: "><Tag color="orange">{dataforView ? dataforView.reference : ' '}</Tag></Descriptions.Item>
                <Descriptions.Item label="Client Name: ">{dataforView ? dataforView.client_name : ' '}</Descriptions.Item>
                <Descriptions.Item label="Quantity: ">{dataforView ? dataforView.quantity : ' '}</Descriptions.Item>
                <Descriptions.Item label="UOM: ">{dataforView ? dataforView.uom : ' '}</Descriptions.Item>
                <Descriptions.Item label="Length: ">{dataforView ? dataforView.length : ' '}</Descriptions.Item>
                <Descriptions.Item label="Width: ">{dataforView ? dataforView.width : ' '}</Descriptions.Item>
                <Descriptions.Item label="Height: ">{dataforView ? dataforView.height : ' '}</Descriptions.Item>
                <Descriptions.Item label="Weight: ">{dataforView ? dataforView.weight : ' '}</Descriptions.Item>
                <Descriptions.Item label="Volume: ">{dataforView ? dataforView.volume : ' '}</Descriptions.Item>
                <Descriptions.Item label="Value: ">{dataforView ? dataforView.value : ' '}</Descriptions.Item>
                <Descriptions.Item label="Comment: ">{dataforView ? dataforView.comment : ' '}</Descriptions.Item>
                <Descriptions.Item label="Status: "><Tag color="orange">{dataforView ? dataforView.status : ' '}</Tag></Descriptions.Item>
                <Descriptions.Item label="Current Location: ">{dataforView ? dataforView.current_location : ' '}</Descriptions.Item>
                <Descriptions.Item label="Handover Quantity: ">{dataforView ? dataforView.handover_quantity : ' '}</Descriptions.Item>
                <Descriptions.Item label="Deliverd Quantity: ">{dataforView ? dataforView.delivered_quantity : ' '}</Descriptions.Item>
                <Descriptions.Item label="Returned Quantity: ">{dataforView ? dataforView.returned_quantity : ' '}</Descriptions.Item>
                <Descriptions.Item label="Claims Quantity: ">{dataforView ? dataforView.claims_quantity : ' '}</Descriptions.Item>
                <Descriptions.Item label="Description: ">{dataforView ? dataforView.description : ' '}</Descriptions.Item>
              </Descriptions>
            </Spin>
          </Card>
          <Spacer />
          <Card title="History">
            <Spin spinning={loadingItemview} tip="Loading item view history...">
              <ReactTable
                data={history}
                columns={itemListColumns}
              />
            </Spin>
          </Card>
        </Container>
      </div>
    );
  }
}

ItemListView.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doItemfetchviews: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doItemfetchviews: itemfetchviews,
};

const WrappedItemListView = Form.create({ name: 'create' })(ItemListView);
export default connect(null, mapDispatchToProps)(WrappedItemListView);
