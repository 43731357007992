export default {
  all: [0, 1, 2, 3, 4, 99, 5, 6, 7, 11, 13, 18, 23, 19, 21],
  superAdmin: [1],
  admin: [2],

  // Parent Menu
  adminPanel: [0, 2, 3, 4, 99, 5, 11, 13, 18, 19, 23, 21], // 1
  dashboardPanel: [1, 2, 3, 4, 99, 5, 13, 18, 19, 23, 21],
  reportsPanel: [1, 2, 3, 4, 99, 5, 11, 13, 18, 19, 23, 21],
  financePanel: [1, 2, 3, 4, 99, 5, 11, 18, 19, 23, 21],
  packagesPanel: [9, 0, 1, 2, 3, 4, 99, 5, 6, 7, 11, 13, 18, 23, 19, 21],
  itemsPanel: [0, 1, 2, 3, 4, 99, 5, 6, 7, 11, 13, 18, 23, 19, 21],

  // Sub-Parent Menu
  billing: [1, 2, 4, 99, 19, 21],
  cashierRemittance: [1, 2, 4, 99, 5, 19, 23],
  courierRemittance: [1, 2, 3, 4, 99, 5, 11, 18, 19, 23],
  reports: [1, 2, 3, 4, 99, 5, 11, 13, 18, 19, 23, 21],
  customerPickup: [1, 2, 3, 4, 99, 5, 6, 7, 11, 13, 18, 19, 23],
  merchantPickup: [1, 2, 3, 4, 99, 5, 6, 7, 11, 13, 18, 19, 23],
  delivery: [1, 2, 3, 4, 99, 5, 6, 7, 11, 13, 18, 19, 23],
  shipperPickup: [1, 2, 3, 4, 99, 5, 11, 13, 18, 19, 23],
  transfer: [1, 2, 3, 4, 99, 5, 11, 13, 18, 19, 23],
  RTS: [1, 2, 3, 4, 99, 5, 6, 11, 13, 18, 19, 23],
  RTM: [1, 2, 3, 4, 99, 5, 6, 11, 13, 18, 19, 23],
  tools: [0, 1, 2, 3, 4, 99, 5, 7, 11, 13, 18, 19, 21, 23],

  // Menu
  waybillOperations: [1, 2, 3, 4, 99, 11, 13, 18, 23],

  dashboard: [1, 2, 3, 4, 99, 5, 13, 18, 19, 23],
  billingDashboard: [1, 2, 3, 4, 99, 5, 13, 18, 19, 23, 21],

  dispatchOperations: [1, 2, 3, 5, 11, 13, 18, 23],
  dispatchViews: [1, 2, 3, 4, 99, 5, 11, 13, 18, 19, 23, 21],

  billingOperations: [1, 21],
  billingViews: [1, 2, 4, 99, 19, 21],

  monitoring: [1, 2, 3, 4, 99, 5, 11, 13, 18, 19, 23],

  cashierRemittanceOperations: [1, 5, 23],
  cashierRemittanceViews: [1, 2, 4, 99, 5, 19, 23],

  courierRemittanceOperations: [1, 5, 23],
  courierRemittanceViews: [1, 2, 3, 4, 99, 5, 11, 18, 19, 23],

  reportsCashierUnremitted: [1, 2, 4, 99, 5, 19, 23],
  reportsItems: [1, 2, 3, 4, 99, 5, 11, 13, 18, 19, 23, 21],
  reportsItemsLocation: [1, 2, 3, 4, 99, 5, 11, 13, 18, 19, 23],
  reportsUnremittedItems: [1, 2, 3, 4, 99, 5, 11, 13, 18, 19, 23],

  repush: [1, 2, 3, 4, 99, 5, 19, 21],

  clients: [2, 3, 4, 99, 5, 11, 13, 18, 19, 23, 21],
  clientsStatusMapView: [0, 1, 2, 3, 4, 99, 5, 11, 13, 18, 19, 23, 21],

  usersViews: [2, 4, 99],

  portClusterViews: [2, 3, 4, 99, 11, 13, 18, 19, 23],
  portClusterOperations: [2, 4, 99],

  customerPickupOperations: [1, 2, 3, 4, 99, 11, 13, 18, 23],
  customerPickupTagging: [1, 2, 3, 4, 99, 6, 7, 13, 18, 23],
  customerPickupViews: [1, 2, 3, 4, 99, 5, 11, 13, 18, 19, 23],

  merchantPickupViews: [1, 2, 3, 4, 99, 5, 6, 7, 11, 13, 18, 19, 23],
  merchantPickupOperations: [1, 2, 3, 11, 13, 18, 23],
  merchantPickupTagging: [1, 2, 3, 6, 7, 13, 18, 23],

  deliveryOperations: [1, 2, 3, 4, 99, 5, 11, 13, 18, 23],
  deliveryPODReturned: [1, 2, 5, 6, 7, 13, 18, 23],
  deliveryViews: [1, 2, 3, 4, 99, 5, 11, 13, 18, 19, 23],
  deliveryTagging: [1, 2, 6, 13, 18, 23],

  shipperPickupOperations: [1, 2, 3, 11, 13, 18, 19, 23],
  shipperPickupViews: [1, 2, 3, 4, 99, 5, 11, 13, 18, 19, 23],

  transferViews: [1, 2, 3, 4, 99, 5, 11, 13, 18, 19, 23],
  transferOperations: [1, 2, 3, 11, 13, 18, 19, 23],

  RTSOperations: [1, 2, 3, 11, 13, 18, 19, 23],
  RTSPODReturned: [1, 2, 5, 6, 7, 13, 18, 23],
  RTSViews: [1, 2, 3, 4, 99, 5, 11, 13, 18, 19, 23],
  RTSTagging: [1, 2, 3, 6, 13, 18, 23],

  RTMOperations: [1, 2, 3, 11, 13, 18, 19, 23],
  RTMViews: [1, 2, 3, 4, 99, 5, 11, 13, 18, 19, 23],
  RTMTagging: [1, 2, 3, 6, 13, 18, 23],

  acceptToWarehouse: [1, 2, 3, 7, 11, 13, 18, 19, 23],

  voidWaybill: [0, 1, 2, 4, 99, 11, 13, 23],

  claims: [1, 2, 4, 99, 13, 19],

  toolsGroupChecker: [0, 1, 2, 3, 4, 99, 5, 11, 13, 18, 19, 23],
  toolsDimweightUpload: [9],
  packageWaybillList:[9, 0, 1, 2, 3, 4, 99, 5, 6, 7, 11, 13, 18, 23, 19, 21],
  toolsSidebarMenu: [9, 0, 1, 2, 3, 4, 99, 5, 7, 11, 13, 18, 19, 21, 23],
  toolsStatusUpdater: [1, 2],

  itemsOperations: [0, 1, 2, 3, 4, 99, 5, 11, 13, 18, 19, 23],
  itemsViews: [0, 1, 2, 3, 4, 99, 5, 11, 13, 18, 19, 23, 21],

  waybillEditor: [1],
};
