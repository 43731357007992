import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, Col, Row, Icon, Popconfirm, message,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit, faTrash,
} from '@fortawesome/free-solid-svg-icons';
import ServerSideTable from '../../../components/ServerSideTable';
import PortClusterUpdateModal from './PortClusterUpdateModal';
import { deletePortClusterRequest } from '../ducks';

function PortClusterList({
  tableFilter, data, onTableUpdate, fetchingTableDetails, branchCodeList, ...props
}) {
  const { doDeletePortClusterRequest } = props;

  const [rowDetails, setRowDetails] = useState({
    id: null,
    branch: null,
    cluster: null,
    route_code: null,
  });

  const [editModalVisibility, setEditModalVisibility] = useState(false);

  function DeleteDataRow(dataId) {
    doDeletePortClusterRequest(dataId).then((res) => {
      message.success(res.payload.data.message);
      onTableUpdate({
        ...tableFilter,
        page: tableFilter.page - 1,
      });
    }).catch(() => {
      message.error('Something wen`t wrong, please try again later.');
    });
  }

  return (
    <React.Fragment>
      <PortClusterUpdateModal
        editModalVisibility={editModalVisibility}
        setEditModalVisibility={setEditModalVisibility}
        rowDetails={rowDetails}
        setRowDetails={setRowDetails}
        tableFilter={tableFilter}
        onTableUpdate={onTableUpdate}
        branchCodeList={branchCodeList}
      />
      <ServerSideTable
        data={data.rows}
        pages={data.pages}
        columns={[
          {
            Header: 'Id',
            accessor: 'id',
          },
          {
            Header: 'Province',
            accessor: 'province',
          },
          {
            Header: 'City',
            accessor: 'city',
          },
          {
            Header: 'Barangay',
            accessor: 'barangay',
          },
          {
            Header: 'Branch',
            accessor: 'branch',
          },
          {
            Header: 'Route Code',
            accessor: 'route_code',
          },
          {
            Header: 'Cluster',
            accessor: 'cluster',
          },
          {
            Header: 'Created At',
            accessor: 'created_at',
          },
          {
            Header: 'Options',
            sortable: false,
            filterable: false,
            // eslint-disable-next-line no-unused-vars
            Cell: info => (
              <Row>
                <Col span={11}>
                  <Button
                    type="link"
                    block
                    onClick={() => {
                      setRowDetails({ ...info.original });
                      setEditModalVisibility(true);
                    }}
                  >
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={faEdit} fixedWidth />
                    </Icon>
                      Edit
                  </Button>
                </Col>
                <Col span={13}>
                  <Popconfirm
                    placement="topLeft"
                    title="Are you sure you want to delete this port cluster?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => { DeleteDataRow(info.row.id); }}
                  >
                    <Button type="link">
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faTrash} fixedWidth />
                      </Icon>
                        Delete
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            ),
          },
        ]}
        loading={fetchingTableDetails}
        loadingText="Fetching port cluster list..."
        onFetchData={onTableUpdate}
      />
    </React.Fragment>

  );
}


PortClusterList.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  tableFilter: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  branchCodeList: PropTypes.oneOfType([PropTypes.array]).isRequired,
  onTableUpdate: PropTypes.func.isRequired,
  doDeletePortClusterRequest: PropTypes.func.isRequired,
  fetchingTableDetails: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  doDeletePortClusterRequest: deletePortClusterRequest,
};


export default connect(null, mapDispatchToProps)(PortClusterList);
