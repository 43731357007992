import {
  apiFetchOptions,
  apiFetchPortClusterList,
  apiDeletePortCluster,
  apiCreatePortCluster,
  apiFetchPhilippineLocationTypeLike,
  apiUpdatePortCluster,
  apiValidateMassCreateUpload,
  apiMassCreateUpload,
  apiValidateMassUpdateUpload,
  apiMassUpdateUpload,
  apiFetchPortClusterLocationTypeWhereIn,
  apiFetchPortClusterLocationType,
  apiFetchPortClusterPhilippineLocationType,
  apiFetchPortClusterPhilpiineLocationTypeLike,
} from '../../services/apiEndpoints';

export const FETCH_PHILIPPINE_LOCATION_TYPE_LIST = 'portCluster/FETCH_PHILIPPINE_LOCATION_TYPE_LIST';
export const fetchPhilippineLocationTypeListRequest = type => ({
  type: FETCH_PHILIPPINE_LOCATION_TYPE_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchPortClusterPhilippineLocationType(type),
    },
  },
});

export const FETCH_PHILIPPINE_LOCATION_TYPE_LIST_LIKE = 'portCluster/FETCH_PHILIPPINE_LOCATION_TYPE_LIST_LIKE';
export const fetchPhilippineLocationTypeListLikeRequest = (type, string) => ({
  type: FETCH_PHILIPPINE_LOCATION_TYPE_LIST_LIKE,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchPortClusterPhilpiineLocationTypeLike(type, string),
    },
  },
});

export const FETCH_PORT_CLUSTER_LOCATION_TYPE = 'portCluster/FETCH_PORT_CLUSTER_LOCATION_TYPE';
export const fetchPcLocationTypeRequest = string => ({
  type: FETCH_PORT_CLUSTER_LOCATION_TYPE,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchPortClusterLocationType(string),
    },
  },
});

// Legacy
//
export const FETCH_PORT_CLUSTER_LIST = 'portCluster/FETCH_PORT_CLUSTER_LIST';
export const fetchPortClusterListRequest = (params, hasExport) => ({
  type: FETCH_PORT_CLUSTER_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchPortClusterList(),
      params: {
        ...params,
        filters: JSON.stringify(params.filtered),
        sorts: JSON.stringify(params.sorted),
        export: hasExport,
      },
    },
  },
});

export const DELETE_PORT_CLUSTER = 'portCluster/DELETE_PORT_CLUSTER';
export const deletePortClusterRequest = id => ({
  type: DELETE_PORT_CLUSTER,
  payload: {
    client: 'secure',
    request: {
      ...apiDeletePortCluster(id),
    },
  },
});

export const UPDATE_PORT_CLUSTER = 'portCluster/UPDATE_PORT_CLUSTER';
export const updatePortClusterRequest = (id, data) => ({
  type: UPDATE_PORT_CLUSTER,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdatePortCluster(id),
      data,
    },
  },
});

export const CREATE_PORT_CLUSTER = 'portCluster/CREATE_PORT_CLUSTER';
export const portClusterCreateRequest = data => ({
  type: CREATE_PORT_CLUSTER,
  payload: {
    client: 'secure',
    request: {
      ...apiCreatePortCluster(),
      data,
    },
  },
});

export const FETCH_BRANCH_CODE_LIST = 'portCluster/FETCH_BRANCH_CODE_LIST';
export const fetchBranchCodeListRequest = () => ({
  type: FETCH_BRANCH_CODE_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchOptions('destination'),
    },
  },
});


export const FETCH_LOCATION_TYPE_LIKE = 'portCluster/FETCH_LOCATION_TYPE_LIKE';
export const fetchLocationTypeLikeRequest = (endpoint, string) => ({
  type: FETCH_LOCATION_TYPE_LIKE,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchPhilippineLocationTypeLike(endpoint, string),
    },
  },
});

export const FETCH_LOCATION_TYPE_WHERE_IN = 'portCluster/FETCH_LOCATION_TYPE_WHERE_IN';
export const fetchLocationTypeLikeRequestWhereIn = (endpoint, params) => ({
  type: FETCH_LOCATION_TYPE_WHERE_IN,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchPortClusterLocationTypeWhereIn(endpoint),
      params,
    },
  },
});

export const VALIDATE_MASS_CREATE_UPLOAD = 'portCluster/VALIDATE_MASS_CREATE_UPLOAD';
export const validateMassCreateUploadRequest = file => ({
  type: VALIDATE_MASS_CREATE_UPLOAD,
  payload: {
    client: 'secure',
    request: {
      ...apiValidateMassCreateUpload(),
      data: file,
    },
  },
});
export const MASS_UPLOAD_CREATE = 'portCluster/MASS_UPLOAD_CREATE';
export const massCreateUploadRequest = data => ({
  type: MASS_UPLOAD_CREATE,
  payload: {
    client: 'secure',
    request: {
      ...apiMassCreateUpload(),
      data,
    },
  },
});

export const VALIDATE_MASS_UPDATE_UPLOAD = 'portCluster/VALIDATE_MASS_UPDATE_UPLOAD';
export const validateMassUpdateUploadRequest = file => ({
  type: VALIDATE_MASS_UPDATE_UPLOAD,
  payload: {
    client: 'secure',
    request: {
      ...apiValidateMassUpdateUpload(),
      data: file,
    },
  },
});
export const MASS_UPLOAD_UPDATE = 'portCluster/MASS_UPLOAD_UPDATE';
export const massUpdateUploadRequest = data => ({
  type: MASS_UPLOAD_UPDATE,
  payload: {
    client: 'secure',
    request: {
      ...apiMassUpdateUpload(),
      data,
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
