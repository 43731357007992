import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { message } from 'antd';
import moment from 'moment';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import DispatchForm from '../../components/DispatchForm';
import { fetchCustomerPickupTrackingNumber, createCustomerPickup } from './newDucks';

export class CustomerPickupDispatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preventDuplicate: true,
      trackingNumberRows: [],
      errors: [],
      isSubmitting: false,
      trackingNumberValidation: [],
    };
    this.packageFormHandler = this.packageFormHandler.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
  }

  deleteHandler(trackingNumber) {
    const { trackingNumberRows } = this.state;
    const filteredTrackingNumber = trackingNumberRows.filter(
      data => data.tracking_number !== trackingNumber,
    );

    this.setState({
      trackingNumberRows: filteredTrackingNumber,
    });

    message.success('Successfully removed tracking number!');
  }

  packageFormHandler(values) {
    const { trackingNumberRows } = this.state;
    const { doFetchCustomerPickupTrackingNumber } = this.props;
    this.setState(prevState => ({
      trackingNumberValidation: [
        ...prevState.trackingNumberValidation,
        { tracking_number: values.tracking_number },
      ],
    }));

    const splitTrackingNumber = values.tracking_number ? values.tracking_number.split('\n') : '';
    const filteredTrackingNumber = splitTrackingNumber.filter(
      (item, key) => splitTrackingNumber.indexOf(item) >= key && item !== '',
    );
    // eslint-disable-next-line no-unused-vars
    filteredTrackingNumber.forEach((item, key) => {
      const { trackingNumberValidation: validation } = this.state;
      const existLocal = validation.filter(tn => tn.tracking_number === item);

      const exists = trackingNumberRows.filter(tn => tn.tracking_number === item);

      if (existLocal.length > 0) {
        // console.log('first console log');
        message.error(`Tracking number ${item} is already entered.`);
        this.setState(prevState => ({
          preventDuplicate: true,
          errorMessage: `Tracking number ${item} is already entered.`,
          errors: [
            ...prevState.errors,
            {
              tracking_number: item,
              message: 'Tracking number is already entered.',
            },
          ],
        }));
      } else if (exists.length > 0) {
        message.error(`Tracking number ${item} is already entered.`);
        this.setState(prevState => ({
          preventDuplicate: true,
          errorMessage: `Tracking number ${item} is already entered.`,
          errors: [
            ...prevState.errors,
            {
              tracking_number: item,
              message: 'Tracking number is already entered.',
            },
          ],
        }));
      } else {
        doFetchCustomerPickupTrackingNumber(item)
          .then((action) => {
            this.setState(prevState => ({
              trackingNumberValidation: [],
              preventDuplicate: true,
              errorMessage: ' ',
              trackingNumberRows: [
                ...prevState.trackingNumberRows,
                {
                  customer: action.payload.data.shipper.shipper_name,
                  tracking_number: item,
                  customer_address: action.payload.data.shipper.shipper_address,
                  customer_contact_number: action.payload.data.shipper.shipper_contact,
                },
              ],
            }));
            message.success(`Successfully added ${item}`);
          })
          .catch((action) => {
            let errorMessage;
            if (action.error.response.status === 400) {
              errorMessage = action.error.response.data.message;
              this.setState(prevState => ({
                trackingNumberValidation: [],
                preventDuplicate: true,
                errorMessage: action.error.response.data.message,
                errors: [
                  ...prevState.errors,
                  {
                    tracking_number: item,
                    message: action.error.response.data.message,
                  },
                ],
              }));
            } else {
              errorMessage = 'Something went wrong, please try again later.';
            }
            message.error(errorMessage, 3);
          });
      }
    });
  }

  formSubmitHandler(values) {
    const { trackingNumberRows } = this.state;
    const { doCreateCustomerPickup, history } = this.props;
    const valuesValidate = values;
    if (valuesValidate.helper_id === undefined) {
      valuesValidate.helper_id = 0;
    }
    if (valuesValidate.helper2_id === undefined) {
      valuesValidate.helper2_id = 0;
    }
    if (valuesValidate.driver2_id === undefined) {
      valuesValidate.driver2_id = 0;
    }

    if (trackingNumberRows.length > 0) {
      const packages = [];

      this.setState({
        isSubmitting: true,
      });
      // eslint-disable-next-line no-unused-vars
      trackingNumberRows.forEach((item, key) => {
        packages.push(item.tracking_number);
      });

      const params = {
        ...valuesValidate,
        expected_arrival: moment(valuesValidate.expected_arrival).format('YYYY-MM-DD HH:mm:ss'),
        type: 'customer',
        packages,
      };

      doCreateCustomerPickup(params)
        .then((action) => {
          this.setState({
            isSubmitting: false,
          });
          history.push(`/customer-pickup/${action.payload.data.ph_id}`);
        })
        .catch(() => {
          this.setState({
            isSubmitting: false,
          });
          message.error('Something went wrong, please try again later.');
        });
    } else {
      message.error('No tracking number found!');
    }
  }

  render() {
    const {
      trackingNumberRows, errors, isSubmitting, preventDuplicate, errorMessage,
    } = this.state;

    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Customer Pickup',
      },
      {
        breadcrumbName: 'Dispatch',
      },
    ];

    const listColumns = [
      {
        Header: 'Tracking Number',
        accessor: 'tracking_number',
      },
      {
        Header: 'Customer',
        accessor: 'customer',
      },
      {
        Header: 'Customer Address',
        accessor: 'customer_address',
      },
      {
        Header: 'Customer Contact Number',
        accessor: 'customer_contact_number',
      },
    ];

    const selectOptions = ['vehicle', 'courier'];

    const requiredFormSubmitFields = [
      'driver_id',
      'driver2_id',
      'helper_id',
      'helper2_id',
      'vehicle_id',
      'remarks',
      'expected_arrival',
      'driver_rate',
      'driver2_rate',
      'helper_rate',
      'helper2_rate',
    ];

    const requiredPackageFormFields = ['tracking_number'];

    return (
      <div className="CustomerPickupDispatch">
        <PageHeader title="Customer Pickup" routes={breadCrumbs} />
        <Container>
          <DispatchForm
            doPreventDuplicate={preventDuplicate}
            formInformation="Customer Pickup Information"
            tablInformation="Customer Pickup Dispatch List"
            selectOptions={selectOptions}
            requiredFormSubmitFields={requiredFormSubmitFields}
            requiredPackageFormFields={requiredPackageFormFields}
            listColumns={listColumns}
            packageFormHandler={this.packageFormHandler}
            formSubmitHandler={this.formSubmitHandler}
            deleteHandler={this.deleteHandler}
            dispatchItems={trackingNumberRows}
            errorMessageDispatch={errorMessage}
            errorItems={errors}
            isSubmitting={isSubmitting}
            hasNoChargableWeight
            hasExpectedArrival
          />
        </Container>
      </div>
    );
  }
}

CustomerPickupDispatch.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchCustomerPickupTrackingNumber: PropTypes.func.isRequired,
  doCreateCustomerPickup: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchCustomerPickupTrackingNumber: fetchCustomerPickupTrackingNumber,
  doCreateCustomerPickup: createCustomerPickup,
};

export default connect(
  null,
  mapDispatchToProps,
)(CustomerPickupDispatch);
