import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
 DatePicker, Form, Button, Icon 
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faSearch } from '@fortawesome/free-solid-svg-icons';

import { bList, bListViews } from './ducks';
import { billingListView } from '../ducks';
import PageHeader from '../../components/PageHeader';
import ReactTable from '../../components/ServerSideTable';
import Card from '../../components/Card';
import Container from '../../components/Container';
import Spacer from '../../components/Spacer';

export class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateHandler: [],
      loading: true,
      userData: {
        rows: [],
        pages: 0,
        total: 0,
      },
      payloadReq: {
        export: false,
        page: 0,
        pageSize: 0,
        filter: [],
        sorted: [],
      },
      dateApplied: false,
    };

    this.onFetchDataFunc = this.onFetchDataFunc.bind(this);
  }

  onFetchDataFunc(state, instance) {
    this.setState({
      loading: true,
    });
    const { dateHandler, dateApplied } = this.state;
    const { billingNonFilterList } = this.props;
    const paramsReq = {
      export: false,
      page: state.page,
      pageSize: state.pageSize,
      filter: state.filtered,
      sorted: state.sorted,
    };

    if (dateApplied) {
      paramsReq.filter = [...dateHandler, ...paramsReq.filter];
    } else {
      paramsReq.filter = [...paramsReq.filter];
    }

    billingNonFilterList('billing-list', paramsReq).then((res) => {
      this.setState({
        loading: false,
        userData: {
          rows: res.payload.data.rows,
          pages: res.payload.data.pages,
          total: res.payload.data.total,
        },
        payloadReq: paramsReq,
      });
    });
  }

  getViewAccountHandler(e) {
    const { billingListViews, history } = this.props;
    billingListViews(e.id).then(() => history.push(`/billing/details/${e.id}`));
  }

  submitHandlerExport() {
    this.setState({
      loading: true,
    });
    const { payloadReq } = this.state;
    const { billingNonFilterList } = this.props;
    const paramsReq = {
      export: true,
      page: payloadReq.page,
      pageSize: payloadReq.pageSize,
      filter: payloadReq.filter,
      sorted: payloadReq.sorted,
    };
    billingNonFilterList('billing-list', paramsReq).then((res) => {
      const newBlob = new Blob([res.payload.data]);
      fileDownload(newBlob, 'Billing-list.csv');
      this.setState({
        loading: false,
      });
    });
  }

  dateHandler(dateStrings) {
    const newDateOne = dateStrings[0];
    const newDateTwo = dateStrings[1];
    const formatDateOne = format(newDateOne, 'YYYY-MM-DD HH:mm');
    const formatDateTwo = format(newDateTwo, 'YYYY-MM-DD HH:mm');
    const dateForm = `${formatDateOne}|${formatDateTwo}`;

    this.setState({
      dateHandler: [
        {
          id: 'created_at',
          value: formatDateOne !== 'Invalid Date' ? dateForm : '',
        },
      ],
      dateApplied: true,
    });
  }

  submitHandler() {
    const payloadReq = {
      page: 0,
      pageSize: 10,
      filtered: [],
      sorted: [],
    };

    this.onFetchDataFunc(payloadReq);
  }

  render() {
    const { loading, userData } = this.state;
    const breadCrumbs = [
      {
        breadcrumbName: 'Billing',
      },
      {
        breadcrumbName: 'List',
      },
    ];
    return (
      <div className="List">
        <PageHeader title="BILLING LIST" routes={breadCrumbs} />
        <Container>
          <Card>
            <Form.Item label="DateRange">
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment('00:00', 'HH:mm'),
                    moment('00:00', 'HH:mm'),
                  ],
                  format: 'HH:mm',
                }}
                onChange={e => this.dateHandler(e)}
              />
            </Form.Item>
            <Button onClick={() => this.submitHandler()} type="primary" block>
              <Icon viewBox="0 0 1024 1024">
                <FontAwesomeIcon icon={faSearch} fixedWidth />
              </Icon>
              Apply
            </Button>
          </Card>
          <Spacer />
          <Card
            title={(
<Button onClick={() => this.submitHandlerExport()} type="danger">
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faFileExport} fixedWidth />
                </Icon>
                Export
              </Button>
)}
            extra={`Total: ${userData.total
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
          >
            <ReactTable
              data={userData.rows}
              pages={userData.pages}
              columns={[
                {
                  Header: 'Date',
                  accessor: 'created_at',
                },
                {
                  Header: 'Billing ID',
                  accessor: 'id',
                },
                {
                  Header: 'Total Payment',
                  accessor: 'total_payment',
                },
                {
                  Header: 'Processed By',
                  accessor: 'created_by',
                },
                {
                  Header: 'Options',
                  sortable: false,
                  filterable: false,
                  Cell: row => (
                    <center>
                      <Button
                        type="link"
                        onClick={() => this.getViewAccountHandler(row.original)}
                      >
                        <Icon type="eye" />
                        View
                      </Button>
                    </center>
                  ),
                },
              ]}
              loadingText="Fetching user list. . ."
              loading={loading}
              onFetchData={this.onFetchDataFunc}
            />
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  billingListTable: state.billing.dataBillingList,
});

const mapDispatchToProps = {
  billingList: bList,
  billingNonFilterList: billingListView,
  billingListViews: bListViews,
};

List.propTypes = {
  billingNonFilterList: PropTypes.func.isRequired,
  billingListViews: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
