import {
  apiCreateUser,
  apiFetchOptions,
  apiFetchUserList,
  apiResetPassword,
  apiUpdateUser,
  apiFetchUserDetails,
  apiFetchOptionsLike,
  apiValidateUsername,
} from '../../services/apiEndpoints';

const CREATE_USER = 'users/CREATE_USER';
export const createUser = values => ({
  type: CREATE_USER,
  payload: {
    client: 'secure',
    request: {
      ...apiCreateUser,
      data: values,
    },
  },
});

const FETCH_OPTIONS_LIKE = 'users/FETCH_OPTIONS_LIKE';
export const fetchOptionsLike = (type, string) => ({
  type: FETCH_OPTIONS_LIKE,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchOptionsLike(type, string),
    },
  },
});

const FETCH_OPTIONS = 'user/FETCH_OPTIONS';
export const fetchOptions = option => ({
  type: FETCH_OPTIONS,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchOptions(option),
    },
  },
});

const FETCH_USER_LIST = 'user/FETCH_USER_LIST';
export const fetchUserList = params => ({
  type: FETCH_USER_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchUserList,
      params,
    },
  },
});

const RESET_PASSWORD = 'users/RESET_PASSWORD';
export const resetPassword = userId => ({
  type: RESET_PASSWORD,
  payload: {
    client: 'secure',
    request: {
      ...apiResetPassword(userId),
    },
  },
});

const FETCH_USER_DETAILS = 'users/FETCH_USER_DETAILS';
export const fetchUserDetails = userId => ({
  type: FETCH_USER_DETAILS,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchUserDetails(userId),
    },
  },
});

const UPDATE_USER = 'users/UPDATE_USER';
export const updateUser = (userId, dataFields) => ({
  type: UPDATE_USER,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateUser(userId),
      data: dataFields,
    },
  },
});

const VALIDATE_USERNAME = 'users/VALIDATE_USERNAME';
export const validateUsername = string => ({
  type: VALIDATE_USERNAME,
  payload: {
    client: 'secure',
    request: {
      ...apiValidateUsername(string),
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
