import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { format } from 'date-fns';
import {
  Button,
  Popconfirm,
  Spin,
  DatePicker,
  Col,
  Form,
  Icon,
  message,
  Descriptions,
} from 'antd';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import LineChart from '../../components/LineChart';

import {
  dashboardKpi,
  dashboardKpiDownload,
  fetchOptions,
  fetchOptionsLike,
} from '../ducks';
import Pageheader from '../../components/PageHeader';
import Container from '../../components/Container';
import Card from '../../components/Card';
import ReactSelect from '../../components/Select';
import ReactTable from '../../components/ReactTable';
import Row from '../../components/Row';
import Spacer from '../../components/Spacer';

const styleTable = width => ({
  border: '0.5px solid black',
  fontSize: '10px',
  padding: '5px',
  width,
  borderCollapse: 'collapse',
});

class KpiDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardData: [],
      branchOptional: {
        branch: null,
      },
      typeOptional: {
        type: null,
      },
      showIsland: true,
      kpiReference: 'Standard',
      island: 'GMA',
      clientOption: {
        client: null,
      },
      clientCol: [],
      branchCol: [],
      dateHandlerState: {
        from: null,
        to: null,
      },
      spinnerState: true,
      dashboardChart: {},
      isFetchingSelect: false,
      responsiveTable: true,
    };
  }

  componentDidMount() {
    this.setState({ spinnerState: true });
    // const { dataChart } = this.state;
    const { doDashboardKpi, doFetchOptions } = this.props;
    const dateAdd = new Date();
    dateAdd.setHours(0);
    dateAdd.setMinutes(0);
    dateAdd.setSeconds(0);
    const formatDateTo = format(dateAdd, 'YYYY-MM-DD HH:mm:ss');
    dateAdd.setDate(dateAdd.getDate() - 6);
    const formatDateFrom = format(dateAdd, 'YYYY-MM-DD HH:mm:ss');

    const params = {
      from: formatDateFrom,
      to: formatDateTo,
    };
    this.setState({
      dateHandlerState: {
        from: formatDateFrom,
        to: formatDateTo,
      },
    });
    doDashboardKpi(params).then((res) => {
      let dataChart = res.payload.data;
      dataChart = dataChart.map(data => ({
        ...data,
        first_attempt_delivered: parseInt(data.first_attempt_delivered, 10),
        otif: parseInt(data.otif, 10),
        rotif: data.rotif !== 'N/A' ? parseInt(data.rotif, 10) : 0,
      }));
      this.setState({
        dashboardData: dataChart,
        spinnerState: false,
        dashboardChart: {
          labels: dataChart.map(data => data.report_date),
          datasets: [
            {
              label: 'Volume',
              data: dataChart.map(data => data.volume),
              backgroundColor: 'rgb(77, 255, 77)',
              borderColor: 'rgb(77, 255, 77)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Delivered',
              data: dataChart.map(data => data.delivered),
              backgroundColor: 'rgb(255, 204, 102)',
              borderColor: 'rgb(255, 204, 102)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'No Attempt',
              data: dataChart.map(data => data.no_attempt),
              backgroundColor: 'rgb(139, 0, 0)',
              borderColor: 'rgb(139, 0, 0)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'One Attempt',
              data: dataChart.map(data => data.one_attempt),
              backgroundColor: 'rgb(50, 205, 50)',
              borderColor: 'rgb(50, 205, 50)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Two Attempts',
              data: dataChart.map(data => data.two_attempts),
              backgroundColor: 'rgb(205, 92, 92)',
              borderColor: 'rgb(205, 92, 92)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: '3+ Attempts',
              data: dataChart.map(data => data.three_attempts),
              backgroundColor: 'rgb(220, 20, 60)',
              borderColor: 'rgb(220, 20, 60)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Failed Delivery',
              data: dataChart.map(data => data.fd),
              backgroundColor: 'rgb(140, 26, 255)',
              borderColor: 'rgb(140, 26, 255)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Claims',
              data: dataChart.map(data => data.claims),
              backgroundColor: 'rgb(0, 102, 51)',
              borderColor: 'rgb(0, 102, 51)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Open',
              data: dataChart.map(data => data.open),
              backgroundColor: 'rgb(77, 148, 255)',
              borderColor: 'rgb(77, 148, 255)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'First Attempt Delivered',
              data: dataChart.map(data => data.first_attempt_delivered),
              backgroundColor: 'rgb(255, 255, 0)',
              borderColor: 'rgb(255, 255, 0)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'On Time In Full',
              data: dataChart.map(data => data.otif),
              backgroundColor: 'rgb(0, 0, 0)',
              borderColor: 'rgb(0, 0, 0)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Returned On Time In Full',
              data: dataChart.map(data => data.rotif),
              backgroundColor: 'rgb(80, 80, 80)',
              borderColor: 'rgb(80, 80, 80)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
          ],
        },
      });
    });
    doFetchOptions('destination').then(res => this.setState({ branchCol: res.payload.data, typeOptional: { type: 'standard' } }));
    doFetchOptions('client').then(res => this.setState({ clientCol: res.payload.data.slice(0, 50), typeOptional: { type: 'standard' } }));
  }

  downloadSet(date, reference) {
    this.setState({ spinnerState: true });
    const {
      branchOptional,
      typeOptional,
      clientOption,
      kpiReference,
      island,
    } = this.state;
    const { doDashboardKpiDownload } = this.props;
    const dateAdd = new Date(date);
    dateAdd.setHours(0);
    dateAdd.setMinutes(0);
    dateAdd.setSeconds(0);
    const formatDateFrom = format(dateAdd, 'YYYY-MM-DD HH:mm:ss');
    dateAdd.setHours(23);
    dateAdd.setMinutes(59);
    dateAdd.setSeconds(59);
    const formatDateTo = format(dateAdd, 'YYYY-MM-DD HH:mm:ss');

    const params = {
      from: formatDateFrom,
      to: formatDateTo,
      ...branchOptional,
      ...typeOptional,
      reference,
      ...clientOption,
      type: kpiReference,
      state: island,
    };

    doDashboardKpiDownload(params).then((res) => {
      const newBlob = new Blob([res.payload.data]);
      fileDownload(newBlob, `kpi-${reference}.csv`);
      this.setState({
        spinnerState: false,
      });
      message.success('The file is successfully downloaded!');
    });
  }

  branchHandler(e) {
    this.setState({
      branchOptional: {
        branch: e !== undefined ? e : null,
      },
    });
  }

  clientHandler(e) {
    this.setState({
      clientOption: {
        client: e !== undefined ? e : null,
      },
    });
  }

  searchClient(value) {
    const { doFetchOptionsLike } = this.props;

    this.setState({
      isFetchingSelect: true,
    });

    doFetchOptionsLike('client', value).then((action) => {
      this.setState({
        clientCol: action.payload.data.slice(0, 50),
        isFetchingSelect: false,
      });
    });
  }

  typeHandler(e) {
    this.setState({
      typeOptional: {
        type: e !== undefined ? e : null,
      },
    });
  }

  islandHandler(e) {
    this.setState({
      island: e !== undefined ? e : null,
    });
  }

  referenceHandler(e) {
    let showIsland = true;

    if (e === 'Lazada') {
      showIsland = false;
    }

    this.setState({
      kpiReference: e !== undefined ? e : null,
      showIsland,
    });
  }

  dateHandler(dateStrings) {
    const newDateOne = dateStrings[0];
    const newDateTwo = dateStrings[1];
    const formatDateOne = format(newDateOne, 'YYYY-MM-DD HH:mm:ss');
    const formatDateTwo = format(newDateTwo, 'YYYY-MM-DD HH:mm:ss');

    this.setState({
      dateHandlerState: {
        from: formatDateOne !== 'Invalid Date' ? formatDateOne : null,
        to: formatDateTwo !== 'Invalid Date' ? formatDateTwo : null,
      },
    });
  }

  submitSearch() {
    this.setState({ spinnerState: true });
    const {
      dateHandlerState,
      branchOptional,
      typeOptional,
      clientOption,
      kpiReference,
      island,
    } = this.state;
    const { doDashboardKpi } = this.props;

    const params = {
      ...dateHandlerState,
      ...branchOptional,
      ...typeOptional,
      ...clientOption,
      reference: kpiReference,
      state: island,
    };

    doDashboardKpi(params).then((res) => {
      let dataChart = res.payload.data;
      dataChart = dataChart.map(data => ({
        ...data,
        first_attempt_delivered: parseInt(data.first_attempt_delivered, 10),
        otif: parseInt(data.otif, 10),
        rotif: data.rotif !== 'N/A' ? parseInt(data.rotif, 10) : 0,
      }));
      this.setState({
        dashboardData: dataChart,
        spinnerState: false,
        dashboardChart: {
          labels: dataChart.map(data => data.report_date),
          datasets: [
            {
              label: 'Volume',
              data: dataChart.map(data => data.volume),
              backgroundColor: 'rgb(77, 255, 77)',
              borderColor: 'rgb(77, 255, 77)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Delivered',
              data: dataChart.map(data => data.delivered),
              backgroundColor: 'rgb(255, 204, 102)',
              borderColor: 'rgb(255, 204, 102)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'No Attempt',
              data: dataChart.map(data => data.no_attempt),
              backgroundColor: 'rgb(139, 0, 0)',
              borderColor: 'rgb(139, 0, 0)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'One Attempt',
              data: dataChart.map(data => data.one_attempt),
              backgroundColor: 'rgb(50, 205, 50)',
              borderColor: 'rgb(50, 205, 50)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Two Attempts',
              data: dataChart.map(data => data.two_attempts),
              backgroundColor: 'rgb(205, 92, 92)',
              borderColor: 'rgb(205, 92, 92)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: '3+ Attempts',
              data: dataChart.map(data => data.three_attempts),
              backgroundColor: 'rgb(220, 20, 60)',
              borderColor: 'rgb(220, 20, 60)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Failed Delivery',
              data: dataChart.map(data => data.fd),
              backgroundColor: 'rgb(140, 26, 255)',
              borderColor: 'rgb(140, 26, 255)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Claims',
              data: dataChart.map(data => data.claims),
              backgroundColor: 'rgb(0, 102, 51)',
              borderColor: 'rgb(0, 102, 51)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Open',
              data: dataChart.map(data => data.open),
              backgroundColor: 'rgb(77, 148, 255)',
              borderColor: 'rgb(77, 148, 255)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'First Attempt Delivered',
              data: dataChart.map(data => data.first_attempt_delivered),
              backgroundColor: 'rgb(255, 255, 0)',
              borderColor: 'rgb(255, 255, 0)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'On Time In Full',
              data: dataChart.map(data => data.otif),
              backgroundColor: 'rgb(0, 0, 0)',
              borderColor: 'rgb(0, 0, 0)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Returned On Time In Full',
              data: dataChart.map(data => data.rotif),
              backgroundColor: 'rgb(80, 80, 80)',
              borderColor: 'rgb(80, 80, 80)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
          ],
        },
      });
    });
  }

  /* eslint-disable class-methods-use-this */
  printReport() {
    const content = window.document.getElementById('divcontents');
    const pri = document.getElementById('ifmcontentstoprint').contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  }
  /* eslint-enable class-methods-use-this */

  downloadReport() {

  }

  render() {
    const {
      dashboardData,
      clientCol,
      branchCol,
      spinnerState,
      dashboardChart,
      isFetchingSelect,
      responsiveTable,
      showIsland,
    } = this.state;
    const { RangePicker } = DatePicker;
    const breadCrumbs = [
      {
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: 'KPI',
      },
    ];

    const dateAdd = new Date();
    dateAdd.setHours(23);
    dateAdd.setMinutes(59);
    dateAdd.setSeconds(59);
    const DateDefaultTo = format(dateAdd, 'YYYY-MM-DD HH:mm:ss');
    dateAdd.setHours(0);
    dateAdd.setMinutes(0);
    dateAdd.setSeconds(0);
    dateAdd.setDate(dateAdd.getDate() - 6);
    const DateDefaultFrom = format(dateAdd, 'YYYY-MM-DD HH:mm:ss');
    return (
      <div className="Dashboard">
        <div style={{ display: 'none' }} id="divcontents">
          <div style={{ marginBottom: '10px' }}>
            <center>
              <strong style={{ fontSize: '14px' }}>Lazada KPI Report</strong>
            </center>
          </div>
          <table className="tableStyle" style={styleTable()}>
            <thead>
              <tr>
                <th style={styleTable()}>Report Date</th>
                <th style={styleTable()}>Volume</th>
                <th style={styleTable()}>Open</th>
                <th style={styleTable()}>Open %</th>
                <th style={styleTable()}>Delivered</th>
                <th style={styleTable()}>Delivered %</th>
                <th style={styleTable()}>No Attempt</th>
                <th style={styleTable()}>1 Attempt</th>
                <th style={styleTable()}>2 Attempts</th>
                <th style={styleTable()}>3+ Attempts</th>
                <th style={styleTable(100)}>First Attempt Delivered</th>
                <th style={styleTable(100)}>First Attempt Delivered %</th>
                <th style={styleTable()}>OTIF</th>
                <th style={styleTable()}>OTIF %</th>
                <th style={styleTable(70)}>Avg LT</th>
                <th style={styleTable()}>Failed Delivery</th>
                <th style={styleTable()}>FD %</th>
                <th style={styleTable()}>ROTIF</th>
                <th style={styleTable()}>ROTIF %</th>
                <th style={styleTable()}>Claims</th>
                <th style={styleTable()}>Claims %</th>
              </tr>
            </thead>
            <tbody>
              {dashboardData
                ? dashboardData.map(res => (
                  <tr key={res.report_date}>
                    <td style={styleTable()}>{res.report_date}</td>
                    <td style={styleTable()}>{res.volume}</td>
                    <td style={styleTable()}>{res.open}</td>
                    <td style={styleTable()}>{res.open_pct}</td>
                    <td style={styleTable()}>{res.delivered}</td>
                    <td style={styleTable()}>{res.delivered_pct}</td>
                    <td style={styleTable()}>{res.no_attempt}</td>
                    <td style={styleTable()}>{res.one_attempt}</td>
                    <td style={styleTable()}>{res.two_attempts}</td>
                    <td style={styleTable()}>{res.three_attempts}</td>
                    <td style={styleTable(100)}>
                      {res.first_attempt_delivered}
                    </td>
                    <td style={styleTable(100)}>
                      {res.delivered_first_attempt_pct}
                    </td>
                    <td style={styleTable()}>{res.otif}</td>
                    <td style={styleTable()}>{res.otif_pct}</td>
                    <td style={styleTable(70)}>{res.average_lead_time}</td>
                    <td style={styleTable()}>{res.fd}</td>
                    <td style={styleTable()}>{res.fd_pct}</td>
                    <td style={styleTable()}>{res.rotif}</td>
                    <td style={styleTable()}>{res.rotif_pct}</td>
                    <td style={styleTable()}>{res.claims}</td>
                    <td style={styleTable()}>{res.claims_pct}</td>
                  </tr>
                ))
                : null}
            </tbody>
          </table>
        </div>
        <iframe
          title="myFrame"
          id="ifmcontentstoprint"
          style={{ height: '0px', width: '0px', position: 'absolute' }}
        />
        <Pageheader title="KPI Dashboard" routes={breadCrumbs} />
        <Spacer />
        <Container>
          <div>
            <Card>
              <Row>
                <Col xs={24} sm={6} lg={6}>
                  <Form.Item label="Select Date">
                    <RangePicker
                      style={{ width: '100%' }}
                      defaultValue={[
                        moment(DateDefaultFrom, 'YYYY-MM-DD HH:mm:ss'),
                        moment(DateDefaultTo, 'YYYY-MM-DD HH:mm:ss'),
                      ]}
                      showTime={{
                        hideDisabledOptions: true,
                        format: 'HH:mm',
                      }}
                      onChange={e => this.dateHandler(e)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6} lg={6}>
                  <Form.Item label="Branch">
                    <ReactSelect
                      style={{ width: '100%' }}
                      placeholder="Select branch..."
                      options={branchCol}
                      onChange={e => this.branchHandler(e)}
                      optionFilterProp="children"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6} lg={6}>
                  <Form.Item label="Client">
                    <ReactSelect
                      style={{ width: '100%' }}
                      options={clientCol}
                      onChange={e => this.clientHandler(e)}
                      allowClear
                      loading={isFetchingSelect}
                      onSearch={value => this.searchClient(value)}
                      filterOption={false}
                      placeholder="Type to search for client"
                      defaultValue={[1]}
                      showSearch
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6} lg={6}>
                  <Form.Item label="Shipping Type">
                    <ReactSelect
                      defaultValue="Standard"
                      style={{ width: '100%' }}
                      placeholder="Select shipping type..."
                      options={[
                        { value: 'standard', label: 'Standard' },
                        { value: 'lazmart', label: 'Lazmart' },
                        { value: 'mixed', label: 'Mixed' },
                      ]}
                      onChange={e => this.typeHandler(e)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={showIsland ? 12 : 24} lg={showIsland ? 12 : 24}>
                  <Form.Item label="KPI Reference">
                    <ReactSelect
                      defaultValue="Standard"
                      style={{ width: '100%' }}
                      placeholder="Select KPI reference..."
                      options={[
                        { value: 'Standard', label: 'Standard' },
                        { value: 'Lazada', label: 'Lazada' },
                      ]}
                      onChange={e => this.referenceHandler(e)}
                    />
                  </Form.Item>
                </Col>
                {
                  showIsland ? (
                    <Col xs={24} sm={12} lg={12}>
                      <Form.Item label="Island">
                        <ReactSelect
                          defaultValue="GMA"
                          style={{ width: '100%' }}
                          placeholder="Select island..."
                          options={[
                            { value: 'GMA', label: 'GMA' },
                            { value: 'NLUZ', label: 'NLUZ' },
                            { value: 'SLUZ', label: 'SLUZ' },
                            { value: 'VIS', label: 'VIS' },
                            { value: 'MIN', label: 'MIN' },
                            { value: 'Standard', label: 'Unrecognized' },
                          ]}
                          onChange={e => this.islandHandler(e)}
                        />
                      </Form.Item>
                    </Col>
                  ) : null
                }
              </Row>
              <Button type="primary" onClick={() => this.submitSearch()} block>
                <Icon type="file-search" />
                Filter
              </Button>
            </Card>
            <Spacer />
            <Card title="KPI OTIF Reference">
              <Descriptions size="small" bordered>
                <Descriptions.Item label="Standard">
                  <strong>GMA:</strong>
                  &nbsp;3 Days
                  <br />
                  <strong>Unrecognized, SLUZ, NLUZ:</strong>
                  &nbsp;5 Days
                  <br />
                  <strong>VIS, MIN:</strong>
                  &nbsp;8 Days
                  <br />
                  <br />
                  <i>Sundays not included</i>
                </Descriptions.Item>
                <Descriptions.Item label="Lazada">
                  <strong>GMA:</strong>
                  &nbsp;2.5 Days
                  <br />
                  <strong>Other Areas:</strong>
                  &nbsp;5.5 Days
                  <br />
                  <br />
                  <i>Sundays not included (Except Lazmart)</i>
                </Descriptions.Item>
              </Descriptions>
            </Card>
            <Spacer />
            <Card title="KPI Returned OTIF Reference">
              <Descriptions size="small" bordered>
                <Descriptions.Item label="Standard">
                  <strong>GMA:</strong>
                  &nbsp;7 Days
                  <br />
                  <strong>Unrecognized, SLUZ, NLUZ:</strong>
                  &nbsp;9 Days
                  <br />
                  <strong>VIS, MIN:</strong>
                  &nbsp;15 Days
                  <br />
                  <br />
                  <i>Sundays not included</i>
                </Descriptions.Item>
                <Descriptions.Item label="Lazada">
                  <strong>NOT YET SUPPORTED</strong>
                </Descriptions.Item>
              </Descriptions>
            </Card>
            <Spacer />
            <Spin
              spinning={spinnerState}
              size="small"
              tip="Fetching KPI report..."
            >
              <Card title="KPI Chart">
                <LineChart data={dashboardChart} />
              </Card>
              <Spacer />
              <Card
                title="KPI Table"
                extra={(
                  <React.Fragment>
                    <Button
                      onClick={() => this.setState(prevState => ({
                        responsiveTable: !prevState.responsiveTable,
                      }))}
                      type="primary"
                    >
                      <Icon type="swap" />
                      {`Switch to ${responsiveTable ? 'Plain' : 'Responsive'} Table`}
                    </Button>
                    &nbsp;
                    <CSVLink
                      data={dashboardData}
                      target="_blank"
                      filename="kpi_dashboard.csv"
                    >
                      <Button type="primary">
                        <Icon type="download" />
                        Download Report
                      </Button>
                    </CSVLink>
                    &nbsp;
                    <Button
                      onClick={() => this.downloadReport()}
                      type="primary"
                    >
                      <Icon type="printer" />
                      Print Report
                    </Button>
                  </React.Fragment>
                )}
              >
                {
                  responsiveTable ? (
                    <ReactTable
                      data={dashboardData}
                      loading={spinnerState}
                      columns={[
                        {
                          Header: 'Handover Date',
                          accessor: 'report_date',
                        },
                        {
                          Header: 'Volume',
                          accessor: 'volume',
                          Cell: row => (
                            <React.Fragment>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  row.original.report_date,
                                  'volume',
                                )}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">{row.original.volume}</Button>
                              </Popconfirm>
                            </React.Fragment>
                          ),
                        },
                        {
                          Header: 'Open',
                          accessor: 'open',
                          Cell: row => (
                            <React.Fragment>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  row.original.report_date,
                                  'open',
                                )}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">{row.original.open}</Button>
                              </Popconfirm>
                            </React.Fragment>
                          ),
                        },
                        {
                          Header: 'Open %',
                          accessor: 'open_pct',
                          Cell: row => (
                            <React.Fragment>
                              { `${parseFloat(row.original.open_pct).toFixed(2)}%` }
                            </React.Fragment>
                          ),
                        },
                        {
                          Header: 'Delivered',
                          accessor: 'delivered',
                          Cell: row => (
                            <React.Fragment>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  row.original.report_date,
                                  'delivered',
                                )}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">{row.original.delivered}</Button>
                              </Popconfirm>
                            </React.Fragment>
                          ),
                        },
                        {
                          Header: 'Delivered %',
                          accessor: 'delivered_pct',
                          Cell: row => (
                            <React.Fragment>
                              { `${parseFloat(row.original.delivered_pct).toFixed(2)}%` }
                            </React.Fragment>
                          ),
                        },
                        {
                          Header: 'No Attempt',
                          accessor: 'no_attempt',
                          Cell: row => (
                            <React.Fragment>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  row.original.report_date,
                                  'no_attempt',
                                )}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">{row.original.no_attempt}</Button>
                              </Popconfirm>
                            </React.Fragment>
                          ),
                        },
                        {
                          Header: 'One Attempt',
                          accessor: 'one_attempt',
                          Cell: row => (
                            <React.Fragment>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  row.original.report_date,
                                  'one_attempt',
                                )}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">{row.original.one_attempt}</Button>
                              </Popconfirm>
                            </React.Fragment>
                          ),
                        },
                        {
                          Header: 'Two Attempts',
                          accessor: 'two_attempts',
                          Cell: row => (
                            <React.Fragment>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  row.original.report_date,
                                  'two_attempts',
                                )}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">{row.original.two_attempts}</Button>
                              </Popconfirm>
                            </React.Fragment>
                          ),
                        },
                        {
                          Header: '3+ Attempts',
                          accessor: 'three_attempts',
                          Cell: row => (
                            <React.Fragment>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  row.original.report_date,
                                  'three_attempts',
                                )}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">{row.original.three_attempts}</Button>
                              </Popconfirm>
                            </React.Fragment>
                          ),
                        },
                        {
                          Header: 'First Attempt Delivered',
                          accessor: 'first_attempt_delivered',
                          Cell: row => (
                            <React.Fragment>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  row.original.report_date,
                                  'first_attempt_delivered',
                                )}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">{row.original.first_attempt_delivered}</Button>
                              </Popconfirm>
                            </React.Fragment>
                          ),
                        },
                        {
                          Header: 'First Attempt Delivered %',
                          accessor: 'delivered_first_attempt_pct',
                          Cell: row => (
                            <React.Fragment>
                              { `${parseFloat(row.original.delivered_first_attempt_pct).toFixed(2)}%` }
                            </React.Fragment>
                          ),
                        },
                        {
                          Header: 'OTIF',
                          accessor: 'otif',
                          Cell: row => (
                            <React.Fragment>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  row.original.report_date,
                                  'otif',
                                )}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">{row.original.otif}</Button>
                              </Popconfirm>
                            </React.Fragment>
                          ),
                        },
                        {
                          Header: 'OTIF %',
                          accessor: 'otif_pct',
                          Cell: row => (
                            <React.Fragment>
                              { `${parseFloat(row.original.otif_pct).toFixed(2)}%` }
                            </React.Fragment>
                          ),
                        },
                        {
                          Header: 'Avg LT',
                          accessor: 'average_lead_time',
                          Cell: row => (
                            <React.Fragment>
                              { `${parseFloat(row.original.average_lead_time).toFixed(2)} hours` }
                            </React.Fragment>
                          ),
                        },
                        {
                          Header: 'Failed Delivery',
                          accessor: 'fd',
                          Cell: row => (
                            <React.Fragment>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  row.original.report_date,
                                  'fd',
                                )}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">{row.original.fd}</Button>
                              </Popconfirm>
                            </React.Fragment>
                          ),
                        },
                        {
                          Header: 'Failed Delivery %',
                          accessor: 'fd_pct',
                          Cell: row => (
                            <React.Fragment>
                              { `${parseFloat(row.original.fd_pct).toFixed(2)}%` }
                            </React.Fragment>
                          ),
                        },
                        {
                          Header: 'Returned',
                          accessor: 'returned',
                          Cell: row => (
                            <React.Fragment>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  row.original.report_date,
                                  'returned',
                                )}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">{row.original.returned}</Button>
                              </Popconfirm>
                            </React.Fragment>
                          ),
                        },
                        {
                          Header: 'Returned %',
                          accessor: 'returned_pct',
                          Cell: row => (
                            <React.Fragment>
                              { `${parseFloat(row.original.returned_pct).toFixed(2)}%` }
                            </React.Fragment>
                          ),
                        },
                        {
                          Header: 'Returned OTIF',
                          accessor: 'rotif',
                          Cell: row => (row.original.rotif > 0 ? (
                            <React.Fragment>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  row.original.report_date,
                                  'rotif',
                                )}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">{row.original.rotif}</Button>
                              </Popconfirm>
                            </React.Fragment>
                          ) : 0),
                        },
                        {
                          Header: 'Returned OTIF %',
                          accessor: 'rotif_pct',
                          Cell: row => (
                            <React.Fragment>
                              { `${parseFloat(row.original.rotif_pct).toFixed(2)}%` }
                            </React.Fragment>
                          ),
                        },
                        {
                          Header: 'Claims',
                          accessor: 'claims',
                          Cell: row => (
                            <React.Fragment>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  row.original.report_date,
                                  'claims',
                                )}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">{row.original.claims}</Button>
                              </Popconfirm>
                            </React.Fragment>
                          ),
                        },
                        {
                          Header: 'Claims %',
                          accessor: 'claims',
                          Cell: row => (
                            <React.Fragment>
                              { `${parseFloat(row.original.claims_pct).toFixed(2)}%` }
                            </React.Fragment>
                          ),
                        },
                      ]}
                    />
                  ) : (
                    <table className="tableStyle dashboardContainer">
                      <thead>
                        <tr>
                          <th>Report Date</th>
                          <th>Volume</th>
                          <th>Open</th>
                          <th>Open %</th>
                          <th>Delivered</th>
                          <th>Delivered %</th>
                          <th>No Attempt</th>
                          <th>1 Attempt</th>
                          <th>2 Attempts</th>
                          <th>3+ Attempts</th>
                          <th>First Attempt Delivered</th>
                          <th>First Attempt Delivered %</th>
                          <th>OTIF</th>
                          <th>OTIF %</th>
                          <th>Avg LT</th>
                          <th>Failed Delivery</th>
                          <th>FD %</th>
                          <th>Returned OTIF</th>
                          <th>Returned OTIF %</th>
                          <th>Claims</th>
                          <th>Claims %</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardData
                          ? dashboardData.map(res => (
                            <tr key={res.report_date}>
                              <td>{res.report_date}</td>
                              <td>
                                <Popconfirm
                                  placement="topRight"
                                  title="Are you sure to download this item?"
                                  onConfirm={() => this.downloadSet(res.report_date, 'volume')
                                    }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button type="link">{res.volume}</Button>
                                </Popconfirm>
                              </td>
                              <td>
                                <Popconfirm
                                  placement="topRight"
                                  title="Are you sure to download this item?"
                                  onConfirm={() => this.downloadSet(res.report_date, 'open')
                                    }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button type="link">{res.open}</Button>
                                </Popconfirm>
                              </td>
                              <td>
                                {`${parseFloat(res.open_pct).toFixed(2)} %`}
                              </td>
                              <td>
                                <Popconfirm
                                  placement="topRight"
                                  title="Are you sure to download this item?"
                                  onConfirm={() => this.downloadSet(res.report_date, 'delivered')
                                    }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button type="link">{res.delivered}</Button>
                                </Popconfirm>
                              </td>
                              <td>
                                {`${parseFloat(res.delivered_pct).toFixed(2)} %`}
                              </td>
                              <td>
                                <Popconfirm
                                  placement="topRight"
                                  title="Are you sure to download this item?"
                                  onConfirm={() => this.downloadSet(res.report_date, 'no_attempt')
                                    }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button type="link">{res.no_attempt}</Button>
                                </Popconfirm>
                              </td>
                              <td>
                                <Popconfirm
                                  placement="topRight"
                                  title="Are you sure to download this item?"
                                  onConfirm={() => this.downloadSet(res.report_date, 'one_attempt')
                                    }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button type="link">{res.one_attempt}</Button>
                                </Popconfirm>
                              </td>
                              <td>
                                <Popconfirm
                                  placement="topRight"
                                  title="Are you sure to download this item?"
                                  onConfirm={() => this.downloadSet(res.report_date, 'two_attempts')
                                    }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button type="link">{res.two_attempts}</Button>
                                </Popconfirm>
                              </td>
                              <td>
                                <Popconfirm
                                  placement="topRight"
                                  title="Are you sure to download this item?"
                                  onConfirm={() => this.downloadSet(res.report_date, 'three_attempts')
                                    }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button type="link">{res.three_attempts}</Button>
                                </Popconfirm>
                              </td>
                              <td>
                                <Popconfirm
                                  placement="topRight"
                                  title="Are you sure to download this item?"
                                  onConfirm={() => this.downloadSet(res.report_date, 'first_attempt_delivered')
                                    }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button type="link">{res.first_attempt_delivered}</Button>
                                </Popconfirm>
                              </td>
                              <td>
                                {`${parseFloat(
                                  res.delivered_first_attempt_pct,
                                ).toFixed(2)} %`}
                              </td>
                              <td>
                                <Popconfirm
                                  placement="topRight"
                                  title="Are you sure to download this item?"
                                  onConfirm={() => this.downloadSet(res.report_date, 'otif')
                                    }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button type="link">{res.otif}</Button>
                                </Popconfirm>
                              </td>
                              <td>
                                {`${parseFloat(res.otif_pct).toFixed(2)} %`}
                              </td>
                              <td>
                                {`${parseFloat(res.average_lead_time).toFixed(
                                  2,
                                )}`}
                              </td>
                              <td>
                                <Popconfirm
                                  placement="topRight"
                                  title="Are you sure to download this item?"
                                  onConfirm={() => this.downloadSet(
                                    res.report_date,
                                    'fd',
                                  )
                                    }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button type="link">
                                    {res.fd}
                                  </Button>
                                </Popconfirm>
                              </td>
                              <td>{`${parseFloat(res.fd_pct).toFixed(2)} %`}</td>
                              <td>
                                { res.rotif > 0 ? (
                                  <Popconfirm
                                    placement="topRight"
                                    title="Are you sure to download this item?"
                                    onConfirm={() => this.downloadSet(res.report_date, 'rotif')
                                      }
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Button type="link">{res.rotif}</Button>
                                  </Popconfirm>
                                ) : 0 }
                              </td>
                              <td>
                                {`${parseFloat(res.rotif_pct).toFixed(2)} %`}
                              </td>
                              <td>
                                <Popconfirm
                                  placement="topRight"
                                  title="Are you sure to download this item?"
                                  onConfirm={() => this.downloadSet(res.report_date, 'claims')
                                    }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button type="link">{res.claims}</Button>
                                </Popconfirm>
                              </td>
                              <td>
                                {`${parseFloat(res.claims_pct).toFixed(2)} %`}
                              </td>
                            </tr>
                          ))
                          : null}
                      </tbody>
                    </table>
                  )
                }
              </Card>
            </Spin>
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = {
  doDashboardKpi: dashboardKpi,
  doFetchOptions: fetchOptions,
  doFetchOptionsLike: fetchOptionsLike,
  doDashboardKpiDownload: dashboardKpiDownload,
};
KpiDashboard.propTypes = {
  doDashboardKpi: PropTypes.func.isRequired,
  doDashboardKpiDownload: PropTypes.func.isRequired,
  doFetchOptions: PropTypes.func.isRequired,
  doFetchOptionsLike: PropTypes.func.isRequired,
};

// const WrappedKpiDashboard = Form.create({ name: 'Dashboard' })(KpiDashboard);

export default connect(null, mapDispatchToProps)(KpiDashboard);
