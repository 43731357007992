import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Button, Input } from 'antd';

import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';

export class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dmNumber: '',
    };
  }

  inputHandler(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  dmSubmit() {
    const { dmNumber } = this.state;
    const { history } = this.props;
    history.push(`/remittance/create/${dmNumber}`);
  }

  keypressHandler(e) {
    if (e.keyCode === 13) {
      const { dmNumber } = this.state;
      const { history } = this.props;
      history.push(`/remittance/create/${dmNumber}`);
    }
  }

  render() {
    const { dmNumber } = this.state;
    const breadCrumbs = [
      {
        breadcrumbName: 'Courier Remittance',
      },
      {
        breadcrumbName: 'Create',
      },
    ];
    return (
      <div className="Create">
        <PageHeader title="CREATE REMITTANCE" routes={breadCrumbs} />
        <Container>
          <Form>
            <div>
              <Card title="DISPATCH MANIFEST NUMBER">
                <Form.Item label="DM number">
                  <Input
                    onChange={e => this.inputHandler(e)}
                    onKeyUp={e => this.keypressHandler(e)}
                    name="dmNumber"
                    value={dmNumber}
                  />
                </Form.Item>
                <Button onClick={e => this.dmSubmit(e)} type="primary" block>
                  Create
                </Button>
              </Card>
            </div>
          </Form>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

Create.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Create);
