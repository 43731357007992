import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
 Col, Form, Button, DatePicker, Icon 
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faSearch } from '@fortawesome/free-solid-svg-icons';

import { rubixpickOptions } from '../../components/options/ducks';
import { waybill } from '../../components/waybill/ducks';
import { bList } from './ducks';
import { billingListView } from '../ducks';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import ReactTable from '../../components/ServerSideTable';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactSelect from '../../components/Select';
import Select from '../../components/Spacer';

export class UnbilledList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: [],
      handoverDate: [],
      loading: true,
      userData: {
        rows: [],
        pages: 0,
        total: 0,
      },
      payloadReq: {
        export: false,
        page: 0,
        pageSize: 0,
        sorted: [],
      },
      tableFilter: [],
      searchFilter: [],
    };

    this.onFetchDataFunc = this.onFetchDataFunc.bind(this);
  }

  componentDidMount() {
    const { doRubixpickOptions } = this.props;
    doRubixpickOptions('client').then(res => this.setState({
      client: res.payload.data,
    }));
  }

  onFetchDataFunc(state) {
    this.setState({
      loading: true,
    });
    // console.log(state);
    const { searchFilter } = this.state;
    const { billingNonFilterUnbilledList } = this.props;
    const paramsReq = {
      export: false,
      page: state.page,
      pageSize: state.pageSize,
      filter: state.filtered,
      sorted: state.sorted,
    };
    if (searchFilter.length) {
      paramsReq.filter = paramsReq.filter.concat(searchFilter);
    }
    billingNonFilterUnbilledList('unbilled-list', paramsReq).then(res => this.setState({
      loading: false,
      userData: {
        rows: res.payload.data.rows,
        pages: res.payload.data.pages,
        total: res.payload.data.total,
      },
      payloadReq: paramsReq,
      tableFilter: state.filtered,
    }));
  }

  getEditAccountHandler(e) {
    const { waybillComponent, history } = this.props;
    waybillComponent(e.tracking_number).then(() => history.push(`/waybill/${e.tracking_number}`),);
    // console.log(e);
  }

  submitHandler() {
    const { handoverDate, payloadReq, tableFilter } = this.state;
    const { billingNonFilterUnbilledList, form } = this.props;
    const { validateFields } = form;
    validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });
        const paramsReq = {
          export: false,
          page: payloadReq.page,
          pageSize: payloadReq.pageSize,
          filter: values.client
            ? [...handoverDate, { id: 'client_id', value: values.client }]
            : [...handoverDate],
          sorted: payloadReq.sorted,
        };
        if (tableFilter.length) {
          paramsReq.filter = paramsReq.filter.concat(tableFilter);
        }
        billingNonFilterUnbilledList('unbilled-list', paramsReq).then(res => this.setState({
          loading: false,
          userData: {
            rows: res.payload.data.rows,
            pages: res.payload.data.pages,
            total: res.payload.data.total,
          },
          payloadReq: paramsReq,
          searchFilter: values.client
            ? [...handoverDate, { id: 'client_id', value: values.client }]
            : [...handoverDate],
        }));
      }
    });
  }

  submitHandlerExport() {
    const { payloadReq, handoverDate } = this.state;
    const { billingNonFilterUnbilledList, form } = this.props;
    const { validateFields } = form;
    validateFields((err, values) => {
      if (!err) {
        const paramsReq = {
          export: true,
          page: payloadReq.page,
          pageSize: payloadReq.pageSize,
          filter: [
            ...handoverDate,
            values.client ? { id: 'client_id', value: values.client } : null,
          ],
          sorted: payloadReq.sorted,
        };
        this.setState({
          loading: true,
          payloadReq: paramsReq,
        });
        billingNonFilterUnbilledList('unbilled-list', paramsReq).then((res) => {
          const newBlob = new Blob([res.payload.data]);
          fileDownload(newBlob, 'Unbilled-list.csv');
          this.setState({
            loading: false,
          });
        });
      }
    });
  }

  dateHandler(dateStrings) {
    const newDateOne = dateStrings[0];
    const newDateTwo = dateStrings[1];
    const formatDateOne = format(newDateOne, 'YYYY-MM-DD HH:mm:ss');
    const formatDateTwo = format(newDateTwo, 'YYYY-MM-DD HH:mm:ss');
    const dateForm = `${formatDateOne}|${formatDateTwo}`;

    this.setState({
      handoverDate: [
        {
          id: 'handover_date',
          value: formatDateOne !== 'Invalid Date' ? dateForm : '',
        },
      ],
    });
  }

  render() {
    const { loading, userData, client } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const breadCrumbs = [
      {
        breadcrumbName: 'Billing',
      },
      {
        breadcrumbName: 'Unbilled List',
      },
    ];
    return (
      <div className="UnbilledList">
        <PageHeader title="UNBILLED LIST" routes={breadCrumbs} />
        <Container>
          <Card>
            <Row>
              <Col xs={24} sm={12} lg={12}>
                <Form.Item label="Client">
                  {getFieldDecorator('client', {
                    rules: [{ required: false, message: 'Please insert value' }],
                  })(
                    <ReactSelect
                      style={{ width: '100%' }}
                      placeholder="Select Client..."
                      options={client}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={12}>
                <Form.Item label="Date Range">
                  <DatePicker.RangePicker
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment('00:00', 'HH:mm'),
                        moment('00:00', 'HH:mm'),
                      ],
                    }}
                    style={{ width: '100%' }}
                    onChange={e => this.dateHandler(e)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Button onClick={() => this.submitHandler()} type="primary" block>
              <Icon viewBox="0 0 1024 1024">
                <FontAwesomeIcon icon={faSearch} fixedWidth />
              </Icon>
              Apply
            </Button>
          </Card>
          <Select />
          <Card
            title={(
              <Button onClick={() => this.submitHandlerExport()} type="danger">
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faFileExport} fixedWidth />
                </Icon>
                Export
              </Button>
            )}
            extra={`Total: ${userData.total
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
          >
            <ReactTable
              data={userData.rows}
              pages={userData.pages}
              columns={[
                {
                  Header: 'Handover Date',
                  accessor: 'handover_date',
                },
                {
                  Header: 'Tracking Number',
                  accessor: 'tracking_number',
                },
                {
                  Header: 'Client',
                  accessor: 'client_name',
                },
                {
                  Header: 'Package Type',
                  accessor: 'package_type',
                },
                {
                  Header: 'Length',
                  accessor: 'length',
                },
                {
                  Header: 'Width',
                  accessor: 'width',
                },
                {
                  Header: 'Height',
                  accessor: 'height',
                },
                {
                  Header: 'Weight',
                  accessor: 'weight',
                },
                {
                  Header: 'Options',
                  sortable: false,
                  filterable: false,
                  Cell: row => (
                    <center>
                      <Button
                        type="link"
                        onClick={() => this.getEditAccountHandler(row.original)}
                      >
                        <Icon type="eye" />
                        View
                      </Button>
                    </center>
                  ),
                },
              ]}
              loadingText="Fetching unbilled list..."
              loading={loading}
              onFetchData={this.onFetchDataFunc}
            />
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = {};

const mapDispatchToProps = {
  ubList: bList,
  billingNonFilterUnbilledList: billingListView,
  waybillComponent: waybill,
  doRubixpickOptions: rubixpickOptions,
};

UnbilledList.propTypes = {
  billingNonFilterUnbilledList: PropTypes.func.isRequired,
  waybillComponent: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doRubixpickOptions: PropTypes.func.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const WrappedUnbilledList = Form.create({ name: 'Register' })(UnbilledList);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedUnbilledList);
