import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faMinus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import {
  Card,
  Form,
  Input,
  Button,
  Col,
  Icon,
  message,
  Popconfirm,
  Descriptions,
  Badge,
} from 'antd';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import Select from '../../components/Select';
import Row from '../../components/Row';
import Spacer from '../../components/Spacer';
import ReactTable from '../../components/ReactTable';
import { fetchOptionsLike } from '../ducks';
import { fetchMultiMerchantPickupTrackingNumber, createMerchantPickup } from './newDucks';

export class MerchantMultiPickupDispatch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSecondDriverandHelper: false,
      isSearchingcourier: false,
      isSearchingvehicle: false,
      hasSubmitted: false,
      // creating, [ph_id], error
      manifestsStatuses: [],
      options: {
        courier: [],
        vehicles: [],
      },
      /**
       * shipperPackages = {
       *  shipper_id: {
       *    name: shipper_name,
       *    address: shipper_address,
       *    packages: [
       *      ...TNs
       *    ]
       *  }
       * }
       */
      shipperPackages: {},
      errors: [],
      formDetails: {},
    };

    this.breadcrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Merchant Pickup',
      },
      {
        breadcrumbName: 'Multi Dispatch',
      },
    ];

    this.toggleSecondaryDriverAndHelper = this.toggleSecondaryDriverAndHelper.bind(this);
    this.validateTrackingNumber = this.validateTrackingNumber.bind(this);
    this.createManifests = this.createManifests.bind(this);
    this.searchOption = this.searchOption.bind(this);
  }

  componentDidMount() {
    this.searchOption('courier', 'A');
    this.searchOption('vehicle', 'A');
  }

  toggleSecondaryDriverAndHelper() {
    const { showSecondDriverandHelper } = this.state;

    this.setState({
      showSecondDriverandHelper: !showSecondDriverandHelper,
    });
  }

  searchOption(type, value) {
    const { doFetchOptionsLike } = this.props;

    this.setState({
      [`isSearching${type}`]: true,
    });

    doFetchOptionsLike(type, value).then((action) => {
      this.setState(prevState => ({
        options: {
          ...prevState.options,
          [type]: action.payload.data.slice(0, 50),
        },
        [`isSearching${type}`]: false,
      }));
    });
  }

  validateTrackingNumber(event) {
    event.preventDefault();
    const { shipperPackages } = this.state;
    const { form, doFetchMultiMerchantPickupTrackingNumber } = this.props;
    const { validateFields } = form;

    validateFields(['trackingNumber'], (err, values) => {
      if (!err) {
        let trackingNumbers = values.trackingNumber.trim().split('\n');
        trackingNumbers = trackingNumbers.map(
          trackingNumber => trackingNumber.trim().toUpperCase(),
        );

        // remove input duplicates
        trackingNumbers = trackingNumbers.reduce((newList, trackingNumber) => {
          if (newList.indexOf(trackingNumber) < 0) {
            newList.push(trackingNumber);
          }

          return newList;
        }, []);

        const shippers = Object.keys(shipperPackages);
        // filter for duplicates on local pending list
        trackingNumbers = trackingNumbers.filter((trackingNumber) => {
          if (!trackingNumber.length) {
            return false;
          }

          // will return true if .every run without returning false
          const existing = !shippers.every(shipperID => (
            // return false for break
            !shipperPackages[shipperID].packages.find(
              existingPackage => existingPackage.tracking_number === trackingNumber,
            )
          ));

          if (existing) {
            message.error(`Tracking number ${trackingNumber} already exists in the list.`);
            return false;
          }
          return true;
        });

        if (!trackingNumbers.length) {
          message.info('No package was validated.');
          return;
        }

        message.info(`Started validation for ${trackingNumbers.length} unique packages.`);

        trackingNumbers.forEach((trackingNumber) => {
          doFetchMultiMerchantPickupTrackingNumber(trackingNumber)
            .then((res) => {
              const packageData = res.payload.data;
              const shipperData = packageData.shipper;

              if (shipperData) {
                this.setState((prevState) => {
                  let existingPackages = [];

                  // Check if shipper already exists
                  if (Object.prototype.hasOwnProperty.call(
                    prevState.shipperPackages,
                    shipperData.shipper_id,
                  )) {
                    if (Object.prototype.hasOwnProperty.call(
                      prevState.shipperPackages[shipperData.shipper_id],
                      'packages',
                    )) {
                      existingPackages = prevState.shipperPackages[shipperData.shipper_id].packages;
                    }
                  }

                  // Remove duplicate packages
                  const packageExists = existingPackages.find(
                    existingPackage => existingPackage.tracking_number
                      === packageData.tracking_number,
                  );

                  if (packageExists) {
                    return null;
                  }

                  existingPackages.push(packageData);
                  message.success(`Successfully added package ${packageData.tracking_number} to list.`);

                  return {
                    shipperPackages: {
                      ...prevState.shipperPackages,
                      [shipperData.shipper_id]: {
                        name: shipperData.shipper_name,
                        address: shipperData.shipper_address,
                        packages: existingPackages,
                      },
                    },
                  };
                });
              } else {
                const invalidShipper = { name: 'InvalidShipper' };
                throw invalidShipper;
              }
            })
            .catch((res) => {
              this.setState((prevState) => {
                message.error(`Failed to add package ${trackingNumber}`);
                let errorMessage = 'Application error! Please contact IT administrator for support.';

                if (Object.prototype.hasOwnProperty.call(res, 'error')
                  && res.error.response.status === 400
                ) {
                  errorMessage = res.error.response.data.message;
                } else if (res.name === 'InvalidShipper') {
                  errorMessage = 'Package doesn\'t have a shipper.';
                }

                return {
                  errors: [
                    {
                      trackingNumber,
                      errorMessage,
                    },
                    ...prevState.errors,
                  ],
                };
              });
            });
        });

        // validate response for duplicate in list
        // add tn to staging list
        // show the data
        form.resetFields(['trackingNumber']);
      }
    });
  }

  removeShipperPackage(deletePackage) {
    // eslint-disable-next-line camelcase
    const { shipper_id } = deletePackage.shipper;

    this.setState((prevState) => {
      let { packages } = prevState.shipperPackages[shipper_id];

      packages = packages.filter(shipperPackage => shipperPackage.tracking_number
        !== deletePackage.tracking_number);

      if (!packages.length) {
        const { shipperPackages } = prevState;

        delete shipperPackages[shipper_id];

        return { shipperPackages };
      }

      return {
        shipperPackages: {
          ...prevState.shipperPackages,
          [shipper_id]: {
            ...prevState.shipperPackages[shipper_id],
            packages,
          },
        },
      };
    });
  }

  createManifests() {
    const { shipperPackages } = this.state;
    const { form, doCreateMerchantPickup } = this.props;
    const { validateFields, getFieldsValue } = form;

    window.scrollTo(0, 0);

    validateFields([
      'driver_id',
      'driver_rate',
      'vehicle_id',
    ], (err, val) => {
      if (!err) {
        const requiredFieldsValues = val;
        const additionalOptionalFields = Object.keys(shipperPackages).map(shipper => `remarks-${shipper}`);
        const optionalFieldsValues = getFieldsValue([
          'driver2_id',
          'driver2_rate',
          'helper_id',
          'helper_rate',
          'helper2_id',
          'helper2_rate',
          ...additionalOptionalFields,
        ]);

        const formDetails = {
          ...requiredFieldsValues,
          ...optionalFieldsValues,
        };

        const manifestsStatuses = {};
        Object.keys(shipperPackages).forEach((shipper) => {
          manifestsStatuses[shipper] = 'creating';
        });

        this.setState({
          hasSubmitted: true,
          formDetails,
          manifestsStatuses,
        }, () => {
          Object.keys(shipperPackages).forEach((shipper) => {
            const params = {
              ...formDetails,
              driver_id: formDetails.driver_id.key,
              driver2_id: formDetails.driver2_id ? formDetails.key : null,
              helper_id: formDetails.helper_id ? formDetails.key : null,
              helper2_id: formDetails.helper2_id ? formDetails.key : null,
              vehicle_id: formDetails.vehicle_id.key,
              expected_arrival: 'N/A',
              type: 'merchant',
              shipper_id: shipper,
              packages: shipperPackages[shipper]
                .packages.map(shipperPackage => shipperPackage.tracking_number),
              remarks: optionalFieldsValues[`remarks-${shipper}`] ? optionalFieldsValues[`remarks-${shipper}`] : null,
            };

            doCreateMerchantPickup(params)
              .then((res) => {
                this.setState(prevState => ({
                  manifestsStatuses: {
                    ...prevState.manifestsStatuses,
                    [shipper]: res.payload.data.ph_id,
                  },
                }));

                message.success(`Manifest for ${shipperPackages[shipper].name} was successfully created.`);
              })
              .catch(() => {
                this.setState(prevState => ({
                  manifestsStatuses: {
                    ...prevState.manifestsStatuses,
                    [shipper]: 'error',
                  },
                }));

                message.error(`Manifest for ${shipperPackages[shipper].name} was not created due to server error.`);
              });
          });
        });
      }
    });
  }

  renderProcessingPage() {
    const {
      formDetails,
      shipperPackages,
      manifestsStatuses,
    } = this.state;

    const manifests = Object.keys(shipperPackages).map((shipper) => {
      const shipperPackage = shipperPackages[shipper];

      let badgeStatus = 'processing';
      let badgeText = 'Creating...';
      if (manifestsStatuses[shipper] === 'error') {
        badgeStatus = 'error';
        badgeText = 'Error creating manifest';
      } else if (manifestsStatuses[shipper] !== 'creating') {
        badgeStatus = 'success';
        badgeText = (
          <span>
            DM Created:&nbsp;
            <strong>{manifestsStatuses[shipper]}</strong>
            &nbsp;
            <Button
              type="primary"
              onClick={() => window.open(
                `${window.location.origin}/merchant-pickup/${manifestsStatuses[shipper]}`,
              )}
            >
              Open Manifest
            </Button>
          </span>
        );
      }

      return (
        <React.Fragment key={shipper}>
          <Descriptions title={shipperPackage.name} bordered>
            <Descriptions.Item label="Shipper ID">{shipper}</Descriptions.Item>
            <Descriptions.Item label="Package Count">{shipperPackage.packages.length}</Descriptions.Item>
            <Descriptions.Item label="Manifest Status"><Badge status={badgeStatus} text={badgeText} /></Descriptions.Item>
            <Descriptions.Item label="Shipper Address">{shipperPackage.address}</Descriptions.Item>
          </Descriptions>
          <Spacer />
        </React.Fragment>
      );
    });

    return (
      <React.Fragment>
        <Card title="Dispatch Header">
          <Descriptions bordered>
            <Descriptions.Item label="Driver" span={2}>{formDetails.driver_id.label}</Descriptions.Item>
            <Descriptions.Item label="Driver Rate">{formDetails.driver_rate}</Descriptions.Item>
            <Descriptions.Item label="Helper" span={2}>{formDetails.helper_id ? formDetails.helper_id.label : null}</Descriptions.Item>
            <Descriptions.Item label="Helper Rate">{formDetails.helper_rate}</Descriptions.Item>
            <Descriptions.Item label="Driver 2" span={2}>{formDetails.driver2_id ? formDetails.driver2_id.label : null}</Descriptions.Item>
            <Descriptions.Item label="Driver 2 Rate">{formDetails.driver2_rate}</Descriptions.Item>
            <Descriptions.Item label="Helper 2" span={2}>{formDetails.helper2_id ? formDetails.helper2_id.label : null}</Descriptions.Item>
            <Descriptions.Item label="Helper 2 Rate">{formDetails.helper2_rate}</Descriptions.Item>
            <Descriptions.Item label="Vehicle">{formDetails.vehicle_id.label}</Descriptions.Item>
          </Descriptions>
        </Card>
        <Spacer />
        <Card title="Dispatch Manifests">
          { manifests }
        </Card>
      </React.Fragment>
    );
  }

  renderFormGroup() {
    const {
      showSecondDriverandHelper,
      options,
      isSearchingcourier,
      isSearchingvehicle,
      shipperPackages,
      hasSubmitted,
    } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <React.Fragment>
        <Form scrollToFirstError>
          <Card title="Dispatch Details">
            <Row>
              <Col xs={24} sm={24} lg={8}>
                <Form.Item label="Driver">
                  {getFieldDecorator('driver_id', {
                    rules: [
                      {
                        required: true,
                        message: 'Driver cannot be blank.',
                      },
                    ],
                  })(
                    <Select
                      allowClear
                      loading={isSearchingcourier}
                      options={options.courier}
                      onSearch={e => this.searchOption('courier', e)}
                      filterOption={false}
                      placeholder="Type to search for driver"
                      showSearch
                      labelInValue
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={4}>
                <Form.Item label="Rate">
                  {getFieldDecorator('driver_rate', {
                    rules: [
                      {
                        required: true,
                        message: 'Driver rate cannot be blank.',
                      },
                    ],
                  })(
                    <Input
                      type="number"
                      placeholder="Type to put driver rate."
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={8}>
                <Form.Item label="Helper">
                  {getFieldDecorator('helper_id')(
                    <Select
                      allowClear
                      loading={isSearchingcourier}
                      options={options.courier}
                      onSearch={e => this.searchOption('courier', e)}
                      filterOption={false}
                      placeholder="Type to search for helper"
                      showSearch
                      labelInValue
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={4}>
                <Form.Item label="Rate">
                  {getFieldDecorator('helper_rate')(
                    <Input
                      type="number"
                      placeholder="Type to put helper rate."
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            {showSecondDriverandHelper ? (
              <Row>
                <Col xs={24} sm={24} lg={8}>
                  <Form.Item label="Driver 2">
                    {getFieldDecorator('driver2_id')(
                      <Select
                        allowClear
                        loading={isSearchingcourier}
                        options={options.courier}
                        onSearch={e => this.searchOption('courier', e)}
                        filterOption={false}
                        placeholder="Type to search for driver"
                        showSearch
                        labelInValue
                      />,
                    )}
                    <Button type="link" onClick={this.toggleSecondaryDriverAndHelper}>
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faMinus} fixedWidth />
                      </Icon>
                      Remove second driver and helper
                    </Button>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={4}>
                  <Form.Item label="Driver 2 Rate">
                    {getFieldDecorator('driver2_rate')(
                      <Input
                        type="number"
                        placeholder="Type to put driver rate."
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col>
                  <Col xs={24} sm={24} lg={8}>
                    <Form.Item label="Helper 2">
                      {getFieldDecorator('helper2_id')(
                        <Select
                          allowClear
                          loading={isSearchingcourier}
                          options={options.courier}
                          onSearch={e => this.searchOption('courier', e)}
                          filterOption={false}
                          placeholder="Type to search for helper"
                          showSearch
                          labelInValue
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={4}>
                    <Form.Item label="Helper 2 Rate">
                      {getFieldDecorator('helper2_rate')(
                        <Input
                          type="number"
                          placeholder="Type to put helper rate."
                        />,
                      )}
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
            ) : (
              <Button type="link" onClick={this.toggleSecondaryDriverAndHelper}>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faPlus} fixedWidth />
                </Icon>
                Add second driver and helper
              </Button>
            )}
            <Row>
              <Col xs={24}>
                <Form.Item label="Vehicle">
                  {getFieldDecorator('vehicle_id', {
                    rules: [
                      {
                        required: true,
                        message: 'Vehicle cannot be blank.',
                      },
                    ],
                  })(
                    <Select
                      allowClear
                      loading={isSearchingvehicle}
                      options={options.vehicle}
                      onSearch={e => this.searchOption('vehicle', e)}
                      filterOption={false}
                      placeholder="Type to search for vehicle"
                      showSearch
                      labelInValue
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Spacer />
          <Card title="Enter Package">
            <Row>
              <Col>
                <Form.Item label="Tracking Number">
                  {getFieldDecorator('trackingNumber', {
                    rules: [
                      {
                        required: true,
                        message: 'Tracking Number cannot be blank.',
                      },
                    ],
                    defaultValue: null,
                  })(
                    <Input.TextArea
                      placeholder="Enter tracking number(s)"
                      onPressEnter={this.validateTrackingNumber}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={this.validateTrackingNumber}
                  block
                >
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faPlus} fixedWidth />
                  </Icon>
                  Add tracking number
                </Button>
              </Col>
            </Row>
          </Card>
          { this.renderShipperPackages() }
        </Form>
        { this.renderErrors() }
        <Spacer />
        <Button
          type="primary"
          htmlType="submit"
          onClick={this.createManifests}
          block
          disabled={Object.keys(shipperPackages).length === 0 || hasSubmitted}
          loading={hasSubmitted}
        >
          <Icon viewBox="0 0 1024 1024">
            <FontAwesomeIcon
              icon={faPlus}
              fixedWidth
            />
          </Icon>
          Create Multiple Manifests
        </Button>
      </React.Fragment>
    );
  }

  renderShipperPackages() {
    const { shipperPackages } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;

    if (!Object.keys(shipperPackages).length) {
      return null;
    }

    const shippers = Object.keys(shipperPackages).map((shipper) => {
      if (!shipperPackages[shipper].packages.length) {
        return null;
      }

      return (
        <Col xs={24} md={12} key={shipper}>
          <Spacer />
          {/* eslint-disable react/jsx-one-expression-per-line */}
          <Card
            title={(
              <strong>
                {shipperPackages[shipper].name} - ID# {shipper}
              </strong>
            )}
            extra={`Total Items: ${shipperPackages[shipper].packages.length}`}
          >
            {/* eslint-enable react/jsx-one-expression-per-line */}
            <p style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
            >
              <strong>Address: </strong>
              { shipperPackages[shipper].address }
            </p>
            <Form.Item label="Remarks">
              {getFieldDecorator(`remarks-${shipper}`, {
                defaultValue: null,
              })(
                <Input.TextArea
                  placeholder="Enter additional instructions..."
                />,
              )}
            </Form.Item>
            <ReactTable
              data={shipperPackages[shipper].packages}
              columns={[
                {
                  Header: 'Tracking Number',
                  accessor: 'tracking_number',
                },
                {
                  Header: 'Item Description',
                  accessor: 'description',
                },
                {
                  Header: 'Delete',
                  sortable: false,
                  filterable: false,
                  Cell: data => (
                    <React.Fragment>
                      <Popconfirm
                        placement="leftBottom"
                        title={`Are you sure you want to delete ${data.row.tracking_number} from the list?`}
                        okText="Yes"
                        onConfirm={() => this.removeShipperPackage(data.original)}
                        cancelText="No"
                      >
                        <Button type="link" style={{ float: 'left', height: '14px' }}>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faTrash} fixedWidth />
                          </Icon>
                          Remove
                        </Button>
                      </Popconfirm>
                    </React.Fragment>
                  ),
                },
              ]}
            />
          </Card>
        </Col>
      );
    });

    return (
      <Row>
        { shippers }
      </Row>
    );
  }

  renderErrors() {
    const { errors } = this.state;

    if (!errors.length) {
      return null;
    }

    return (
      <React.Fragment>
        <Spacer />
        <Card
          title="Error List"
          style={{ border: '2px solid red' }}
          extra={`Total: ${errors.length}`}
        >
          <ReactTable
            data={errors}
            columns={[
              {
                Header: 'Tracking Number',
                accessor: 'trackingNumber',
              },
              {
                Header: 'Message',
                accessor: 'errorMessage',
              },
            ]}
          />
        </Card>
      </React.Fragment>
    );
  }

  render() {
    const { hasSubmitted } = this.state;

    return (
      <div className="MerchantPickupDispatch">
        <PageHeader title="Multi Merchant Pickup" routes={this.breadcrumbs} />
        <Spacer />
        <Container>
          { hasSubmitted ? this.renderProcessingPage() : this.renderFormGroup() }
        </Container>
      </div>
    );
  }
}

MerchantMultiPickupDispatch.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  // history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchOptionsLike: PropTypes.func.isRequired,
  doFetchMultiMerchantPickupTrackingNumber: PropTypes.func.isRequired,
  doCreateMerchantPickup: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchMultiMerchantPickupTrackingNumber: fetchMultiMerchantPickupTrackingNumber,
  doFetchOptionsLike: fetchOptionsLike,
  doCreateMerchantPickup: createMerchantPickup,
};

const WrappedMerchantMultiPickupDispatchForm = Form.create({ name: 'MerchantMultiPickupDispatchForm' })(MerchantMultiPickupDispatch);

export default connect(
  null,
  mapDispatchToProps,
)(WrappedMerchantMultiPickupDispatchForm);
