import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  Button, message, Icon, Popconfirm, Card, Row, Col,
} from 'antd';
import moment from 'moment';
import CSVReader from 'react-csv-reader';
import Spacer from '../../components/Spacer';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import StatusTaggingForm from '../../components/StatusTaggingForm';
import { fetchTrackingNumber, updateTrackingNumberStatus } from '../ducks';

export class DeliveryDelivered extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trackingNumberRows: [],
      errors: [],
      reason: [],
      success: [],
      isDisabled: false,
    };

    this.validatePackage = this.validatePackage.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.confirmRemove = this.confirmRemove.bind(this);
    this.uploadHandler = this.uploadHandler.bind(this);
  }

  submitForm() {
    this.setState({ isDisabled: true });
    const { trackingNumberRows } = this.state;
    const { doUpdateTrackingNumberStatus } = this.props;

    // eslint-disable-next-line no-unused-vars
    trackingNumberRows.forEach((item, key) => {
      const payload = {
        current_status: item.current_status,
        comment: item.comment,
        status: item.status,
        reference_code: item.reference_code,
        tracking_number: item.tracking_number,
        timestamp: item.timestamp,
      };

      doUpdateTrackingNumberStatus(payload, item.tracking_number)
        .then((action) => {
          this.setState({
            trackingNumberRows: [],
            isDisabled: false,
          });

          message.success(`Successfully updated ${item.tracking_number}`);
          this.setState(prevState => ({
            success: [
              ...prevState.success,
              {
                tracking_number: action.payload.data.tracking_number,
                address_city: action.payload.data.address_city,
                reference_code: action.payload.data.status_reference,
                status: action.payload.data.status,
              },
            ],
          }));
        })
        .catch(() => {
          message.error('Something went wrong, please try again later.');
          this.setState({
            isDisabled: false,
          });
        });
    });
  }

  validatePackage(values) {
    const { trackingNumberRows } = this.state;
    const { doFetchTrackingNumber } = this.props;

    const splitTrackingNumber = values.trackingNumber
      ? values.trackingNumber.split('\n')
      : '';
    const filteredTrackingNumber = splitTrackingNumber.filter(
      (item, key) => splitTrackingNumber.indexOf(item) >= key && item !== '',
    );

    // eslint-disable-next-line no-unused-vars
    filteredTrackingNumber.forEach((item, key) => {
      const exists = trackingNumberRows.filter(
        tn => tn.tracking_number === item,
      );

      if (exists.length > 0) {
        message.error(`Tracking number ${item} is already entered.`);
        this.setState(prevState => ({
          errors: [
            ...prevState.errors,
            {
              tracking_number: item,
              message: 'Tracking number is already entered.',
            },
          ],
        }));
      } else {
        doFetchTrackingNumber('delivery_successful', item)
          .then((action) => {
            this.setState(prevState => ({
              trackingNumberRows: [
                ...prevState.trackingNumberRows,
                {
                  tracking_number: action.payload.data.tracking_number,
                  current_status: action.payload.data.status,
                  status: 'delivery_successful',
                  reference_code: values.received_by,
                  timestamp: moment(values.timestamp).format(
                    'YYYY-MM-DD HH:mm:ss',
                  ),
                  status_reference: action.payload.data.status_reference,
                  comment: values.comment,
                },
              ],
            }));
            message.success(`Successfully added tracking number: ${action.payload.data.tracking_number}`);
          })
          .catch((action) => {
            let errorMessage;
            if (action.error.response.status === 400) {
              errorMessage = action.error.response.data.message;
              this.setState(prevState => ({
                errors: [
                  ...prevState.errors,
                  {
                    tracking_number: item,
                    message: action.error.response.data.message,
                  },
                ],
              }));
            } else {
              errorMessage = 'Something went wrong, please try again later.';
            }
            message.error(errorMessage, 3);
          });
      }
    });
  }

  confirmRemove(trackingNumber) {
    const { trackingNumberRows } = this.state;
    const filteredTrackingNumber = trackingNumberRows.filter(
      data => data.tracking_number !== trackingNumber,
    );

    this.setState({
      trackingNumberRows: filteredTrackingNumber,
    });

    message.success('Successfully removed tracking number!');
  }

  uploadHandler(data) {
    const validPackages = data.reduce((list, entry) => {
      if (entry.tracking_number.length
        && entry.reference_code.length
        && (
          !entry.created_at.length
          || moment(entry.created_at).format('YYYY-MM-DD HH:mm:ss') !== 'Invalid date'
        )
      ) {
        const date = entry.created_at.length ? moment(entry.created_at) : moment();

        list.push({
          trackingNumber: entry.tracking_number,
          received_by: entry.reference_code,
          timestamp: date.format('YYYY-MM-DD HH:mm:ss'),
          comment: entry.remarks,
        });
      }
      return list;
    }, []);

    if (validPackages.length) {
      message.info(`Started validation for ${validPackages.length} packages`);

      validPackages.forEach((entry) => {
        this.validatePackage(entry);
      });
    } else {
      message.warning('No package was added for validation.');
    }

    document.getElementById('csv_parser').value = null;
  }

  render() {
    const {
      trackingNumberRows,
      errors,
      success,
      reason,
      isDisabled,
    } = this.state;

    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Delivery',
      },
      {
        breadcrumbName: 'Delivered',
      },
    ];

    const trackingNumberRowColumns = [
      {
        Header: 'Tracking Number',
        accessor: 'tracking_number',
      },
      {
        Header: 'Current Status',
        accessor: 'current_status',
      },
      {
        Header: 'Target Status',
        accessor: 'status',
      },
      {
        Header: 'Received by',
        accessor: 'reference_code',
      },
      {
        Header: 'Comment',
        accessor: 'comment',
      },
      {
        Header: 'Timestamp',
        accessor: 'timestamp',
      },
      {
        Header: 'Options',
        sortable: false,
        filterable: false,
        Cell: data => (
          <React.Fragment>
            <Popconfirm
              placement="leftBottom"
              title={`Are you sure you want to delete ${data.row.tracking_number} from the list?`}
              okText="Yes"
              onConfirm={() => this.confirmRemove(data.row.tracking_number)}
              cancelText="No"
            >
              <Button type="link" block>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faTrash} fixedWidth />
                </Icon>
                Remove
              </Button>
            </Popconfirm>
          </React.Fragment>
        ),
      },
    ];

    return (
      <div className="DeliveryDelivered">
        <PageHeader title="Delivery" routes={breadCrumbs} />
        <Spacer />
        <Card>
          <Row>
            <Col xs={24} md={18}>
              <p>
                This module supports mass tagging via upload.
                Please fill up the tracking number and reference.
                You can leave remarks and timestamp blank.
              </p>
              <Button type="primary" href={`${process.env.REACT_APP_API_URL}/templates/mass_tagging_template.csv`}>Download Template</Button>
            </Col>
            <Col xs={24} md={6}>
              <p>
                <strong>Upload Mass Tagging File</strong>
              </p>
              <CSVReader
                parserOptions={{
                  header: true,
                }}
                inputId="csv_parser"
                onFileLoaded={this.uploadHandler}
              />
            </Col>
          </Row>
        </Card>
        <Spacer />
        <Container>
          <StatusTaggingForm
            inputDisabled={isDisabled}
            validateHandler={this.validatePackage}
            submitHandler={this.submitForm}
            trackingNumberList={trackingNumberRows}
            trackingNumberListColumns={trackingNumberRowColumns}
            errorList={errors}
            successList={success}
            options={reason}
            hasReceiver
            hasDatePicker
          />
        </Container>
      </div>
    );
  }
}

DeliveryDelivered.propTypes = {
  doFetchTrackingNumber: PropTypes.func.isRequired,
  doUpdateTrackingNumberStatus: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchTrackingNumber: fetchTrackingNumber,
  doUpdateTrackingNumberStatus: updateTrackingNumberStatus,
};

export default connect(null, mapDispatchToProps)(DeliveryDelivered);
