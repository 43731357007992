import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  Button, message, Icon, Popconfirm, Spin,
} from 'antd';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import StatusTaggingForm from '../../components/StatusTaggingForm';
import { fetchTrackingNumber, updateTrackingNumberStatus } from '../ducks';

export class AcceptToWarehouse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preventDuplicate: true,
      trackingNumberRows: [],
      errors: [],
      success: [],
      errorMessage: ' ',
      trackingNumberValidation: [],
      isLoading: false,
      loadingText: null,
    };

    this.validatePackage = this.validatePackage.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.confirmRemove = this.confirmRemove.bind(this);
  }

  submitForm() {
    const { trackingNumberRows } = this.state;
    const { doUpdateTrackingNumberStatus } = this.props;

    // eslint-disable-next-line no-unused-vars
    trackingNumberRows.forEach((item, key) => {
      const payload = {
        status_reference: item.status_reference,
        current_status: item.current_status,
        comment: item.comment,
        status: item.status,
        tracking_number: item.tracking_number,
      };
      this.setState({
        isLoading: true,
        loadingText: 'Updating packages, please don`t refresh or leave this page. . .',
      });
      doUpdateTrackingNumberStatus(payload, item.tracking_number)
        .then((action) => {
          this.setState({
            errorMessage: ' ',
            trackingNumberRows: [],
            isLoading: false,
          });
          message.success(`Successfully updated ${item.tracking_number}`);
          this.setState(prevState => ({
            success: [
              ...prevState.success,
              {
                tracking_number: action.payload.data.tracking_number,
                address_city: action.payload.data.address_city,
                reference_code: action.payload.data.status_reference,
                status: action.payload.data.status,
              },
            ],
          }));
        })
        .catch(() => {
          message.error('Something went wrong, please try again later.');
          this.setState({
            errorMessage: 'Something went wrong, please try again later.',
          });
        });
    });
  }

  validatePackage(values) {
    const { trackingNumberRows } = this.state;
    const { doFetchTrackingNumber } = this.props;
    this.setState(prevState => ({
      trackingNumberValidation: [
        ...prevState.trackingNumberValidation,
        { tracking_number: values.trackingNumber },
      ],
    }));

    const splitTrackingNumber = values.trackingNumber
      ? values.trackingNumber.split('\n')
      : '';

    const filteredTrackingNumber = splitTrackingNumber.filter(
      (item, key) => splitTrackingNumber.indexOf(item) >= key && item !== '',
    );

    filteredTrackingNumber.forEach((item) => {
      const { trackingNumberValidation: validation } = this.state;
      // console.log(validation);
      const existLocal = validation.filter(tn => tn.tracking_number === item);

      const exists = trackingNumberRows.filter(
        tn => tn.tracking_number === item,
      );

      if (existLocal.length > 0) {
        message.error(`Tracking number ${item} is already entered.`);
        this.setState(prevState => ({
          preventDuplicate: true,
          errors: [
            ...prevState.errors,
            {
              tracking_number: item,
              message: 'Tracking number is already entered.',
            },
          ],
        }));
      } else if (exists.length > 0) {
        message.error(`Tracking number ${item} is already entered.`);
        this.setState(prevState => ({
          preventDuplicate: true,
          errors: [
            ...prevState.errors,
            {
              tracking_number: item,
              message: 'Tracking number is already entered.',
            },
          ],
        }));
      } else {
        doFetchTrackingNumber('accepted_to_warehouse', item)
          .then((action) => {
            this.setState(prevState => ({
              trackingNumberValidation: [],
              preventDuplicate: true,
              errorMessage: ' ',
              trackingNumberRows: [
                ...prevState.trackingNumberRows,
                {
                  tracking_number: action.payload.data.tracking_number,
                  current_status: action.payload.data.status,
                  status: 'accepted_to_warehouse',
                  status_reference: action.payload.data.status_reference,
                  comment: values.comment,
                },
              ],
            }));
            message.success('Successfully added tracking number!.');
          })
          .catch((action) => {
            let errorMessage;
            if (action.error.response.status === 400) {
              errorMessage = action.error.response.data.message;
              this.setState(prevState => ({
                trackingNumberValidation: [],
                preventDuplicate: true,
                errorMessage: action.error.response.data.message,
                errors: [
                  ...prevState.errors,
                  {
                    tracking_number: item,
                    message: action.error.response.data.message,
                  },
                ],
              }));
            } else {
              errorMessage = 'Something went wrong, please try again later.';
            }
            message.error(errorMessage, 3);
          });
      }
    });
  }

  confirmRemove(trackingNumber) {
    const { trackingNumberRows } = this.state;
    const filteredTrackingNumber = trackingNumberRows.filter(
      data => data.tracking_number !== trackingNumber,
    );

    this.setState({
      trackingNumberRows: filteredTrackingNumber,
    });

    message.success('Successfully removed tracking number!');
  }

  render() {
    const {
      trackingNumberRows,
      errors,
      success,
      errorMessage,
      preventDuplicate,
      isLoading,
      loadingText,
    } = this.state;
    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Accept To Warehouse',
      },
    ];

    const trackingNumberRowColumns = [
      {
        Header: 'Tracking Number',
        accessor: 'tracking_number',
      },
      {
        Header: 'Current Status',
        accessor: 'current_status',
      },
      {
        Header: 'Target Status',
        accessor: 'status',
      },
      {
        Header: 'Reference',
        accessor: 'status_reference',
      },
      {
        Header: 'Comment',
        accessor: 'comment',
      },
      {
        Header: 'Options',
        sortable: false,
        filterable: false,
        Cell: data => (
          <React.Fragment>
            <Popconfirm
              placement="leftBottom"
              title={`Are you sure you want to delete ${data.row.tracking_number} from the list?`}
              okText="Yes"
              onConfirm={() => this.confirmRemove(data.row.tracking_number)}
              cancelText="No"
            >
              <Button type="link" block>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faTrash} fixedWidth />
                </Icon>
                Remove
              </Button>
            </Popconfirm>
          </React.Fragment>
        ),
      },
    ];

    return (
      <div className="AcceptToWarehouse">
        <PageHeader title="Accept To Warehouse" routes={breadCrumbs} />
        <Spin
          spinning={isLoading}
          size="small"
          tip={loadingText}
        >
          <Container>
            <StatusTaggingForm
              doPreventDuplicate={preventDuplicate}
              hasDatePicker={false}
              validateHandler={this.validatePackage}
              submitHandler={this.submitForm}
              trackingNumberList={trackingNumberRows}
              trackingNumberListColumns={trackingNumberRowColumns}
              errorList={errors}
              successList={success}
              errorMessageDispatch={errorMessage}
              saveTrackingNumber
            />
          </Container>
        </Spin>
      </div>
    );
  }
}

AcceptToWarehouse.propTypes = {
  doFetchTrackingNumber: PropTypes.func.isRequired,
  doUpdateTrackingNumberStatus: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchTrackingNumber: fetchTrackingNumber,
  doUpdateTrackingNumberStatus: updateTrackingNumberStatus,
};

export default connect(null, mapDispatchToProps)(AcceptToWarehouse);
