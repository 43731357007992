import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form, Col, Input, InputNumber, Button, Icon, message, Popconfirm,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faTrash, faEdit,
} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import Card from '../../components/Card';
import Spacer from '../../components/Spacer';
import Row from '../../components/Row';
import Select from '../../components/Select';
import ReactTable from '../../components/ReactTable';
import CreatableSelect from '../../components/CreatableSelect';
import { fetchOptions, fetchOptionsLike } from '../ducks';
import { fetchPcLocationTypeRequest, fetchLocationTypeLikeRequestWhereIn } from '../portCluster/ducks';
import { createWaybill } from './newDucks';
import { fetchShipperDetails, fetchShipperOptionsLike, fetchShipperOptions } from '../shipper/ducks';

export class WaybillCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        branch_code: [],
        category: [],
        city: [],
        client: [],
        shipper: [],
      },
      province: [],
      provinceValue: null,
      city: [],
      cityValue: null,
      barangay: [],
      barangayValue: null,
      cluster: null,
      port_code: null,
      itemRows: [],
      editedItem: null,
      isSubmitting: false,
      selectedShipperProvince: null,
      selectedShipperCity: null,
      shipperCity: [],
      shipperBarangay: [],
      filteredShipperOptions: [],
    };

    this.setCity = this.setCity.bind(this);
    this.validateItem = this.validateItem.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.setToEdit = this.setToEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.updateRow = this.updateRow.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
    this.fetchShippersLike = this.fetchShippersLike.bind(this);
    this.fetchShipperDetails = this.fetchShipperDetails.bind(this);
  }

  componentDidMount() {
    const { doFetchOptions, doFetchPcLocationTypeRequest, doFetchShipperOptions } = this.props;

    const selectOptions = [
      'branch_code', 'category', 'client',
    ];

    Object.keys(selectOptions).forEach((key) => {
      doFetchOptions(selectOptions[key]).then((action) => {
        this.setState(prevState => ({
          options: {
            ...prevState.options,
            // eslint-disable-next-line no-constant-condition
            [selectOptions[key]]: selectOptions[key] === 'client'
              ? action.payload.data.slice(0, 50) : action.payload.data,
          },
        }));
      });
    });

    doFetchShipperOptions().then((res) => {
      this.setState(prevState => ({
        options: {
          ...prevState.options,
          shipper: res.payload.data,
        },
        filteredShipperOptions: res.payload.data,
      }));
    });


    doFetchPcLocationTypeRequest('province').then((res) => {
      this.setState({
        province: res.payload.data,
      });
    });
  }

  onEdit(cellInfo) {
    const { itemRows, editedItem } = this.state;

    const { form } = this.props;
    const { getFieldDecorator } = form;

    if (editedItem === cellInfo.original.fieldId) {
      const inputFields = ['reference', 'description', 'type', 'uom', 'comment'];
      const requiredFields = ['reference', 'description', 'quantity'];
      if (inputFields.includes(cellInfo.column.id)) {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{
                required: requiredFields.includes(cellInfo.column.id),
                message: `${cellInfo.column.Header} is required.`,
              }],
              initialValue: itemRows[cellInfo.index][cellInfo.column.id],
            })(<Input autoComplete="off" placeholder={`Enter ${cellInfo.column.id}`} />)}
          </Form.Item>
        );
      }

      return (
        <Form.Item style={{ margin: '0px' }}>
          {getFieldDecorator(`${cellInfo.column.id}_update`, {
            rules: [{
              required: requiredFields.includes(cellInfo.column.id),
              message: `${cellInfo.column.Header} is required.`,
            }],
            initialValue: itemRows[cellInfo.index][cellInfo.column.id],
          })(<InputNumber min={0} placeholder={`Enter ${cellInfo.column.id}`} />)}
        </Form.Item>
      );
    }

    return (
      <div>
        {itemRows[cellInfo.index][cellInfo.column.id]}
      </div>
    );
  }

  setCity(e) {
    const { doFetchOptionsLike } = this.props;
    doFetchOptionsLike('city', `&reference=${e}`).then((action) => {
      this.setState(prevState => ({
        options: {
          ...prevState.options,
          city: action.payload.data,
        },
        disableCity: false,
      }));
    });
  }

  setToEdit(fieldId) {
    this.setState({
      editedItem: fieldId,
    });
  }

  cancelEdit() {
    this.setState({
      editedItem: null,
    });
  }

  updateRow() {
    const { itemRows, editedItem } = this.state;
    const { form } = this.props;
    const { validateFields } = form;

    validateFields(
      [
        'reference_update', 'description_update', 'quantity_update',
        'type_update', 'length_update', 'width_update', 'height_update',
        'weight_update', 'uom_update', 'comment_update',
      ], (err, values) => {
        if (!err) {
          const params = {
            reference: values.reference_update,
            description: values.description_update,
            quantity: values.quantity_update,
            type: values.type_update,
            length: values.length_update,
            width: values.width_update,
            height: values.height_update,
            weight: values.weight_update,
            uom: values.uom_update,
            comment: values.comment_update,
          };

          const newItem = itemRows.map((item) => {
            if (editedItem !== item.fieldId) {
              return item;
            }
            return {
              ...item,
              ...params,
            };
          });
          message.success('Successfully updated item!');
          this.setState({
            itemRows: newItem,
            editedItem: null,
          });
        }
      },
    );
  }

  confirmDelete(fieldId) {
    const { itemRows } = this.state;
    const filteredItems = itemRows.filter(
      data => data.fieldId !== fieldId,
    );

    this.setState({
      itemRows: filteredItems,
    });

    message.success('Successfully removed item!');
  }

  validateItem() {
    const { itemRows } = this.state;
    const { form } = this.props;
    const { validateFields } = form;

    const number = itemRows.length > 0 ? itemRows.map(res => res.fieldId)[itemRows.length - 1] : 0;

    validateFields(
      [
        'reference', 'description', 'quantity',
        'type', 'itemLength', 'itemWidth', 'itemHeight',
        'itemWeight', 'uom', 'comment',
      ], (err, values) => {
        if (!err) {
          this.setState(prevState => ({
            itemRows: [
              ...prevState.itemRows,
              {
                id: null,
                fieldId: number + 1,
                reference: values.reference,
                description: values.description,
                quantity: values.quantity,
                type: values.type,
                length: values.itemLength,
                width: values.itemWidth,
                height: values.itemHeight,
                weight: values.itemWeight,
                uom: values.uom,
                comment: values.comment,
              },
            ],
          }));

          form.resetFields([
            'reference', 'description', 'quantity',
            'type', 'itemLength', 'itemWidth', 'itemHeight',
            'itemWeight', 'uom', 'comment',
          ]);

          message.success('Successfully added item!');
        }
      },
    );
  }

  formSubmit() {
    const {
      itemRows,
      cluster,
      port_code,
    } = this.state;
    const { form, doCreateWaybill, history } = this.props;
    const { validateFieldsAndScroll } = form;

    validateFieldsAndScroll([
      'address_barangay', 'address_city', 'address_province',
      'branch_code', 'category', 'client_id',
      'consignee_address', 'consignee_email', 'consignee_mobile',
      'consignee_name', 'declared_value', 'delivery_type', 'height',
      'length', 'package_id', 'package_size', 'package_type',
      'payment_type', 'reference_1', 'reference_2', 'reference_3', 'reference_4', 'remarks',
      'total_price', 'transport_mode', 'weight', 'width', 'order_no', 'group_id',
      'shipping_type', 'journey_type', 'shipper_name', 'shipper_contact', 'shipper_address',
      'shipper_province', 'shipper_city', 'shipper_barangay', 'shipper_email', 'building_type',
    ], (err, values) => {
      if (!err) {
        this.setState({
          isSubmitting: true,
        });
        const params = {
          items: itemRows,
          package: {
            ...values,
            cluster,
            port_code,
            shipper_name: values.shipper_name.label,
          },
        };

        doCreateWaybill(params).then((action) => {
          history.push(`/waybill/${action.payload.data.tracking_number}`);
        }).catch((action) => {
          this.setState({
            isSubmitting: false,
          });
          let errorMessage;
          if (action.error.response.status === 400) {
            errorMessage = action.error.response.data.message;
          } else {
            errorMessage = 'Something went wrong, please try again later.';
          }
          message.error(errorMessage, 3);
        });
      }
    });
  }

  searchOption(type, e) {
    const { doFetchOptionsLike } = this.props;

    this.setState({
      isFetching: true,
    });

    doFetchOptionsLike(type, e).then((action) => {
      this.setState(prevState => ({
        options: {
          ...prevState.options,
          [type]: action.payload.data.slice(0, 50),
        },
        isFetching: false,
      }));
    });
  }

  selectShipperProvinceHandler(e) {
    const { doFetchLocationTypeLikeRequestWhereIn, form } = this.props;
    doFetchLocationTypeLikeRequestWhereIn('city', {
      provinces: [e],
    }).then((res) => {
      this.setState({
        shipperCity: res.payload.data,
        selectedShipperProvince: e,
      }, () => {
        form.setFieldsValue(
          {
            shipper_city: undefined,
            shipper_barangay: undefined,
          },
        );
      });
    });
  }

  selectShipperCityHandler(e) {
    const { doFetchLocationTypeLikeRequestWhereIn, form } = this.props;
    doFetchLocationTypeLikeRequestWhereIn('barangay', {
      cities: [e],
    }).then((res) => {
      this.setState({
        shipperBarangay: res.payload.data,
        selectedShipperCity: e,
      }, () => {
        form.setFieldsValue(
          {
            shipper_barangay: undefined,
          },
        );
      });
    });
  }


  selectHandler(type, e) {
    const { doFetchLocationTypeLikeRequestWhereIn, form } = this.props;
    const {
      provinceValue,
      cityValue,
      barangayValue,
    } = this.state;

    if (type === 'province') {
      if (cityValue !== null || barangayValue !== null) {
        form.setFieldsValue(
          {
            address_city: undefined,
            address_barangay: undefined,
            cluster: undefined,
          },
        );

        this.setState({
          cityValue: null,
          city: [],
          barangayValue: null,
          barangay: [],
          cluster: null,
          port_code: null,
        });
      }

      doFetchLocationTypeLikeRequestWhereIn('city', {
        provinces: [e],
      }).then((res) => {
        this.setState({
          provinceValue: e,
          city: res.payload.data,
        });
      });
    }

    if (type === 'city') {
      if (provinceValue !== null) {
        form.setFieldsValue(
          {
            address_barangay: undefined,
            cluster: undefined,
          },
        );

        this.setState({
          barangayValue: null,
          barangay: [],
          cluster: null,
          port_code: null,
        });
      }
      doFetchLocationTypeLikeRequestWhereIn('barangay', {
        cities: [e],
      }).then((res) => {
        this.setState({
          cityValue: e,
          barangay: res.payload.data,
        });
      });
    }

    if (type === 'barangay') {
      doFetchLocationTypeLikeRequestWhereIn('cluster', {
        barangays: [e],
      }).then((res) => {
        form.setFieldsValue({
          cluster: `${res.payload.data[0].cluster_value} - ${res.payload.data[0].branch_value}`,
        });

        this.setState({
          cluster: res.payload.data[0].cluster_value,
          port_code: res.payload.data[0].branch_value,
        });
      });
    }
  }

  fetchShippersLike(e) {
    const { doFetchShipperOptionsLike } = this.props;
    const { options } = this.state;
    if (e !== '') {
      doFetchShipperOptionsLike(e).then((res) => {
        this.setState({
          filteredShipperOptions: _.differenceWith(res.payload.data, options.shipper, _.isEqual),
        });
      });
    } else {
      this.setState({
        filteredShipperOptions: options.shipper,
      });
    }
  }

  fetchShipperDetails(option) {
    const { doFetchShipperDetails, form } = this.props;
    doFetchShipperDetails(option.value).then((res) => {
      if (res.payload.data) {
        form.setFieldsValue(
          {
            shipper_city: res.payload.data.address_city,
            shipper_email: res.payload.data.email,
            shipper_contact: res.payload.data.shipper_contact,
            shipper_address: res.payload.data.shipper_address,
            shipper_barangay: res.payload.data.address_barangay,
            shipper_province: res.payload.data.address_province,
          },
        );
        this.setState({
          selectedShipperCity: res.payload.data.address_city,
          selectedShipperProvince: res.payload.data.address_province,
        });
      } else {
        form.setFieldsValue(
          {
            shipper_city: undefined,
            shipper_email: undefined,
            shipper_contact: undefined,
            shipper_address: undefined,
            shipper_barangay: undefined,
            shipper_province: undefined,
          },
        );
      }
    });
  }

  render() {
    const {
      options, itemRows, editedItem, isFetching,
      isSubmitting, province, provinceValue, city, cityValue,
      barangay, shipperCity, shipperBarangay, selectedShipperCity, selectedShipperProvince,
      filteredShipperOptions,
    } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;

    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Waybill',
      },
      {
        breadcrumbName: 'Create',
      },
    ];
    const transportModeOptions = [
      { label: 'Land', value: 'land' },
      { label: 'Air', value: 'air' },
      { label: 'Sea', value: 'sea' },
    ];
    const deliveryTypeOptions = [
      { value: 'Regular', label: 'Regular' },
      { value: 'Subselling', label: 'Subselling' },
    ];
    const categoryOptions = [
      { value: 'test booklet', label: 'Test Booklet' },
      { value: 'answer sheet', label: 'Answer Sheet' },
    ];
    const packageSizeOptions = [
      { value: 'Bulky', label: 'Bulky' },
      { value: 'Pouches', label: 'Pouches' },
    ];
    const paymentTypeOptions = [
      { value: 'Not Applicable', label: 'Not Applicable' },
      { value: 'CashOnDelivery', label: 'Cash On Delivery' },
      { value: 'No Payment', label: 'No Payment' },
    ];

    return (
      <div className="WaybillCreate">
        <PageHeader title="Waybill" routes={breadCrumbs} />
        <Container>
          <Form>
            <Card title="Waybill Reference">
              <Row>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Client">
                    {getFieldDecorator('client_id', {
                      rules: [{ required: true, message: 'Client cannot be blank' }],
                    })(<Select
                      allowClear
                      loading={isFetching}
                      options={options.client}
                      onSearch={e => this.searchOption('client', e)}
                      filterOption={false}
                      placeholder="Type to search for client"
                      showSearch
                    />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Branch Code">
                    {getFieldDecorator('branch_code', {
                      rules: [{ required: true, message: 'Branch code cannot be blank' }],
                    })(<Select
                      options={options.branch_code}
                      placeholder="Select branch code"
                      allowClear
                      loading={isFetching}
                      onSearch={e => this.searchOption('branch_code', e)}
                      filterOption={false}
                      showSearch
                    />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Package ID/SICC">
                    {getFieldDecorator('package_id', {
                      rules: [{ required: true, message: 'Package ID/SICC cannot be blank' }],
                    })(<Input placeholder="Enter package ID/SICC" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Group ID">
                    {getFieldDecorator('group_id', {
                    })(<Input placeholder="Enter package group id" autoComplete="off" />)}
                  </Form.Item>
                </Col>

              </Row>
              <Row>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Reference 1">
                    {getFieldDecorator('reference_1', {
                    })(<Input placeholder="Enter refernece" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Reference 2">
                    {getFieldDecorator('reference_2', {
                    })(<Input placeholder="Enter refernece" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Reference 3">
                    {getFieldDecorator('reference_3', {
                    })(<Input placeholder="Enter refernece" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Reference 4">
                    {getFieldDecorator('reference_4', {
                    })(<Input placeholder="Enter refernece" autoComplete="off" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Order Number">
                    {getFieldDecorator('order_no', {
                      rules: [{ required: true, message: 'Package ID/SICC cannot be blank' }],
                    })(<Input placeholder="Enter package order number" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Category">
                    {getFieldDecorator('category', {
                      rules: [{ required: false, message: 'Category cannot be blank' }],
                    })(<Select options={categoryOptions} placeholder="Select category" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Package Type">
                    {getFieldDecorator('package_type', {
                    })(<Input placeholder="Enter package type" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Transport Mode">
                    {getFieldDecorator('transport_mode', {
                      rules: [{ required: true, message: 'Transport Mode cannot be blank' }],
                    })(<Select
                      options={transportModeOptions}
                      placeholder="Select transport mode"
                    />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Shipping Type">
                    {getFieldDecorator('shipping_type', {
                    })(<Input placeholder="Enter shipping type" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Journey Type">
                    {getFieldDecorator('journey_type', {
                    })(<Input placeholder="Enter shipping type" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Delivery Type">
                    {getFieldDecorator('delivery_type', {
                      rules: [{ required: true, message: 'Delivery Type cannot be blank' }],
                    })(<Select options={deliveryTypeOptions} placeholder="Select delivery type" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Remarks">
                    {getFieldDecorator('remarks', {
                      rules: [{ required: false, message: 'Remarks cannot be blank' }],
                    })(<Input.TextArea placeholder="Enter remarks" autoComplete="off" />)}
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Form>
          <Spacer />
          <Form>
            <Card title="Consignee Information">
              <Row>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Name">
                    {getFieldDecorator('consignee_name', {
                      rules: [{ required: true, message: 'Name cannot be blank' }],
                    })(<Input placeholder="Enter name" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Email Address">
                    {getFieldDecorator('consignee_email', {
                      rules: [{ required: false, message: 'Email Address cannot be blank' }],
                    })(<Input placeholder="Enter email address" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Mobile Number">
                    {getFieldDecorator('consignee_mobile', {
                      rules: [{ required: false, message: 'Mobile Number cannot be blank' }],
                    })(<Input placeholder="Enter mobile number" autoComplete="off" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={18} sm={18} lg={18}>
                  <Form.Item label="Address">
                    {getFieldDecorator('consignee_address', {
                      rules: [{ required: true, message: 'Address cannot be blank' }],
                    })(<Input placeholder="Enter address" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={6} sm={6} lg={6}>
                  <Form.Item label="Building Type">
                    {getFieldDecorator('building_type', {
                    })(<Input placeholder="Enter building type" autoComplete="off" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12} lg={6}>
                  {/* <Form.Item label="Province">
                    {getFieldDecorator('address_province', {
                      rules: [{ required: true, message: 'Province cannot be blank' }],
                    })(<Select
                      options={province}
                      placeholder="Select province"
                      onSelect={(e) => { this.selectHandler('province', e); }}
                    />)}
                  </Form.Item> */}
                  <Form.Item label="Province">
                    {getFieldDecorator('address_province', {
                      rules: [{ required: true, message: 'Province cannot be blank' }],
                    })(<Input placeholder="Enter province" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  {/* <Form.Item label="City">
                    {getFieldDecorator('address_city', {
                      rules: [{ required: true, message: 'City cannot be blank' }],
                    })(<Select
                      disabled={provinceValue === null}
                      options={city}
                      onSelect={(e) => { this.selectHandler('city', e); }}
                      placeholder="Select city"
                    />)}
                  </Form.Item> */}
                  <Form.Item label="City">
                    {getFieldDecorator('address_city', {
                      rules: [{ required: true, message: 'City cannot be blank' }],
                    })(<Input placeholder="Enter city" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  {/* <Form.Item label="Barangay">
                    {getFieldDecorator('address_barangay', {
                      rules: [{ required: true, message: 'Barangay cannot be blank' }],
                    })(<Select
                      disabled={cityValue === null}
                      options={barangay}
                      onSelect={(e) => { this.selectHandler('barangay', e); }}
                      placeholder="Select barangay"
                    />)}
                  </Form.Item> */}
                  <Form.Item label="Barangay">
                    {getFieldDecorator('address_barangay', {
                      rules: [{ required: true, message: 'Barangay cannot be blank' }],
                    })(<Input placeholder="Enter barangay" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                {/* <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Cluster">
                    {getFieldDecorator('cluster', {
                      rules: [{ }],
                    })(<Input disabled />)}
                  </Form.Item>
                </Col> */}
              </Row>
            </Card>
            <Spacer />
            <Card title="Merchant Information">
              <Row>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Name">
                    {getFieldDecorator('shipper_name', {
                      rules: [{ required: true, message: 'Name cannot be blank' }],
                    })(
                      <CreatableSelect
                        options={filteredShipperOptions}
                        onInputChange={this.fetchShippersLike}
                        placeholder="Select merchant"
                        onChange={this.fetchShipperDetails}
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Email Address">
                    {getFieldDecorator('shipper_email', {
                    })(<Input placeholder="Enter email address" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Mobile Number">
                    {getFieldDecorator('shipper_contact', {
                      rules: [{ required: true, message: 'Mobile Number cannot be blank' }],
                    })(<Input placeholder="Enter mobile number" autoComplete="off" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item label="Address">
                    {getFieldDecorator('shipper_address', {
                      rules: [{ required: true, message: 'Address cannot be blank' }],
                    })(<Input placeholder="Enter address" autoComplete="off" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item label="Province">
                    {getFieldDecorator('shipper_province', {
                    })(<Select
                      options={province}
                      placeholder="Select province"
                      onSelect={(e) => { this.selectShipperProvinceHandler(e); }}
                    />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item label="City">
                    {getFieldDecorator('shipper_city', {
                    })(<Select
                      options={shipperCity}
                      disabled={selectedShipperProvince === null}
                      onSelect={(e) => { this.selectShipperCityHandler(e); }}
                      placeholder="Select city"
                    />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item label="Barangay">
                    {getFieldDecorator('shipper_barangay', {
                    })(<Select
                      options={shipperBarangay}
                      disabled={selectedShipperCity === null}
                      placeholder="Select barangay"
                    />)}
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Form>
          <Spacer />
          <Form>
            <Card title="Package Information">
              <Row>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Package Size">
                    {getFieldDecorator('package_size', {
                      rules: [{ required: true, message: 'Package Size cannot be blank' }],
                    })(<Select options={packageSizeOptions} placeholder="Select package size" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Payment Type">
                    {getFieldDecorator('payment_type', {
                      rules: [{ required: true, message: 'Payment Type cannot be blank' }],
                    })(<Select options={paymentTypeOptions} placeholder="Select payment type" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Amount to be Collected">
                    {getFieldDecorator('total_price', {
                      rules: [{ required: true, message: 'Amount to be Collected cannot be blank' }],
                    })(<InputNumber min={0} style={{ width: '100%' }} placeholder="Enter amount to be collected" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Declared Value">
                    {getFieldDecorator('declared_value', {
                      rules: [{ required: true, message: 'Declared Value cannot be blank' }],
                    })(<InputNumber min={0} style={{ width: '100%' }} placeholder="Enter declared value" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Length">
                    {getFieldDecorator('length', {
                      rules: [{ required: true, message: 'Length cannot be blank' }],
                    })(<InputNumber min={0} placeholder="Enter length" style={{ width: '100%' }} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Width">
                    {getFieldDecorator('width', {
                      rules: [{ required: true, message: 'Width cannot be blank' }],
                    })(<InputNumber min={0} placeholder="Enter width" style={{ width: '100%' }} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Height">
                    {getFieldDecorator('height', {
                      rules: [{ required: true, message: 'Height cannot be blank' }],
                    })(<InputNumber min={0} placeholder="Enter height" style={{ width: '100%' }} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Weight">
                    {getFieldDecorator('weight', {
                      rules: [{ required: true, message: 'Weight cannot be blank' }],
                    })(<InputNumber min={0} placeholder="Enter weight" style={{ width: '100%' }} />)}
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Form>
          <Spacer />
          <Form>
            <Card title="Item Summary">
              <Row>
                <Col xs={24} sm={6} lg={6}>
                  <Form.Item label="Reference">
                    {getFieldDecorator('reference', {
                      rules: [{ required: true, message: 'Reference cannot be blank' }],
                    })(<Input placeholder="Enter reference" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6} lg={6}>
                  <Form.Item label="Description">
                    {getFieldDecorator('description', {
                      rules: [{ required: true, message: 'Description cannot be blank' }],
                    })(<Input placeholder="Enter description" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6} lg={6}>
                  <Form.Item label="Quantity">
                    {getFieldDecorator('quantity', {
                      rules: [{ required: true, message: 'Quantity cannot be blank' }],
                    })(<InputNumber min={0} placeholder="Enter quantity" style={{ width: '100%' }} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6} lg={6}>
                  <Form.Item label="Type">
                    {getFieldDecorator('type', {
                      rules: [{ required: false, message: 'Type cannot be blank' }],
                    })(<Input placeholder="Enter type" autoComplete="off" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Length">
                    {getFieldDecorator('itemLength', {
                      rules: [{ required: false, message: 'Length cannot be blank' }],
                    })(<InputNumber min={0} placeholder="Enter length" style={{ width: '100%' }} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Width">
                    {getFieldDecorator('itemWidth', {
                      rules: [{ required: false, message: 'Width cannot be blank' }],
                    })(<InputNumber min={0} placeholder="Enter width" style={{ width: '100%' }} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Height">
                    {getFieldDecorator('itemHeight', {
                      rules: [{ required: false, message: 'Height cannot be blank' }],
                    })(<InputNumber min={0} placeholder="Enter height" style={{ width: '100%' }} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Weight">
                    {getFieldDecorator('itemWeight', {
                      rules: [{ required: false, message: 'Weight cannot be blank' }],
                    })(<InputNumber min={0} placeholder="Enter weight" style={{ width: '100%' }} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={6} lg={6}>
                  <Form.Item label="UOM">
                    {getFieldDecorator('uom', {
                      rules: [{ required: false, message: 'UOM cannot be blank' }],
                    })(<Input placeholder="Enter uom" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={18} lg={18}>
                  <Form.Item label="Comment">
                    {getFieldDecorator('comment', {
                      rules: [{ required: false, message: 'Comment cannot be blank' }],
                    })(<Input placeholder="Enter comment" autoComplete="off" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Button
                  type="primary"
                  block
                  onClick={this.validateItem}
                >
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faPlus} fixedWidth />
                  </Icon>
                  Add Item
                </Button>
              </Row>
            </Card>
          </Form>
          <Spacer />
          <Form>
            <Card title="Item List">
              <ReactTable
                data={itemRows}
                columns={[
                  {
                    Header: 'ID',
                    accessor: 'fieldId',
                    show: false,
                  },
                  {
                    Header: 'Reference',
                    accessor: 'reference',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Description',
                    accessor: 'description',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Quantity',
                    accessor: 'quantity',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'UOM',
                    accessor: 'uom',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Type',
                    accessor: 'type',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Length',
                    accessor: 'length',
                    Cell: this.onEdit,
                  },

                  {
                    Header: 'Width',
                    accessor: 'width',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Height',
                    accessor: 'height',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Weight',
                    accessor: 'weight',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Comment',
                    accessor: 'comment',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Options',
                    filterable: false,
                    sortable: false,
                    width: 200,
                    Cell: data => (
                      <React.Fragment>

                        { editedItem === data.original.fieldId ? (
                          <Row>
                            <Col xs={24} sm={12} lg={12}>
                              <Button type="link" onClick={this.updateRow} block>
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faEdit} fixedWidth />
                                </Icon>
                                Update
                              </Button>
                            </Col>
                            <Col xs={24} sm={12} lg={12}>
                              <Button type="link" onClick={this.cancelEdit} block>
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faTrash} fixedWidth />
                                </Icon>
                                  Cancel
                              </Button>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col xs={24} sm={12} lg={12}>
                              <Button type="link" onClick={() => this.setToEdit(data.original.fieldId)} block>
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faEdit} fixedWidth />
                                </Icon>
                                Edit
                              </Button>
                            </Col>
                            <Col xs={24} sm={12} lg={12}>
                              <Popconfirm
                                placement="leftBottom"
                                title={`Are you sure you want to delete ${data.original.fieldId} from the list?`}
                                okText="Yes"
                                onConfirm={() => this.confirmDelete(data.original.fieldId)}
                                cancelText="No"
                              >
                                <Button type="link" block>
                                  <Icon viewBox="0 0 1024 1024">
                                    <FontAwesomeIcon icon={faTrash} fixedWidth />
                                  </Icon>
                                Remove
                                </Button>
                              </Popconfirm>
                            </Col>
                          </Row>
                        ) }

                      </React.Fragment>
                    ),
                  },
                ]}
              />
            </Card>
          </Form>
          <Spacer />
          <Button
            type="primary"
            onClick={this.formSubmit}
            block
            disabled={isSubmitting}
          >
            <Icon viewBox="0 0 1024 1024">
              <FontAwesomeIcon icon={faPlus} fixedWidth />
            </Icon>
                  Create Waybill
          </Button>

        </Container>
      </div>
    );
  }
}

WaybillCreate.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchOptions: PropTypes.func.isRequired,
  doFetchOptionsLike: PropTypes.func.isRequired,
  doCreateWaybill: PropTypes.func.isRequired,
  doFetchPcLocationTypeRequest: PropTypes.func.isRequired,
  doFetchLocationTypeLikeRequestWhereIn: PropTypes.func.isRequired,
  doFetchShipperDetails: PropTypes.func.isRequired,
  doFetchShipperOptionsLike: PropTypes.func.isRequired,
  doFetchShipperOptions: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchOptions: fetchOptions,
  doFetchOptionsLike: fetchOptionsLike,
  doCreateWaybill: createWaybill,
  doFetchPcLocationTypeRequest: fetchPcLocationTypeRequest,
  doFetchLocationTypeLikeRequestWhereIn: fetchLocationTypeLikeRequestWhereIn,
  doFetchShipperDetails: fetchShipperDetails,
  doFetchShipperOptionsLike: fetchShipperOptionsLike,
  doFetchShipperOptions: fetchShipperOptions,
};

const WrappedWaybillCreate = Form.create({ name: 'WaybillCreate' })(WaybillCreate);

export default connect(null, mapDispatchToProps)(WrappedWaybillCreate);
