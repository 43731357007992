import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button, Icon, Col, Form, Upload, message, Steps, Divider, Checkbox, Spin, Tooltip, Alert, Switch,
} from 'antd';
import fileDownload from 'js-file-download';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInbox, faFileUpload } from '@fortawesome/free-solid-svg-icons';

import { isObject } from 'lodash';
import Row from '../../components/Row';
import Card from '../../components/Card';
import Select from '../../components/Select';
import Spacer from '../../components/Spacer';
import Container from '../../components/Container';
import PageHeader from '../../components/PageHeader';
import ReactTable from '../../components/ReactTable';
import OldWaybillUpload from './OldWaybillUpload';
import { fetchOptionsLike, fetchOptions } from '../ducks';
import {
  uploadWaybill, createWaybillUploadTemplate, validateWaybillUpload, oldUploadWaybill,
  exportWaybill,
} from './newDucks';

export class WaybillUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        client: [],
        branch_code: [],
      },
      fileList: [],
      currentStep: 0,
      loadingMessage: null,
      isLoading: false,
      isExporting: false,
      selectedClientId: null,
      selectedBranchCode: null,
      uploadValidationResult: {
        newPackages: [],
        failedPackages: [],
        reference: null,
      },
      uploadResult: {
        success: [],
        failed: [],
      },
      uploadVersion: 'new',
      isMerchantSelected: false,
    };

    this.tooltips = {
      tracking_number: 'The shipment\'s unique waybill number used for tracking',
      package_id: 'The unique identifier for the shipment usually filled up with order ID',
      order_no: 'The shipment\'s order number',
      serial_number: 'The shipment\'s serial number',
      asset_number: 'The shipment\'s asset number',
      group_id: 'The unique identifier for the group/couple of the shipment used mainly for multi-part packages',
      payment_type: 'The shipment\'s identifier for collection method. Use "NONCOD" for packages not requiring payment from consignee and "COD" for packages with amount to be collected',
      total_price: 'The amount to be collected from consignee during delivery. User 0 if nothing will be collected',
      declared_value: 'The cost of goods sold',
      package_size: 'The named size of the package. E.g. Pouch, Small Box, Large Box',
      package_total_quantity: 'The sum of quantity of all items in the shipment',
      package_length: 'The total length of the shipment',
      package_width: 'The total width of the shipment',
      package_height: 'The total height of the shipment',
      package_weight: 'The total weight of the shipment',
      package_type: 'The type of order availed for the package. Use "Sales Order" if not using specific order',
      package_remarks: 'The additional instructions for the shipment',
      delivery_type: 'The type of service availed for the package. Use "Standard" if not using specific service',
      shipping_type: 'The availed milestone of the shipment. Use "Marketplace" for packages that will be picked from the merchant, "Warehouse" for packages that will be picked from the client\'s warehouse',
      journey_type: 'The indicator of what journey should the shipment undertake. Use "Last Mile" for standard delivery service, "First Mile" for merchant pickup and delivery to the crossdock, "Mixed" for end-to-end delivery service',
      item_reference: 'The shipment\'s item entry reference',
      item_description: 'The shipment\'s item entry description',
      item_quantity: 'The shipment\'s item entry quantity',
      item_uom: 'The shipment\'s item entry unit of measurement',
      item_length: 'The shipment\'s item entry length',
      item_width: 'The shipment\'s item entry width',
      item_height: 'The shipment\'s item entry height',
      item_weight: 'The shipment\'s item entry weight',
      item_value: 'The shipment\'s item entry value',
      item_volume: 'The shipment\'s item entry volume',
      item_type: 'The shipment\'s item entry type',
      item_remarks: 'The shipment\'s item entry remarks',
      consignee_name: 'The shipment\'s consignee',
      consignee_mobile_number: 'The shipment\'s consignee\'s mobile number',
      consignee_email_address: 'The shipment\'s consignee\'s e-mail address',
      consignee_full_address: 'The shipment\'s consignee\'s full shipping address',
      consignee_province: 'The shipment\'s consignee\'s shipping address province',
      consignee_city: 'The shipment\'s consignee\'s shipping address city',
      consignee_barangay: 'The shipment\'s consignee\'s shipping address barangay',
      consignee_building_type: 'The shipment\'s consignee\'s shipping address building type. Use "Commercial" or "Residential" to help optimize the route',
      consignee_coordinate: 'The shipment\'s consignee\'s address map coordinate. Use decimal degrees (DD): 14.481198, 121.050357',
      merchant_name: 'The merchant\'s name. Use if the package has to be picked from the merchant first',
      merchant_full_address: 'The merchant\'s full address. Use if the package has to be picked from the merchant first',
      merchant_mobile_number: 'The merchant\'s mobile number. Use if the package has to be picked from the merchant first',
      merchant_email_address: 'The merchant\'s e-mail address. Use if the package has to be picked from the merchant first',
      merchant_province: 'The merchant\'s province. Use if the package has to be picked from the merchant first',
      merchant_city: 'The merchant\'s city. Use if the package has to be picked from the merchant first',
      merchant_barangay: 'The merchant\'s barangay. Use if the package has to be picked from the merchant first',
    };

    this.fieldValidations = {
      tracking_number: true,
      package_id: true,
      order_no: false,
      serial_number: false,
      asset_number: false,
      group_id: false,
      payment_type: true,
      total_price: false,
      declared_value: true,
      package_size: false,
      package_total_quantity: true,
      package_length: true,
      package_width: true,
      package_height: true,
      package_weight: true,
      package_type: false,
      package_remarks: false,
      delivery_type: true,
      shipping_type: false,
      journey_type: false,
      item_reference: true,
      item_description: true,
      item_quantity: false,
      item_uom: false,
      item_length: false,
      item_width: false,
      item_height: false,
      item_weight: false,
      item_value: false,
      item_volume: false,
      item_type: false,
      item_remarks: false,
      consignee_name: true,
      consignee_mobile_number: true,
      consignee_email_address: false,
      consignee_full_address: true,
      consignee_province: true,
      consignee_city: true,
      consignee_barangay: true,
      consignee_building_type: false,
      consignee_coordinate: false,
      merchant_name: true,
      merchant_full_address: true,
      merchant_mobile_number: true,
      merchant_email_address: false,
      merchant_province: false,
      merchant_city: false,
      merchant_barangay: false,
    };

    this.searchOption = this.searchOption.bind(this);
    this.beforeUploadHandler = this.beforeUploadHandler.bind(this);
    this.removeUploadHandler = this.removeUploadHandler.bind(this);
    this.validateUploadFile = this.validateUploadFile.bind(this);
    this.createUploadTemplate = this.createUploadTemplate.bind(this);
    this.parsePackageItems = this.parsePackageItems.bind(this);
    this.submitUpload = this.submitUpload.bind(this);
    this.exportResult = this.exportResult.bind(this);
    this.selectMerchantHandler = this.selectMerchantHandler.bind(this);
  }

  componentDidMount() {
    const { doFetchOptions } = this.props;

    const selectOptions = [
      'branch_code', 'client',
    ];

    Object.keys(selectOptions).forEach((key) => {
      doFetchOptions(selectOptions[key]).then((action) => {
        this.setState(prevState => ({
          options: {
            ...prevState.options,
            [selectOptions[key]]: action.payload.data.slice(0, 50),
          },
        }));
      });
    });
  }

  searchOption(type, e) {
    const { doFetchOptionsLike } = this.props;

    doFetchOptionsLike(type, e).then((action) => {
      this.setState(prevState => ({
        options: {
          ...prevState.options,
          [type]: action.payload.data.slice(0, 50),
        },
      }));
    });
  }

  beforeUploadHandler(file) {
    const { fileList } = this.state;

    if (fileList.length === 1) {
      message.error('Cannot upload more than 1 file!');
      return false;
    }
    this.setState(state => ({
      fileList: [...state.fileList, file],
    }));
    return false;
  }

  // eslint-disable-next-line no-unused-vars
  removeUploadHandler(file) {
    this.setState({
      fileList: [],
    });

    // Multiple remove entries
    //
    // this.setState(state => {
    //     const index = state.fileList.indexOf(file);
    //     const newFileList = state.fileList.slice();
    //     newFileList.splice(index, 1);
    //     return {
    //       fileList: newFileList,
    //     };
    //   });
  }

  validateUploadFile(e) {
    e.preventDefault();
    const { fileList } = this.state;
    const { form, doValidateWaybillUpload } = this.props;
    const { validateFields } = form;

    validateFields((err, values) => {
      if (!err) {
        if (fileList.length > 0) {
          this.setState({ isLoading: true, loadingMessage: 'Validating upload file . . .' });
          const formData = new FormData();
          fileList.forEach((file) => {
            formData.append('file', file);
          });
          formData.append('client_id', values.client_id);
          formData.append('strict', values.strict);
          formData.append('branch_code', values.branch_code);
          doValidateWaybillUpload(formData).then((res) => {
            this.setState({
              fileList: [],
              currentStep: 2,
              isLoading: false,
              uploadValidationResult: {
                newPackages: res.payload.data.success,
                failedPackages: res.payload.data.failed,
                reference: res.payload.data.reference,
              },
              selectedClientId: values.client_id,
              selectedBranchCode: values.branch_code,
            });
            form.resetFields();
          });
        } else {
          message.error('Please select a file for uploading');
        }
      }
    });
  }

  createUploadTemplate(e) {
    e.preventDefault();
    const { doCreateWaybillUploadTemplate, form } = this.props;
    const { validateFields } = form;
    validateFields((err, values) => {
      const keys = Object.keys(values);
      if (!err) {
        this.setState({
          isLoading: true,
          loadingMessage: 'Generating template...',
        });
        const selectedColumns = {};
        keys.forEach((key) => {
          if (values[key]) {
            selectedColumns[key] = this.fieldValidations[key];
          }
        });

        doCreateWaybillUploadTemplate(selectedColumns).then((res) => {
          const file = new Blob([res.payload.data]);
          fileDownload(file, 'Waybill-Upload-Template.xlsx');
          this.setState({
            isLoading: false,
            currentStep: 1,
          });
          window.scrollTo(0, 0);
        });
      }
    });
  }

  parsePackageItems() {
    const { uploadValidationResult } = this.state;
    const packages = uploadValidationResult.newPackages;
    const { reference } = uploadValidationResult;
    const parsedPackages = [];

    // insert items array for each package then merge packages that has saame package_id / reference
    packages.forEach((details) => {
      if (reference === 'tracking_number') {
        if (parsedPackages.some(packageDetails => packageDetails.package.tracking_number === details.package.tracking_number)) {
          const index = parsedPackages.findIndex(packageDetails => packageDetails.package.tracking_number === details.package.tracking_number);
          parsedPackages[index].items = [...parsedPackages[index].items, details.item];
        } else {
          const initialItem = details.item;
          // eslint-disable-next-line no-param-reassign
          delete details.item;
          parsedPackages.push({
            ...details,
            items: [initialItem],
          });
        }
      }

      if (reference === 'package_id') {
        if (parsedPackages.some(packageDetails => packageDetails.package.id === details.package.id
          && packageDetails.consignee.full_address === details.consignee.full_address
          && packageDetails.consignee.name === details.consignee.name)) {
          const index = parsedPackages.findIndex(packageDetails => packageDetails.package.id === details.package.id
            && packageDetails.consignee.full_address === details.consignee.full_address
            && packageDetails.consignee.name === details.consignee.name);
          parsedPackages[index].items = [...parsedPackages[index].items, details.item];
        } else {
          const initialItem = details.item;
          // eslint-disable-next-line no-param-reassign
          delete details.item;
          parsedPackages.push({
            ...details,
            items: [initialItem],
          });
        }
      }
    });
    return parsedPackages;
  }

  submitUpload() {
    const { doUploadWaybill } = this.props;
    const { uploadValidationResult, selectedClientId, selectedBranchCode } = this.state;

    if (uploadValidationResult.newPackages.length) {
      const payload = {
        clientId: selectedClientId,
        branchCode: selectedBranchCode,
        newPackages: this.parsePackageItems(),
        reference: uploadValidationResult.reference,
      };
      this.setState({ isLoading: true, loadingMessage: 'Submitting data...' });
      doUploadWaybill(payload).then((res) => {
        this.setState({
          isLoading: false,
          currentStep: 3,
          uploadResult: res.payload.data,
        });
      });
    } else {
      message.error('No data to upload.');
    }
  }

  exportResult() {
    const { doExportResult } = this.props;
    const { uploadResult } = this.state;
    const trackingNumbers = uploadResult.success.map(details => `${details.tracking_number}`);

    const payload = {
      filters: { tracking_number: trackingNumbers },
      selected_columns: [
        'tracking_number',
        'package_id',
        'item_id',
        'package_description',
        'declared_value',
        'total_price',
        'consignee_name',
        'consignee_province',
        'consignee_city',
      ],
    };
    if (trackingNumbers.length) {
      this.setState({ isExporting: true });
      doExportResult(payload).then((res) => {
        this.setState({ isExporting: false });
        const file = new Blob([res.payload.data]);
        fileDownload(file, 'Upload-result.xlsx');
      });
    } else {
      message.error('No data to export.');
    }
  }

  selectMerchantHandler(e) {
    const { form } = this.props;
    const { setFieldsValue } = form;
    setFieldsValue({
      merchant_name: e.target.checked,
      merchant_full_address: e.target.checked,
      merchant_mobile_number: e.target.checked,
    });
    this.setState({ isMerchantSelected: e.target.checked });
  }

  render() {
    const {
      options,
      fileList,
      currentStep,
      isLoading,
      loadingMessage,
      uploadValidationResult,
      uploadVersion,
      uploadResult,
      isExporting,
      isMerchantSelected,
    } = this.state;
    const {
      form, doOldUploadWaybill,
    } = this.props;
    const { getFieldDecorator } = form;
    const { Dragger } = Upload;
    const { Step } = Steps;

    const breadCrumbs = [
      {
        breadcrumbName: 'Waybill',
      },
      {
        breadcrumbName: 'Upload',
      },
    ];

    const inputLayout = {
      labelCol: { span: 17 },
      wrapperCol: { span: 7 },
    };

    const checkBoxStyle = {
      margin: 0,
    };

    return (
      <div className="WaybillUpload">
        <PageHeader title="Waybill" routes={breadCrumbs} />
        <Spacer />
        <Alert
          message="Upload File Incompatibility"
          description="Old CSV template files are not supported in the new upload module. Create a new template below to utilize the module. Switch to old version if using old upload file."
          type="warning"
          showIcon
          closable
        />
        <Spacer />
        <Spin spinning={isLoading} tip={loadingMessage}>
          <Container>
            {currentStep === 0 ? (
              <Card>
                <Row>
                  { uploadVersion === 'new' ? (
                    <React.Fragment>
                      <Col sm={24} lg={12}>
                        <Button type="primary" block onClick={() => this.setState({ currentStep: 1 })}>Skip template builder</Button>
                      </Col>
                      <Col sm={24} lg={12}>
                        <Button type="danger" block onClick={() => this.setState({ uploadVersion: 'old' })}>Switch to old version</Button>
                      </Col>
                    </React.Fragment>
                  ) : (
                    <Col>
                      <Button type="primary" block onClick={() => this.setState({ uploadVersion: 'new' })}>Switch to new version</Button>
                    </Col>
                  )}
                </Row>
              </Card>
            ) : null}
            <Spacer />
            {uploadVersion === 'new' ? (
              <React.Fragment>
                <Card>
                  <Steps current={currentStep}>
                    <Step title="Build Template" />
                    <Step title="Select File" />
                    <Step title="File Preview" />
                    <Step title="Result" />
                  </Steps>
                </Card>
                <Spacer />
                { currentStep === 0 ? (
                  <Card>
                    <Form onSubmit={this.createUploadTemplate}>
                      <Divider orientation="left"><h3>Package</h3></Divider>
                      <Row>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={(
                              <Tooltip title={this.tooltips.tracking_number}>
                                Tracking number
                              </Tooltip>
                            )}
                          >
                            {getFieldDecorator('tracking_number', {
                              initialValue: false,
                            })(<Checkbox
                              defaultChecked={false}
                              disabled={false}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.order_no}>Order Number</Tooltip>
                        }
                          >
                            {getFieldDecorator('order_no', {
                              initialValue: this.fieldValidations.order_no,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.order_no}
                              disabled={this.fieldValidations.order_no}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.package_id}>Package ID</Tooltip>
                        }
                          >
                            {getFieldDecorator('package_id', {
                              initialValue: this.fieldValidations.package_id,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.package_id}
                              disabled={this.fieldValidations.package_id}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.serial_number}>Serial Number</Tooltip>
                        }
                          >
                            {getFieldDecorator('serial_number', {
                              initialValue: this.fieldValidations.serial_number,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.serial_number}
                              disabled={this.fieldValidations.serial_number}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.asset_number}>Asset Number</Tooltip>
                        }
                          >
                            {getFieldDecorator('asset_number', {
                              initialValue: this.fieldValidations.asset_number,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.asset_number}
                              disabled={this.fieldValidations.asset_number}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.group_id}>Group ID</Tooltip>
                        }
                          >
                            {getFieldDecorator('group_id', {
                              initialValue: this.fieldValidations.group_id,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.group_id}
                              disabled={this.fieldValidations.group_id}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.payment_type}>Payment Type</Tooltip>
                        }
                          >
                            {getFieldDecorator('payment_type', {
                              initialValue: this.fieldValidations.payment_type,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.payment_type}
                              disabled={this.fieldValidations.payment_type}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.total_price}>Total Price</Tooltip>
                        }
                          >
                            {getFieldDecorator('total_price', {
                              // initialValue: this.fieldValidations.total_price,
                              initialValue: true,
                            })(<Checkbox
                              // defaultChecked={this.fieldValidations.total_price}
                              checked
                              disabled={this.fieldValidations.total_price}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.declared_value}>Declared Value</Tooltip>
                        }
                          >
                            {getFieldDecorator('declared_value', {
                              initialValue: this.fieldValidations.declared_value,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.declared_value}
                              disabled={this.fieldValidations.declared_value}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.package_size}>Size</Tooltip>
                        }
                          >
                            {getFieldDecorator('package_size', {
                              // initialValue: this.fieldValidations.package_size,
                              initialValue: true,
                            })(<Checkbox
                              // defaultChecked={this.fieldValidations.package_size}
                              checked
                              disabled={this.fieldValidations.package_size}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={(
                              <Tooltip title={this.tooltips.package_total_quantity}>
                            Total Quantity
                              </Tooltip>
                        )}
                          >
                            {getFieldDecorator('package_total_quantity', {
                              initialValue: this.fieldValidations.package_total_quantity,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.package_total_quantity}
                              disabled={this.fieldValidations.package_total_quantity}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.package_length}>Length</Tooltip>
                        }
                          >
                            {getFieldDecorator('package_length', {
                              initialValue: this.fieldValidations.package_length,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.package_length}
                              disabled={this.fieldValidations.package_length}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.package_width}>Width</Tooltip>
                        }
                          >
                            {getFieldDecorator('package_width', {
                              initialValue: this.fieldValidations.package_width,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.package_width}
                              disabled={this.fieldValidations.package_width}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.package_height}>Height</Tooltip>
                        }
                          >
                            {getFieldDecorator('package_height', {
                              initialValue: this.fieldValidations.package_height,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.package_height}
                              disabled={this.fieldValidations.package_height}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.package_weight}>Weight</Tooltip>
                        }
                          >
                            {getFieldDecorator('package_weight', {
                              initialValue: this.fieldValidations.package_weight,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.package_weight}
                              disabled={this.fieldValidations.package_weight}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.package_type}>Package Type</Tooltip>
                        }
                          >
                            {getFieldDecorator('package_type', {
                              initialValue: this.fieldValidations.package_type,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.package_type}
                              disabled={this.fieldValidations.package_type}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.delivery_type}>Delivery Type</Tooltip>
                        }
                          >
                            {getFieldDecorator('delivery_type', {
                              initialValue: this.fieldValidations.delivery_type,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.delivery_type}
                              disabled={this.fieldValidations.delivery_type}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.package_remarks}>Remarks</Tooltip>
                        }
                          >
                            {getFieldDecorator('package_remarks', {
                              initialValue: this.fieldValidations.package_remarks,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.package_remarks}
                              disabled={this.fieldValidations.package_remarks}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.shipping_type}>Shipping Type</Tooltip>
                        }
                          >
                            {getFieldDecorator('shipping_type', {
                              initialValue: this.fieldValidations.shipping_type,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.shipping_type}
                              disabled={this.fieldValidations.shipping_type}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.journey_type}>Journey Type</Tooltip>
                        }
                          >
                            {getFieldDecorator('journey_type', {
                              initialValue: this.fieldValidations.journey_type,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.journey_type}
                              disabled={this.fieldValidations.journey_type}
                            />)}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider orientation="left"><h3>Items</h3></Divider>
                      <Alert
                        message="Packages with multiple items"
                        description={(
                          <React.Fragment>
                            <p>
                              If a package (single tracking number or package ID)
                              will contain multiple item,
                              encode each item in new line with same package details.
                            </p>
                            <Button type="primary" href={`${process.env.REACT_APP_API_URL}/templates/multi_item_demo.xlsx`}>Download Example</Button>
                          </React.Fragment>
                        )}
                        type="info"
                        showIcon
                      />
                      <Row>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.item_reference}>Reference</Tooltip>
                        }
                          >
                            {getFieldDecorator('item_reference', {
                              initialValue: this.fieldValidations.item_reference,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.item_reference}
                              disabled={this.fieldValidations.item_reference}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.item_description}>Description</Tooltip>
                        }
                          >
                            {getFieldDecorator('item_description', {
                              initialValue: this.fieldValidations.item_description,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.item_description}
                              disabled={this.fieldValidations.item_description}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.item_quantity}>Quantity</Tooltip>
                        }
                          >
                            {getFieldDecorator('item_quantity', {
                              initialValue: this.fieldValidations.item_quantity,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.item_quantity}
                              disabled={this.fieldValidations.item_quantity}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.item_uom}>UOM</Tooltip>
                        }
                          >
                            {getFieldDecorator('item_uom', {
                              initialValue: this.fieldValidations.item_uom,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.item_uom}
                              disabled={this.fieldValidations.item_uom}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.item_length}>Length</Tooltip>
                        }
                          >
                            {getFieldDecorator('item_length', {
                              initialValue: this.fieldValidations.item_length,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.item_length}
                              disabled={this.fieldValidations.item_length}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.item_width}>Width</Tooltip>
                        }
                          >
                            {getFieldDecorator('item_width', {
                              initialValue: this.fieldValidations.item_width,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.item_width}
                              disabled={this.fieldValidations.item_width}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.item_height}>Height</Tooltip>
                        }
                          >
                            {getFieldDecorator('item_height', {
                              initialValue: this.fieldValidations.item_height,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.item_height}
                              disabled={this.fieldValidations.item_height}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.item_weight}>Weight</Tooltip>
                        }
                          >
                            {getFieldDecorator('item_weight', {
                              initialValue: this.fieldValidations.item_weight,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.item_weight}
                              disabled={this.fieldValidations.item_weight}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.item_value}>Value</Tooltip>
                        }
                          >
                            {getFieldDecorator('item_value', {
                              initialValue: this.fieldValidations.item_value,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.item_value}
                              disabled={this.fieldValidations.item_value}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.item_volume}>Volume</Tooltip>
                        }
                          >
                            {getFieldDecorator('item_volume', {
                              initialValue: this.fieldValidations.item_volume,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.item_volume}
                              disabled={this.fieldValidations.item_volume}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.item_type}>Type</Tooltip>
                        }
                          >
                            {getFieldDecorator('item_type', {
                              initialValue: this.fieldValidations.item_type,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.item_type}
                              disabled={this.fieldValidations.item_type}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.item_remarks}>Remarks</Tooltip>
                        }
                          >
                            {getFieldDecorator('item_remarks', {
                              initialValue: this.fieldValidations.item_remarks,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.item_remarks}
                              disabled={this.fieldValidations.item_remarks}
                            />)}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider orientation="left"><h3>Consignee</h3></Divider>
                      <Row>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.consignee_name}>Name</Tooltip>
                        }
                          >
                            {getFieldDecorator('consignee_name', {
                              initialValue: this.fieldValidations.consignee_name,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.consignee_name}
                              disabled={this.fieldValidations.consignee_name}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={(
                              <Tooltip title={this.tooltips.consignee_mobile_number}>
                            Mobile Number
                              </Tooltip>
                        )}
                          >
                            {getFieldDecorator('consignee_mobile_number', {
                              initialValue: this.fieldValidations.consignee_mobile_number,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.consignee_mobile_number}
                              disabled={this.fieldValidations.consignee_mobile_number}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={(
                              <Tooltip title={this.tooltips.consignee_email_address}>
                            Email Address
                              </Tooltip>
                        )}
                          >
                            {getFieldDecorator('consignee_email_address', {
                              initialValue: this.fieldValidations.consignee_email_address,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.consignee_email_address}
                              disabled={this.fieldValidations.consignee_email_address}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={(
                              <Tooltip title={this.tooltips.consignee_full_address}>
                            Full Address
                              </Tooltip>
                        )}
                          >
                            {getFieldDecorator('consignee_full_address', {
                              initialValue: this.fieldValidations.consignee_full_address,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.consignee_full_address}
                              disabled={this.fieldValidations.consignee_full_address}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.consignee_province}>Province</Tooltip>
                        }
                          >
                            {getFieldDecorator('consignee_province', {
                              initialValue: this.fieldValidations.consignee_province,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.consignee_province}
                              disabled={this.fieldValidations.consignee_province}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.consignee_city}>City</Tooltip>
                        }
                          >
                            {getFieldDecorator('consignee_city', {
                              initialValue: this.fieldValidations.consignee_city,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.consignee_city}
                              disabled={this.fieldValidations.consignee_city}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.consignee_barangay}>Barangay</Tooltip>
                        }
                          >
                            {getFieldDecorator('consignee_barangay', {
                              initialValue: this.fieldValidations.consignee_barangay,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.consignee_barangay}
                              disabled={this.fieldValidations.consignee_barangay}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={(
                              <Tooltip title={this.tooltips.consignee_building_type}>
                            Building Type
                              </Tooltip>
                        )}
                          >
                            {getFieldDecorator('consignee_building_type', {
                              initialValue: this.fieldValidations.consignee_building_type,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.consignee_building_type}
                              disabled={this.fieldValidations.consignee_building_type}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={(
                              <Tooltip title={this.tooltips.consignee_coordinate}>
                                Coordinate
                              </Tooltip>
                            )}
                          >
                            {getFieldDecorator('consignee_coordinate', {
                              initialValue: this.fieldValidations.consignee_coordinate,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.consignee_coordinate}
                              disabled={this.fieldValidations.consignee_coordinate}
                            />)}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider orientation="left"><h3>Merchant</h3></Divider>
                      <Row>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={
                              <Tooltip title={this.tooltips.merchant_name}>Name</Tooltip>
                        }
                          >
                            {getFieldDecorator('merchant_name', {
                              initialValue: false,
                            })(<Checkbox
                              checked={isMerchantSelected}
                              disabled={false}
                              onChange={e => this.selectMerchantHandler(e)}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={(
                              <Tooltip title={this.tooltips.merchant_full_address}>
                            Full Address
                              </Tooltip>
                        )}
                          >
                            {getFieldDecorator('merchant_full_address', {
                              initialValue: false,
                            })(<Checkbox
                              checked={isMerchantSelected}
                              disabled={false}
                              onChange={e => this.selectMerchantHandler(e)}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={(
                              <Tooltip title={this.tooltips.merchant_mobile_number}>
                            Mobile Number
                              </Tooltip>
                        )}
                          >
                            {getFieldDecorator('merchant_mobile_number', {
                              initialValue: false,
                            })(<Checkbox
                              checked={isMerchantSelected}
                              disabled={false}
                              onChange={e => this.selectMerchantHandler(e)}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={(
                              <Tooltip title={this.tooltips.merchant_email_address}>
                            Email Address
                              </Tooltip>
                        )}
                          >
                            {getFieldDecorator('merchant_email_address', {
                              initialValue: this.fieldValidations.merchant_email_address,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.merchant_email_address}
                              disabled={this.fieldValidations.merchant_email_address}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={(
                              <Tooltip title={this.tooltips.merchant_province}>
                            Province
                              </Tooltip>
                        )}
                          >
                            {getFieldDecorator('merchant_province', {
                              initialValue: this.fieldValidations.merchant_province,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.merchant_province}
                              disabled={this.fieldValidations.merchant_province}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={(
                              <Tooltip title={this.tooltips.merchant_city}>
                            City
                              </Tooltip>
                        )}
                          >
                            {getFieldDecorator('merchant_city', {
                              initialValue: this.fieldValidations.merchant_city,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.merchant_city}
                              disabled={this.fieldValidations.merchant_city}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col sm={12} lg={6}>
                          <Form.Item
                            style={checkBoxStyle}
                            {...inputLayout}
                            labelAlign="right"
                            label={(
                              <Tooltip title={this.tooltips.merchant_barangay}>
                            Barangay
                              </Tooltip>
                        )}
                          >
                            {getFieldDecorator('merchant_barangay', {
                              initialValue: this.fieldValidations.merchant_barangay,
                            })(<Checkbox
                              defaultChecked={this.fieldValidations.merchant_barangay}
                              disabled={this.fieldValidations.merchant_barangay}
                            />)}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Spacer />
                      <Row>
                        <Col style={{ textAlign: 'right' }}>
                          <Button
                            type="primary"
                            style={{ marginRight: '5px' }}
                            onClick={() => {
                              this.setState({ currentStep: 1 });
                              window.scrollTo(0, 0);
                            }
                          }
                          >
Skip

                          </Button>
                          <Button htmlType="submit" type="primary">Create Template</Button>
                        </Col>
                      </Row>
                    </Form>
                  </Card>
                ) : null}
                { currentStep === 1 ? (
                  <Card>
                    <Form encType="multipart/form-data" onSubmit={this.validateUploadFile}>
                      <Row>
                        <Col xs={24} sm={24} lg={12}>
                          <Form.Item label="Strict Address Validation" wrapperCol={{ span: 3 }} labelAlign="left">
                            {getFieldDecorator('strict', {
                              initialValue: true,
                            })(<Switch defaultChecked />)}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} sm={24} lg={12}>
                          <Form.Item label="Client">
                            {getFieldDecorator('client_id', {
                              rules: [{ required: true, message: 'Client cannot be blank' }],
                            })(<Select
                              allowClear
                              options={options.client}
                              onSearch={e => this.searchOption('client', e)}
                              filterOption={false}
                              placeholder="Type to search for client"
                              showSearch
                            />)}
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} lg={12}>
                          <Form.Item label="Branch Code">
                            {getFieldDecorator('branch_code', {
                              rules: [{ required: true, message: 'Branch code cannot be blank' }],
                            })(<Select
                              options={options.branch_code}
                              placeholder="Selet branch code"
                              allowClear
                              onSearch={e => this.searchOption('branch_code', e)}
                              filterOption={false}
                              showSearch
                            />)}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Dragger
                            fileList={fileList}
                            onRemove={this.removeUploadHandler}
                            beforeUpload={this.beforeUploadHandler}
                            accept=".xlsx,.csv"
                          >
                            <p className="ant-upload-drag-icon">
                              <Icon viewBox="0 0 1024 1024">
                                <FontAwesomeIcon icon={faInbox} fixedWidth />
                              </Icon>
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                            Note: single file upload of .csv or .xlsx is allowed
                            </p>
                          </Dragger>
                        </Col>
                      </Row>
                      <Spacer />
                      <Row>
                        <Col style={{ textAlign: 'right' }}>
                          <Button type="primary" style={{ marginRight: '5px' }} onClick={() => this.setState({ currentStep: 0 })}>Back</Button>
                          <Button type="primary" htmlType="submit">
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faFileUpload} fixedWidth />
                            </Icon>
                            Validate
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Card>
                ) : null }
                { currentStep === 2 ? (
                  <React.Fragment>
                    <Card title={`New Packages(${uploadValidationResult.newPackages.length})`}>
                      <ReactTable
                        data={uploadValidationResult.newPackages}
                        columns={[
                          {
                            Header: 'Tracking Number',
                            accessor: 'package.tracking_number',
                            filterable: true,
                            sortable: true,
                          },
                          {
                            Header: 'Item ID',
                            accessor: 'item.reference',
                            filterable: true,
                            sortable: true,
                          },
                          {
                            Header: 'Order Number',
                            accessor: 'package.order_no',
                            filterable: true,
                            sortable: true,
                          },
                          {
                            Header: 'Package ID',
                            accessor: 'package.id',
                            filterable: true,
                            sortable: true,
                          },
                          {
                            Header: 'Package Type',
                            accessor: 'package.package_type',
                            filterable: true,
                            sortable: true,
                          },
                          {
                            Header: 'Package Size',
                            accessor: 'package.package_size',
                            filterable: true,
                            sortable: true,
                          },
                          {
                            Header: 'Remarks',
                            accessor: 'package.remarks',
                            filterable: true,
                            sortable: true,
                          },
                        ]}
                      />
                    </Card>
                    <Spacer />
                    <Card title={`Failed(${uploadValidationResult.failedPackages.length})`}>
                      <ReactTable
                        data={uploadValidationResult.failedPackages}
                        columns={[
                          {
                            Header: 'Tracking Number',
                            accessor: 'tracking_number',
                            filterable: true,
                            sortable: true,
                          },
                          {
                            Header: 'Package ID',
                            accessor: 'package_id',
                            filterable: true,
                            sortable: true,
                          },
                          {
                            Header: 'Item Reference',
                            accessor: 'reference',
                            filterable: true,
                            sortable: true,
                          },
                          {
                            Header: 'Reason',
                            Cell: (data) => {
                              let errorMessage = data.original.message;
                              if (data.original.error && isObject(data.original.error)) {
                                const errorSections = Object.keys(data.original.error);
                                const errors = [];
                                errorSections.forEach((section) => {
                                  const errorFields = Object.keys(data.original.error[section]);
                                  errorFields.forEach((field) => {
                                    errors.push(data.original.error[section][field][0]);
                                  });
                                });
                                errorMessage = errors.join();
                              }
                              return errorMessage;
                            },
                          },
                        ]}
                      />
                      <Spacer />
                      <Row>
                        <Col style={{ textAlign: 'right' }}>
                          <Button type="primary" style={{ marginRight: '5px' }} onClick={() => this.setState({ currentStep: 1 })}>Back</Button>
                          <Button type="primary" onClick={() => this.submitUpload()}>
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faFileUpload} fixedWidth />
                            </Icon>
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </React.Fragment>
                ) : null }
                { currentStep === 3 ? (
                  <React.Fragment>
                    <Card
                      title={`Success(${uploadResult.success.length})`}
                      extra={(
                        <Button type="primary" onClick={() => this.exportResult()} disabled={isExporting}>
                          { isExporting ? 'Export . . .' : 'Export'}
                        </Button>
                      )}
                    >
                      <ReactTable
                        data={uploadResult.success}
                        columns={[
                          {
                            Header: 'Tracking Number',
                            accessor: 'tracking_number',
                            filterable: true,
                            sortable: true,
                          },
                          {
                            Header: 'Item ID',
                            accessor: 'item_id',
                            filterable: true,
                            sortable: true,
                          },
                          {
                            Header: 'Order Number',
                            accessor: 'order_no',
                            filterable: true,
                            sortable: true,
                          },
                          {
                            Header: 'Package ID',
                            accessor: 'package_id',
                            filterable: true,
                            sortable: true,
                          },
                          {
                            Header: 'Package Type',
                            accessor: 'package_type',
                            filterable: true,
                            sortable: true,
                          },
                          {
                            Header: 'Remarks',
                            accessor: 'remarks',
                            filterable: true,
                            sortable: true,
                          },
                        ]}
                      />
                    </Card>
                    <Spacer />
                    <Card title={`Failed(${uploadResult.failed.length})`}>
                      <ReactTable
                        data={uploadResult.failed}
                        columns={[
                          {
                            Header: 'Tracking Number',
                            accessor: 'tracking_number',
                            filterable: true,
                            sortable: true,
                          },
                          {
                            Header: 'Package ID',
                            accessor: 'package_id',
                            filterable: true,
                            sortable: true,
                          },
                          {
                            Header: 'Reason',
                            Cell: (data) => {
                              let errorMessage = data.original.message;
                              if (data.original.error) {
                                const errorSections = Object.keys(data.original.error);
                                const errors = [];
                                errorSections.forEach((section) => {
                                  const errorFields = Object.keys(data.original.error[section]);
                                  errorFields.forEach((field) => {
                                    errors.push(data.original.error[section][field][0]);
                                  });
                                });
                                errorMessage = errors.join();
                              }
                              return errorMessage;
                            },
                          },

                        ]}
                      />
                      <Spacer />
                      <Row>
                        <Col style={{ textAlign: 'right' }}>
                          <Button type="primary" style={{ marginRight: '5px' }} onClick={() => this.setState({ currentStep: 0 })}>Finish</Button>
                        </Col>
                      </Row>
                    </Card>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            ) : (
              <OldWaybillUpload
                clientOptions={options.client}
                branchCodeOptions={options.branch_code}
                searchOption={this.searchOption}
                doUploadWaybill={doOldUploadWaybill}
              />
            ) }
          </Container>
        </Spin>
      </div>
    );
  }
}

WaybillUpload.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchOptions: PropTypes.func.isRequired,
  doFetchOptionsLike: PropTypes.func.isRequired,
  doUploadWaybill: PropTypes.func.isRequired,
  doCreateWaybillUploadTemplate: PropTypes.func.isRequired,
  doValidateWaybillUpload: PropTypes.func.isRequired,
  doOldUploadWaybill: PropTypes.func.isRequired,
  doExportResult: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchOptions: fetchOptions,
  doFetchOptionsLike: fetchOptionsLike,
  doUploadWaybill: uploadWaybill,
  doCreateWaybillUploadTemplate: createWaybillUploadTemplate,
  doValidateWaybillUpload: validateWaybillUpload,
  doOldUploadWaybill: oldUploadWaybill,
  doExportResult: exportWaybill,
};

const WrappedWaybillUpload = Form.create({ name: 'WaybillUpload' })(WaybillUpload);

export default connect(null, mapDispatchToProps)(WrappedWaybillUpload);
