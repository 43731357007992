import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Descriptions, Spin } from 'antd';

import ReactTable from '../../components/ReactTable';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import Spacer from '../../components/Spacer';

import { billingViews } from '../ducks';

export class ViewBillingUploadDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadRes: {},
      loadSpin: false,
    };
  }

  componentDidMount() {
    this.setState({ loadSpin: true });
    const { doBillingViews, match } = this.props;
    doBillingViews('billing', match.params.id).then(res => this.setState({ uploadRes: res.payload.data, loadSpin: false }),);
  }

  render() {
    const { loadSpin } = this.state;
    const { uploadRes } = this.state;
    const { match } = this.props;
    return (
      <div className="ViewBillingUploadDetails">
        <PageHeader title="View Billing" />
        <Container>
          <Card>
            <Spin spinning={loadSpin} tip="Loading Billing view...">
              <Descriptions title="" bordered size="small">
                <Descriptions.Item label="Billing ID" span={2}>
                  {match.params.id}
                </Descriptions.Item>
                <Descriptions.Item label="Total Payment">
                  {uploadRes.total_payment ? uploadRes.total_payment : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Date Created" span={2}>
                  {uploadRes.created_at ? uploadRes.created_at : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Created By">
                  {uploadRes.creator ? uploadRes.creator.firstname : ''}
{" "}
                  {uploadRes.creator ? uploadRes.creator.lastname : ''}
                </Descriptions.Item>
              </Descriptions>
            </Spin>
          </Card>
          <Spacer />
          <Card
            extra={`Total: ${uploadRes.details ? uploadRes.details.length : 0}`}
          >
            <Spin spinning={loadSpin} tip="Loading Billing view...">
              <ReactTable
                data={uploadRes.details}
                columns={[
                  {
                    Header: 'Tracking Number',
                    accessor: 'tracking_number',
                  },
                  {
                    Header: 'Total Payment',
                    accessor: 'total_payment',
                  },
                  {
                    Header: 'Invoice Number',
                    accessor: 'invoice_number',
                  },
                  {
                    Header: 'Invoice Date',
                    accessor: 'invoice_date',
                  },
                ]}
              />
            </Spin>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  doBillingViews: billingViews,
};

ViewBillingUploadDetails.propTypes = {
  doBillingViews: PropTypes.func.isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewBillingUploadDetails);
