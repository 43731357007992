import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes, { number } from 'prop-types';
import {
  Descriptions, Spin, Button, Icon, Col, Modal, Result, notification, Steps,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import Row from '../../components/Row';
import DispatchView from '../../components/DispatchView';
import Card from '../../components/Card';
import Spacer from '../../components/Spacer';

import {
  fetchDispatchDetails,
  printDispatch,
  fetchDispatchList,
} from '../ducks';

const styleTable = width => ({
  border: '0.5px solid black',
  fontSize: '10px',
  padding: '5px',
  width,
  borderCollapse: 'collapse',
});

export class DeliveryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dispatchDetails: [],
      dispatchPackages: [],
      isMountingModule: true,
      accountStatus: null,
      hasVisible: false,
      updateStatusLoading: false,
      progressBar: 0,
      progressError: true,
      loadingText: 'Fetching delivery details. . .',
    };
    this.fetchDispatch = this.fetchDispatch.bind(this);
  }

  componentDidMount() {
    this.fetchDispatch();
  }

  fetchDispatch() {
    const { doFetchDispatchDetails, match, doFetchDispatchList } = this.props;

    doFetchDispatchDetails(match.params.id).then((action) => {
      const sortPackages = action.payload.data.packages.sort((a, b) => {
        if (a.firstname < b.address_barangay) {
          return -1;
        }
        if (a.firstname > b.address_barangay) {
          return 1;
        }
        return 0;
      });
      // console.log(sortPackages);
      console.log(sortPackages.total_price);
      let total_price = 0;
      sortPackages.map((res)=>{
        if(res.payment_type !== 'PAID_BY_SHIPPER_COD'){
          total_price+=Number(res.total_price);
        }
      });
      sortPackages.total_price = total_price;
      this.setState({
        dispatchDetails: action.payload.data,
        dispatchPackages: sortPackages,
        isMountingModule: false,
      });
    });

    const params = {
      export: false,
      filter: [
        { id: 'type', value: 'delivery' },
        { id: 'dh_id', value: match.params.id },
      ],
      page: 0,
      pageSize: 10,
      sorted: [],
    };

    doFetchDispatchList(params).then((res) => {
      this.setState({ accountStatus: res.payload.data.rows[0].status });
    });
  }

  confimationPrint() {
    this.setState({
      hasVisible: true,
    });
  }

  handleCancel() {
    this.setState({
      hasVisible: false,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  printDispatch() {
    this.setState({
      updateStatusLoading: true,
      hasVisible: false,
      progressBar: 2,
      progressError: true,
    });
    const { doPrintDispatch, match } = this.props;
    doPrintDispatch(match.params.id).then(() => {
      this.setState({
        updateStatusLoading: false,
        progressBar: 3,
        isMountingModule: true,
      }, () => {
        this.fetchDispatch();
        notification.success({
          message: 'Success updating manifest.',
          description: 'You can now print your updated manifest, Thank you for waiting.',
          duration: 5,
        });
      });
    }).catch(() => {
      this.setState({
        updateStatusLoading: false,
        progressError: false,
      });

      notification.error({
        message: "There's an error in printing manifest.",
        description: 'Check your internet connection or call an IT to assist you.',
        duration: 0,
      });
    });
    const content = document.getElementById('divcontents');
    const pri = document.getElementById('ifmcontentstoprint').contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();

    const { updateStatusLoading } = this.state;
    if (updateStatusLoading) {
      notification.warning({
        message: 'Please do not leave the page.',
        description: 'All changes will be lost, please wait until the current action is finished.',
        duration: 5,
      });
    }
  }

  render() {
    const {
      dispatchPackages,
      dispatchDetails,
      isMountingModule,
      accountStatus,
      hasVisible,
      updateStatusLoading,
      progressBar,
      progressError,
      loadingText,
    } = this.state;
    const { Step } = Steps;
    const { match } = this.props;
    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Pickup',
      },
      {
        breadcrumbName: 'List',
      },
      {
        breadcrumbName: `View #${match.params.id}`,
      },
    ];

    const dispatchPackageListColumns = [
      {
        id: 'tracking_number',
        Header: 'Tracking Number',
        filterable: false,
        // sortable: false,
        accessor: data => data.tracking_number,
      },
      {
        id: 'consignee_name',
        Header: 'Consignee Name',
        filterable: false,
        // sortable: false,
        accessor: data => data.consignee_name,
      },
      {
        id: 'consignee_address',
        Header: 'Consignee Address',
        filterable: false,
        // sortable: false,
        accessor: data => data.consignee_address,
      },
      {
        id: 'payment_type',
        Header: 'Payment Type',
        filterable: false,
        // sortable: false,
        accessor: data => data.payment_type,
      },
      {
        id: 'handover_data',
        Header: 'Handover Date',
        filterable: false,
        // sortable: false,
        accessor: data => data.handover_data,
      },
      {
        id: 'status',
        Header: 'Status',
        filterable: false,
        // sortable: false,
        accessor: data => data.status,
      },
    ];
    return (
      <div className="DeliveryView">
        <Modal
          title="New status confirmation"
          visible={hasVisible}
          footer={(
            /* eslint-disable */
            <React.Fragment>
              <Button
                onClick={() => this.handleCancel()}
              >
                Cancel
              </Button>
              <Button type="primary" onClick={() => this.printDispatch()}>
                Confirm
              </Button>
            </React.Fragment>
          )}
        >
          <Result
            status="warning"
            title="Are you sure you want to print this manifest?"
          />
        </Modal>
        <div style={{ display: 'none' }} id="divcontents">
          <center>
            <strong style={{ fontSize: '14px' }}>
              ORIGINATING WAREHOUSE MANIFEST
            </strong>
          </center>
          <div
            style={{
              content: '',
              display: 'inline-block',
              verticalAlign: 'middle',
              width: '100%',
              marginTop: '20px',
              marginBottom: '20px',
            }}
          >
            <div
              style={{
                width: '25%',
                display: 'inline-block',
                verticalAlign: 'middle',
                textAlign: 'middle',
                float: 'left',
              }}
            >
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Dispatch Number: </strong>
                {dispatchDetails.dispatch ? dispatchDetails.dispatch.dh_id : ''}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Operator: </strong>
                {dispatchDetails.dispatch
                  ? dispatchDetails.dispatch.driver
                  : ''}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Outsource: </strong>
                {dispatchDetails.dispatch
                  ? dispatchDetails.dispatch.outsource
                  : ''}
              </p>
            </div>
            <div
              style={{
                width: '25%',
                display: 'inline-block',
                verticalAlign: 'middle',
                textAlign: 'middle',
              }}
            >
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Vehicle: </strong>
                {dispatchDetails.dispatch
                  ? dispatchDetails.dispatch.vehicle_name
                  : ''}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Mobile Number: </strong>
                {dispatchDetails.dispatch
                  ? dispatchDetails.dispatch.driver_mobile
                  : ''}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Dispatch Date: </strong>
                {dispatchDetails.dispatch
                  ? dispatchDetails.dispatch.dispatch_date
                  : ''}
              </p>
            </div>
            <div
              style={{
                width: '25%',
                display: 'inline-block',
                verticalAlign: 'middle',
                textAlign: 'middle',
              }}
            >
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Destination: </strong>
                {dispatchDetails.dispatch
                  ? dispatchDetails.dispatch.destination
                  : ''}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Helper: </strong>
                {dispatchDetails.dispatch
                  ? dispatchDetails.dispatch.helper
                  : ''}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Remarks: </strong>
                {dispatchDetails.dispatch
                  ? dispatchDetails.dispatch.remarks
                  : ''}
              </p>
            </div>
            <div
              style={{
                width: '23%',
                display: 'inline-block',
                verticalAlign: 'middle',
                textAlign: 'middle',
                float: 'right',
              }}
            >
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Origin: </strong>
                {dispatchDetails.dispatch
                  ? dispatchDetails.dispatch.port_code
                  : ''}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Mobile Number: </strong>
                {dispatchDetails.dispatch
                  ? dispatchDetails.dispatch.helper_mobile
                  : ''}
              </p>
            </div>
          </div>
          <div style={{ marginBottom: '30px' }}>
            <strong style={{ fontSize: '12px' }}>Customer Waybills</strong>
            <table style={styleTable()}>
              <thead>
                <tr>
                  <th style={styleTable('3%')} />
                  <th style={styleTable('10%')}>Cargo #</th>
                  <th style={styleTable('10%')}>Waybill Date</th>
                  <th style={styleTable('10%')}>Consignee Name</th>
                  <th style={styleTable('10%')}>Contact No</th>
                  <th style={styleTable('11%')}>Handling Instruction</th>
                  <th style={styleTable('20%')}>Address</th>
                  <th style={styleTable('11%')}>Barangay</th>
                  <th style={styleTable('5%')}>Route Order</th>
                  <th style={styleTable('5%')}>Signature</th>
                  <th style={styleTable('5%')}>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {dispatchPackages
                  ? dispatchPackages.map((val, key) => (
                    <React.Fragment key={key}>
                        <tr key={`${key}a`}>
                          <td style={styleTable('3%')}>{key + 1}</td>
                          <td style={styleTable('10%')}>
                            {val.tracking_number}
                          </td>
                          <td style={styleTable('10%')}>{val.handover_date}</td>
                          <td style={styleTable('10%')}>
                            {val.consignee_name}
                          </td>
                          <td style={styleTable('10%')}>
                            {val.consignee_mobile}
                          </td>
                          <td style={styleTable('11%')}>
                            {val.payment_type}
                            {' : ₱'}
                            {val.total_price
                              ? val.total_price.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ',',
                              )
                              : 0}
                          </td>
                          <td style={styleTable('20%')}>
                            {val.consignee_address}
                          </td>
                          <td style={styleTable('11%')}>
                            {val.address_barangay}
                          </td>
                          <td style={styleTable('5%')} />
                          <td style={styleTable('5%')} />
                          <td style={styleTable('5%')} />
                        </tr>
                        <tr key={`${key}b`}>
                          <td style={styleTable('3%')} />
                          <td
                            colSpan={24}
                            style={{ borderCollapse: 'collapse', border: '1px solid black', fontSize: '12px', padding: '5px' }}
                          >
                            <strong>Description: </strong>
                            {val.description.length > 250 ? val.description.substr(0, 250) + '...' : val.description}
                          </td>
                        </tr>
                        <tr key={`${key}c`}>
                          <td style={styleTable('3%')} />
                          <td
                            colSpan={6}
                            style={{ borderCollapse: 'collapse', border: '1px solid black', fontSize: '12px', padding: '5px' }}
                          >
                            <strong>Length: </strong>
                            {parseFloat(val.length).toFixed(2)}
                            {'\xa0\xa0\xa0\xa0\xa0\xa0\xa0'}
                            <strong>Width: </strong>
                            {parseFloat(val.width).toFixed(2)}
                            {'\xa0\xa0\xa0\xa0\xa0\xa0\xa0'}
                            <strong>Height: </strong>
                            {parseFloat(val.height).toFixed(2)}
                            {'\xa0\xa0\xa0\xa0\xa0\xa0\xa0'}
                            <strong>Weight: </strong>
                            {parseFloat(val.weight).toFixed(2)}
                          </td>
                          <td
                            colSpan={4}
                            style={{ borderCollapse: 'collapse', border: '1px solid black', fontSize: '12px', padding: '5px' }}
                          >
                            <strong>Time: </strong>
                          </td>
                        </tr>
                      </React.Fragment>
                  ))
                  : null}
              </tbody>
            </table>
          </div>
          <p style={{ fontSize: '12px', margin: '5px' }}>
            {'Total Transaction:'}
{" "}
            <span style={{ color: 'red' }}>
              {dispatchPackages ? dispatchPackages.length : 0}
            </span>
          </p>
          <p style={{ fontSize: '12px', margin: '5px' }}>
            {'Total COD Amount:'}
            {' ₱'}
            {dispatchPackages
              ? dispatchPackages.length !== 0
                ? dispatchPackages
                  .map(res => parseFloat(res.total_price, 10))
                  .filter(resFilter => resFilter).length === 0
                  ? 0
                  : dispatchPackages.total_price ? dispatchPackages
                  .total_price : 0
                : 0
              : 0}
          </p>
          <p style={{ fontSize: '12px', margin: '5px' }}>
            {'Total Weight:'}
{" "}
            {dispatchPackages
              ? dispatchPackages.length !== 0
                ? dispatchPackages
                  .map(res => parseFloat(res.weight))
                  .filter(resFilter => resFilter).length === 0
                  ? 0
                  : dispatchPackages
                    .map(res => parseFloat(res.weight))
                    .filter(resFilter => resFilter)
                    .reduce((a, b) => a + b)
                    .toFixed(2)
                : 0
              : 0}
          </p>
          <div style={{ marginTop: '50px' }}>
            <strong style={{ fontSize: '12px', float: 'left' }}>
              Signature of Warehouse Representative
            </strong>
            <strong
              style={{ fontSize: '12px', float: 'left', marginLeft: '250px' }}
            >
              Signature of Operator
            </strong>
          </div>
        </div>
        <iframe
          title="myFrame"
          id="ifmcontentstoprint"
          style={{ height: '0px', width: '0px', position: 'absolute' }}
        />
        <Spin
          spinning={isMountingModule}
          size="small"
          tip={loadingText}
        >
        {progressBar !== 0 ? (
          <React.Fragment>
            <Card>
              <Steps current={progressBar}>
                <Step title="Print Manifest" description="Updating manifest." />
                <Step title="In Progress" description="Validating data." icon={progressBar === 2 ? (!progressError ? <Icon style={{ color: 'red' }} type="cross" /> : <Icon type="loading" />) : null} />
                <Step title="Done" description="Manifest is Updated." />
              </Steps>
            </Card>
            <Spacer />
          </React.Fragment>
        ) : null}
          <PageHeader
            title={`Delivery #${match.params.id}`}
            routes={breadCrumbs}
          />
          <Container>
            <DispatchView
              dispatchTitle="Delivery Information"
              dispatchExportName="Delivery"
              loadingUpdate={updateStatusLoading}
              dispatchPackageListColumns={dispatchPackageListColumns}
              dispatchItems={dispatchPackages}
              hasExport
              exportType="dispatch"
              exportId={match.params.id}
              dispatchDetails={(
                <React.Fragment>
                  <Descriptions bordered size="small">
                    <Descriptions.Item label="Driver: ">
                      {dispatchDetails.dispatch
                        ? dispatchDetails.dispatch.driver
                        : ""}
                    </Descriptions.Item>
                    <Descriptions.Item label="Type: ">
                      {dispatchDetails.dispatch
                        ? dispatchDetails.dispatch.type
                        : ""}
                    </Descriptions.Item>
                    <Descriptions.Item label="Contact Number: ">
                      {dispatchDetails.dispatch
                        ? dispatchDetails.dispatch.driver_mobile
                        : ""}
                    </Descriptions.Item>
                    <Descriptions.Item label="Origin: ">
                      {dispatchDetails.dispatch
                        ? dispatchDetails.dispatch.port_code
                        : ""}
                    </Descriptions.Item>
                    <Descriptions.Item label="Vehicle: ">
                      {dispatchDetails.dispatch
                        ? dispatchDetails.dispatch.vehicle_name
                        : ""}
                    </Descriptions.Item>
                    <Descriptions.Item label="Destination: ">
                      {dispatchDetails.dispatch
                        ? dispatchDetails.dispatch.destination
                        : ""}
                    </Descriptions.Item>
                    <Descriptions.Item label="Dispatch Date: ">
                      {dispatchDetails.dispatch
                        ? dispatchDetails.dispatch.dispatch_date
                        : ""}
                    </Descriptions.Item>
                    <Descriptions.Item label="Outsource: ">
                      {dispatchDetails.dispatch
                        ? dispatchDetails.dispatch.outsource
                        : ""}
                    </Descriptions.Item>
                    <Descriptions.Item label="Processed By: ">
                      {dispatchDetails.dispatch
                        ? `${dispatchDetails.dispatch.added_by}`
                        : ""}
                    </Descriptions.Item>
                  </Descriptions>
                </React.Fragment>
              )}
              printTypes={(
                <React.Fragment>
                  <Row>
                    <Col xs={24} sm={8} lg={8} style={{ marginBottom: 5 }}>
                      <Button
                        onClick={
                          accountStatus === "new"
                            ? () => this.confimationPrint()
                            : () => this.printDispatch()
                        }
                        type="primary"
                        block
                      >
                        <Icon viewBox="0 0 1024 1024">
                          <FontAwesomeIcon icon={faPrint} fixedWidth />
                        </Icon>
                        Print Manifest
                      </Button>
                    </Col>
                    <Col xs={24} sm={8} lg={8} style={{ marginBottom: 5 }}>
                      <Button
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_API_URL}/web/dispatch/print-checklist/${match.params.id}`
                          )
                        }
                        type="primary"
                        block
                      >
                        <Icon viewBox="0 0 1024 1024">
                          <FontAwesomeIcon icon={faPrint} fixedWidth />
                        </Icon>
                        Print Checklist
                      </Button>
                    </Col>
                    <Col xs={24} sm={8} lg={8}>
                      <Button
                        style={{ paddingRight: 60 }}
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_API_URL}/web/dispatch/finalmanifest/${match.params.id}`
                          )
                        }
                        type="primary"
                        block
                      >
                        <Icon viewBox="0 0 1024 1024">
                          <FontAwesomeIcon icon={faPrint} fixedWidth />
                        </Icon>
                        Print Final Manifest
                      </Button>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            />
          </Container>
        </Spin>
      </div>
    );
  }
}

DeliveryView.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchDispatchDetails: PropTypes.func.isRequired,
  doPrintDispatch: PropTypes.func.isRequired,
  doFetchDispatchList: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchDispatchDetails: fetchDispatchDetails,
  doPrintDispatch: printDispatch,
  doFetchDispatchList: fetchDispatchList,
};

export default connect(null, mapDispatchToProps)(DeliveryView);
