import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { format } from 'date-fns';
import {
  Button,
  Spin,
  DatePicker,
  Popconfirm,
  Col,
  Form,
  Icon,
  message,
  Descriptions,
} from 'antd';
import PropTypes from 'prop-types';
import fileDownload from 'js-file-download';
import {
  fetchOptionsLike,
  getPickupCalendar,
  downloadPickupCalendar,
} from '../ducks';
import Pageheader from '../../components/PageHeader';
import Container from '../../components/Container';
import Card from '../../components/Card';
import ReactSelect from '../../components/Select';
import ReactTable from '../../components/ReactTable';
import Row from '../../components/Row';
import Spacer from '../../components/Spacer';

class PickupCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClient: 899,
      clientOptions: [],
      selectedDates: {
        from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        to: moment().add(7, 'days').format('YYYY-MM-DD'),
      },
      spinnerState: true,
      isClientOptionsLoading: false,
      pendingPickups: [],
    };
  }

  componentDidMount() {
    const { doFetchOptionsLike } = this.props;
    this.submitSearch();
    // Temporarily add lazada as this module is for shopee client
    doFetchOptionsLike('client', 'Shopee').then(
      res => this.setState(
        { 
          clientOptions: [
            {
              value: 1, label: "Lazada",
            },
            ...res.payload.data.slice(0, 50),
          ]
        }
    ));
  }

  searchClient(e) {
    const { doFetchOptionsLike } = this.props;

    this.setState({
      isClientOptionsLoading: true,
    });

    doFetchOptionsLike('client', e).then((action) => {
      this.setState({
        clientOptions: action.payload.data.slice(0, 50),
        isClientOptionsLoading: false,
      });
    });
  }

  clientHandler(e) {
    this.setState({
      selectedClient: e,
    });
  }

  dateHandler(dateStrings) {
    const newDateOne = dateStrings[0];
    const newDateTwo = dateStrings[1];
    const formatDateOne = format(newDateOne, 'YYYY-MM-DD');
    const formatDateTwo = format(newDateTwo, 'YYYY-MM-DD');

    this.setState({
      selectedDates: {
        from: formatDateOne !== 'Invalid Date' ? formatDateOne : null,
        to: formatDateTwo !== 'Invalid Date' ? formatDateTwo : null,
      },
    });
  }

  submitSearch() {
    const {
      selectedClient,
      selectedDates,
    } = this.state;

    const { doGetPickupCalendar } = this.props;

    this.setState({ spinnerState: true });

    const params = {
      clientId: selectedClient,
      pickup_from: selectedDates.from,
      pickup_to: selectedDates.to,
    };

    doGetPickupCalendar(params)
      .then((action) => {
        const allDates = (() => {
          const from = moment(selectedDates.from);
          const dates = [];
          while (from.isSameOrBefore(selectedDates.to)) {
            dates.push(from.format('YYYY-MM-DD'));
            from.add(1, 'days');
          }
          return dates;
        })();

        const { data } = action.payload;
        const pendingPickups = allDates.map((date) => {
          const dataMatch = data.find(pickup => pickup.booked_pickup_date === date);

          let initialData = {
            booked_pickup_date: date,
            bookedPickups: dataMatch ? parseInt(dataMatch.booked_pickups, 10) : 0,
            forPickup: dataMatch ? parseInt(dataMatch.for_pickup, 10) : 0,
            handedOver: dataMatch ? parseInt(dataMatch.handed_over, 10) : 0,
            open: dataMatch ? parseInt(dataMatch.open, 10) : 0,
            released: dataMatch ? parseInt(dataMatch.released, 10) : 0,
            abc: dataMatch ? parseInt(dataMatch.accepted_by_courier, 10) : 0,
            pickup_request_cancelled_1: dataMatch ? parseInt(
              dataMatch.pickup_request_cancelled_1,
              10,
            ) : 0,
            pickup_request_cancelled_2: dataMatch ? parseInt(
              dataMatch.pickup_request_cancelled_2,
              10,
            ) : 0,
            pickup_request_cancelled_3: dataMatch ? parseInt(
              dataMatch.pickup_request_cancelled_3,
              10,
            ) : 0,
          };

          initialData = {
            ...initialData,
            successPct: (
              (
                (initialData.abc + initialData.handedOver + initialData.released)
                / initialData.bookedPickups
              ) * 100
            ).toFixed(2),
            failedPct: (
              (initialData.pickup_request_cancelled_3 / initialData.bookedPickups) * 100
            ).toFixed(2),
          };

          return {
            ...initialData,
            // eslint-disable-next-line no-restricted-globals
            successPct: !isNaN(initialData.successPct) ? initialData.successPct : 0,
            // eslint-disable-next-line no-restricted-globals
            failedPct: !isNaN(initialData.failedPct) ? initialData.failedPct : 0,
          };
        });

        this.setState({ spinnerState: false, pendingPickups });
      })
      .catch(() => {
        message.error('Server error. Please contact IT for help.');
        this.setState({ spinnerState: false });
      });
  }

  prepareDownload(pickupDate) {
    const {
      selectedClient,
    } = this.state;

    const { doDownloadPickupCalendar } = this.props;

    this.setState({
      spinnerState: true,
    });

    const params = {
      clientId: selectedClient,
      pickupDate,
      type: 'all',
    };
    doDownloadPickupCalendar(params)
      .then((action) => {
        const file = new Blob([action.payload.data]);
        fileDownload(file, `pickup-${pickupDate}-all.xlsx`);
        this.setState({ spinnerState: false });
        message.success('The file is successfully downloaded!');
      })
      .catch(() => {
        message.error('Server error. Please contact IT for help.');
      });
  }

  render() {
    const {
      clientOptions,
      spinnerState,
      isClientOptionsLoading,
      pendingPickups,
    } = this.state;

    const { RangePicker } = DatePicker;

    const breadCrumbs = [
      {
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: 'Pending Pickups',
      },
    ];

    return (
      <div className="Dashboard">
        <Pageheader title="Pending Pickups" routes={breadCrumbs} />
        <Spacer />
        <Container>
          <div>
            <Card>
              <Row>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Booked Pickup Date">
                    <RangePicker
                      style={{ width: '100%' }}
                      defaultValue={[
                        moment().subtract(7, 'days'),
                        moment().add(7, 'days'),
                      ]}
                      onChange={e => this.dateHandler(e)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Client">
                    <ReactSelect
                      defaultValue={[899]}
                      style={{ width: '100%' }}
                      placeholder="Type to search for client..."
                      loading={isClientOptionsLoading}
                      onSearch={e => this.searchClient(e)}
                      options={clientOptions}
                      onChange={e => this.clientHandler(e)}
                      filterOption={false}
                      showSearch
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Button disabled={spinnerState} type="primary" onClick={() => this.submitSearch()} block>
                <Icon type="file-search" />
                Filter
              </Button>
            </Card>
            <Spacer />
            <Card title="Dashboard Formula">
              <Descriptions size="small" bordered>
                <Descriptions.Item label="Success %">(Handed Over + Accepted By Courier + Released) / Total Booked</Descriptions.Item>
                <Descriptions.Item label="Failed %">Pickup Failed 3 / Total Booked</Descriptions.Item>
              </Descriptions>
            </Card>
            <Spacer />
            <Spin
              spinning={spinnerState}
              size="small"
              tip="Fetching pickups..."
            >
              <Card>
                <ReactTable
                  data={pendingPickups}
                  columns={[
                    {
                      Header: 'Pickup Date',
                      accessor: 'booked_pickup_date',
                    },
                    {
                      Header: 'Total Booked',
                      Cell: row => (row.original.bookedPickups > 0 ? (
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.prepareDownload(row.original.booked_pickup_date)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            { row.original.bookedPickups }
                          </Button>
                        </Popconfirm>
                      ) : 0),
                    },
                    {
                      Header: 'For Pickup',
                      accessor: 'forPickup',
                    },
                    {
                      Header: 'Pickup Failed 1',
                      accessor: 'pickup_request_cancelled_1',
                    },
                    {
                      Header: 'Pickup Failed 2',
                      accessor: 'pickup_request_cancelled_2',
                    },
                    {
                      Header: 'Total Open',
                      accessor: 'open',
                    },
                    {
                      Header: 'Handed Over',
                      accessor: 'handedOver',
                    },
                    {
                      Header: 'Accepted by Courier',
                      accessor: 'abc',
                    },
                    {
                      Header: 'Released',
                      accessor: 'released',
                    },
                    {
                      Header: 'Success %',
                      accessor: 'successPct',
                    },
                    {
                      Header: 'Pickup Failed 3',
                      accessor: 'pickup_request_cancelled_3',
                    },
                    {
                      Header: 'Failed %',
                      accessor: 'failedPct',
                    },
                  ]}
                  loadingText="Fetching pending pickups..."
                  loading={spinnerState}
                  defaultPageSize={20}
                />
              </Card>
            </Spin>
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = {
  doFetchOptionsLike: fetchOptionsLike,
  doGetPickupCalendar: getPickupCalendar,
  doDownloadPickupCalendar: downloadPickupCalendar,
};

PickupCalendar.propTypes = {
  doFetchOptionsLike: PropTypes.func.isRequired,
  doGetPickupCalendar: PropTypes.func.isRequired,
  doDownloadPickupCalendar: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(PickupCalendar);
