import { repushStatus, deliveryStatus } from '../../services/api';

const RESEND_VALIDATE_RD = 'repush/RESEND_VALIDATE_RD';
const RESEND_VALIDATE_RD_SUCCESS = 'repush/RESEND_VALIDATE_RD_SUCCESS';

const RESEND_VALIDATE_RFD = 'repush/RESEND_VALIDATE_RFD';
const RESEND_VALIDATE_RFD_SUCCESS = 'repush/RESEND_VALIDATE_RFD_SUCCESS';

const RESEND_VALIDATE_RR = 'repush/RESEND_VALIDATE_RR';
const RESEND_VALIDATE_RR_SUCCESS = 'repush/RESEND_VALIDATE_RR_SUCCESS';

const RESEND_VALIDATE_VW = 'repush/RESEND_VALIDATE_VW';

export const submitStatus = (resentMethod, tnumber) => {
  switch (resentMethod) {
    case 'resend_delivered':
      return {
        type: RESEND_VALIDATE_RD,
        payload: {
          client: 'secure',
          request: {
            ...repushStatus('resend_delivered', tnumber),
          },
        },
      };

    case 'resend_failed_delivery':
      return {
        type: RESEND_VALIDATE_RFD,
        payload: {
          client: 'secure',
          request: {
            ...repushStatus('resend_failed_delivery', tnumber),
          },
        },
      };

    case 'resend_returned':
      return {
        type: RESEND_VALIDATE_RR,
        payload: {
          client: 'secure',
          request: {
            ...repushStatus('resend_returned', tnumber),
          },
        },
      };

    case 'void':
      return {
        type: RESEND_VALIDATE_VW,
        payload: {
          client: 'secure',
          request: {
            ...repushStatus('void', tnumber),
          },
        },
      };

    default:
      return false;
  }
};

const RESEND_VALIDATE_PAYLOAD = 'repush/RESEND_VALIDATE_PAYLOAD';

export const submitStatusLazada = (dataPayload, tNumber) => ({
  type: RESEND_VALIDATE_PAYLOAD,
  payload: {
    client: 'secure',
    request: {
      ...deliveryStatus(tNumber),
      data: dataPayload,
    },
  },
});

// resend_delivered
// resend_failed_delivery
// resend_returned

const initialState = {
  data1: [],
  data2: [],
  data3: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case RESEND_VALIDATE_RD_SUCCESS:
      return {
        ...state,
        data1: [...state.data1, action.payload.data],
      };

    case RESEND_VALIDATE_RFD_SUCCESS:
      return {
        ...state,
        data2: [...state.data2, action.payload.data],
      };

    case RESEND_VALIDATE_RR_SUCCESS:
      return {
        ...state,
        data3: [...state.data3, action.payload.data],
      };

    default:
      return state;
  }
}

export default reducer;
