import {
  itemTrackerByReferenceAPI, itemTrackerByTrackinNumberAPI, itemTrackerTaggingAPI, generateItemBarcode, fetchItemsAPI, fetchItemsViews, fetchItemsViewsByReference, exportItemsAPI, itemTrackerByBarcode,
} from '../../services/apiEndpoints';

const ITEM_FETCH_VIEWS_BYREFERENCE = 'itemTracker/ITEM_FETCH_VIEWS_BYREFERENCE';
export const itemfetchviewsByReference = reference => ({
  type: ITEM_FETCH_VIEWS_BYREFERENCE,
  payload: {
    client: 'secure',
    request: {
      ...fetchItemsViewsByReference(reference),
    },
  },
});

const ITEM_FETCH_VIEWS = 'itemTracker/ITEM_FETCH_VIEWS';
export const itemfetchviews = (reference, itemId) => ({
  type: ITEM_FETCH_VIEWS,
  payload: {
    client: 'secure',
    request: {
      ...fetchItemsViews(reference, itemId),
    },
  },
});

const ITEM_EXPORT = 'itemTracker/ITEM_EXPORT';
export const itemExport = data => ({
  type: ITEM_EXPORT,
  payload: {
    client: 'secure',
    request: {
      ...exportItemsAPI,
      data,
    },
  },
});

const ITEM_FETCH = 'itemTracker/ITEM_FETCH';
export const itemfetch = data => ({
  type: ITEM_FETCH,
  payload: {
    client: 'secure',
    request: {
      ...fetchItemsAPI,
      data,
    },
  },
});

const ITEM_TRACKER_BY_BARCODE = 'itemTracker/ITEM_TRACKER_BY_BARCODE';
export const itemTrackerByBarcodeScan = (barcodeNum, status) => ({
  type: ITEM_TRACKER_BY_BARCODE,
  payload: {
    client: 'secure',
    request: {
      ...itemTrackerByBarcode(barcodeNum, status),
    },
  },
});

const ITEM_TRACKER_BY_REFERENCE = 'itemTracker/ITEM_TRACKER_BY_REFERENCE';
export const itemTrackerByReference = (referenceNumber, status) => ({
  type: ITEM_TRACKER_BY_REFERENCE,
  payload: {
    client: 'secure',
    request: {
      ...itemTrackerByReferenceAPI(referenceNumber, status),
    },
  },
});

const ITEM_TRACKER_BY_TRACKINGNUMBER = 'itemTracker/ITEM_TRACKER_BY_TRACKINGNUMBER';
export const itemTrackerByTrackingNumber = (trackingNumber, status) => ({
  type: ITEM_TRACKER_BY_TRACKINGNUMBER,
  payload: {
    client: 'secure',
    request: {
      ...itemTrackerByTrackinNumberAPI(trackingNumber, status),
    },
  },
});

const ITEM_TRACKER_TAGGING = 'itemTracker/ITEM_TRACKER_TAGGING';
export const itemTrackerTaggingEndpoints = validateTagging => ({
  type: ITEM_TRACKER_TAGGING,
  payload: {
    client: 'secure',
    request: {
      ...itemTrackerTaggingAPI,
      data: validateTagging,
    },
  },
});

const GENERATE_BARCODE = 'itemTracker/GENERATE_BARCODE';
export const generateBarcode = itemIDs => ({
  type: GENERATE_BARCODE,
  payload: {
    client: 'secure',
    request: {
      ...generateItemBarcode,
      data: itemIDs,
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
