import {
  apiFetchTrackingNumberByStatus,
  apiFetchOptionsLike,
  apiFetchDispatchList,
  apiUpdateTrackingNumberStatus,
  apiFetchOptions,
  apiFetchDispatchTrackingNumber,
  apiFetchPickupList,
  apiFetchPickupDetails,
  apiFetchTransimittalTrackingNumberByType,
  apiSubmitTransmittal,
  apiCreateDispatch,
  apiFetchDispatchDetails,
  apiUpdateDispatch,
  apiFetchShipmentProviders,
  printManifest,
  printPickupManifest,
  billingListViews,
  billingList,
  cashierRemittanceList,
  apiFetchTransmittalList,
  apiFetchTransmittalDetails,
  apiFetchTransimittalRefused,
  apiDeleteDispatch,
  kpiDashboard,
  backlogsDashboard,
  kpiDashboardDownload,
  backlogsDashboardDownload,
  backlogsDashboardDownloadOverall,
  volumeTrendAPI,
  apiGetPickupCalendar,
  apiDownloadPickupCalendar,
  apiGetFleetPerfFM,
  apiGetFleetPerfLM,
  apiExportDispatch,
  apiExportPickupDispatch,
} from '../services/apiEndpoints';

const DASHBOARD_BACKLOGS_FETCH = 'kpiDash/DASHBOARD_BACKLOGS_FETCH';
export const dashboardBacklogs = (params, branch) => ({
  type: DASHBOARD_BACKLOGS_FETCH,
  payload: {
    client: 'secure',
    request: {
      ...backlogsDashboard(params, branch),
    },
  },
});

const DASHBOARD_BACKLOGS_DOWNLOAD = 'kpiDash/DASHBOARD_BACKLOGS_DOWNLOAD';
export const dashboardBacklogsDownload = params => ({
  type: DASHBOARD_BACKLOGS_DOWNLOAD,
  payload: {
    client: 'secure',
    request: {
      ...backlogsDashboardDownload(params),
    },
  },
});

const DASHBOARD_BACKLOGS_DOWNLOAD_OVERALL = 'kpiDash/DASHBOARD_BACKLOGS_DOWNLOAD_OVERALL';
export const dashboardBacklogsDownloadOverall = params => ({
  type: DASHBOARD_BACKLOGS_DOWNLOAD_OVERALL,
  payload: {
    client: 'secure',
    request: {
      ...backlogsDashboardDownloadOverall(params),
    },
  },
});

const DASHBOARD_VOLUME_TREND_FETCH = 'kpiDash/DASHBOARD_VOLUME_TREND_FETCH';
export const volumeTrend = params => ({
  type: DASHBOARD_VOLUME_TREND_FETCH,
  payload: {
    client: 'secure',
    request: {
      ...volumeTrendAPI,
      params,
    },
  },
});

const PICKUP_CALENDAR_FETCH = 'dashboard/PICKUP_CALENDAR_FETCH';
export const getPickupCalendar = params => ({
  type: PICKUP_CALENDAR_FETCH,
  payload: {
    client: 'secure',
    request: {
      ...apiGetPickupCalendar(params),
    },
  },
});

const PICKUP_CALENDAR_DOWNLOAD = 'dashboard/PICKUP_CALENDAR_DOWNLOAD';
export const downloadPickupCalendar = params => ({
  type: PICKUP_CALENDAR_DOWNLOAD,
  payload: {
    client: 'secure',
    request: {
      ...apiDownloadPickupCalendar(params),
    },
  },
});

const DASHBOARD_KPI_FETCH = 'kpiDash/DASHBOARD_KPI_FETCH';
export const dashboardKpi = params => ({
  type: DASHBOARD_KPI_FETCH,
  payload: {
    client: 'secure',
    request: {
      ...kpiDashboard,
      params,
    },
  },
});

const DASHBOARD_KPI_DOWNLOAD = 'kpiDash/DASHBOARD_KPI_DOWNLOAD';
export const dashboardKpiDownload = params => ({
  type: DASHBOARD_KPI_DOWNLOAD,
  payload: {
    client: 'secure',
    request: {
      ...kpiDashboardDownload,
      params,
    },
  },
});

const FLEET_PERFORMANCE_FM_FETCH = 'fleetperf/FLEET_PERFORMANCE_FM_FETCH';
export const getFleetPerfFM = params => ({
  type: FLEET_PERFORMANCE_FM_FETCH,
  payload: {
    client: 'secure',
    request: {
      ...apiGetFleetPerfFM,
      params,
    },
  },
});

const FLEET_PERFORMANCE_LM_FETCH = 'fleetperf/FLEET_PERFORMANCE_LM_FETCH';
export const getFleetPerfLM = params => ({
  type: FLEET_PERFORMANCE_LM_FETCH,
  payload: {
    client: 'secure',
    request: {
      ...apiGetFleetPerfLM,
      params,
    },
  },
});

const CASHIER_REMITTANCE_LIST = 'statusTagging/CASHIER_REMITTANCE_LIST';
export const cashierRemittanceListVIew = (page, data) => ({
  type: CASHIER_REMITTANCE_LIST,
  payload: {
    client: 'secure',
    request: {
      ...cashierRemittanceList(page),
      data,
    },
  },
});

const BILLING_LIST = 'statusTagging/BILLING_LIST';
export const billingListView = (page, data) => ({
  type: BILLING_LIST,
  payload: {
    client: 'secure',
    request: {
      ...billingList(page),
      data,
    },
  },
});

const BILLING_VIEWS = 'statusTagging/BILLING_VIEWS';
export const billingViews = (page, billingViewsId) => ({
  type: BILLING_VIEWS,
  payload: {
    client: 'secure',
    request: {
      ...billingListViews(page, billingViewsId),
    },
  },
});

const PRINT_DISPATCH = 'statusTagging/PRINT_DISPATCH';
export const printDispatch = printManifestId => ({
  type: PRINT_DISPATCH,
  payload: {
    client: 'secure',
    request: {
      ...printManifest(printManifestId),
    },
  },
});

const PRINT_PICKUP = 'statusTagging/PRINT_PICKUP';
export const printPickup = printPickupManifestId => ({
  type: PRINT_PICKUP,
  payload: {
    client: 'secure',
    request: {
      ...printPickupManifest(printPickupManifestId),
    },
  },
});

const FETCH_TRACKING_NUMBER = 'statusTagging/FETCH_TRACKING_NUMBER';
export const fetchTrackingNumber = (status, trackingNumber) => ({
  type: FETCH_TRACKING_NUMBER,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchTrackingNumberByStatus(status, trackingNumber),
    },
  },
});

const FETCH_TRANSMITTAL_TRACKING_NUMBER = 'statusTagging/FETCH_TRANSMITTAL_TRACKING_NUMBER';
export const fetchTransmittalTrackingNumber = (type, trackingNumber) => ({
  type: FETCH_TRANSMITTAL_TRACKING_NUMBER,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchTransimittalTrackingNumberByType(type, trackingNumber),
    },
  },
});

const FETCH_TRANSMITTAL_TRACKING_NUMBER_REFUSAL = 'statusTagging/FETCH_TRANSMITTAL_TRACKING_NUMBER_REFUSAL';
export const fetchTransmittalTrackingNumberRefusal = (
  data,
  trackingNumber,
) => ({
  type: FETCH_TRANSMITTAL_TRACKING_NUMBER_REFUSAL,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchTransimittalRefused(trackingNumber),
      data,
    },
  },
});

const UPDATE_TRACKING_NUMBER_STATUS = 'statusTagging/UPDATE_TRACKING_NUMBER_STATUS';
export const updateTrackingNumberStatus = (data, trackingNumber) => ({
  type: UPDATE_TRACKING_NUMBER_STATUS,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateTrackingNumberStatus(trackingNumber),
      data,
    },
  },
});

const UPDATE_DISPATCH = 'statusTagging/UPDATE_DISPATCH';
export const updateDispatch = (data, dispatchId) => ({
  type: UPDATE_DISPATCH,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateDispatch(dispatchId),
      data,
    },
  },
});

const DELETE_DISPATCH = 'statusTagging/DELETE_DISPATCH';
export const deleteDispatch = dispatchId => ({
  type: DELETE_DISPATCH,
  payload: {
    client: 'secure',
    request: {
      ...apiDeleteDispatch(dispatchId),
    },
  },
});

const SUBMIT_TRANSMITTAL_TRACKING_NUMBER_STATUS = 'statusTagging/SUBMIT_TRANSMITTAL_TRACKING_NUMBER_STATUS';
export const submitTransmittal = data => ({
  type: SUBMIT_TRANSMITTAL_TRACKING_NUMBER_STATUS,
  payload: {
    client: 'secure',
    request: {
      ...apiSubmitTransmittal,
      data,
    },
  },
});

const CREATE_DISPATCH = 'statusTagging/CREATE_DISPATCH';
export const createDispatch = data => ({
  type: CREATE_DISPATCH,
  payload: {
    client: 'secure',
    request: {
      ...apiCreateDispatch,
      data,
    },
  },
});

const FETCH_OPTIONS = 'dispatch/FETCH_OPTIONS';
export const fetchOptions = option => ({
  type: FETCH_OPTIONS,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchOptions(option),
    },
  },
});

const FETCH_OPTIONS_LIKE = 'dispatch/FETCH_OPTIONS_LIKE';
export const fetchOptionsLike = (type, string) => ({
  type: FETCH_OPTIONS_LIKE,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchOptionsLike(type, string),
    },
  },
});

const FETCH_DISPATCH_TRACKING_NUMBER = 'dispatch/FETCH_DISPATCH_TRACKING_NUMBER';
export const fetchDispatchTrackingNumber = (type, trackingNumber) => ({
  type: FETCH_DISPATCH_TRACKING_NUMBER,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchDispatchTrackingNumber(type, trackingNumber),
    },
  },
});

const FETCH_DISPATCH_LIST = 'dispatch/FETCH_DISPATCH_LIST';
export const fetchDispatchList = data => ({
  type: FETCH_DISPATCH_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchDispatchList,
      data,
    },
  },
});

const FETCH_PICKUP_LIST = 'pickup/FETCH_PICKUP_LIST';
export const fetchPickupList = data => ({
  type: FETCH_PICKUP_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchPickupList,
      data,
    },
  },
});

const FETCH_PICKUP_DETAILS = 'pickup/FETCH_PICKUP_DETAILS';
export const fetchPickupDetails = pickupId => ({
  type: FETCH_PICKUP_DETAILS,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchPickupDetails(pickupId),
    },
  },
});
const FETCH_DISPATCH_DETAILS = 'pickup/FETCH_DISPATCH_DETAILS';
export const fetchDispatchDetails = pickupId => ({
  type: FETCH_DISPATCH_DETAILS,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchDispatchDetails(pickupId),
    },
  },
});

const FETCH_SHIPMENT_PROVIDERS = 'pickup/FETCH_SHIPMENT_PROVIDERS';
export const fetchShipmentProviders = () => ({
  type: FETCH_SHIPMENT_PROVIDERS,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchShipmentProviders,
    },
  },
});

const FETCH_TRANSMITTAL_LIST = 'merchantPickup/FETCH_TRANSMITTAL_LIST';
export const fetchTransmittalList = data => ({
  type: FETCH_TRANSMITTAL_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchTransmittalList,
      data,
    },
  },
});

const FETCH_TRANSMITTAL_DETAILS = 'merchantPickup/FETCH_TRANSMITTAL_DETAILS';
export const fetchTransmittalId = transmittalId => ({
  type: FETCH_TRANSMITTAL_DETAILS,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchTransmittalDetails(transmittalId),
    },
  },
});

const EXPORT_DISPATCH = 'dispatch/EXPORT_DISPATCH';
export const exportDispatch = id => ({
  type: EXPORT_DISPATCH,
  payload: {
    client: 'secure',
    request: {
      ...apiExportDispatch(id),
    },
  },
});

const EXPORT_PICKUP_DISPATCH = 'dispatch/EXPORT_PICKUP_DISPATCH';
export const exportPickupDispatch = id => ({
  type: EXPORT_PICKUP_DISPATCH,
  payload: {
    client: 'secure',
    request: {
      ...apiExportPickupDispatch(id),
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
