import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

export class ReactSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    // this.selectHandler = this.selectHandler.bind(this);
  }

  // selectHandler(selectedOption) {
  //   this.setState({ selectedOption });
  //   // console.log(selectedOption);
  // }

  render() {
    const { options } = this.props;
    // console.log(options);
    // console.log(test[0]);
    return (
      <Select
        styles={{
          menuPortal: (base) => {
            const { zIndex, ...rest } = base;
            return { ...rest, zIndex: 9999 };
          },
          control: (base) => {
            const { height, ...rest } = base;
            return { ...rest, height: 5 };
          },

          indicatorSeparator: (base) => {
            const { height, ...rest } = base;
            return { ...rest, height: 22 };
          },
          indicatorsContainer: (base) => {
            const { height, ...rest } = base;
            return { ...rest, height: 35 };
          },
          input: (base) => {
            const { height, ...rest } = base;
            return { ...rest, height: 28 };
          },
          singleValue: (base) => {
            const { height, ...rest } = base;
            return { ...rest, height: 28 };
          },
          valueContainer: (base) => {
            const { height, ...rest } = base;
            return { ...rest, height: 28 };
          },
        }}
        menuPortalTarget={document.body}
        options={options}
        {...this.props}
      />
    );
  }
}

ReactSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  handler: PropTypes.func,
  children: PropTypes.func,
  values: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object])),
  ]),
};

export default ReactSelect;
