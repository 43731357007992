import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { message, Spin } from 'antd';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import DispatchForm from '../../components/DispatchForm';
import { fetchDispatchDetails, fetchDispatchTrackingNumber, updateDispatch } from '../ducks';

export class PickupDispatchEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dispatchDetails: {},
      trackingNumberRows: [],
      errors: [],
      isSubmitting: false,
      isMountingModule: true,
    };
    this.packageFormHandler = this.packageFormHandler.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
  }

  componentDidMount() {
    const { doFetchDispatchDetails, match } = this.props;

    doFetchDispatchDetails(match.params.id).then((action) => {
      const packageList = [];
      // eslint-disable-next-line no-unused-vars
      action.payload.data.packages.forEach((item, index) => {
        packageList.push({
          address_city: item.address_city,
          tracking_number: item.tracking_number,
          consignee_address: item.consignee_address,
          payment_type: item.payment_type,
        });
      });

      this.setState({
        trackingNumberRows: packageList,
        dispatchDetails: action.payload.data.dispatch,
        isMountingModule: false,
      });
    });
  }

  deleteHandler(trackingNumber) {
    const { trackingNumberRows } = this.state;
    const filteredTrackingNumber = trackingNumberRows.filter(
      data => data.tracking_number !== trackingNumber,
    );

    this.setState({
      trackingNumberRows: filteredTrackingNumber,
    });

    message.success('Successfully removed tracking number!');
  }

  packageFormHandler(values) {
    const { trackingNumberRows } = this.state;
    const { doFetchDispatchTrackingNumber } = this.props;
    const splitTrackingNumber = values.tracking_number ? values.tracking_number.split('\n') : '';
    const filteredTrackingNumber = splitTrackingNumber.filter(
      (item, key) => splitTrackingNumber.indexOf(item) >= key && item !== '',
    );
    // eslint-disable-next-line no-unused-vars
    filteredTrackingNumber.forEach((item, key) => {
      const exists = trackingNumberRows.filter(tn => tn.tracking_number === item);

      if (exists.length > 0) {
        message.error(`Tracking number ${item} is already entered.`);
        this.setState(prevState => ({
          errors: [
            ...prevState.errors,
            {
              tracking_number: item,
              message: 'Tracking number is already entered.',
            },
          ],
        }));
      } else {
        doFetchDispatchTrackingNumber('pickup', item)
          .then((action) => {
            this.setState(prevState => ({
              trackingNumberRows: [
                ...prevState.trackingNumberRows,
                {
                  address_city: action.payload.data.address_city,
                  tracking_number: item,
                  consignee_address: action.payload.data.consignee_address,
                  payment_type: action.payload.data.payment_type,
                },
              ],
            }));
            message.success(`Successfully added ${item}`);
          })
          .catch((action) => {
            let errorMessage;
            if (action.error.response.status === 400) {
              errorMessage = action.error.response.data.message;
              this.setState(prevState => ({
                errors: [
                  ...prevState.errors,
                  {
                    tracking_number: item,
                    message: action.error.response.data.message,
                  },
                ],
              }));
            } else {
              errorMessage = 'Something went wrong, please try again later.';
            }
            message.error(errorMessage, 3);
          });
      }
    });
  }

  formSubmitHandler(values) {
    const { trackingNumberRows } = this.state;
    const { doUpdateDispatch, match, history } = this.props;
    if (trackingNumberRows.length > 0) {
      const packages = [];

      this.setState({
        isSubmitting: true,
      });
      // eslint-disable-next-line no-unused-vars
      trackingNumberRows.forEach((item, key) => {
        packages.push(item.tracking_number);
      });

      const params = {
        ...values,
        type: 'pickup',
        packages,
      };

      doUpdateDispatch(params, match.params.id)
        .then(() => {
          this.setState({
            isSubmitting: false,
          });
          history.push(`/pickup/${match.params.id}`);
        })
        .catch(() => {
          this.setState({
            isSubmitting: false,
          });
          message.error('Something went wrong, please try again later.');
        });
    } else {
      message.error('No tracking number found!');
    }
  }

  render() {
    const {
      trackingNumberRows,
      errors,
      dispatchDetails,
      isMountingModule,
      isSubmitting,
    } = this.state;
    const { match } = this.props;

    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Shipper Pickup',
      },
      {
        breadcrumbName: 'List',
      },
      {
        breadcrumbName: `Edit #${match.params.id}`,
      },
    ];

    const listColumns = [
      {
        Header: 'Tracking Number',
        accessor: 'tracking_number',
      },
      {
        Header: 'Customer',
        accessor: 'address_city',
      },
      {
        Header: 'Consignee Address',
        accessor: 'consignee_address',
      },
      {
        Header: 'Payment Type',
        accessor: 'payment_type',
      },
    ];

    const selectOptions = ['vehicle', 'courier', 'outsource'];

    const requiredFormSubmitFields = [
      'driver_id',
      'driver2_id',
      'helper_id',
      'helper2_id',
      'vehicle_id',
      'remarks',
      'outsource',
      'driver_rate',
      'driver2_rate',
      'helper_rate',
      'helper2_rate',
    ];

    const requiredPackageFormFields = ['tracking_number'];

    return (
      <div className="PickupDispatchEdit">
        <Spin spinning={isMountingModule} size="small" tip="Fetching shipper pickup details . . .">
          <PageHeader title={`Shipper Pickup #${match.params.id}`} routes={breadCrumbs} />
          <Container>
            <DispatchForm
              formInformation="Shipper Pickup Information"
              tablInformation="Shipper Pickup Dispatch List"
              selectOptions={selectOptions}
              requiredFormSubmitFields={requiredFormSubmitFields}
              requiredPackageFormFields={requiredPackageFormFields}
              listColumns={listColumns}
              packageFormHandler={this.packageFormHandler}
              formSubmitHandler={this.formSubmitHandler}
              deleteHandler={this.deleteHandler}
              dispatchItems={trackingNumberRows}
              errorItems={errors}
              dispatchDetails={dispatchDetails}
              isSubmitting={isSubmitting}
              isDispatchEdit
              hasOutsource
            />
          </Container>
        </Spin>
      </div>
    );
  }
}

PickupDispatchEdit.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchDispatchTrackingNumber: PropTypes.func.isRequired,
  doUpdateDispatch: PropTypes.func.isRequired,
  doFetchDispatchDetails: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchDispatchTrackingNumber: fetchDispatchTrackingNumber,
  doUpdateDispatch: updateDispatch,
  doFetchDispatchDetails: fetchDispatchDetails,
};

export default connect(
  null,
  mapDispatchToProps,
)(PickupDispatchEdit);
