import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import fileDownload from 'js-file-download';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import {
 Col, Form, Button, Icon, DatePicker 
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faSearch } from '@fortawesome/free-solid-svg-icons';

import { rubixpickOptions } from '../../components/options/ducks';
import { waybill } from '../../components/waybill/ducks';
import { bList } from './ducks';
import { billingListView } from '../ducks';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import ReactSelect from '../../components/Select';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactTable from '../../components/ServerSideTable';
import Spacer from '../../components/Spacer';

export class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      handoverDate: [],
      client: [],
      loading: true,
      userData: {
        rows: [],
        pages: 0,
        total: 0,
      },
      payloadReq: {
        export: false,
        page: 0,
        pageSize: 0,
        sorted: [],
      },
      tableFilter: [],
      searchFilter: [],
    };

    this.onFetchDataFunc = this.onFetchDataFunc.bind(this);
  }

  componentDidMount() {
    const { doRubixpickOptions } = this.props;
    doRubixpickOptions('client').then(res => this.setState({
        client: res.payload.data,
      }),);
  }

  onFetchDataFunc(state, instance) {
    this.setState({
      loading: true,
    });
    // console.log(state);
    const { searchFilter } = this.state;
    const { billingNonFilterReportList } = this.props;
    const paramsReq = {
      export: false,
      page: state.page,
      pageSize: state.pageSize,
      filter: state.filtered,
      sorted: state.sorted,
    };
    if (searchFilter.length) {
      paramsReq.filter = paramsReq.filter.concat(searchFilter);
    }
    billingNonFilterReportList('report-list', paramsReq).then(res => this.setState({
        loading: false,
        userData: {
          rows: res.payload.data.rows,
          pages: res.payload.data.pages,
          total: res.payload.data.total,
        },
        payloadReq: paramsReq,
        tableFilter: state.filtered,
      }),);
  }

  getEditAccountHandler(e) {
    const { waybillComponent, history } = this.props;
    waybillComponent(e.tracking_number).then(() => history.push(`/waybill/${e.tracking_number}`),);
    // console.log(e);
  }

  submitHandlerExport() {
    this.setState({
      loading: true,
    });
    const { payloadReq, handoverDate } = this.state;
    const { billingNonFilterReportList, form } = this.props;
    const { setFieldsValue, validateFields } = form;
    validateFields((err, values) => {
      const paramsReq = {
        export: true,
        page: payloadReq.page,
        pageSize: payloadReq.pageSize,
        filter: values.client
          ? [...handoverDate, { id: 'client_id', value: values.client }]
          : [],
        sorted: payloadReq.sorted,
      };

      billingNonFilterReportList('report-list', paramsReq).then((res) => {
        const newBlob = new Blob([res.payload.data]);
        fileDownload(newBlob, 'Unbilled-list.csv');
        this.setState({
          loading: false,
        });
        setFieldsValue({
          date: [],
        });
      });
    });
  }

  submitHandler() {
    // reportList('', 'report-list');
    const { billingNonFilterReportList, form } = this.props;
    const { validateFields } = form;
    const { payloadReq, tableFilter, handoverDate } = this.state;
    validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });
        const paramsReq = {
          export: false,
          page: payloadReq.page,
          pageSize: payloadReq.pageSize,
          filter: values.client
            ? [...handoverDate, { id: 'client_id', value: values.client }]
            : [...handoverDate],
          sorted: payloadReq.sorted,
        };
        if (tableFilter.length) {
          paramsReq.filter = paramsReq.filter.concat(tableFilter);
        }
        billingNonFilterReportList('report-list', paramsReq).then(res => this.setState({
            loading: false,
            userData: {
              rows: res.payload.data.rows,
              pages: res.payload.data.pages,
              total: res.payload.data.total,
            },
            payloadReq: paramsReq,
            searchFilter: values.client
              ? [...handoverDate, { id: 'client_id', value: values.client }]
              : [...handoverDate],
          }),);
      }
    });
  }

  dateHandler(dateStrings) {
    const newDateOne = dateStrings[0];
    const newDateTwo = dateStrings[1];
    const formatDateOne = format(newDateOne, 'YYYY-MM-DD HH:mm:ss');
    const formatDateTwo = format(newDateTwo, 'YYYY-MM-DD HH:mm:ss');
    const dateForm = `${formatDateOne}|${formatDateTwo}`;

    this.setState({
      handoverDate: [
        {
          id: 'package_created_at',
          value: formatDateOne !== 'Invalid Date' ? dateForm : '',
        },
      ],
    });
  }

  render() {
    const { loading, userData, client } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const breadCrumbs = [
      {
        breadcrumbName: 'Billing',
      },
      {
        breadcrumbName: 'Reports',
      },
    ];
    return (
      <div className="Reports">
        <PageHeader title="BILLING REPORTS" routes={breadCrumbs} />
        <Container>
          <Card>
            <Row>
              <Col xs={24} sm={12} lg={12}>
                <Form.Item label="Client">
                  {getFieldDecorator('client', {
                    rules: [
                      { required: false, message: 'Please insert value' },
                    ],
                    onChange: this.clientHandler,
                  })(
                    <ReactSelect
                      style={{ width: '100%' }}
                      placeholder="Select Client..."
                      options={client}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={12}>
                <Form.Item label="Date Range">
                  {getFieldDecorator('date', {
                    rules: [{ required: false, message: 'Please insert value' }],
                  })(
                    <DatePicker.RangePicker
                      onChange={e => this.dateHandler(e)}
                      showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [
                          moment('00:00', 'HH:mm'),
                          moment('00:00', 'HH:mm'),
                        ],
                      }}
                      style={{ width: '100%' }}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Button onClick={() => this.submitHandler()} type="primary" block>
              <Icon viewBox="0 0 1024 1024">
                <FontAwesomeIcon icon={faSearch} fixedWidth />
              </Icon>
              Apply
            </Button>
          </Card>
          <Spacer />
          <Card
            title={(
<Button onClick={() => this.submitHandlerExport()} type="danger">
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faFileExport} fixedWidth />
                </Icon>
                Export
              </Button>
)}
            extra={`Total: ${userData.total
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
          >
            <ReactTable
              data={userData.rows}
              pages={userData.pages}
              columns={[
                {
                  Header: 'Client',
                  accessor: 'client_name',
                },
                {
                  Header: 'Tracking Number',
                  accessor: 'tracking_number',
                },
                {
                  Header: 'Total Payment',
                  accessor: 'total_payment',
                },
                {
                  Header: 'Invoice Number',
                  accessor: 'invoice_number',
                },
                {
                  Header: 'Invoice Date',
                  accessor: 'invoice_date',
                },
                {
                  Header: 'Options',
                  sortable: false,
                  filterable: false,
                  Cell: row => (
                    <center>
                      <Button
                        type="link"
                        onClick={() => this.getEditAccountHandler(row.original)}
                      >
                        <Icon type="eye" />
                        View
                      </Button>
                    </center>
                  ),
                },
              ]}
              loadingText="Fetching user list. . ."
              loading={loading}
              onFetchData={this.onFetchDataFunc}
            />
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  billingReportTable: state.billing.dataReportList,
  options: state.options.options,
});

const mapDispatchToProps = {
  reportList: bList,
  billingNonFilterReportList: billingListView,
  waybillComponent: waybill,
  doRubixpickOptions: rubixpickOptions,
};

Reports.propTypes = {
  billingNonFilterReportList: PropTypes.func.isRequired,
  waybillComponent: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doRubixpickOptions: PropTypes.func.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const WrappedReports = Form.create({ name: 'Register' })(Reports);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedReports);
