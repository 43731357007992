import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Spin, Descriptions } from 'antd';
import PropTypes from 'prop-types';

import { unRemittedViewsAccount } from './ducks';

import PageHeader from '../../components/PageHeader';
import ReactTable from '../../components/ReactTable';
import Card from '../../components/Card';
import Container from '../../components/Container';
import Spacer from '../../components/Spacer';

export class ListViews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadSpin: false,
    };
  }

  componentDidMount() {
    this.setState({ loadSpin: true });
    const { urViews, match } = this.props;
    urViews(match.params.id).then(() => this.setState({ loadSpin: false }));
  }

  render() {
    const { loadSpin } = this.state;
    const { viewAccount } = this.props;

    return (
      <div className="ListView">
        <PageHeader title="VIEW COURIER-REMITTANCE" />
        <Container>
          <Card>
            <Spin spinning={loadSpin} tip="Loading Courier Remittance view...">
              <Descriptions bordered size="small">
                <Descriptions.Item label="Dispatch Number">
                  {viewAccount.remittance
                    ? viewAccount.remittance.dispatch_id
                    : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Type">
                  {viewAccount.dispatch ? viewAccount.dispatch.type : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Dispatch Date">
                  {viewAccount.dispatch
                    ? viewAccount.dispatch.dispatch_date
                    : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Driver">
                  {viewAccount.dispatch ? viewAccount.dispatch.driver : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Vehicle">
                  {viewAccount.dispatch
                    ? viewAccount.dispatch.vehicle_name
                    : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Date Remitted">
                  {viewAccount.remittance
                    ? viewAccount.remittance.remitted_date
                    : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Total Amount">
                  {viewAccount.remittance
                    ? `₱ ${viewAccount.remittance.total_amount
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                    : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Remitted Amount">
                  {viewAccount.remittance
                    ? `₱ ${viewAccount.remittance.remitted_amount
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                    : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Processed By">
                  {viewAccount.remittance
                    ? viewAccount.remittance.created_by
                    : ''}
                </Descriptions.Item>
              </Descriptions>
              <Spacer />
              <Button
                type="primary"
                block
                onClick={() => window.open(
                    `${process.env.REACT_APP_API_URL}/web/remittance/print/${viewAccount.remittance.id}`,
                  )
                }
              >
                Print
              </Button>
            </Spin>
          </Card>
          <Spacer />
          <Card
            extra={`Total: ${
              viewAccount.packages ? viewAccount.packages.length : 0
            }`}
          >
            <Spin spinning={loadSpin} tip="Loading Courier Remittance view...">
              <ReactTable
                data={viewAccount.packages}
                columns={[
                  {
                    Header: 'Tracking Number',
                    accessor: 'tracking_number',
                  },
                  {
                    Header: 'Handover Date',
                    accessor: 'handover_date',
                  },
                  {
                    Header: 'Consignee Name',
                    accessor: 'consignee_name',
                  },
                  {
                    Header: 'Payments Type',
                    accessor: 'payment_type',
                  },
                  {
                    Header: 'Declared Amount',
                    accessor: 'total_price',
                  },
                  {
                    Header: 'Remitted Amount',
                    accessor: 'amount',
                  },
                  {
                    Header: 'Status',
                    accessor: 'status',
                  },
                ]}
              />
            </Spin>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  viewAccount: state.courierRemittance.listViews,
});

const mapDispatchToProps = {
  urViews: unRemittedViewsAccount,
};

ListViews.propTypes = {
  viewAccount: PropTypes.oneOfType([PropTypes.object]).isRequired,
  urViews: PropTypes.func.isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListViews);
