import {
  remittanceCreate,
  dispatchTnumber,
  getOptionsDispatch,
  dispatchSend,
  customerPickupDispatch,
  sendAllCustomer,
  merchantPickupSendTrackingNumber,
  merchantWebPickup,
  remittanceCreateTrackingNumber,
  deleteDispatchList,
  dispatchPrint,
  remittance,
} from '../../services/api';

const REMITTANCE_FINAL = 'dispatch/REMITTANCE_FINAL';

export const finalRemittance = payloadData => ({
  type: REMITTANCE_FINAL,
  payload: {
    client: 'secure',
    request: {
      ...remittance(),
      data: payloadData,
    },
  },
});

const DISPATCH_PRINT = 'dispatch/DISPATCH_PRINT';

export const dispatchPrintID = dpId => ({
  type: DISPATCH_PRINT,
  payload: {
    client: 'secure',
    request: {
      ...dispatchPrint(dpId),
    },
  },
});

const DISPATCH_GETOPTIONS_COURIER = 'dispatch/DISPATCH_GETOPTIONS_COURIER';
const DISPATCH_GETOPTIONS_COURIER_SUCCESS = 'dispatch/DISPATCH_GETOPTIONS_COURIER_SUCCESS';

const DISPATCH_GETOPTIONS_VEHICLE = 'dispatch/DISPATCH_GETOPTIONS_VEHICLE';
const DISPATCH_GETOPTIONS_VEHICLE_SUCCESS = 'dispatch/DISPATCH_GETOPTIONS_VEHICLE_SUCCESS';

const DISPATCH_GETOPTIONS_OUTSOURCE = 'dispatch/DISPATCH_GETOPTIONS_OUTSOURCE';
const DISPATCH_GETOPTIONS_OUTSOURCE_SUCCESS = 'dispatch/DISPATCH_GETOPTIONS_OUTSOURCE_SUCCESS';

const DISPATCH_GETOPTIONS_MERCHANT = 'dispatch/DISPATCH_GETOPTIONS_MERCHANT';
const DISPATCH_GETOPTIONS_MERCHANT_SUCCESS = 'dispatch/DISPATCH_GETOPTIONS_MERCHANT_SUCCESS';

export const getOptions = (options) => {
  switch (options) {
    case 'courier':
      return {
        type: DISPATCH_GETOPTIONS_COURIER,
        payload: {
          client: 'secure',
          request: {
            ...getOptionsDispatch('courier'),
          },
        },
      };

    case 'vehicle':
      return {
        type: DISPATCH_GETOPTIONS_VEHICLE,
        payload: {
          client: 'secure',
          request: {
            ...getOptionsDispatch('vehicle'),
          },
        },
      };

    case 'outsource':
      return {
        type: DISPATCH_GETOPTIONS_OUTSOURCE,
        payload: {
          client: 'secure',
          request: {
            ...getOptionsDispatch('outsource'),
          },
        },
      };

    case 'merchant':
      return {
        type: DISPATCH_GETOPTIONS_MERCHANT,
        payload: {
          client: 'secure',
          request: {
            ...getOptionsDispatch('merchant'),
          },
        },
      };

    default:
      return false;
  }
};

const DISPATCH_ACCOUNT_VIEW = 'dispatch/DISPATCH_ACCOUNT_VIEW';
const DISPATCH_ACCOUNT_VIEW_SUCCESS = 'dispatch/DISPATCH_ACCOUNT_VIEW_SUCCESS';

export const dispatchNumber = dpId => ({
  type: DISPATCH_ACCOUNT_VIEW,
  payload: {
    client: 'secure',
    request: {
      ...remittanceCreate(dpId),
    },
  },
});

const DISPATCH_MERCHANT = 'dispatch/DISPATCH_MERCHANT';
const DISPATCH_MERCHANT_SUCCESS = 'dispatch/DISPATCH_MERCHANT_SUCCESS';

export const dispatchIdTrack = dpId => ({
  type: DISPATCH_MERCHANT,
  payload: {
    client: 'secure',
    request: {
      ...merchantWebPickup(dpId),
    },
  },
});

const DISPATCH_ENTERTRACKING_NUMBER = 'dispatch/DISPATCH_ENTERTRACKING_NUMBER';
const DISPATCH_ENTERTRACKING_NUMBER_SUCCESS = 'dispatch/DISPATCH_ENTERTRACKING_NUMBER_SUCCESS';
const DISPATCH_ENTERTRACKING_NUMBER_FAIL = 'dispatch/DISPATCH_ENTERTRACKING_NUMBER_FAIL';

export const dispatchEnterTnumber = (page, tnumber) => ({
  type: DISPATCH_ENTERTRACKING_NUMBER,
  payload: {
    client: 'secure',
    request: {
      ...dispatchTnumber(page, tnumber),
    },
  },
});

const DISPATCH_ENTERTRACKING_NUMBER_CUSTOMERPICKUP = 'dispatch/DISPATCH_ENTERTRACKING_NUMBER_CUSTOMERPICKUP';
const DISPATCH_ENTERTRACKING_NUMBER_CUSTOMERPICKUP_SUCCESS = 'dispatch/DISPATCH_ENTERTRACKING_NUMBER_CUSTOMERPICKUP_SUCCESS';
const DISPATCH_ENTERTRACKING_NUMBER_CUSTOMERPICKUP_FAIL = 'dispatch/DISPATCH_ENTERTRACKING_NUMBER_CUSTOMERPICKUP_FAIL';
// customerpickup
export const dispatchCustomerPickup = tnumber => ({
  type: DISPATCH_ENTERTRACKING_NUMBER_CUSTOMERPICKUP,
  payload: {
    client: 'secure',
    request: {
      ...customerPickupDispatch(tnumber),
    },
  },
});

const DISPATCH_ENTERTRACKING_NUMBER_MERCHANTPICKUP = 'dispatch/DISPATCH_ENTERTRACKING_NUMBER_MERCHANTPICKUP';
const DISPATCH_ENTERTRACKING_NUMBER_MERCHANTPICKUP_SUCCESS = 'dispatch/DISPATCH_ENTERTRACKING_NUMBER_MERCHANTPICKUP_SUCCESS';
const DISPATCH_ENTERTRACKING_NUMBER_MERCHANTPICKUP_FAIL = 'dispatch/DISPATCH_ENTERTRACKING_NUMBER_MERCHANTPICKUP_FAIL';
// customerpickup
export const dispatchMerchantPickup = (tnumber, shipperID) => ({
  type: DISPATCH_ENTERTRACKING_NUMBER_MERCHANTPICKUP,
  payload: {
    client: 'secure',
    request: {
      ...merchantPickupSendTrackingNumber(tnumber, shipperID),
      data: {
        shipper_id: shipperID,
      },
    },
  },
});

const DISPATCH = 'dispatch/DISPATCH';

export const dispatchSendAccount = dataDispatch => ({
  type: DISPATCH,
  payload: {
    client: 'secure',
    request: {
      ...dispatchSend,
      data: dataDispatch,
    },
  },
});

const DISPATCH_CUSTOMERPICKUP = 'dispatch/DISPATCH_CUSTOMERPICKUP';

export const dispatchCustomerPickupSubmit = (page, dataDispatch) => ({
  type: DISPATCH_CUSTOMERPICKUP,
  payload: {
    client: 'secure',
    request: {
      ...sendAllCustomer(page),
      data: dataDispatch,
    },
  },
});

const DELETE_DISPATCH_LIST = 'dispatch/DELETE_DISPATCH_LIST';

export const deleteListDispatch = dhNumber => ({
  type: DELETE_DISPATCH_LIST,
  payload: {
    client: 'secure',
    request: {
      ...deleteDispatchList(dhNumber),
    },
  },
});

const CREATE_REMITTANCE_TRACKINGNUMBER = 'dispatch/CREATE_REMITTANCE_TRACKINGNUMBER';
const CREATE_REMITTANCE_TRACKINGNUMBER_SUCCESS = 'dispatch/CREATE_REMITTANCE_TRACKINGNUMBER_SUCCESS';

export const createRemittanceTrackingNumber = (dhNumber, trackingNumber) => ({
  type: CREATE_REMITTANCE_TRACKINGNUMBER,
  payload: {
    client: 'secure',
    request: {
      ...remittanceCreateTrackingNumber(dhNumber, trackingNumber),
    },
  },
});

const initialState = {
  options: {
    courier: [],
    vehicle: [],
    outsource: [],
    merchant: [],
  },
  views: {},
  dispatchViews: [],
  error: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case DISPATCH_ACCOUNT_VIEW_SUCCESS:
      return {
        ...state,
        views: action.payload.data,
      };

    case DISPATCH_MERCHANT_SUCCESS:
      return {
        ...state,
        views: action.payload.data,
      };

    case DISPATCH_ENTERTRACKING_NUMBER_SUCCESS:
      return {
        ...state,
        dispatchViews: [...state.dispatchViews, action.payload.data],
      };

    case DISPATCH_ENTERTRACKING_NUMBER_CUSTOMERPICKUP_SUCCESS:
      return {
        ...state,
        dispatchViews: [...state.dispatchViews, action.payload.data],
      };

    case DISPATCH_ENTERTRACKING_NUMBER_MERCHANTPICKUP_SUCCESS:
      return {
        ...state,
        dispatchViews: [...state.dispatchViews, action.payload.data],
      };

    case DISPATCH_ENTERTRACKING_NUMBER_FAIL:
      return {
        ...state,
        error: [...state.error, action.error.response.data],
      };

    case DISPATCH_ENTERTRACKING_NUMBER_CUSTOMERPICKUP_FAIL:
      return {
        ...state,
        error: [...state.error, action.error.response.data],
      };

    case DISPATCH_ENTERTRACKING_NUMBER_MERCHANTPICKUP_FAIL:
      return {
        ...state,
        error: [...state.error, action.error.response.data],
      };

    case DISPATCH_GETOPTIONS_COURIER_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          courier: action.payload.data,
        },
      };

    case DISPATCH_GETOPTIONS_VEHICLE_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          vehicle: action.payload.data,
        },
      };

    case DISPATCH_GETOPTIONS_OUTSOURCE_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          outsource: action.payload.data,
        },
      };

    case DISPATCH_GETOPTIONS_MERCHANT_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          merchant: action.payload.data,
        },
      };

    case CREATE_REMITTANCE_TRACKINGNUMBER_SUCCESS:
      return {};

    default:
      return state;
  }
}

export default reducer;
