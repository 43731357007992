import {
  apiFetchShipperDetails,
  apiFetchShipperOptionsLike,
  apiFetchShipperOptions,
} from '../../services/apiEndpoints';

const FETCH_SHIPPER_DETAILS = 'shipper/FETCH_SHIPPER_DETAILS';
export const fetchShipperDetails = id => ({
  type: FETCH_SHIPPER_DETAILS,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchShipperDetails(id),
    },
  },
});

const FETCH_SHIPPER_OPTIONS_LIKE = 'shipper/FETCH_SHIPPER_OPTIONS_LIKE';
export const fetchShipperOptionsLike = string => ({
  type: FETCH_SHIPPER_OPTIONS_LIKE,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchShipperOptionsLike(string),
    },
  },
});

const FETCH_SHIPPER_OPTIONS = 'shipper/FETCH_SHIPPER_OPTIONS';
export const fetchShipperOptions = () => ({
  type: FETCH_SHIPPER_OPTIONS,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchShipperOptions,
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
