import React from 'react';
import Proptypes from 'prop-types';

const Container = ({ children }) => <div className="container">{children}</div>;

Container.propTypes = {
  children: Proptypes.node.isRequired,
};

export default Container;
